import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import { DocumentNode } from 'graphql';
import Button from '@material-ui/core/Button';
import _ from 'lodash';

import './delivery.assign.scss';
import {
	GET_DELIVERY_REQS,
	GET_DELIVERY_REQS_EXPRESS,
	GET_DELIVERY_REQS_OUTSIDE,
	GET_DELIVERY_REQS_WAITING,
	GET_DELIVERY_REQS_RETURN_CUSTOMER,
} from '../../graphql/queries/delivery.requests';
import useMessage from '../../hooks/useMessage';
import DeliveryReqCard from '../deliveryReqTable/DeliveryReqCard';
import DeliveryReqTable from '../deliveryReqTable/DeliveryReqTable';
import Layout from '../layout/Layout';
import { DeliveryReqs } from '../../context/contextTypes';
import useDelivery from '../../hooks/useDelivery';
import { SelectLabel } from '../../screens/bulkScreen/BulkScreen';
import {
	FormControl,
	RadioGroup,
	Grid,
	FormControlLabel,
	Radio,
} from '@material-ui/core';
import { isToday, isYesterday, isBefore, addDays } from 'date-fns';
import {
	countSelected,
	getSelected,
	PRIORITY_STATUS,
} from '../../helper/helper';
import useRiders from '../../hooks/useRiders';

export type DelType =
	| 'express'
	| 'outside_dhaka'
	| 'regular'
	| 'waiting'
	| 'return';

interface Props {
	deliveryType: DelType;
	title: string;
}

export interface DeliveryReqSelect {
	id: string;
	index: number;
	type: 'ADD_ALL' | 'ADD_ONE' | 'REMOVE_ALL' | 'REMOVE_ONE';
}

const delType: { [index: string]: DocumentNode } = {
	express: GET_DELIVERY_REQS_EXPRESS,
	outside_dhaka: GET_DELIVERY_REQS_OUTSIDE,
	regular: GET_DELIVERY_REQS,
	waiting: GET_DELIVERY_REQS_WAITING,
	return: GET_DELIVERY_REQS_RETURN_CUSTOMER,
};

const DeliveryAssign: React.FC<Props> = ({ deliveryType, title }) => {
	const isWaiting = deliveryType === 'waiting';

	const isRegular = deliveryType === 'regular';

	const isExpress = deliveryType === 'express';

	const isReturn = deliveryType === 'return';

	const areaRef = useRef() as any;

	const brandRef = useRef() as any;

	const riderRef = useRef() as any;

	const riderSortRef = useRef() as any;

	const statusRef = useRef() as any;

	const [areas, setAreas] = useState<string[] | null>(null);

	const [areaOpen, setAreaOpen] = useState(false);

	const [dataLoaded, setDataLoaded] = useState(false);

	const [statusSort, setStatusSort] = useState<string[] | null>(null);

	const [delReqsSort, setDelReqsSort] = useState<DeliveryReqs[]>([]);

	const [delReqs, setDelReqs] = useState<DeliveryReqs[]>([]);

	const [status, setStatus] = useState<string[] | null>(null);

	const [color, setColor] = useState<'red' | 'yellow' | 'blue' | 'clear'>(
		'clear'
	);

	const [brands, setBrands] = useState<SelectLabel[] | null>(null);

	const [brand, setBrand] = useState<SelectLabel | null>(null);

	const [area, setArea] = useState<string[] | null>(null);

	const [rider, setRider] = useState<string | null>(null);

	const [riderSort, setRiderSort] = useState<string | null>(null);

	const [isSetAll, setIsSetAll] = useState(false);

	const { ridersData, ridersLoading } = useRiders('rider', { all: isReturn });

	const { handleNotification } = useMessage();

	const { handleAssign, isLoading, handleStatusChange } = useDelivery();

	const POLL_INTERVAL_IN_MIN = 15;

	// @queries

	const { loading, data, error, refetch } = useQuery(delType[deliveryType], {
		pollInterval: 1000 * 60 * POLL_INTERVAL_IN_MIN,
	});

	const reset = () => {
		if (!delReqs || !delReqsSort) return;

		setDataLoaded(false);

		setArea(null);
		setBrand(null);
		setRider(null);
		setRiderSort(null);
		setStatusSort(null);
		setIsSetAll(false);

		setDelReqs((old) => {
			if (old) {
				return old.map((d) => ({ ...d, selected: false }));
			}

			return old;
		});

		setDelReqsSort((old) => {
			if (old) {
				return old.map((d) => ({ ...d, selected: false }));
			}

			return old;
		});

		if (areaRef.current) areaRef.current.state.value = null;
		if (brandRef.current) brandRef.current.state.value = null;
		if (riderRef.current) riderRef.current.state.value = null;
		if (riderSortRef.current) riderSortRef.current.state.value = null;
		if (statusRef.current) statusRef.current.state.value = null;
	};

	const dispatcher = (show: DeliveryReqs[]) => {
		setDelReqs(show);
	};

	const setSortSelect = (ids: string[]) => {
		const dataRef = [...delReqsSort];

		ids.forEach((id) => {
			const foundIndex = dataRef.findIndex((del) => id === del.node.objectId);

			const found = foundIndex !== -1;

			if (found) {
				const isSelected = dataRef[foundIndex].selected;

				if (isSelected) {
					dataRef[foundIndex].selected = false;
				}

				if (!isSelected) {
					dataRef[foundIndex].selected = true;
				}
			}
		});

		setDelReqsSort([...dataRef]);
	};

	const handleSelect = ({ type, index, id }: DeliveryReqSelect) => {
		if (!delReqs) return;

		let dataRef = [...delReqs];

		switch (type) {
			case 'ADD_ONE':
				setSortSelect([id]);

				dataRef[index].selected = true;

				break;
			case 'ADD_ALL':
				setSortSelect(getSelected(dataRef));

				dataRef.forEach((_d, i) => {
					dataRef[i].selected = true;
				});

				break;
			case 'REMOVE_ONE':
				if (isSetAll) setIsSetAll(false);

				setSortSelect([id]);

				dataRef[index].selected = false;

				break;
			case 'REMOVE_ALL':
				setSortSelect(getSelected(dataRef));

				dataRef.forEach((_d, i) => {
					dataRef[i].selected = false;
				});

				break;
			default:
				break;
		}

		dispatcher([...dataRef]);
	};

	const findFromSortAndSelect = () => {
		const dataSortRef = [...delReqsSort];

		const dataRef = [...delReqs];

		dataRef.forEach((d) => {
			if (d.selected) {
				const index = dataSortRef.findIndex(
					(ref) => ref.node.objectId === d.node.objectId
				);

				const found = index !== -1;

				if (found) {
					dataSortRef[index].selected = true;
				}
			}
		});

		setDelReqs(dataSortRef);
	};

	const handleCheckAll = () => {
		if (isSetAll) {
			handleSelect({ type: 'REMOVE_ALL', index: 0, id: '' });
		} else {
			handleSelect({ type: 'ADD_ALL', index: 0, id: '' });
		}

		setIsSetAll((old) => !old);
	};

	const resetCheckAll = () => setIsSetAll(false);

	// @memos

	const temp = useMemo(() => {
		const hasNotChanged = delReqsSort?.length === delReqs.length;

		if (hasNotChanged) return;

		const brandParcelCount = _.countBy([...delReqs], 'node.brand.name');

		return Object.keys(brandParcelCount).map((value) => {
			return (
				<li
					className='anim-card'
					key={value}>{`${value} (${brandParcelCount[value]})`}</li>
			);
		});
	}, [delReqs, delReqsSort]);

	useEffect(() => {
		if (data) {
			let dataSet: DeliveryReqs[] = data['parcels']['edges'];

			if (!areas) {
				setAreas(_.uniq(dataSet.map(({ node }) => node.area)));
			}

			if (!status) {
				setStatus(_.uniq(dataSet.map(({ node }) => node.status)));
			}

			if (!brands) {
				const brandParcelCount = _.countBy(dataSet, 'node.brand.name');

				setBrands(
					Object.keys(brandParcelCount).map((value) => {
						return {
							label: `${value} (${brandParcelCount[value]})`,
							value,
						};
					})
				);
			}

			if (isExpress) {
				let modified = [];

				modified = dataSet
					.map((data) => {
						return { priority: PRIORITY_STATUS[data.node.status], data };
					})
					.sort((a, b) => a.priority - b.priority);

				dataSet = modified.map(({ data }) => data);
			}

			// compound

			const isNotClear = color !== 'clear';

			const isFilterActive =
				brand || riderSort || area || statusSort || isNotClear;

			if (isFilterActive) {
				resetCheckAll();

				let nodeObj: any = {};

				let filtered: DeliveryReqs[] = [...delReqsSort];

				if (area) {
					filtered = filtered.filter(({ node }) => area.includes(node.area));
				}

				if (statusSort) {
					filtered = filtered.filter(({ node }) =>
						statusSort.includes(node.status)
					);
				}

				if (brand) {
					nodeObj = {
						...nodeObj,
						brand: { name: brand.value },
					};
				}

				if (riderSort) {
					nodeObj = {
						...nodeObj,
						deliveryRider: {
							objectId: riderSort,
						},
					};
				}

				if (color === 'clear') {
					console.log(filtered);
				}

				if (isNotClear) {
					const filter = filtered.filter(({ node }) => {
						const _isToday = isToday(new Date(node.pickedupAt));

						const _isYesterday = isYesterday(new Date(node.pickedupAt));

						const _isOld = isBefore(
							new Date(node.pickedupAt),
							addDays(new Date(), 2)
						);

						const _color: 'red' | 'blue' | 'yellow' | 'clear' = _isToday
							? 'blue'
							: _isYesterday
							? 'yellow'
							: _isOld
							? 'red'
							: 'clear';

						return _color === color;
					});

					filtered = _.sortBy(filter, ['node.delivery_type']);
				}

				setDelReqs(
					_.filter(filtered, {
						node: nodeObj,
					})
				);

				return;
			}

			if (dataLoaded) {
				findFromSortAndSelect();
				resetCheckAll();
				return;
			}

			const dataRef = dataSet.map((data) => ({ ...data, selected: false }));

			// normal

			setDelReqs(dataRef);

			setDelReqsSort(dataRef);

			setDataLoaded(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		data,
		brand,
		area,
		riderSort,
		statusSort,
		color,
		isExpress,
		areas,
		brands,
		status,
	]);

	useEffect(() => {
		if (color !== 'clear') return;

		const isEqual = countSelected(delReqs) === delReqsSort.length;

		if (isEqual) setIsSetAll(true);
	}, [color, delReqsSort, delReqs]);

	useEffect(() => {
		if (error) {
			handleNotification({
				variant: 'standard',
				message: error.message,
				type: 'error',
			});
		}
	}, [error, handleNotification]);

	return (
		<Layout className='deliveryAssign' loading={loading} title={title}>
			<div className='deliveryAssign__container anim-frame rounded shadow-lg'>
				<div className='deliveryAssign__assign'>
					<div className='deliveryAssign__assign__each'>
						<div className='deliveryAssign__assign__each__title'>
							<h5>Area:</h5>
						</div>
						<div className='deliveryAssign__assign__each__select'>
							<Select
								onBlur={() => {
									setAreaOpen(false);
								}}
								ref={areaRef}
								className='deliveryAssign__search'
								isClearable
								isSearchable
								isMulti
								menuIsOpen={areaOpen ? true : undefined}
								isLoading={loading}
								onChange={(v) => {
									// areaRef.current.state
									const list: SelectLabel[] = Array.from(v);

									if (list.length === 0) {
										setArea(null);
										setAreaOpen(false);
										return;
									}

									if (!areaOpen) {
										setAreaOpen(true);
									}
									setArea(list.map((l) => l.value));
								}}
								placeholder='Select Zone....'
								options={areas?.map((area: string) => {
									return {
										label: area,
										value: area,
									};
								})}
							/>
						</div>
					</div>
					<div className='deliveryAssign__assign__each'>
						<div className='deliveryAssign__assign__each__title'>
							<h5>Brand:</h5>
						</div>
						<div className='deliveryAssign__assign__each__select'>
							<Select
								ref={brandRef}
								className='deliveryAssign__search'
								isClearable
								isSearchable
								isLoading={loading}
								value={brand}
								onChange={(value) => setBrand(value)}
								placeholder='Select Brand...'
								options={brands ? brands : undefined}
							/>
						</div>
					</div>

					{!isWaiting && (
						<>
							<div className='deliveryAssign__assign__each'>
								<div className='deliveryAssign__assign__each__title'>
									<h5>Rider Sort :</h5>
								</div>
								<div className='deliveryAssign__assign__each__select'>
									<Select
										ref={riderSortRef}
										className='deliveryAssign__search'
										isClearable
										isSearchable
										isLoading={ridersLoading}
										onChange={(value) => setRiderSort(value?.value as string)}
										placeholder='Select Rider....'
										options={ridersData.map(({ objectId, username }) => {
											return {
												label: username,
												value: objectId,
											};
										})}
									/>
								</div>
							</div>

							<div className='deliveryAssign__assign__each'>
								<div className='deliveryAssign__assign__each__title'>
									<h5> Status:</h5>
								</div>
								<div className='deliveryAssign__assign__each__select'>
									<Select
										ref={statusRef}
										className='deliveryAssign__search'
										isClearable
										isSearchable
										isMulti
										isLoading={loading}
										onChange={(v) => {
											const list: SelectLabel[] = Array.from(v);

											if (list.length === 0) {
												setStatusSort(null);
												return;
											}

											setStatusSort(list.map((l: SelectLabel) => l.value));
										}}
										placeholder='Select status....'
										options={status?.map((area: string) => {
											return {
												label: area,
												value: area,
											};
										})}
									/>
								</div>
							</div>
						</>
					)}
					{isRegular && (
						<div className='deliveryAssign__assign__each'>
							<div className='deliveryAssign__assign__each__title'>
								<h5> Color:</h5>
							</div>
							<div
								className='deliveryAssign__assign__each__select'
								style={{ transform: 'translateY(-1.5rem)' }}>
								<FormControl component='fieldset'>
									<RadioGroup
										aria-label='color'
										name='color'
										value={color}
										onChange={(e) => {
											setIsSetAll(false);
											setColor(e.target.value as 'red');
										}}>
										<Grid>
											<FormControlLabel
												value='blue'
												control={<Radio color='primary' />}
												label='Blue'
											/>
											<FormControlLabel
												value='yellow'
												control={<Radio color='primary' />}
												label='Yellow'
											/>
											<FormControlLabel
												value='red'
												control={<Radio color='primary' />}
												label='Red'
											/>
											<FormControlLabel
												value={'clear'}
												control={<Radio color='primary' />}
												label='clear'
											/>
										</Grid>
									</RadioGroup>
								</FormControl>
							</div>
						</div>
					)}

					{isWaiting ? (
						<>
							<div className='deliveryAssign__assign__each btn'>
								<Button
									onClick={() => {
										handleStatusChange({
											objectIds: getSelected(delReqsSort),
											status: 'rejected',
											refetch,
											reset,
										});
									}}
									fullWidth
									variant='outlined'
									color='primary'
									disabled={isLoading}>
									Reject <strong> ({countSelected(delReqsSort)})</strong>
								</Button>
							</div>
							<div className='deliveryAssign__assign__each btn'>
								<Button
									onClick={() =>
										handleStatusChange({
											objectIds: getSelected(delReqsSort),
											status: 'picked_up',
											refetch,
											reset,
										})
									}
									fullWidth
									variant='contained'
									color='primary'
									disabled={isLoading}>
									Pickedup <strong> ({countSelected(delReqsSort)})</strong>
								</Button>
							</div>
						</>
					) : (
						<>
							<div className='deliveryAssign__assign__each'>
								<div className='deliveryAssign__assign__each__title'>
									<h5> Rider:</h5>
								</div>
								<div className='deliveryAssign__assign__each__select'>
									<Select
										ref={riderRef}
										className='deliveryAssign__search'
										isClearable
										isSearchable
										isLoading={ridersLoading}
										onChange={(value) => setRider(value?.value as string)}
										placeholder='Assign Rider....'
										options={ridersData.map(({ objectId, username }) => {
											return {
												label: username,
												value: objectId,
											};
										})}
									/>
								</div>
							</div>
							<div className='deliveryAssign__assign__each btn'>
								<Button
									fullWidth
									variant='contained'
									color='primary'
									disabled={isLoading}
									onClick={() =>
										handleAssign({
											rider,
											objectIds: getSelected(delReqsSort),
											refetch,
											reset,
											funcName: isReturn
												? 'assignReturnRider'
												: 'assignDeliveryRider',
										})
									}>
									Assign <strong> ({countSelected(delReqsSort)})</strong>
								</Button>
							</div>
						</>
					)}
				</div>

				{isExpress && <ul className='deliveryAssign__temp'>{temp}</ul>}

				<div className='deliveryAssign__brandTable'>
					<DeliveryReqTable
						deliveryReqs={delReqs}
						deliveryType={deliveryType}
						isSetAll={isSetAll}
						handleCheck={handleCheckAll}
						handleSelect={handleSelect}
					/>
				</div>

				<div className='deliveryAssign__brandCard'>
					<div className='check'>
						<input
							type='checkbox'
							name='check-all'
							checked={isSetAll}
							onChange={handleCheckAll}
						/>
						<span>Select All</span>
					</div>
					{delReqs?.map((reqs, i) => (
						<DeliveryReqCard
							index={i}
							handleSelect={handleSelect}
							reqs={reqs}
							key={i}
						/>
					))}
				</div>
			</div>
		</Layout>
	);
};

export default DeliveryAssign;
