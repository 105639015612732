import React from 'react';
import styled, { keyframes } from 'styled-components';
import TransitionApp from '../transition/TransitionApp';

interface Props {
	open: boolean;
	closeModal: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const Modal: React.FC<Props> = ({ children, open, closeModal }) => {
	return (
		<TransitionApp className='modal' show={open}>
			<ModalOverLay onClick={closeModal}>
				<MainModal>{children}</MainModal>
			</ModalOverLay>
		</TransitionApp>
	);
};

export default Modal;

const scale = keyframes`

    from {
      transform: scale(0);
		opacity: 0;


    }

    to {
        transform: scale(1);
		opacity: 1;

    }

`;

const ModalOverLay = styled.div`
	background-color: hsla(0, 0%, 10%, 0.4);
	padding: 0 1rem;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000000;

	&.modal-enter {
		opacity: 0;
	}
	&.modal-enter-active {
		opacity: 1;
		transition: opacity 200ms;
	}
	&.modal-exit {
		opacity: 1;
	}
	&.modal-exit-active {
		opacity: 0;
		transition: opacity 200ms;
	}
`;

const MainModal = styled.main`
	background: hsl(0, 1000%, 100%);
	border-radius: 6px;
	padding: 1rem;
	box-shadow: 0 0px 15px 4px hsla(0, 0%, 0%, 0.2);
	animation: ${scale} 0.3s ease 0.2s forwards;
	min-height: 300px;
	max-width: 100%;
	transform: scale(0);
	opacity: 0;
`;
