import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';

interface Props {
	regex: RegExp;
	helperTxt: string;
	placeholder?: string;
	label?: string;
	type: string;
	value: string;
	setValue: React.Dispatch<React.SetStateAction<string>>;
	setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
	multiline?: boolean;
	onBlur?:
		| React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
		| undefined;
	fullWidth?: boolean;
	rowSpan?: number;
	disabled?: boolean;
	size?: 'small' | 'medium' | undefined;
	focused?: boolean;
}

const CustomInput: React.FC<Props> = ({
	regex,
	helperTxt,
	placeholder,
	label,
	type,
	value,
	setValue,
	setDisabled,
	multiline,
	onBlur,
	fullWidth,
	rowSpan,
	size,
	disabled,
	focused,
}) => {
	const REGEX = regex;

	const [isError, setIsError] = useState(false);

	const [helperText, setHelperText] = useState('');

	useEffect(() => {
		switch (REGEX.test(value)) {
			case true:
				setIsError(false);
				setDisabled(false);
				setHelperText('');
				break;
			case false:
				if (value === '') {
					setIsError(false);
					setHelperText('');
				} else {
					setIsError(true);
					setHelperText(helperTxt);
				}
				setDisabled(true);

				break;
		}
	}, [value, REGEX]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	};

	return (
		<TextField
			focused={focused}
			InputProps={{ inputProps: { min: 0 } }}
			size={size}
			onBlur={onBlur}
			margin='normal'
			type={type}
			value={value}
			onChange={handleChange}
			error={isError}
			helperText={helperText}
			variant='outlined'
			color='primary'
			label={label}
			placeholder={placeholder}
			multiline={multiline}
			fullWidth={fullWidth}
			rows={rowSpan}
			disabled={disabled}
		/>
	);
};

export default CustomInput;
