import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import useGTM from '@elgorditosalsero/react-gtm-hook';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Button from '@material-ui/core/Button';
import './global.scss';
import { theme } from './theme/theme';
import AppRoutes from './AppRoutes';
import ScrollToTop from 'react-scroll-up';
import { restClient } from './config/client';

const HOST = 'localhost:3000';
const REACT_APP_REST_URI = process.env.REACT_APP_REST_URI;
const toWarn =
	REACT_APP_REST_URI === 'https://api.now.com.bd/parse' &&
	window.location.host === HOST;

const App: React.FC = () => {
	const { init, UseGTMHookProvider } = useGTM();

	useEffect(() => {
		init({ id: process.env.REACT_APP_GTM_ID || 'GTM-PMTPSD3' });
	}, [init]);

	return (
		<UseGTMHookProvider>
			<QueryClientProvider client={restClient}>
				<ThemeProvider theme={theme}>
					<StylesProvider injectFirst>
						<div className={`App ${toWarn && 'warn'}`}>
							<Router>
								<AppRoutes />
							</Router>
							<ScrollToTop showUnder={160}>
								<Button
									color='primary'
									variant='contained'
									startIcon={<ArrowUpwardIcon />}>
									UP
								</Button>
							</ScrollToTop>
						</div>
					</StylesProvider>
				</ThemeProvider>
			</QueryClientProvider>
		</UseGTMHookProvider>
	);
};

export default App;
