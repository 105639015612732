import {
	format,
	differenceInHours,
	isBefore,
	startOfDay,
	startOfToday,
	addHours,
	addDays,
} from 'date-fns';

export interface TimeArray {
	date: Date;
	morning: string | null;
	evening: string | null;
}

let today = new Date();

const MAX_PICK_UP_DAY_TO_SHOW = 3;

const THRESHOLD_IN_HOUR = 1;

export const timeSlotMaker = (isAdmin?: boolean): TimeArray[] => {
	let timeObj = {
		date: format(today, 'dd-MM-yyyy'),
		morning: '7:00 - 9:00 AM',
		evening: '4:00 - 10:00 PM',
	};

	const SLOT = 2;

	const MORNING = 7;

	const EVENING = 16;

	let timeArray: TimeArray[] = [];

	const sevenAM = addHours(startOfDay(today), MORNING);

	const threePM = addHours(startOfDay(today), EVENING);

	const isSevenPossible: boolean =
		Math.abs(differenceInHours(sevenAM, today)) >= THRESHOLD_IN_HOUR &&
		isBefore(today, sevenAM);

	const isFourPossible: boolean =
		Math.abs(differenceInHours(threePM, today)) >= THRESHOLD_IN_HOUR &&
		isBefore(today, threePM);

	const startOfTheDay = new Date(startOfToday());

	Array(MAX_PICK_UP_DAY_TO_SHOW - 1)
		.fill({})
		.forEach((_, dayIndex) => {
			Array(SLOT)
				.fill({})
				.forEach((_, slotIndex) => {
					timeArray.push({
						date: addDays(
							addHours(startOfTheDay, slotIndex === 0 ? MORNING : EVENING),
							dayIndex + 1
						),
						morning: slotIndex === 0 ? timeObj.morning : null,
						evening: slotIndex === 1 ? timeObj.evening : null,
					});
				});
		});

	if (isAdmin) {
		Array(SLOT)
			.fill({})
			.forEach((_, slotIndex) => {
				timeArray.unshift({
					date: addHours(startOfTheDay, slotIndex === 0 ? MORNING : EVENING),
					morning: slotIndex === 0 ? timeObj.morning : null,
					evening: slotIndex === 1 ? timeObj.evening : null,
				});
			});

		return timeArray;
	}

	if (!isFourPossible) {
		Array(SLOT)
			.fill({})
			.forEach((_, slotIndex) => {
				timeArray.push({
					date: addDays(
						addHours(startOfTheDay, slotIndex === 0 ? MORNING : EVENING),
						MAX_PICK_UP_DAY_TO_SHOW
					),
					morning: slotIndex === 0 ? timeObj.morning : null,
					evening: slotIndex === 1 ? timeObj.evening : null,
				});
			});
	}

	if (!isSevenPossible && isFourPossible) {
		timeArray.unshift({
			date: addHours(startOfTheDay, EVENING),
			morning: null,
			evening: timeObj.evening,
		});
	}

	if (isSevenPossible && isFourPossible) {
		Array(SLOT)
			.fill({})
			.forEach((_, slotIndex) => {
				timeArray.unshift({
					date: addHours(startOfTheDay, slotIndex === 0 ? MORNING : EVENING),
					morning: slotIndex === 0 ? timeObj.morning : null,
					evening: slotIndex === 1 ? timeObj.evening : null,
				});
			});
	}

	return timeArray;
};
