import { gql } from '@apollo/client';

export const SENT_NOTIFICATION = gql`
mutation createANotice($text: String!) {
  createNotice_board(
    input: {
      fields: {
        text: $text
      }
    }
  ) {
    notice_board {
      id
      updatedAt
      createdAt
      text
      visible
    }
  }
}
`;

export const UPDATE_NOTIFICATION = gql`
mutation updateNotice($id: ID!, $visible: Boolean!){
  updateNotice_board(
    input:{
      id:$id
      fields:{
        visible: $visible
      }
    }
  ){
    notice_board{
      objectId
      text
      visible
    }
  }
}
`

export const DELETE_NOTIFICATION = gql`
  mutation deleteNotice($id: ID!){
  deleteNotice_board(input:{id:$id}){
    notice_board{
      objectId
      text
    }
  }
}
`