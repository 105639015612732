/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { format, isWithinInterval } from 'date-fns';

import useMessage from './useMessage';
import { useStore } from '../context/Context';
import { instanceOfAxios } from '../helper/axios';
import { useHistory } from 'react-router-dom';
import { Cost, ODRE, OffDays } from '../context/contextTypes';
import { dateMake, errorMessage } from '../helper/helper';
import { TimeArray, timeSlotMaker } from '../helper/pickUpArray';
import { useMutation } from '@apollo/client';
import { UPDATE_PARCEL_DELIVERY_CHARGE_BY_ID } from '../graphql/mutations/parcel';

export interface Zone {
	node: Node;
}
export interface Node {
	city: string;
	areas?: AreasEntity[] | null;
}
export interface AreasEntity {
	__typename: string;
	value: string;
}

export interface Note {
	by: string;
	when?: Date;
	text: string;
}

export interface Parcel {
	amount: number;
	address: string;
	customer_name: string;
	is_express: boolean;
	notes?: Note[] | null;
	weight: number;
	customer_phone: string;
	pickup_cash: boolean;
	zone: string;
	parcel_type: string;
	requestedSlot:
		| {
				__type: 'Date';
				iso: string;
		  }
		| string;
	moid: string;
	description: string;
	area: string;
	delivery_type: string;
	geolocation?: { latitude: number; longitude: number; __type: 'GeoPoint' };
	meta?: any;
	pickup_location?: string;
}

export interface Data {
	result: Result;
}
export interface Result {
	cost: Cost;
	scheme: Scheme;
	params: Params;
}

export interface Scheme {
	agreement: string;
	pricing: Pricing;
}
export interface Pricing {
	price_unit: number;
	extra_per_unit: number;
	cod_charge: number;
}
export interface Params {
	weight: number;
	delivery_type: string;
	parcel_type: string;
	zone: string;
	amount: number;
	pickup_cash: boolean;
}

export interface Estimation {
	weight: number;
	delivery_type: string;
	parcel_type: string;
	zone: string;
	amount: number;
	pickup_cash: boolean;
	brandId: string | null;
}

export interface DateSel {
	label: string;
	value: Date;
}

export const TIME_MAKER = timeSlotMaker();

const useParcel = () => {
	const { handleNotification } = useMessage();

	const { replace } = useHistory();

	const {
		store: { user, brand, store, settings },
		dispatch,
	} = useStore();

	const [loading, setLoading] = useState(false);

	const [eiditLoading, setEiditLoading] = useState(false);

	const [slot, setSlot] = useState<string | null | undefined>(null);

	const [slotSelect, setSlotSelect] = useState<DateSel | null>();

	const { location } = useHistory();

	const query = new URLSearchParams(location.search);

	const isEditable = query.get('edit');

	const moment = brand?.node.pickup_time;

	const timeMaker = TIME_MAKER.reduce(
		(slotCollection: DateSel[], slot: TimeArray) => {
			if (moment) {
				if (slot[moment]) {
					slotCollection.push({
						value: slot.date,
						label: `${format(slot.date, 'do LLLL')} ${slot[moment]}`,
					});
				}
			}

			return slotCollection;
		},
		[]
	);

	const timeMakerAdmin = timeSlotMaker(true).map((slot) => {
		if (slot.morning) {
			return {
				value: slot.date,
				label: `${format(slot.date, 'do LLLL')} ${slot.morning}`,
			};
		}

		return {
			value: slot.date,
			label: `${format(slot.date, 'do LLLL')} ${slot.evening}`,
		};
	});

	const offDays = useMemo(() => {
		let main: { objectId: string | null; value: OffDays | null } = {
			objectId: null,
			value: null,
		};

		settings?.configs.edges.forEach(({ node }) => {
			if (node.key === 'offdays') {
				main = {
					objectId: node.objectId,
					value: node.value,
				};
			}
		});

		return main;
	}, [settings]);

	const checkTypeDisabled = useMemo(() => {
		let main: { [index: string]: boolean } = {
			outside_dhaka: false,
			dhaka_24hrs: false,
			regular: false,
			express: false,
		};

		if (isEditable) return main;

		if (offDays.objectId && offDays.value) {
			Object.keys(offDays.value).forEach((key) => {
				const mutated: any = offDays;

				mutated.value[key].forEach((data: ODRE) => {
					const from = dateMake(data.from.iso);

					const to = dateMake(data.to.iso);

					const today = dateMake();

					const isWithin = isWithinInterval(today, { start: from, end: to });

					if (isWithin) {
						let newKey: { [index: string]: boolean } = {};

						newKey[`${key}`] = isWithin;

						main = {
							...main,
							...newKey,
						};
					}
				});
			});
		}

		return main;
	}, [offDays]);

	const [updateDeliveryCharge, { loading: delLoading }] = useMutation(
		UPDATE_PARCEL_DELIVERY_CHARGE_BY_ID
	);

	const handleSlot = () => {
		let slotData = null;

		if (moment) {
			TIME_MAKER.every((time) => {
				if (time[moment]) {
					slotData = {
						value: time.date,
						label: `${format(time.date, 'do LLLL')} ${time[moment]}`,
					};
					setSlot(time.date.toISOString());
					return false;
				}

				return true;
			});
		}

		setSlotSelect(slotData);
	};

	useEffect(() => {
		handleSlot();
	}, []);

	useEffect(() => {
		if (store) return;

		if (brand?.node.pickup_locations) {
			dispatch({ type: 'ADD_STORE', payload: brand.node.pickup_locations[0] });
		}
	}, [brand]);

	const updateEstimation = async (data: Estimation): Promise<Data | null> => {
		setLoading(true);

		const headers = {
			'X-Parse-Session-Token': user?.sessionToken,
		};

		try {
			const { data: estimatedData } = await instanceOfAxios.post(
				`/functions/getEstimatedPrice`,
				data,
				{
					headers,
				}
			);

			setLoading(false);
			return estimatedData;
		} catch (error:any) {
			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});

			setLoading(false);
			return null;
		}
	};

	const createOrUpdateParcel = async (
		parcelData: Parcel,
		editable = false,
		id: string
	) => {
		// console.log(parcelData)
		setLoading(true);
		const headers = {
			'X-Parse-Session-Token': user?.sessionToken,
		};

		try {
			if (editable) {
				await instanceOfAxios.put(`/classes/parcel/${id}`, parcelData, {
					headers,
				});
			} else {
				await instanceOfAxios.post(`/classes/parcel`, parcelData, {
					headers,
				});
			}

			setLoading(false);
			handleNotification({
				variant: 'standard',
				message: `Parcel ${editable ? 'Edited' : 'created'}`,
				type: 'success',
			});

			if (user?.user.type === 'merchant') {
				replace(`/merchant/all-orders`);
				return;
			}

			replace(`/admin/all-orders`);
		} catch (error:any) {
			setLoading(false);
			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});
		}
	};

	const editFetch = async ({ id }: { id: string }) => {
		setEiditLoading(true);
		const headers = {
			'X-Parse-Session-Token': user?.sessionToken,
		};

		try {
			const { data } = await instanceOfAxios.get(`/classes/parcel/${id}`, {
				headers,
			});

			dispatch({ type: 'ADD_EDIT_ORDER', payload: data });

			setEiditLoading(false);
		} catch (error:any) {
			setEiditLoading(false);

			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});
		}
	};

	const createBulk = async (parcelData: Parcel[]) => {
		setLoading(true);
		const headers = {
			'X-Parse-Session-Token': user?.sessionToken,
		};

		try {
			const data = {
				data: parcelData,
			};

			await instanceOfAxios.post(`/functions/bulkParcel`, data, {
				headers,
			});

			setLoading(false);
			handleNotification({
				variant: 'standard',
				message: 'Bluk Order Created Successfully',
				type: 'success',
			});

			replace('/merchant/all-orders');
		} catch (error:any) {
			console.log(error.response);

			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});

			setLoading(false);
			return null;
		}
	};

	return {
		updateEstimation,
		loading,
		createOrUpdateParcel,
		editFetch,
		eiditLoading,
		createBulk,
		slotSelect,
		setSlotSelect,
		slot,
		setSlot,
		handleSlot,
		delLoading,
		updateDeliveryCharge,
		timeMaker,
		timeMakerAdmin,
		offDays,
		checkTypeDisabled,
	};
};

export default useParcel;
