import { gql } from '@apollo/client';

export const PICKUP_REQUESTS = gql`
	query getPickUpRequest($greater: Date!, $less: Date!) {
		pickup_requests(
			where: {
				requestedOn: {
					greaterThanOrEqualTo: $greater
					lessThanOrEqualTo: $less
				}
        status: { notEqualTo: "deleted" }
			}
			first: 2000
			order: createdAt_ASC
		) {
			edges {
				node {
					objectId
					parent
					address
					pickup_location
					brand {
						pickup_time
						objectId
						name
						address
						zone
						area
						logo
						pickup_locations {
							__typename
							... on Element {
								value
							}
						}
						contact_numbers {
							... on Element {
								value
							}
						}
					}
					status
					orders
					received
					note
					requestedOn
					createdAt
					pickupBy {
						objectId
						username
					}
				}
			}
		}
	}
`;

export const PICKUP_REQUESTS_FAILED_ASSIGNED = gql`
	query getPickUpRequestsFailOrAssigned {
		pickup_requests(
			where: { status: { in: ["failed", "assigned"] } }
			first: 500
			order: createdAt_ASC
		) {
			edges {
				node {
					objectId
					parent
					address
					pickup_location
					brand {
						pickup_time
						objectId
						name
						address
						zone
						area
						logo
						pickup_locations {
							__typename
							... on Element {
								value
							}
						}
						contact_numbers {
							... on Element {
								value
							}
						}
					}
					status
					orders
					received
					note
					requestedOn
					createdAt
					pickupBy {
						objectId
						username
					}
				}
			}
		}
	}
`;
