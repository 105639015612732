import React from 'react';
import {
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
	List,
	createStyles,
	makeStyles,
	Theme,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		nested: {
			paddingLeft: theme.spacing(4),
		},
		arrow: {
			color: theme.palette.common.white,
		},
	})
);

interface MainListItem {
	title: string;
	icon: JSX.Element;
	links: {
		icon: JSX.Element;
		title: string;
		link: string;
	}[];
}

interface Props {
	item: MainListItem;
}

const HeaderLink: React.FC<Props> = ({ item }) => {
	const classes = useStyles();
	const { pathname } = useHistory().location;

	const [open, setOpen] = React.useState(() => {
		const found = item.links.find((each) => each.link === pathname);
		if (found) return true;
		return false;
	});

	const toggle = () => setOpen((o) => !o);

	return (
		<>
			<ListItem
				style={{ background: 'hsl(0, 0%, 10%)' }}
				button
				onClick={toggle}>
				<ListItemIcon className={classes.arrow}>{item.icon}</ListItemIcon>
				<ListItemText primary={item.title} />
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItem>

			<Collapse in={open} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					{item.links.map((link) => {
						const isActive = pathname === link.link;

						return (
							<ListItem
								style={{
									backgroundColor: isActive ? '#022236' : 'inherit',
									borderLeft: `3px solid ${isActive ? '#ddd' : 'inherit'}`,
								}}
								button
								component={Link}
								to={link.link}
								key={link.link}
								className={classes.nested}>
								<ListItemIcon
									className={classes.arrow}
									style={{
										transform: `translateX(${isActive ? '-3px' : 0})`,
									}}>
									{link.icon}
								</ListItemIcon>
								<ListItemText
									style={{
										transform: `translateX(${isActive ? '-3px' : 0})`,
									}}
									primary={link.title}
								/>
							</ListItem>
						);
					})}
				</List>
			</Collapse>
		</>
	);
};

export default HeaderLink;
