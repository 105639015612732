import { useEffect, useState } from "react";
import { useStore } from "../context/Context";
import { endOfDay, startOfDay } from "date-fns";
import { instanceOfAxios } from "../helper/axios";
import { errorMessage, toParseDate } from "../helper/helper";
import useMessage from "./useMessage";

interface OrderProps {
  name?: string;
}

const useAdmin = () => {
  const PARCELS_PER_PAGE = 100;

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const { handleNotification } = useMessage();
  const [currentPage, setCurrentPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState<undefined | number>(undefined);
  const [perPage, setPerPage] = useState(PARCELS_PER_PAGE);
  const [isLoading, setIsloading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [order, setOrder] = useState("-createdAt")
  const {store: { allBrands },dispatch,} = useStore();

  const reset = () => {
    setCount(undefined);
    setCurrentPage(1);
    setSkip(0);
  };

  useEffect(() => {
    getBrands({});
  }, [skip,order]);

  useEffect(() => {
    if (allBrands) {
      if (!firstLoad) {
        setCount(Math.round((allBrands?.count as number) / PARCELS_PER_PAGE));
        setFirstLoad(true);
      }
    }
  }, [allBrands, firstLoad]);

  const handleChange = (_x: unknown, page: number) => {
    setCurrentPage(page);
    const presentCount = (page - 1) * PARCELS_PER_PAGE;
    setSkip(presentCount);
  };

  
  const handleSortBrand = (name:string) => {
    setOrder(name)
  }


  const getBrands = async ({ name }: OrderProps) => {
    setIsloading(true);
    setPerPage(PARCELS_PER_PAGE);

    let mandatory: any = {};

    let actualQuery: any = {
      ...mandatory,
    };

    if (name) {
      // setPerPage(100);
      actualQuery = {
        ...mandatory,
        name: {
          $regex: `${name}`,
          $options: "i",
        },
      };
    }

    if (startDate && endDate) {
      // setPerPage(100);
      actualQuery = {
        ...mandatory,
        createdAt: {
          $gt: toParseDate(startOfDay(startDate).toISOString()),
          $lt: toParseDate(endOfDay(endDate).toISOString()),
        },
      };
    }

    try {
      const keys = [
        "logo",
        "name",
        "contact_numbers",
        "address",
        "area",
        "scheme.name",
      ];

      const { data } = await instanceOfAxios.get(
        `/classes/brand?order=${order}&where=${JSON.stringify(
          actualQuery
        )}&count=${
          count ? "0" : "1"
        }&skip=${skip}&limit=${perPage}&include=scheme&keys=${keys.join(",")}`
      );

      dispatch({ type: "ADD_ALL_BRAND", payload: data });
      setIsloading(false);
    } catch (error: any) {
      setIsloading(false);
      handleNotification({
        variant: "standard",
        message: errorMessage({ error }),
        type: "error",
      });
    }
  };

  const getShemes = async () => {
    setIsloading(true);

    try {
      const keys = ["objectId", "name"];

      const { data } = await instanceOfAxios.get(
        `/classes/pricing_scheme?keys=${keys.join(",")}`
      );

      dispatch({ type: "ADD_PLAN", payload: data.results });

      setIsloading(false);
    } catch (error: any) {
      setIsloading(false);
      handleNotification({
        variant: "standard",
        message: errorMessage({ error }),
        type: "error",
      });
    }
  };

  const getShemeById = async (id: string) => {
    setIsloading(true);

    try {
      const { data } = await instanceOfAxios.get(
        `/classes/pricing_scheme/${id}`
      );

      setIsloading(false);

      dispatch({ type: "ADD_PLAN_BY_ID", payload: data });
    } catch (error: any) {
      setIsloading(false);
      handleNotification({
        variant: "standard",
        message: errorMessage({ error }),
        type: "error",
      });
    }
  };

  return {
    isLoading,
    getBrands,
    handleChange,
    currentPage,
    count,
    skip,
    reset,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    getShemes,
    getShemeById,
    handleSortBrand
  };
};

export default useAdmin;
