import { endOfDay, startOfDay } from 'date-fns';
import { useState } from 'react';
import { useStore } from '../context/Context';
import { instanceOfAxios } from '../helper/axios';
import { errorMessage } from '../helper/helper';
import useMessage from './useMessage';

export interface Breakdown {
	cash_overall: CashOverall;
	brand_breakdown?: BrandBreakdownEntity[] | null;
	cash_breakdown?: BrandBreakdownEntity[] | null;
	parcel_stats?: Status;
	dummy: boolean;
  riders_total_collection?: number;
  jobs_mismatch?: string[];
}
export interface CashOverall {
	no_orders: number;
	collection: number;
	delivery_charge: number;
	objectId?: null;
}
export interface BrandBreakdownEntity {
	no_orders: number;
	collection: number;
	o_id: string;
	delivery_charge: number;
	assigned_orders?: number;
	brand?: Brand;
	rider?: Rider;
}

export interface Status {
	dispatched_for_delivery: number;
	in_office: number;
	pickedup: number;
}

export interface Brand {
	objectId: string;
	name: string;
	logo?: string | null;
}

export interface Rider {
	objectId: string;
	name: string;
}

const useBreakDown = ({ funcName }: { funcName: string }) => {
	const {
		store: { user },
	} = useStore();
	const { handleNotification } = useMessage();

	const [breakDown, setBreakDown] = useState<Breakdown | null>(null);

	const [isDateOpen, setIsDateOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [startDate, setStartDate] = useState<Date>(new Date());
	const [endDate, setEndDate] = useState<Date>(new Date());

	const handleSelect = (ranges: any) => {
		setStartDate(ranges.selection.startDate);
		setEndDate(ranges.selection.endDate);
	};

	const selectionRange = {
		startDate,
		endDate,
		key: 'selection',
		color: 'black',
	};

	const getPickUpCash = async () => {
		setIsLoading(true);

		const token = user?.sessionToken;

		let data = {
			startDate: startOfDay(startDate).toISOString(),
			endDate: endOfDay(endDate).toISOString(),
		};

		try {
			const { data: totalCash } = await instanceOfAxios.post(
				`/functions/${funcName}`,
				data,
				{
					headers: {
						'x-parse-session-token': token,
					},
				}
			);
			setBreakDown(totalCash.result);

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			setBreakDown(null);
			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});
		}
	};

	return {
		isLoading,
		isDateOpen,
		setIsDateOpen,
		startDate,
		endDate,
		selectionRange,
		handleSelect,
		getPickUpCash,
		breakDown,
	};
};

export default useBreakDown;
