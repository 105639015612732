import React, { useMemo, useState } from "react";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Drawer,
  Toolbar,
  CssBaseline,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Tooltip,
} from "@material-ui/core";
import {
  ExitToApp,
  Menu,
  ChevronLeft,
  CardGiftcard,
  Motorcycle,
  AccountBalance,
  PlaylistAddCheck,
  AllInbox,
  Computer,
  ListAlt,
  RecentActors,
  Whatshot,
  AddBox,
  Payment,
  Dashboard,
  DirectionsBike,
  Add,
  LibraryBooks,
  PanTool,
  People,
  OndemandVideo,
  Settings,
} from "@material-ui/icons";
import CancelScheduleSendIcon from "@material-ui/icons/CancelScheduleSend";
import CallReceivedIcon from "@material-ui/icons/CallReceived";
import CallMergeIcon from "@material-ui/icons/CallMerge";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import BugReportIcon from "@material-ui/icons/BugReport";
import ListIcon from "@material-ui/icons/List";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

import { useStore } from "../../context/Context";
import { useAuth } from "../../hooks/useAuth";
import Notification from "../notification/Notification";
import CustomAvatar from "../customAvatar/CustomAvatar";
import HeaderLink from "./HeaderLink";

const drawerWidth = 280;

interface ArrayTabs {
  icon: any;
  title: string;
  link: string;
}

interface Tabs {
  "pickup-manager": ArrayTabs[];
  accounts: ArrayTabs[];
  merchant: ArrayTabs[];
}

export const adminRoute = [
  {
    title: "Admin",
    icon: <AccountBoxIcon />,
    links: [
      {
        icon: <Computer />,
        title: "Dashboard",
        link: "/admin",
      },

      {
        icon: <CardGiftcard />,
        title: "All Parcels",
        link: "/admin/all-orders",
      },
      {
        icon: <CallReceivedIcon color="error" />,
        title: "Rejected Parcels",
        link: `/admin/all-orders/rejected`,
      },
      {
        icon: <RecentActors />,
        title: "All Brands",
        link: "/admin/brands",
      },
      {
        icon: <CallMergeIcon />,
        title: "Retarget Brands",
        link: "/admin/retarget",
      },
      {
        icon: <ListAlt />,
        title: "All Pricings",
        link: "/admin/schemas",
      },
      {
        icon: <BugReportIcon />,
        title: "All Issues",
        link: "/all/issues",
      },
      {
        icon: <LibraryBooks />,
        title: "Notices",
        link: "/admin/notice-board",
      },
      // {
      // 	icon: <LibraryBooks />,
      // 	title: 'Profile',
      // 	link: '/profile',
      // },
      {
        icon: <People />,
        title: "Manage user",
        link: "/manage-user",
      },
      {
        icon: <Settings />,
        title: "Settings",
        link: "/settings",
      },
    ],
  },
  {
    title: "Accounts",
    icon: <MonetizationOnIcon />,
    links: [
      {
        icon: <AccountBalance />,
        title: "Accountants Brand",
        link: "/accounts",
      },
      {
        icon: <ListAlt />,
        title: "All Payment Details",
        link: "/accounts/payment-details",
      },
      {
        icon: <LibraryBooks />,
        title: "Summary",
        link: "/accounts/summary",
      },
    ],
  },
  {
    title: "Pickup Manager",
    icon: <LocalShippingIcon />,
    links: [
      {
        icon: <Computer />,
        title: "Dashboard",
        link: "/pickup-manager",
      },
      {
        icon: <CardGiftcard />,
        title: "Pickup Assign",
        link: "/pickup-manager/assign/pickup-riders",
      },
      {
        icon: <CancelScheduleSendIcon color="error" />,
        title: "Pickup Fail",
        link: "/pickup-manager/assign/pickup-riders/fail-or-assigned",
      },
      {
        icon: <PanTool />,
        title: "Waiting Assign",
        link: "/pickup-manager/waiting",
      },
      {
        icon: <Motorcycle />,
        title: "Delivery Assign",
        link: "/pickup-manager/assign/delivery-riders",
      },
      {
        icon: <KeyboardReturnIcon />,
        title: "Return Assign",
        link: "/pickup-manager/assign/return-parcels",
      },
      {
        icon: <ListIcon />,
        title: "Outside Dhaka",
        link: "/pickup-manager/outside-dhaka",
      },
      {
        icon: <Whatshot />,
        title: "Express",
        link: "/pickup-manager/express",
      },
    ],
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxShadow: "0 3px 10px -2px rgba(0, 0, 0, .1)",
      backgroundColor: theme.palette.common.white,
    },

    title: {
      margin: "0 auto",
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    version: {
      color: "#cfcaca",
      fontWeight: "bold",
      backgroundColor: "black",
      textAlign: "center",
    },
    arrow: {
      color: theme.palette.common.white,
    },

    create: {
      marginRight: "1rem",
    },
  })
);

const Header: React.FC = () => {
  const classes = useStyles();
  const {
    store: { user, brand },
  } = useStore();
  const isAdmin = user?.user.type === "admin";
  const [open, setOpen] = useState(false);
  const [notifiOpen, _setNotifiOpen] = useState(false);
  const { logout } = useAuth();
  const {
    location: { pathname },
    push,
  } = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const merchant = useMemo(() => {
    const dashboard = {
      icon: <Dashboard />,
      title: "Dashboard",
      link: `/merchant/${brand?.node.objectId}`,
    };

    let mainTabs = [
      {
        icon: <CardGiftcard />,
        title: "Pickup Requests",
        link: `/merchant/pickups`,
      },
      {
        icon: <CallReceivedIcon color="error" />,
        title: "Rejected Parcels",
        link: `/merchant/all-orders/rejected`,
      },
      {
        icon: <ExitToApp />,
        title: "Logout",
        link: "/login",
      },
    ];

    let tabs = [dashboard, ...mainTabs];

    if (brand?.node.verified) {
      tabs = [
        dashboard,
        {
          icon: <AllInbox />,
          title: "All Parcels",
          link: "/merchant/all-orders",
        },
        {
          icon: <OndemandVideo />,
          title: "Tutorials",
          link: "/merchant/tutorial",
        },
        {
          icon: <Payment />,
          title: "Payment Details",
          link: "/merchant/payment-details",
        },
        {
          icon: <BugReportIcon />,
          title: "All Issues",
          link: "/merchant/all-issues",
        },
        {
          icon: <AddBox />,
          title: "Create Parcel",
          link: "/merchant/create-parcel",
        },
        {
          icon: <PlaylistAddCheck />,
          title: "Bulk Parcel",
          link: "/merchant/create-parcel/bulk",
        },
        ...mainTabs,
      ];
    }

    return tabs;
  }, [brand]);

  const allTabs: Tabs = useMemo(() => {
    return {
      "pickup-manager": [
        {
          icon: <Dashboard />,
          title: "Dashboard",
          link: "/pickup-manager",
        },

        {
          icon: <CardGiftcard />,
          title: "Pickup Assign",
          link: "/pickup-manager/assign/pickup-riders",
        },
        {
          icon: <CancelScheduleSendIcon color="error" />,
          title: "Pickup Fail",
          link: "/pickup-manager/assign/pickup-riders/fail-or-assigned",
        },
        {
          icon: <PanTool />,
          title: "Waiting Assign",
          link: "/pickup-manager/waiting",
        },
        {
          icon: <Motorcycle />,
          title: "Delivery Assign",
          link: "/pickup-manager/assign/delivery-riders",
        },
        {
          icon: <ListIcon />,
          title: "Outside Dhaka",
          link: "/pickup-manager/outside-dhaka",
        },
        {
          icon: <Whatshot />,
          title: "Express",
          link: "/pickup-manager/express",
        },
        {
          icon: <RecentActors />,
          title: "All Brands",
          link: "/admin/brands",
        },
        {
          icon: <CardGiftcard />,
          title: "All Parcels",
          link: "/admin/all-orders",
        },
        {
          icon: <CallReceivedIcon color="error" />,
          title: "Rejected Parcels",
          link: `/admin/all-orders/rejected`,
        },

        {
          icon: <DirectionsBike />,
          title: "Manage Riders",
          link: "/manage-user",
        },
        {
          icon: <ExitToApp />,
          title: "Logout",
          link: "/login",
        },
      ],
      accounts: [
        {
          icon: <AccountBalance />,
          title: "Accounts",
          link: "/accounts",
        },
        {
          icon: <ListAlt />,
          title: "All Payment Details",
          link: "/accounts/payment-details",
        },
        {
          icon: <LibraryBooks />,
          title: "Summary",
          link: "/accounts/summary",
        },
        {
          icon: <ExitToApp />,
          title: "Logout",
          link: "/login",
        },
      ],
      merchant,
    };
  }, [merchant]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar>
          <IconButton
            color="default"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={clsx(open && classes.hide)}
          >
            <Menu />
          </IconButton>

          <img
            onClick={() => {
              if (brand) {
                push(`/${user?.user.type}/${brand.node.objectId}`);
                return;
              }

              push(`/${user?.user.type}`);
            }}
            src="/custom/logoBlack.svg"
            alt="now logo"
            height="70"
            width="70"
            className={classes.title}
          />

          {isAdmin && (
            <Button
              startIcon={<ExitToApp />}
              disableElevation
              color="primary"
              variant="outlined"
              onClick={() => {
                if (isAdmin) {
                  logout();
                }
              }}
            >
              Logout
            </Button>
          )}

          {brand && (
            <>
              <Link
                to="/merchant/create-parcel"
                onClick={(e) => {
                  if (!brand.node.verified) {
                    e.preventDefault();
                  }
                }}
              >
                <Button
                  disabled={!brand.node.verified}
                  startIcon={<Add />}
                  disableElevation
                  color="primary"
                  variant="contained"
                  className={classes.create}
                >
                  Create Parcel
                </Button>
              </Link>
              )
              <Link to={`/merchant/profile/${brand.node.objectId}`}>
                <Tooltip title="Profile" arrow>
                  <CustomAvatar
                    variant="rounded"
                    logo={brand.node.logo}
                    name={brand.node.name}
                    style={{
                      backgroundColor: "#222",
                      cursor: "pointer",
                      transform: "scale(.90)",
                    }}
                  />
                </Tooltip>
              </Link>
            </>
          )}
        </Toolbar>
        <Notification isOpen={notifiOpen} />
      </AppBar>

      <main>
        <div className={classes.drawerHeader} />
      </main>

      <Drawer
        onClose={() => setOpen(false)}
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton className={classes.arrow} onClick={handleDrawerClose}>
            <ChevronLeft />
          </IconButton>
        </div>

        <Divider style={{ backgroundColor: "#ddd", marginTop: ".3rem" }} />

        {isAdmin ? (
          <List>
            {adminRoute.map((item) => (
              <HeaderLink key={item.title} item={item} />
            ))}
          </List>
        ) : (
          <List>
            {allTabs[user?.user.type as "pickup-manager"]?.map(
              ({ link, title, icon }, index) => (
                <ListItem
                  style={{
                    backgroundColor:
                      `${pathname}` === link ? "#022236" : "inherit",
                    borderLeft: `3px solid ${
                      pathname === link ? "#ddd" : "inherit"
                    }`,
                  }}
                  component={Link}
                  to={link}
                  onClick={() => {
                    if (title === "Logout") {
                      logout();
                    }
                  }}
                  button
                  key={index}
                >
                  <ListItemIcon className={classes.arrow}>{icon}</ListItemIcon>
                  <ListItemText primary={title} />
                </ListItem>
              )
            )}
          </List>
        )}
        <p className={classes.version}>{process.env.REACT_APP_VERSION}</p>
      </Drawer>
    </div>
  );
};

export default Header;
