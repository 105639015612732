import Axios from 'axios';

export const instanceOfAxios = Axios.create({
	baseURL: process.env.REACT_APP_REST_URI,
	headers: {
		'X-Parse-Application-Id': process.env.REACT_APP_PARSE_APP_KEY,
		// 'X-Parse-MASTER-KEY': 'master'
	},
});

export const instanceOfBarikoi = Axios.create({
	baseURL: `https://barikoi.xyz/v1/api/search/${process.env.REACT_APP_BARIKOI_API_KEY}/rupantor/geocode`,
});
