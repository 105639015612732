/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { Link, useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Layout from '../../components/layout/Layout';
import TableHeader from '../../components/TableCommon/TableHeader';
import { useStore } from '../../context/Context';
import Avatar from '@material-ui/core/Avatar';
import useAdmin from '../../hooks/useAdmin';
import TextField from '@material-ui/core/TextField';
import LoadingBtn from '../../components/typography/buttons/LoadingBtn';
import { getSearchParam } from '../../helper/helper';
import { useEffect } from 'react';


const headers = [
	{ field: 'logo' },
	{ field: `Brand ▼` },
	{ field: 'Phone' },
	{ field: 'Address ▼' },
	{ field: 'Area ▼' },
	{ field: 'plan' },
	{ field: 'all parcels' },
	{ field: 'details' },
];

const AllBrands = () => {
	const _name = getSearchParam().get('name');
	const [name, setName] = useState('');
	const { push } = useHistory();
	let { store: { allBrands } } = useStore();
	const { isLoading, getBrands, handleChange, currentPage, count, reset, handleSortBrand } = useAdmin();
	const [direction, setDirection] = useState(true)


	const handleOrderText = useCallback(
		(e: any) => {
			let text = e.target.textContent.toLowerCase()
			console.log(text)
			if (text === 'brand ▼') {
				setDirection(p => !p)
				direction ? handleSortBrand('name') : handleSortBrand('-name')
			}
			if (text === 'address ▼') {
				setDirection(p => !p)
				direction ? handleSortBrand('address') : handleSortBrand('-address')
			}
			if (text === 'area ▼') {
				setDirection(p => !p)
				direction ? handleSortBrand('area') : handleSortBrand('-area')
			}
		}, [direction]
	)

	const handleSearch = () => {
		reset();
		getBrands({ name: _name as string });
	};

	useEffect(() => {
		if (_name) {
			setName(_name);
			handleSearch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_name]);



	return (
		<Layout className='account ' loading={isLoading} title='All Brands'>
			<div className='account__container rounded shadow-lg anim-frame'>
				<Grid container justify="space-between" alignItems="center">
					<form
						onSubmit={(e) => {
							e.preventDefault();

							push(`/admin/brands?name=${name}`);
						}}
						className='allOrders__search'>
						<TextField
							variant='outlined'
							size='small'
							placeholder={'name'}
							type={'text'}
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
						<LoadingBtn disabled={isLoading} type='submit'>
							Search
						</LoadingBtn>
					</form>
					<h3>{allBrands?.count && `Total Brand: ${allBrands?.count}`}</h3>
				</Grid>
				<div className='overflow'>
					<div className='account__table '>
						<div
							className='account__header'
							style={{ gridTemplateColumns: `repeat(${headers.length}, 1fr)` }}>
							{headers.map(({ field }, i) => {
								return (
									<TableHeader
										key={i}
										field={field}
										handleOrderText={handleOrderText}
									/>
								)
							})}
						</div>
						{allBrands?.results?.map((brand: any) => {
							return <>
								<div
									key={brand.objectId}
									className='account__body anim-card'
									style={{
										gridTemplateColumns: `repeat(${headers.length}, 1fr)`,
									}}>
									<div className='account__bodyCol'>
										<Avatar
											src={brand.logo ? brand.logo : undefined}
											variant='circular'
											style={{ backgroundColor: '#222', cursor: 'pointer' }}>
											{brand.name?.split('')[0]}
										</Avatar>
									</div>
									<div className='account__bodyCol'>
										<Link
											to={{
												pathname: `/merchant/profile/${brand.objectId}`,
											}}>
											<p>{brand.name}</p>
										</Link>
									</div>
									<div className='account__bodyCol'>
										{brand.contact_numbers?.map((number: any, i: any) => (
											<p key={number + i}>{number}</p>
										))}
									</div>
									<div className='account__bodyCol'>
										<p>{brand.address}</p>
									</div>
									<div className='account__bodyCol'>
										<p>{brand.area}</p>
									</div>
									<div className='account__bodyCol'>
										<p>{brand?.scheme?.name}</p>
									</div>
									<div className='account__bodyCol'>
										<Link to={`/admin/all-orders?brand=${brand.objectId}`}>
											view parcels
										</Link>
									</div>
									<div className='account__bodyCol'>
										<Link to={`/accounts/payment-details/${brand.objectId}`}>
											Payment Details
										</Link>
									</div>
								</div>
							</>
						})}
						<div className='account__pagination anim-frame'>
							<Pagination
								onChange={handleChange}
								count={count as number}
								color='primary'
								page={currentPage}
								shape='rounded'
							/>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default AllBrands;
