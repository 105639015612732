import { useEffect, useState } from 'react';
import { endOfDay, startOfDay, subMonths } from 'date-fns';
import { useStore } from '../context/Context';
import useMessage from './useMessage';
import { instanceOfAxios } from '../helper/axios';
import { useQuery } from 'react-query';
import { StatsOfBrand } from '../context/contextTypes';
import { errorMessage } from '../helper/helper';

export type QueryGraph = 'sales' | 'order';

export interface GraphResult {
	sales: Sales[];
	orders: Orders[];
}

export interface Sales {
	group_sales: number;
	objectId: string;
}

export interface Orders {
	group_orders: number;
	objectId: string;
}

const useMerchant = (brandId: string | null) => {
	const { store, dispatch } = useStore();
	const { handleNotification } = useMessage();

	const [startDate, setStartDate] = useState(
		endOfDay(subMonths(new Date(), 1))
	);
	const [endDate, setEndDate] = useState(startOfDay(new Date()));

	const [graphData, setGraphData] = useState<GraphResult | null>(null);

	const [_queryGraph, setQueryGraph] = useState<QueryGraph>('sales');

	const selectionRange = {
		startDate,
		endDate,
		key: 'selection',
		color: 'black',
	};

	const {
		data: response,
		refetch,
		isFetching,
		isLoading,
		error,
		isRefetchError,
	} = useQuery<StatsOfBrand>(
		'paymentDetails',
		async () => {
			const headers = {
				'X-Parse-Session-Token': store.user?.sessionToken,
			};

			const { data } = await instanceOfAxios.post(
				'/functions/getStatsOfBrand',
				{
					startDate: startDate.toISOString(),
					endDate: endDate.toISOString(),
					brandId,
				},
				{ headers }
			);

			return data.result;
		},
		{ retry: false }
	);

	// graph query

	const {
		data: responseGraph,
		refetch: refetchGraph,
		isFetching: isFetchingGraph,
	} = useQuery<GraphResult>(
		'graph',
		async () => {
			const headers = {
				'X-Parse-Session-Token': store.user?.sessionToken,
			};

			const data = {
				startDate: startDate.toISOString(),
				endDate: endDate.toISOString(),
				brandId,
				query: 'sales',
			};

			const { data: sales } = await instanceOfAxios.post(
				'/functions/getChartData',
				data,
				{
					headers,
				}
			);

			const { data: orders } = await instanceOfAxios.post(
				'/functions/getChartData',
				{
					...data,
					query: 'order',
				},
				{ headers }
			);

			return {
				sales: sales.result,
				orders: orders.result,
			};
		},
		{ retry: false }
	);

	useEffect(() => {
		if (response) {
			dispatch({ type: 'ADD_BRAND_STATS', payload: response });
			return;
		}

		if (error || isRefetchError) {
			handleNotification({
				variant: 'standard',

				message: errorMessage({ error: error as Error }),
				type: 'error',
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [response, error]);

	useEffect(() => {
		if (responseGraph) {
			setGraphData(responseGraph);
		}
	}, [responseGraph]);

	return {
		setStartDate,
		setEndDate,
		selectionRange,
		refetch,
		isFetching,
		isLoading,
		isFetchingGraph,
		setQueryGraph,
		refetchGraph,
		graphData,
	};
};

export default useMerchant;
