import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { Container } from "../../components/styles/Container";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Button, CircularProgress, Grid, Switch } from "@material-ui/core";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import UserTable from "./UserTable";
import styles from "./styles.module.css";
import FormDialog from "./FormDialgo";
import { instanceOfAxios } from "../../helper/axios";
import useMessage from "../../hooks/useMessage";
import useRiders from "../../hooks/useRiders";
import { errorMessage } from "../../helper/helper";
import { useStore } from "../../context/Context";

export default function HandleUser() {
  const { handleNotification } = useMessage();
  const { refetch } = useRiders("rider");
  const [value, setValue] = useState(0);
  const [users, setUsers] = useState();
  const [userType, setUserType] = useState("rider");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [submit, setSubmit] = useState("create");
  const [userStatus, setUserStatus] = useState(true);
  const {
    store: { user },
  } = useStore();
  const [formData, setFormData] = useState({
    username: "",
    name: "",
    password: "",
    type: "",
  });
  const isAdmin = user?.user?.type === "admin";

  const handleChange = (event, newValue) => {
    setLoading(true);
    setUserType(event.target.textContent);
    // console.log(event.target.textContent);
    setValue(newValue);
    setLoading(false);
  };

  const {
    loading: dataLoading,
    data,
    // error,
  } = useQuery(GET_RIDERS, {
    variables: {
      type: userType,
      active: userStatus,
    },
  });

  useEffect(() => {
    const res = data?.users?.edges?.map((user) => user.node);
    setUsers(res);
    setLoading(false);
  }, [data]);

  // console.log(user);

  const handleShowActiveInActiveUser = () => {
    setUserStatus((e) => !e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData);
    if (submit === "edit") {
      // console.log("edit");
      try {
        await instanceOfAxios.post(`/functions/updateUser`, formData, {
          headers: {
            "x-parse-session-token": user?.sessionToken,
          },
        });

        handleNotification({
          variant: "standard",
          type: "success",
          message: "Success",
        });

        refetch();
      } catch (error) {
        console.log(error);

        handleNotification({
          variant: "standard",
          message: errorMessage({ error }),
          type: "error",
        });
      } finally {
        setTimeout(window.location.reload(), 1000);
      }
    }

    if (submit === "create") {
      // console.log("create");
      try {
        await instanceOfAxios.post(`/functions/createNewUser`, formData, {
          headers: {
            "x-parse-session-token": user?.sessionToken,
          },
        });
        handleNotification({
          variant: "standard",
          type: "success",
          message: "Success",
        });

        refetch();
      } catch (error) {
        console.log(error);

        handleNotification({
          variant: "standard",
          message: errorMessage({ error }),
          type: "error",
        });
      } finally {
        setTimeout(window.location.reload(), 1000);
      }
    }

    await refetch();
    setOpen(false);
  };

  const updateRiderStatus = async (data) => {
    try {
      // console.log(data)
      await instanceOfAxios.post(`/functions/changeUserStatus`, data, {
        headers: {
          "x-parse-session-token": user?.sessionToken,
        },
      });

      handleNotification({
        variant: "standard",
        type: "success",
        message: "Success",
      });
      refetch();
    } catch (error) {
      handleNotification({
        variant: "standard",
        message: errorMessage({ error }),
        type: "error",
      });
    } finally {
      setTimeout(window.location.reload(), 1000);
    }
  };

  const handleCreateUser = async () => {
    setSubmit("create");
    setOpen(true);
  };

  const handleEditUser = async (e) => {
    setSubmit("edit");
    setOpen(true);
    setFormData({
      username: e.username,
      name: e.name,
      userId: e.objectId,
      phone: e.phone,
    });
  };

  return (
    <Layout title="Manage user" className="className" loading={dataLoading}>
      <FormDialog
        open={open}
        setOpen={setOpen}
        submit={submit}
        formData={formData}
        setFormData={setFormData}
        handleSubmit={handleSubmit}
      />
      <Container>
        <Grid
          container
          item
          justify="space-between"
          alignItems="center"
          className={styles.tabsContainer}
        >
          <Grid item>
            <Paper square>
              <Tabs
                value={value}
                indicatorColor="primary"
                // textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
                variant="scrollable"
                scrollButtons="auto"
                // centered
                className={styles.tabs}
              >
                {<Tab label="rider" />}
                {<Tab label="pickup-rider" />}
                {isAdmin && <Tab label="merchant" />}
                {isAdmin && <Tab label="accounts" />}
                {isAdmin && <Tab label="admin" />}
              </Tabs>
            </Paper>
          </Grid>
          <Grid>
            <Switch
              checked={userStatus}
              onClick={handleShowActiveInActiveUser}
            />
          </Grid>
          <Grid>
            <Button
              variant="outlined"
              onClick={handleCreateUser}
              className={styles.createBtn}
            >
              Create user
            </Button>
          </Grid>
        </Grid>

        {loading ? (
          <CircularProgress />
        ) : (
          <Grid>
            <UserTable
              users={users}
              handleEditUser={handleEditUser}
              updateRiderStatus={updateRiderStatus}
            />
          </Grid>
        )}
      </Container>
    </Layout>
  );
}

export const GET_RIDERS = gql`
  query getRiders($type: String!, $active: Boolean!) {
    users(where: { type: { equalTo: $type }, active: { equalTo: $active } }) {
      edges {
        node {
          objectId
          username
          active
          name
          phone
          type
          email
          createdAt
        }
      }
    }
  }
`;
