import { gql } from '@apollo/client';

export const SET_LOCATION = gql`
	mutation setLocation($id: ID!, $lat: Float!, $long: Float!) {
		updateParcel(
			input: {
				fields: { geolocation: { latitude: $lat, longitude: $long } }
				id: $id
			}
		) {
			parcel {
				objectId
			}
		}
	}
`;
