import React, { useState } from 'react';
import Button from '@material-ui/core/Button';

import './resetPassword.scss';
import CustomInput from '../../components/customInput/CustomInput';
import Common from '../merchantSignup/Common';
import { useAuth } from '../../hooks/useAuth';
import { useHistory } from 'react-router-dom';

const ResetPassword: React.FC = () => {
	const [password, setPassword] = useState('');
	const [passwordDis, setPasswordDis] = useState(true);

	const { location } = useHistory<{
		number: string;
		code: string;
		isForgot: boolean;
	}>();

	const { resetPassword } = useAuth();

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!passwordDis) {
			resetPassword({
				username: location.state.number,
				password,
				code: location.state.code,
			});
		}
	};

	return (
		<Common title='Reset Password'>
			<div className='reset'>
				<h2>Set New Password</h2>
				<form onSubmit={handleSubmit}>
					<CustomInput
						label='Password'
						placeholder='****'
						regex={/^.{6,}/gi}
						helperTxt={'should be at least 6 charecters long'}
						value={password}
						setValue={setPassword}
						setDisabled={setPasswordDis}
						type='password'
					/>
					<Button
						disabled={passwordDis}
						className='text-lg'
						size='large'
						variant='contained'
						color='primary'
						type='submit'
					>
						Reset
					</Button>
				</form>
			</div>
		</Common>
	);
};

export default ResetPassword;
