import { useEffect, useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { Link } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import { Header } from '../../components/pickupReqTable/pickup.req.table';
import TableHeader from '../../components/TableCommon/TableHeader';
import { useQuery } from 'react-query';
import { instanceOfAxios } from '../../helper/axios';
import { useStore } from '../../context/Context';
import useMessage from '../../hooks/useMessage';
import './accountScreen.scss';
import { relativeNow } from '../../helper/helper';
import { PaymentDetails } from '../profile/MerchantProfile';
import { Container } from '../../components/styles/Container';

export interface BrandData {
	count?: number;
	data: DataEntity[];
}

export type PreferredMethod = 'bkash' | 'nagad' | 'rocket' | 'bank';

export interface DataEntity {
	delivery_charge: number;
	total_parcel: number;
	cash_collected: number;
	o_id: string;
	brand: Brand;
}

export interface Brand {
	paymentChangedAt?: {
		__type: 'Date';
		iso: string;
	};
	received_payment_after_change?: boolean;
	payment_details?: PaymentDetails;
	preferred_method?: PreferredMethod;
	name: string;
	address: string;
	logo: string;
	contact_numbers: string[];
}

const AccountScreen = () => {
	const [currentPage, setCurrentPage] = useState(1);

	const [firstLoad, setFirstLoad] = useState(false);

	const [skip, setSkip] = useState(0);

	const [count, setCount] = useState<undefined | number>(undefined);

	const { handleNotification } = useMessage();

	const {
		store: { user },
	} = useStore();

	const BRANDS_PER_PAGE = 500;

	const headers: Header[] = [
		{ field: 'id' },
		{ field: 'Brand' },
		{ field: 'Payment Method' },
		{ field: 'Orders' },
		{ field: 'Cash collected' },
		{ field: 'Delivery Fee' },
		{ field: '' },
		{ field: '' },
	];

	const {
		data: response,
		error,
		refetch,
		isLoading,
	} = useQuery('brands', async () => {
		const headers = {
			'X-Parse-Session-Token': user?.sessionToken,
		};

		const { data } = await instanceOfAxios.post<{ result: BrandData }>(
			'/functions/billingList',
			{
				skip,
				limit: BRANDS_PER_PAGE,
				count: count ? false : true,
			},
			{ headers }
		);

		return data.result;
	});

	const handleChange = (_x: unknown, page: number) => {
		setCurrentPage(page);

		const presentCount = (page - 1) * BRANDS_PER_PAGE;

		setSkip(presentCount);
	};

	useEffect(() => {
		refetch();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [skip]);

	useEffect(() => {
		if (response) {
			if (!firstLoad) {
				setCount(Math.round((response.count as number) / BRANDS_PER_PAGE));
				setFirstLoad(true);
			}
		}
	}, [response, firstLoad]);

	useEffect(() => {
		if (error) {
			handleNotification({
				variant: 'standard',
				message: 'Server Error',
				type: 'error',
			});
		}
	}, [error, handleNotification]);

	return (
		<Layout className='account ' loading={isLoading} title='Accountants Brand'>
			<Container className='anim-frame'>
				<div className='overflow'>
					<div className='account__table '>
						<div
							className='account__header'
							style={{ gridTemplateColumns: `repeat(${headers.length}, 1fr)` }}>
							{headers.map(({ field }, i) => (
								<TableHeader key={i} field={field} />
							))}
						</div>
						{response?.data
							.map((d) => ({
								...d,
								worth: d.cash_collected - d.delivery_charge,
							}))
							.sort((a, b) => b.worth - a.worth)
							.map((details) => {
								const isNegetive =
									details.cash_collected - details.delivery_charge < 0;
								return (
									<div
										key={details.o_id}
										className={`account__body anim-card ${
											isNegetive && 'negetive'
										}`}
										style={{
											gridTemplateColumns: `repeat(${headers.length}, 1fr)`,
										}}>
										<div key={details.o_id} className='account__bodyCol'>
											{details.o_id}
										</div>
										<div className='account__bodyCol'>
											<p>
												<Link
													target='_blank'
													to={`/merchant/profile/${details.o_id}`}>
													{details.brand.name}
												</Link>
											</p>
											{details.brand.received_payment_after_change === false &&
												details.brand.paymentChangedAt && (
													<p className='error'>
														payment updated{' '}
														{relativeNow(
															new Date(details.brand.paymentChangedAt.iso)
														)}
													</p>
												)}
										</div>

										<div className='account__bodyCol'>
											<p>{details.brand.preferred_method}</p>
											{details.brand.payment_details &&
												details.brand.preferred_method &&
												details.brand.preferred_method !== 'bank' &&
												details.brand.payment_details[
													details.brand.preferred_method
												]}
										</div>

										<div className='account__bodyCol'>
											<span>{details.total_parcel}</span>
										</div>
										<div className='account__bodyCol'>
											<strong>&#2547; {details.cash_collected}</strong>
										</div>
										<div className='account__bodyCol'>
											<span>{details.delivery_charge}</span>
										</div>
										<div className='account__bodyCol'>
											<Link
												target='_blank'
												to={{
													pathname: `/accounts/payment-details/${
														details.o_id
													}/new-payment?name=${details.brand.name
														.split('&')
														.join(' and ')}`,
												}}>
												New Payment
											</Link>
										</div>
										<div className='account__bodyCol'>
											<Link
												target='_blank'
												to={{
													pathname: `/accounts/payment-details/${details.o_id}`,
													state: { brandName: details.brand.name },
												}}>
												Payment Details
											</Link>
										</div>
									</div>
								);
							})}
						<div className='account__pagination anim-frame'>
							<Pagination
								onChange={handleChange}
								count={count as number}
								color='primary'
								page={currentPage}
								shape='rounded'
							/>
						</div>
					</div>
				</div>
			</Container>
		</Layout>
	);
};

export default AccountScreen;
