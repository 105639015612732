import BreakDown from '../../components/breakDown/BreakDown';

const Summary = () => {
	return (
		<>
			<BreakDown
				title='Breakdowns (All)'
				breakdown='brand_breakdown'
				name='brand'
			/>
		</>
	);
};

export default Summary;
