/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import Pagination from '@material-ui/lab/Pagination';

import '../pickupManager/pickup.assign.scss';
import Layout from '../../components/layout/Layout';
import useMessage from '../../hooks/useMessage';
import TableHeader from '../../components/TableCommon/TableHeader';
import { instanceOfAxios } from '../../helper/axios';
import { errorMessage, PICKUP_STATUS } from '../../helper/helper';
import { useStore } from '../../context/Context';
import { HeaderTable } from '../../components/styles/Table';
import { Container } from '../../components/styles/Container';

export interface Pickups {
	results?: ResultsEntity[] | null;
	count: number;
}
export interface ResultsEntity {
	objectId: string;
	requestedOn: AddedOrRequestedOn;
	address: Address;
	brand: BrandOrPickupBy;
	status: string;
	orders: number;
	pickup_location: string;
	createdAt: string;
	updatedAt: string;
	pickupBy?: Rider | null;
	note?: string;
}
export interface AddedOrRequestedOn {
	__type: string;
	iso: string;
}
export interface Address {
	_id: string;
	added: AddedOrRequestedOn;
	name: string;
	zone: string;
	area: string;
	address: Address1;
	geolocation: Geolocation;
}
export interface Address1 {
	flat: string;
	full_address: string;
}
export interface Geolocation {
	__type: string;
	latitude: number;
	longitude: number;
}
export interface BrandOrPickupBy {
	__type: string;
	className: string;
	objectId: string;
}
export interface Rider {
	__type: string;
	className: string;
	objectId: string;
	name: string;
}

const MerchantPickup: React.FC = () => {
	const PICKUP_PER_PAGE = 100;

	const {
		store: { brand },
	} = useStore();

	const { location, push } = useHistory();

	const { handleNotification } = useMessage();

	const query = new URLSearchParams(location.search);

	const page = query.get('page');

	const skip = query.get('skip');

	const [pickups, setPickups] = useState<Pickups | null>(null);

	const [loading, setLoading] = useState(false);

	const [count, setCount] = useState<undefined | number>(undefined);

	const [totalCount, setTotalCount] = useState(0);

	const [currentPage, setCurrentPage] = useState(1);

	const headers = [
		{ field: 'Address' },
		{ field: `Created` },
		{ field: `Rider` },
		{ field: 'Orders' },
		{ field: 'Status' },
	];

	const getPickups = async () => {
		setLoading(true);

		const query = {
			brand: {
				__type: 'Pointer',
				className: 'brand',
				objectId: brand?.node.objectId,
			},
		};

		try {
			const { data } = await instanceOfAxios.get(
				`/classes/pickup_request?order=-createdAt&where=${JSON.stringify(
					query
				)}&count='${count ? '0' : '1'}'&skip=${skip ? skip : 0
				}&limit=${PICKUP_PER_PAGE}&include=pickupBy`
			);

			setPickups(data);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});
		}
	};

	const pathConstructor = (paths: string[], join: string) => {
		const main = `/merchant/pickups?`;

		return main + paths.join(join);
	};

	const handleChange = (_x: unknown, page: number) => {
		setCurrentPage(page);

		const presentCount = (page - 1) * PICKUP_PER_PAGE;

		let sub = [`skip=${presentCount}`];

		push(pathConstructor(sub, '&'));
	};

	useEffect(() => {
		getPickups();
	}, [skip]);

	useEffect(() => {
		if (page) setCurrentPage(+page);
	}, [page]);

	useEffect(() => {
		if (pickups) {
			const total = Math.ceil((pickups.count as number) / PICKUP_PER_PAGE);

			setTotalCount(pickups.count);
			setCount(total);
		}
	}, [pickups]);

	return (
		<Layout
			className='pickupAssign'
			loading={loading}
			title={`All Pickups | ${loading ? '. . . ' : totalCount}`}>
			<Container className='overflow'>
				<div className='merchantPaymnetDetails__table '>
					<HeaderTable length={headers.length}>
						{headers.map(({ field }, i) => (
							<TableHeader key={i} field={field} />
						))}
					</HeaderTable>
					{pickups?.results?.map((node, i) => (
						<div
							key={node.objectId}
							className='pickTable__bodyMain anim-card'
							style={{
								gridTemplateColumns: `repeat(${headers.length}, 1fr)`,
							}}>
							<div title='address' className='pickTable__bodyCol'>
								<p>{node.address?.address.full_address}</p>
								<p>flat no: {node.address?.address.flat}</p>
							</div>
							<div title='slot' className='pickTable__bodyCol'>
								<p>{format(new Date(node.requestedOn.iso), 'PPpp')}</p>
							</div>
							<div title='rider' className='pickTable__bodyCol '>
								<h2>{node.pickupBy ? node.pickupBy.name : '....'}</h2>
							</div>
							<div title='picked up' className='pickTable__bodyCol tag'>
								<span>{node.orders}</span>
							</div>

							<div title='phone' className='pickTable__bodyCol tag'>
								<p>{PICKUP_STATUS[node.status]}</p>
							</div>
						</div>
					))}
				</div>
				<div className='allOrders__pagination anim-frame'>
					<Pagination
						onChange={handleChange}
						count={count}
						color='primary'
						page={currentPage}
						shape='rounded'
					/>
				</div>
			</Container>
		</Layout>
	);
};

export default MerchantPickup;
