import React, { useMemo, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import Select from 'react-select';

import { SelectLabel } from '../../screens/bulkScreen/BulkScreen';
import { ISSUE_LABEL, issuesInfo } from '../../helper/helper';
import TransitionApp from '../transition/TransitionApp';
import LoadingBtn from '../typography/buttons/LoadingBtn';

const init = {
	text: '',
	label: '',
};

const issueLabels = Object.entries(ISSUE_LABEL).map(([value, key]) => ({
	label: key,
	value,
}));

const Suggestions = styled.div`
	position: absolute;
	border: 2px solid skyblue;
	border-radius: 6px;
	background: hsl(0, 0%, 100%);
	box-shadow: 0 0 10px 3px hsl(0, 0%, 06%, 0.2);
	z-index: 1000;
	top: -140px;
	left: 10px;
	right: 10px;
	max-height: 170px;
	overflow-y: auto;
	&.option-enter {
		opacity: 0;
		transform: scale(0);
	}
	&.option-enter-active {
		opacity: 1;
		transform: scale(1);
		transition: opacity 200ms, transform 200ms;
	}
	&.option-exit {
		opacity: 1;
		transform: scale(1);
	}
	&.option-exit-active {
		transform: scale(0);
		opacity: 0;
		transition: opacity 200ms, transform 200ms;
	}
`;

const Suggestion = styled.p<{ focused?: boolean }>`
	margin: 0.5rem 0;
	border-bottom: 1px solid #ddd;
	padding: 0.4rem 0.6rem;
	background: ${({ focused }) => (focused ? 'black' : 'white')};
	color: ${({ focused }) => (focused ? 'white' : 'black')};
	transition: ease 0.1s;
	cursor: pointer;
	font-size: large;

	&:hover {
		background: #222;
		color: #ddd;
	}
`;

const IssueModal = styled.div`
	width: 500px;
	max-width: 100%;
`;

const Form = styled.form`
	position: relative;
`;

interface Props {
	onSubmit: (data: typeof init) => void;
	loading: boolean;
}

const RaiseIssueAction: React.FC<Props> = ({ onSubmit, loading }) => {
	const [type, setType] = useState<SelectLabel | null>(null);

	const [text, setText] = useState('');

	const [open, setOpen] = useState(false);

	const toggle = () => setOpen((old) => !old);

	const suggestions = useMemo(() => {
		if (type) {
			return issuesInfo.reduce(
				(a: string[], v) => (type.value === v.label ? [...a, ...v.options] : a),
				[]
			);
		}

		return issuesInfo.reduce((a: string[], v) => [...a, ...v.options], []);
	}, [type]);

	return (
		<IssueModal>
			<h2 className='text-center mb2'>Report Issue</h2>
			<hr style={{ margin: '1rem 0' }} />
			<Form
				onSubmit={(e) => {
					e.preventDefault();

					if (!type) return;

					onSubmit({ text, label: type.value });
				}}>
				<TransitionApp show={open} className='option'>
					<Suggestions>
						{suggestions.map((sug, i) => (
							<Suggestion
								focused={sug === text ? true : i === 0 && !text ? true : false}
								tabIndex={i}
								onClick={() => {
									setText(sug);
								}}
								key={sug + i}>
								{sug}
							</Suggestion>
						))}
					</Suggestions>
				</TransitionApp>

				<Select
					placeholder='Type of problem'
					isSearchable
					onChange={(entry) => setType(entry)}
					value={type}
					options={issueLabels}
				/>

				<TextField
					fullWidth
					margin='normal'
					required
					variant='outlined'
					name='description'
					label='Description of your problem'
					placeholder='You can select from the suggested text or customize it...'
					type='text'
					multiline
					rows={4}
					onFocus={toggle}
					onBlur={() => {
						setTimeout(toggle, 300);
					}}
					onChange={(e) => {
						const value = e.target.value;

						setText(value);
					}}
					value={text}
				/>
				<LoadingBtn type='submit' fullWidth disabled={loading}>
					Submit
				</LoadingBtn>
			</Form>
		</IssueModal>
	);
};

export default RaiseIssueAction;
