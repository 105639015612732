import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import Layout from "../components/layout/Layout";
import { Container } from "../components/styles/Container";
import { instanceOfAxios } from "../helper/axios";

const Settings = () => {
  const [delivaryTypeSettings, setDelivaryTypeSettings] = React.useState({
    express: false,
    "24h": false,
    "48h": false,
    outsideDhaka: false,
  });

  useEffect(() => {
    (async () => {
      try {
        const { data } = await instanceOfAxios.get(
          `/classes/config?where={"key":"deliverySettings"}`
        );
        console.log({ data });
        setDelivaryTypeSettings(data.results[0].value);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleChange = (event: any) => {
    setDelivaryTypeSettings({
      ...delivaryTypeSettings,
      [event.target.name]: event.target.checked,
    });
  };

  // console.log(delivaryTypeSettings);
  // console.log(JSON.parse(localStorage.getItem("user") || "").sessionToken);
  const saveChanges = async () => {
    try {
      const { data } = await instanceOfAxios.put(
        // dev key: cazy01Rj9o
        // prod key: QzTYfPYxs8
        `/classes/config/QzTYfPYxs8`,
        {
          value: { ...delivaryTypeSettings },
        },
        {
          headers: {
            // "X-Parse-MASTER-KEY": "master",
            "X-Parse-Session-Token": localStorage.getItem("user")
              ? JSON.parse(localStorage.getItem("user") || "").sessionToken
              : "",
          },
        }
      );
      console.log({ data });
      toast("Changes saved successfully");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout title="Settings" className="admin" loading={false} noBack noTitle>
      <Container>
        <Paper style={{ padding: "15px", display: "inline-block" }}>
          <h2>Delivery type</h2>
          <FormGroup onChange={handleChange}>
            <FormControlLabel
              label="24 hr"
              control={<Switch color="primary" defaultChecked />}
              name="24h"
              checked={delivaryTypeSettings["24h"]}
            />
            <FormControlLabel
              label="48 hr"
              control={<Switch color="primary" defaultChecked />}
              name="48h"
              checked={delivaryTypeSettings["48h"]}
            />
            <FormControlLabel
              control={<Switch color="primary" defaultChecked />}
              label="Express"
              name="express"
              checked={delivaryTypeSettings.express}
            />
            <FormControlLabel
              control={<Switch color="primary" defaultChecked />}
              label="Outside Dhaka"
              name="outsideDhaka"
              checked={delivaryTypeSettings.outsideDhaka}
            />
          </FormGroup>
          <br />
          <Button
            startIcon={<Save />}
            variant="contained"
            color="primary"
            onClick={saveChanges}
          >
            Save changes
          </Button>
        </Paper>
      </Container>
    </Layout>
  );
};

export default Settings;
