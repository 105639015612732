import React, { useEffect, useState } from 'react';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { useMutation } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';

import { useStore } from '../../context/Context';
import { StatusStream } from '../../context/contextTypes';
import { DELETE_PARCEL_BY_ID } from '../../graphql/mutations/parcel';
import useMessage from '../../hooks/useMessage';
import useOrders from '../../hooks/useOrders';

interface Props {
	status: StatusStream;
	objectId: string;
	oid: string;
	redirect?: boolean;
	spacing?: GridSpacing;
	toggleModal?: () => void;
	isRejected?: boolean;
}

const OrderAction: React.FC<Props> = ({
	status,
	objectId,
	oid,
	redirect,
	spacing,
	toggleModal,
	isRejected,
}) => {
	const {
		store: { user, brand },
	} = useStore();

	const userType = user?.user.type;

	const { handleNotification } = useMessage();

	console.log({ isRejected });

	const { refetch } = useOrders({
		admin: userType === 'admin',
		objectId: brand?.node.objectId,
		isRejected,
		callApi: false,
	});

	const [open, setOpen] = useState(false);

	const { goBack } = useHistory();

	const [deleteParcel, { loading, error }] = useMutation(DELETE_PARCEL_BY_ID, {
		variables: {
			id: objectId,
		},
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleDelete = () => {
		deleteParcel()
			.then(() => {
				return refetch();
			})
			.then(() => {
				handleNotification({
					variant: 'standard',
					type: 'success',
					message: 'Parcel deleted successfully',
				});

				if (redirect) {
					goBack();
				}

				setOpen(false);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	useEffect(() => {
		if (error) {
			handleNotification({
				variant: 'standard',
				type: 'error',
				message: error.message,
			});
		}
	}, [error, handleNotification]);

	const canEditMerchant = status === 'created' || status === 'rejected';

	const isNotAvailable = !canEditMerchant && userType === 'merchant';

	return (
		<Grid container direction='column' spacing={2}>
			<Grid item container>
				<Grid container item spacing={spacing}>
					<Grid item>
						<Tooltip
							title={isNotAvailable ? 'not available' : 'edit order'}
							arrow
							placement='top'>
							<Link
								onClick={(e) => {
									if (isNotAvailable) {
										e.preventDefault();
									}
								}}
								to={`/merchant/update-parcel?edit=${userType}&id=${objectId}`}>
								<img
									className='pointer'
									src='/custom/merchant/edit.svg'
									alt='edit'
									height='30'
									width='30'
								/>
							</Link>
						</Tooltip>
					</Grid>
					<Grid item>
						<Tooltip
							onClick={() => {
								if (isNotAvailable) {
									return null;
								}

								handleClickOpen();
							}}
							className='pointer'
							arrow
							title={isNotAvailable ? 'not available' : 'Delete order'}
							placement='top'>
							<img
								src='/custom/merchant/del.svg'
								alt='edit'
								height='30'
								width='30'
							/>
						</Tooltip>
					</Grid>
				</Grid>
			</Grid>
			{redirect && (
				<>
					<Grid item xs={12} onClick={toggleModal}>
						<Tooltip title='solve issue!' placement='right'>
							<p className='issue rounded'>Raise Issue</p>
						</Tooltip>
					</Grid>
					<Grid item xs={12}>
						<Tooltip title='Go to issue' placement='right'>
							<Link
								onClick={(e) => {
									if (isNotAvailable) {
										e.preventDefault();
									}
								}}
								to={`/issues/${objectId}`}>
								<Button color='primary' size='small' variant='outlined'>
									All Issues
								</Button>
							</Link>
						</Tooltip>
					</Grid>
				</>
			)}

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>Are you sure ?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description' variant='h5'>
						Do you want to <span className='error'>delete</span>{' '}
						<strong>{oid}</strong> !
					</DialogContentText>
					<DialogContentText id='alert-dialog-description' variant='subtitle1'>
						It will be <span className='error'>deleted</span>{' '}
						<strong>permanently.</strong>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant='contained'
						onClick={handleClose}
						disabled={loading}
						color='primary'>
						No
					</Button>
					<Button
						variant='outlined'
						onClick={handleDelete}
						color='default'
						disabled={loading}
						autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</Grid>
	);
};

export default OrderAction;
