import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Formik, FieldArray, Form } from 'formik';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddBox from '@material-ui/icons/AddBox';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import * as yup from 'yup';

import './becomeMerchant.scss';
import Common from './Common';
import { useAuth } from '../../hooks/useAuth';
import { getLocation } from '../../helper/helper';

const validationScheme = yup.object().shape({
	name: yup.string().required('please fill up').min(4),
	address: yup.string().required('please fill up').min(4),
	flat: yup.string().required('please fill up'),
	username: yup.string().required('please fill up').min(4),
	number: yup.array().of(
		yup
			.string()
			.min(1)
			.required('please fill up')
			.matches(/^[0][0-9]{10}$/, 'please select a valid number (eg. 017...)')
	),
	slot: yup.string().required(),
	password: yup
		.string()
		.required()
		.min(6, 'password should be atleast 6 characters long'),
});

const initValues = {
	name: '',
	address: '',
	username: '',
	password: '',
	flat: '',
	slot: 'evening',
	number: [''],
};

const BecomeMerchant: React.FC = () => {
	const { location } = useHistory<{ number: string }>();

	const query = new URLSearchParams(location.search);

	const number = query.get('phone');

	const [lat, setLat] = useState(24.45);
	const [long, setLong] = useState(24.67);
	const [area, setArea] = useState('uttara');

	const [accNum, setAccNum] = useState(() => {
		if (number) {
			return number;
		}

		return '';
	});

	const { signUpMerchant, createMerchant, merchantLoading, signUpLoading } =
		useAuth();

	const handleAddress = async (
		e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const value = e.target.value;

		let dataAddress;
		try {
			dataAddress = await getLocation(value);

			setLat(Number(dataAddress.geocoded_address.latitude));
			setLong(Number(dataAddress.geocoded_address.longitude));
			setArea(dataAddress.geocoded_address.area);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Common title='Register as a Merchant'>
			<div className='becomeMerchant'>
				<h1>Become a Merchant</h1>
				<Formik
					validateOnChange={true}
					validationSchema={validationScheme}
					onSubmit={async (values) => {
						const result = await signUpMerchant({
							username: accNum,
							password: values.password,
							name: values.username,
						});

						if (result) {
							createMerchant({
								name: values.name,
								address: values.address,
								token: result.sessionToken,
								number: values.number,
								lat,
								long,
								area,
								slot: values.slot,
								flat: values.flat,
							});
						}
					}}
					initialValues={{
						...initValues,
						number: [accNum],
					}}>
					{({
						handleChange,
						handleSubmit,
						handleBlur,
						isValid,
						errors,
						values,
						touched,
					}) => (
						<Form onSubmit={handleSubmit}>
							<TextField
								margin='normal'
								required
								error={errors.name && touched.name ? true : false}
								variant='outlined'
								name='name'
								label='Name of Business'
								placeholder='eg. spaceX'
								type='text'
								onBlur={handleBlur}
								onChange={handleChange}
								helperText={touched.name && errors.name && errors.name}
								value={values.name}
							/>

							<TextField
								margin='normal'
								required
								onBlur={handleBlur}
								error={errors.username && touched.username ? true : false}
								variant='outlined'
								name='username'
								label='Full Name'
								placeholder='eg. Elon Musk'
								type='text'
								onChange={handleChange}
								helperText={
									errors.username && touched.username && errors.username
								}
								value={values.username}
							/>
							<TextField
								margin='normal'
								required
								error={errors.address && touched.address ? true : false}
								variant='outlined'
								name='address'
								label='Address of Your Pickup Location'
								placeholder='Apartment name, Road, Block, Area'
								type='text'
								multiline
								rows={4}
								onBlur={(e) => {
									handleBlur(e);
									handleAddress(e);
								}}
								onChange={handleChange}
								helperText={errors.address && touched.address && errors.address}
								value={values.address}
							/>
							<TextField
								margin='normal'
								required
								onBlur={handleBlur}
								error={errors.flat && touched.flat ? true : false}
								variant='outlined'
								name='flat'
								label='Flat'
								placeholder='eg. (ABC)'
								type='text'
								onChange={handleChange}
								helperText={errors.flat && touched.flat && errors.flat}
								value={values.flat}
							/>
							<FieldArray
								name='number'
								render={({ push, remove }) => (
									<div className='number'>
										<Tooltip title='Add more numbers!' arrow placement='right'>
											<AddBox onClick={() => push('')} />
										</Tooltip>
										<b style={{ margin: '-.5rem 0 -1rem 0', color: 'teal' }}>
											** Your primary phone number is your login number.{' '}
											<span
												className={
													errors.number && errors.number[0] ? 'error' : ''
												}>
												{accNum ? accNum : ''}
											</span>
										</b>
										{values.number.map((value, i) => {
											const isFirstOne = i === 0;

											return (
												<>
													<TextField
														margin='normal'
														required
														onBlur={handleBlur}
														fullWidth
														key={i}
														name={`number[${i}]`}
														variant='outlined'
														error={
															errors.number && errors.number[i] ? true : false
														}
														label='Phone Number'
														placeholder={
															isFirstOne ? 'primary phone number' : '01...'
														}
														type='text'
														value={value}
														onChange={(e) => {
															if (isFirstOne) {
																setAccNum(e.target.value);
															}

															handleChange(e);
														}}
														helperText={
															errors.number &&
															touched.number &&
															errors.number[i]
														}
													/>
													{i !== 0 && (
														<Tooltip
															title='Delete above number!'
															arrow
															placement='right'>
															<DeleteIcon
																style={{ marginTop: '.8rem' }}
																onClick={() => remove(i)}
															/>
														</Tooltip>
													)}
												</>
											);
										})}
									</div>
								)}
							/>

							<TextField
								margin='normal'
								onBlur={handleBlur}
								required
								label='Password'
								placeholder='****'
								error={errors.password && touched.password ? true : false}
								variant='outlined'
								name='password'
								onChange={handleChange}
								helperText={
									errors.password && touched.password && errors.password
								}
								value={values.password}
								type='password'
							/>

							<Button
								disabled={!isValid || merchantLoading || signUpLoading}
								type='submit'
								size='large'
								variant='contained'
								color='primary'>
								Register Now
							</Button>
						</Form>
					)}
				</Formik>

				<p>
					Already have an account?{' '}
					<strong className=''>
						<Link to='/login'>Login</Link>
					</strong>
				</p>
			</div>
		</Common>
	);
};

export default BecomeMerchant;
