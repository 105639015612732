/* eslint-disable */
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import './merchantOtp.scss';
import Common from './Common';
import { instanceOfAxios } from '../../helper/axios';
import useMessage from '../../hooks/useMessage';
import { errorMessage, isDev } from '../../helper/helper';
import useOtp from '../../hooks/useOtp';
import TimerBtn from '../../components/timerBtn/TimerBtn';

const MerchantOtp: React.FC = () => {
	const ref1 = useRef() as MutableRefObject<HTMLInputElement>;
	const ref2 = useRef() as MutableRefObject<HTMLInputElement>;
	const ref3 = useRef() as MutableRefObject<HTMLInputElement>;
	const ref4 = useRef() as MutableRefObject<HTMLInputElement>;

	const btnRef = useRef() as MutableRefObject<HTMLButtonElement>;

	const formRef = useRef() as MutableRefObject<HTMLFormElement>;

	const [disabled, setDisabled] = useState(true);

	const { location, replace } = useHistory<{
		number: string;
		code: string;
		isForgot: boolean;
	}>();

	const { handleOtp } = useOtp();

	const { handleNotification } = useMessage();

	useEffect(() => {
		if (!location.state) {
			ref1.current.disabled = true;
			ref2.current.disabled = true;
			ref3.current.disabled = true;
			ref4.current.disabled = true;
			replace('/merchant-signup');
		} else {
			ref1.current.focus();
			isDev() && autoFill(location.state.code);
		}
	}, [location]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		switch (event.target.name) {
			case 'one':
				ref1.current.disabled = true;
				ref2.current.focus();

				break;
			case 'two':
				ref2.current.disabled = true;
				ref3.current.focus();

				break;
			case 'three':
				ref3.current.disabled = true;
				ref4.current.focus();

				break;
			case 'four':
				ref4.current.disabled = true;
				btnRef.current.disabled = false;
				btnRef.current.focus();
				setDisabled(false);
				handleVerify();
				break;
		}
	};

	const handleResend = () => {
		handleOtp({
			number: location.state.number,
			isForgot: location.state.isForgot,
		});
		reset();
	};

	const handleVerify = async () => {
		const code =
			ref1.current.value +
			ref2.current.value +
			ref3.current.value +
			ref4.current.value;

		try {
			await instanceOfAxios.post('/functions/verifyOtp', {
				phone: location.state.number,
				code,
			});

			if (location.state.isForgot) {
				replace('/reset-password', {
					number: location.state.number,
					code,
					isForgot: true,
				});
				return;
			}

			replace('/become-merchant', {
				number: location.state.number,
				code: '',
				isForgot: false,
			});
		} catch (error: any) {
			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});
		}
	};

	// for dev

	const autoFill = (value: string) => {
		const [v1, v2, v3, v4] = value.split('');

		ref1.current.value = v1;
		ref2.current.value = v2;
		ref3.current.value = v3;
		ref4.current.value = v4;

		setDisabled(false);

		btnRef.current.focus();
	};

	// reset value

	const reset = () => {
		ref1.current.disabled = false;
		ref2.current.disabled = false;
		ref3.current.disabled = false;
		ref4.current.disabled = false;

		ref1.current.value = '';
		ref2.current.value = '';
		ref3.current.value = '';
		ref4.current.value = '';

		ref1.current.focus();
		setDisabled(true);
	};

	return (
		<Common title='Merchant OTP'>
			<div className='merchantOtp'>
				<h1>Verify phone number!</h1>
				{!location.state?.isForgot && (
					<p>
						Please enter the 4 digit one-time-password (OTP) that we’ve sent to
						you at{' '}
						{location.state ? (
							<strong>{location.state.number}</strong>
						) : (
							<strong>...Oops no number to validate!</strong>
						)}{' '}
						<Link
							to={{
								pathname: `/merchant-signup?phone=${location.state.number}`,
							}}
						>
							Change Number !
						</Link>
					</p>
				)}
				<form ref={formRef} onSubmit={handleVerify}>
					<input
						ref={ref1}
						onChange={(e) => handleChange(e)}
						type='number'
						min={0}
						max={9}
						name='one'
					/>
					<input
						ref={ref2}
						onChange={(e) => handleChange(e)}
						type='number'
						min={0}
						max={9}
						name='two'
					/>
					<input
						ref={ref3}
						onChange={(e) => handleChange(e)}
						type='number'
						min={0}
						max={9}
						name='three'
					/>
					<input
						ref={ref4}
						onChange={(e) => handleChange(e)}
						type='number'
						min={0}
						max={9}
						name='four'
					/>
				</form>
				<Button disabled={!location.state} onClick={reset} variant='outlined'>
					clear
				</Button>
				<p>
					Didn’t recieve the OTP yet?{' '}
					<TimerBtn click={handleResend}>Resend OTP</TimerBtn>
				</p>
				<Button
					disabled={disabled}
					ref={btnRef}
					onClick={handleVerify}
					type='submit'
					size='large'
					variant='contained'
					color='primary'
				>
					Continue
				</Button>
				<p>
					By logging in you agree to our <strong>Terms &amp; Conditions</strong>
				</p>
			</div>
		</Common>
	);
};

export default MerchantOtp;
