import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useStore } from "../context/Context";
import { BrandInfo, User } from "../context/contextTypes";
import { LOGIN_USER } from "../graphql/mutations/auth";
import {
  BECOME_MERCHANT,
  SIGN_UP_MERCHANT,
} from "../graphql/mutations/merchantSignup";
import { GET_BRAND } from "../graphql/queries/brand";
import { instanceOfAxios } from "../helper/axios";
import useMessage from "./useMessage";

interface Login {
  username: string;
  password: string;
  name?: string;
}

interface CreateBrand {
  name: string;
  address: string;
  number: string[];
  zone?: "dhaka";
  flat: string;
  token: string;
  lat: number;
  long: number;
  area: string;
  slot: string;
}

interface ResetPass {
  username: string;
  password: string;
  code: string;
}

const REGEX_OF_SESSION = /^r:/;

export const useAuth = () => {
  const { dispatch } = useStore();

  const { handleNotification } = useMessage();

  const { replace } = useHistory();

  const [id, setId] = useState<string>("");

  // get brand

  const { refetch: refetchBrand, data: brandData } = useQuery<{
    brands: { edges: BrandInfo[] };
  }>(GET_BRAND, {
    skip: id ? false : true,
    variables: { objectId: id },
  });

  useEffect(() => {
    if (brandData?.brands.edges.length) {
      dispatch({
        type: "ADD_BRAND",
        payload: brandData["brands"]["edges"][0],
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandData]);

  const [loginUser, { loading }] = useMutation(LOGIN_USER);

  const dispatchAndNotify = (user: User) => {
    dispatch({ type: "ADD_USER", payload: user });

    handleNotification({
      variant: "standard",
      type: "success",
      message: "Successfully logged in",
    });
  };

  const fetchBrand = async (userId: string) => {
    setId(userId);

    await refetchBrand({ objectId: userId });
  };

  const login = async ({ username, password }: Login) => {
    try {
      const hasDirectAccess = REGEX_OF_SESSION.test(password);

      if (hasDirectAccess) {
        const { data } = await instanceOfAxios.get("/users/me", {
          headers: {
            "x-parse-session-token": password,
          },
        });

        await fetchBrand(data.objectId);

        dispatchAndNotify({ sessionToken: data.sessionToken, user: data });

        return;
      }

      const {
        data: {
          logIn: { viewer },
        },
      } = await loginUser({
        variables: {
          username,
          password,
        },
      });

      if (viewer.user.type === "merchant") {
        await fetchBrand(viewer.user.objectId);
      }

      dispatchAndNotify(viewer);
    } catch ({ graphQLErrors, networkError, message }) {
      handleNotification({
        variant: "standard",
        type: "error",
        message: `${message}`,
      });
      console.log(graphQLErrors);
      console.log(networkError);
    }
  };

  // logout

  const logout = () => {
    dispatch({ type: "REMOVE_USER", payload: null });
    dispatch({ type: "REMOVE_BRAND", payload: null });
    localStorage.clear();
  };

  // reset password

  const resetPassword = async ({ username, password, code }: ResetPass) => {
    try {
      await instanceOfAxios.post("/functions/reset", {
        username,
        password,
        code,
      });
      handleNotification({
        variant: "standard",
        message: "Password Reset Successfull",
        type: "success",
      });

      replace("/login");
    } catch (error: any) {
      handleNotification({
        variant: "standard",
        message: error.response ? error.response.data.error : error.message,
        type: "error",
      });
    }
  };

  // signup merchant

  const [signUp, { loading: signUpLoading }] = useMutation(BECOME_MERCHANT);

  const signUpMerchant = async ({
    username,
    password,
    name,
  }: Login): Promise<User | undefined> => {
    try {
      const {
        data: {
          signUp: { viewer },
        },
      } = await signUp({
        variables: {
          username,
          password,
          name,
        },
      });

      return viewer;
    } catch ({ graphQLErrors, networkError, message }) {
      handleNotification({
        variant: "standard",
        type: "error",
        message: `${message}`,
      });
      console.log(graphQLErrors);
      console.log(networkError);
    }
  };

  // create brand

  const [createBrand, { loading: merchantLoading }] =
    useMutation(SIGN_UP_MERCHANT);

  const createMerchant = async ({
    name,
    address,
    area,
    lat,
    long,
    number,
    zone = "dhaka",
    flat,
    token,
    slot,
  }: CreateBrand) => {
    try {
      await createBrand({
        variables: {
          name,
          address,
          lat,
          long,
          number,
          zone,
          area,
          flat,
          pickup_time: slot,
        },
        context: {
          headers: {
            "X-Parse-Session-Token": token,
          },
        },
      });

      handleNotification({
        variant: "standard",
        type: "success",
        message: "Brand Created Successfullly",
      });
      replace({ pathname: "/login" });
    } catch ({ graphQLErrors, networkError, message }) {
      handleNotification({
        variant: "standard",
        type: "error",
        message: `${message}`,
      });
      console.log(graphQLErrors);
      console.log(networkError);
    }
  };

  return {
    login,
    logout,
    loading,
    signUpLoading,
    signUpMerchant,
    createMerchant,
    resetPassword,
    merchantLoading,
    refetchBrand,
    setId,
  };
};
