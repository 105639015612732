export const cities = [
	{
		district: 'Dhaka',
		area: 'Mohammadpur',
	},
	{
		district: 'Dhaka',
		area: 'Dhanmondi',
	},
	{
		district: 'Dhaka',
		area: 'Gulshan',
	},
	{
		district: 'Dhaka',
		area: 'Kallyanpur',
	},
	{
		district: 'Dhaka',
		area: 'Shyamoli',
	},
	{
		district: 'Dhaka',
		area: 'Adabor',
	},
	{
		district: 'Dhaka',
		area: 'Darussalam',
	},
	{
		district: 'Dhaka',
		area: 'Gabtoli',
	},
	{
		district: 'Dhaka',
		area: 'Pallabi',
	},
	{
		district: 'Dhaka',
		area: 'Lalmatia',
	},
	{
		district: 'Dhaka',
		area: 'Mirpur DOHS',
	},
	{
		district: 'Dhaka',
		area: 'Kochukhet',
	},
	{
		district: 'Dhaka',
		area: 'Gudaraghat',
	},
	{
		district: 'Dhaka',
		area: 'Agargaon',
	},
	{
		district: 'Dhaka',
		area: 'Monipur',
	},
	{
		district: 'Dhaka',
		area: 'Sher-E-Bangla Nagar',
	},
	{
		district: 'Dhaka',
		area: 'Ibrahimpur',
	},
	{
		district: 'Dhaka',
		area: 'Dhaka Cantonment',
	},
	{
		district: 'Dhaka',
		area: 'Mirpur Cantonment',
	},
	{
		district: 'Dhaka',
		area: 'Kafrul',
	},
	{
		district: 'Dhaka',
		area: 'Vashantek',
	},
	{
		district: 'Dhaka',
		area: 'Manikdi',
	},
	{
		district: 'Dhaka',
		area: 'Matikata',
	},
	{
		district: 'Dhaka',
		area: 'M.E.S',
	},
	{
		district: 'Dhaka',
		area: 'Rupnagar Residential Area',
	},
	{
		district: 'Dhaka',
		area: 'Duaripara',
	},
	{
		district: 'Dhaka',
		area: 'Rainkhola',
	},
	{
		district: 'Dhaka',
		area: 'Mirpur Diabari',
	},
	{
		district: 'Dhaka',
		area: 'Mazar Road',
	},
	{
		district: 'Dhaka',
		area: 'Shagufta',
	},
	{
		district: 'Dhaka',
		area: 'Bawnia',
	},
	{
		district: 'Dhaka',
		area: 'Madina nagar',
	},
	{
		district: 'Dhaka',
		area: 'Dewanpara',
	},
	{
		district: 'Dhaka',
		area: 'Namapara',
	},
	{
		district: 'Dhaka',
		area: 'Mastertek',
	},
	{
		district: 'Dhaka',
		area: 'Balughat',
	},
	{
		district: 'Dhaka',
		area: 'Barontek',
	},
	{
		district: 'Dhaka',
		area: 'Goltek',
	},
	{
		district: 'Dhaka',
		area: 'Kurmitola',
	},
	{
		district: 'Dhaka',
		area: 'Shewra',
	},
	{
		district: 'Dhaka',
		area: 'Uttara',
	},
	{
		district: 'Dhaka',
		area: 'Uttarkhan',
	},
	{
		district: 'Dhaka',
		area: 'Dakshinkhan',
	},
	{
		district: 'Dhaka',
		area: 'Fayedabad',
	},
	{
		district: 'Dhaka',
		area: 'Ashkona',
	},
	{
		district: 'Dhaka',
		area: 'Kuril',
	},
	{
		district: 'Dhaka',
		area: 'Khilkhet',
	},
	{
		district: 'Dhaka',
		area: 'Nikunja',
	},
	{
		district: 'Dhaka',
		area: 'Azampur',
	},
	{
		district: 'Dhaka',
		area: 'Hajipara',
	},
	{
		district: 'Dhaka',
		area: 'Kawla',
	},
	{
		district: 'Dhaka',
		area: 'Naddapara',
	},
	{
		district: 'Dhaka',
		area: 'Bashundhara R/A',
	},
	{
		district: 'Dhaka',
		area: 'Vatara',
	},
	{
		district: 'Dhaka',
		area: 'Nadda',
	},
	{
		district: 'Dhaka',
		area: 'Baridhara',
	},
	{
		district: 'Dhaka',
		area: 'Baridhara DOHS',
	},
	{
		district: 'Dhaka',
		area: 'Notun Bazar',
	},
	{
		district: 'Dhaka',
		area: 'Badda',
	},
	{
		district: 'Dhaka',
		area: 'Shahjadpur',
	},
	{
		district: 'Dhaka',
		area: 'Uttor Badda',
	},
	{
		district: 'Dhaka',
		area: 'Middle Badda',
	},
	{
		district: 'Dhaka',
		area: 'South Badda',
	},
	{
		district: 'Dhaka',
		area: 'Merul Badda',
	},
	{
		district: 'Dhaka',
		area: 'Niketon',
	},
	{
		district: 'Dhaka',
		area: 'Banani',
	},
	{
		district: 'Dhaka',
		area: 'Banani DOHS',
	},
	{
		district: 'Dhaka',
		area: 'Mohakhali',
	},
	{
		district: 'Dhaka',
		area: 'Mohakhali DOHS',
	},
	{
		district: 'Dhaka',
		area: 'South Monipur',
	},
	{
		district: 'Dhaka',
		area: 'Shah Ali Bag',
	},
	{
		district: 'Dhaka',
		area: 'Aziz Palli',
	},
	{
		district: 'Dhaka',
		area: 'Bashtola',
	},
	{
		district: 'Dhaka',
		area: 'South Baridhara DIT Project',
	},
	{
		district: 'Dhaka',
		area: 'Aftabnagar',
	},
	{
		district: 'Dhaka',
		area: 'Bijoy Shoroni',
	},
	{
		district: 'Dhaka',
		area: 'Farmgate',
	},
	{
		district: 'Dhaka',
		area: 'Indira Road',
	},
	{
		district: 'Dhaka',
		area: 'Tejgaon',
	},
	{
		district: 'Dhaka',
		area: 'Tejkunipara',
	},
	{
		district: 'Dhaka',
		area: 'Razabazar',
	},
	{
		district: 'Dhaka',
		area: 'Sukrabad',
	},
	{
		district: 'Dhaka',
		area: 'Panthopath',
	},
	{
		district: 'Dhaka',
		area: 'Kalabagan',
	},
	{
		district: 'Dhaka',
		area: 'Green Road',
	},
	{
		district: 'Dhaka',
		area: 'Manik Mia Avenue',
	},
	{
		district: 'Dhaka',
		area: 'Asad Avenue',
	},
	{
		district: 'Dhaka',
		area: 'West Dhanmondi',
	},
	{
		district: 'Dhaka',
		area: 'Shankar',
	},
	{
		district: 'Dhaka',
		area: 'Rayer Bazar',
	},
	{
		district: 'Dhaka',
		area: 'Tallabag',
	},
	{
		district: 'Dhaka',
		area: 'Hazaribag',
	},
	{
		district: 'Dhaka',
		area: 'Pilkhana',
	},
	{
		district: 'Dhaka',
		area: 'New Market',
	},
	{
		district: 'Dhaka',
		area: 'Azimpur',
	},
	{
		district: 'Dhaka',
		area: 'Nilkhet',
	},
	{
		district: 'Dhaka',
		area: 'Lalbagh',
	},
	{
		district: 'Dhaka',
		area: 'Chawkbazar (Dhaka)',
	},
	{
		district: 'Dhaka',
		area: 'Naya Bazar',
	},
	{
		district: 'Dhaka',
		area: 'Tatibazar',
	},
	{
		district: 'Dhaka',
		area: 'Luxmi Bazar',
	},
	{
		district: 'Dhaka',
		area: 'Puran Dhaka',
	},
	{
		district: 'Dhaka',
		area: 'Siddique Bazar',
	},
	{
		district: 'Dhaka',
		area: 'Tikatuly',
	},
	{
		district: 'Dhaka',
		area: 'Motijheel',
	},
	{
		district: 'Dhaka',
		area: 'Nawabpur',
	},
	{
		district: 'Dhaka',
		area: 'Kaptan Bazar',
	},
	{
		district: 'Dhaka',
		area: 'Gulistan',
	},
	{
		district: 'Dhaka',
		area: 'Bongo Bazar',
	},
	{
		district: 'Dhaka',
		area: 'Chankarpul',
	},
	{
		district: 'Dhaka',
		area: 'Palashi',
	},
	{
		district: 'Dhaka',
		area: 'Dhakeshwari',
	},
	{
		district: 'Dhaka',
		area: 'Kamalapur',
	},
	{
		district: 'Dhaka',
		area: 'Wari',
	},
	{
		district: 'Dhaka',
		area: 'Narinda',
	},
	{
		district: 'Dhaka',
		area: 'Dainik Bangla Mor',
	},
	{
		district: 'Dhaka',
		area: 'Fakirapul',
	},
	{
		district: 'Dhaka',
		area: 'Kakrail',
	},
	{
		district: 'Dhaka',
		area: 'Naya Paltan',
	},
	{
		district: 'Dhaka',
		area: 'Bijoynagar',
	},
	{
		district: 'Dhaka',
		area: 'Press Club',
	},
	{
		district: 'Dhaka',
		area: 'High Court',
	},
	{
		district: 'Dhaka',
		area: 'Dhaka University',
	},
	{
		district: 'Dhaka',
		area: 'Dhaka Medical',
	},
	{
		district: 'Dhaka',
		area: 'Bongo Bondhu Avenue',
	},
	{
		district: 'Dhaka',
		area: 'Kazi Nazrul Islam Avenue',
	},
	{
		district: 'Dhaka',
		area: 'Kawran Bazar',
	},
	{
		district: 'Dhaka',
		area: 'Ramna',
	},
	{
		district: 'Dhaka',
		area: 'Shantinagar',
	},
	{
		district: 'Dhaka',
		area: 'Shantibag',
	},
	{
		district: 'Dhaka',
		area: 'Baily Road',
	},
	{
		district: 'Dhaka',
		area: 'Minto Road',
	},
	{
		district: 'Dhaka',
		area: 'Old Elephant Road',
	},
	{
		district: 'Dhaka',
		area: 'Eskaton Garden Road',
	},
	{
		district: 'Dhaka',
		area: 'Eskaton',
	},
	{
		district: 'Dhaka',
		area: 'Moghbazar',
	},
	{
		district: 'Dhaka',
		area: 'Mouchak',
	},
	{
		district: 'Dhaka',
		area: 'Malibag',
	},
	{
		district: 'Dhaka',
		area: 'Rampura',
	},
	{
		district: 'Dhaka',
		area: 'Shahbag',
	},
	{
		district: 'Dhaka',
		area: 'Katabon',
	},
	{
		district: 'Dhaka',
		area: 'Hatirpool',
	},
	{
		district: 'Dhaka',
		area: 'Bashabo',
	},
	{
		district: 'Dhaka',
		area: 'Khilgaon',
	},
	{
		district: 'Dhaka',
		area: 'Middle Bashabo',
	},
	{
		district: 'Dhaka',
		area: 'Goran',
	},
	{
		district: 'Dhaka',
		area: 'Madartek',
	},
	{
		district: 'Dhaka',
		area: 'Manik Nagar',
	},
	{
		district: 'Dhaka',
		area: 'Shahjahanpur',
	},
	{
		district: 'Dhaka',
		area: 'Banasree',
	},
	{
		district: 'Dhaka',
		area: 'Meradia',
	},
	{
		district: 'Dhaka',
		area: 'Khilbari Tek',
	},
	{
		district: 'Dhaka',
		area: 'Bawaliapara',
	},
	{
		district: 'Dhaka',
		area: 'Mughdapara',
	},
	{
		district: 'Dhaka',
		area: 'Golapbag (Dhaka)',
	},
	{
		district: 'Dhaka',
		area: 'Jatrabari',
	},
	{
		district: 'Dhaka',
		area: 'Hatirjheel',
	},
	{
		district: 'Dhaka',
		area: 'Banglamotor',
	},
	{
		district: 'Dhaka',
		area: 'Paribag',
	},
	{
		district: 'Dhaka',
		area: 'Bakshibazar',
	},
	{
		district: 'Dhaka',
		area: 'Elephant Road',
	},
	{
		district: 'Dhaka',
		area: 'Kathalbagan',
	},
	{
		district: 'Dhaka',
		area: 'Malibagh Taltola',
	},
	{
		district: 'Dhaka',
		area: 'Central Road',
	},
	{
		district: 'Dhaka',
		area: 'Sabujbag',
	},
	{
		district: 'Dhaka',
		area: 'Shiddheswari',
	},
	{
		district: 'Dhaka',
		area: 'Dolaikhal',
	},
	{
		district: 'Dhaka',
		area: 'Shegunbagicha',
	},
	{
		district: 'Dhaka',
		area: 'Rajarbag',
	},
	{
		district: 'Dhaka',
		area: 'Babubazar',
	},
	{
		district: 'Dhaka',
		area: 'Islampur(Dhaka)',
	},
	{
		district: 'Dhaka',
		area: 'Imamgonj',
	},
	{
		district: 'Dhaka',
		area: 'Nayabazar',
	},
	{
		district: 'Dhaka',
		area: 'Zigatola',
	},
	{
		district: 'Dhaka',
		area: 'Kazipara',
	},
	{
		district: 'Dhaka',
		area: 'Shewrapara',
	},
	{
		district: 'Dhaka',
		area: 'Kalshi',
	},
	{
		district: 'Dhaka',
		area: 'Sutrapur',
	},
	{
		district: 'Dhaka',
		area: 'Purana Paltan',
	},
	{
		district: 'Dhaka',
		area: 'Shyampur',
	},
	{
		district: 'Dhaka',
		area: 'Dholaipar',
	},
	{
		district: 'Dhaka',
		area: 'Monipuripara',
	},
	{
		district: 'Dhaka',
		area: 'Bosila',
	},
	{
		district: 'Dhaka',
		area: 'Shonir Akhra',
	},
	{
		district: 'Dhaka',
		area: 'Mirpur(Dhaka)',
	},
	{
		district: 'Dhaka',
		area: 'Bongshal',
	},
	{
		district: 'Dhaka',
		area: 'Siddweswari',
	},
	{
		district: 'Dhaka',
		area: 'Nakhalpara',
	},
	{
		district: 'Dhaka',
		area: 'Dokshingaon',
	},
	{
		district: 'Dhaka',
		area: 'Joar Shahara',
	},
	{
		district: 'Dhaka',
		area: 'Science Lab',
	},
	{
		district: 'Dhaka',
		area: 'Sobhanbag',
	},
	{
		district: 'Dhaka',
		area: 'ECB Chattar',
	},
	{
		district: 'Dhaka',
		area: 'Arambag',
	},
	{
		district: 'Dhaka',
		area: 'Armanitola',
	},
	{
		district: 'Dhaka',
		area: 'Islambag',
	},
	{
		district: 'Dhaka',
		area: 'Kamarpara',
	},
	{
		district: 'Dhaka',
		area: 'Mitford',
	},
	{
		district: 'Dhaka',
		area: 'Shakhari Bazar',
	},
	{
		district: 'Dhaka',
		area: 'Katherpol',
	},
	{
		district: 'Dhaka',
		area: 'Bangla Bazar',
	},
	{
		district: 'Dhaka',
		area: 'Patuatuly',
	},
	{
		district: 'Dhaka',
		area: 'Nandipara',
	},
	{
		district: 'Dhaka',
		area: 'Nazira Bazar',
	},
	{
		district: 'Dhaka',
		area: 'Gopibag',
	},
	{
		district: 'Dhaka',
		area: 'Shwamibag',
	},
	{
		district: 'Dhaka',
		area: 'Sayedabad',
	},
	{
		district: 'Dhaka',
		area: 'Sadarghat (Dhaka)',
	},
	{
		district: 'Dhaka',
		area: 'Kaltabazar',
	},
	{
		district: 'Dhaka',
		area: 'Jurain',
	},
	{
		district: 'Dhaka',
		area: 'Gandaria',
	},
	{
		district: 'Dhaka',
		area: 'RayerBag',
	},
	{
		district: 'Dhaka',
		area: 'Faridabad (Jatrabari)',
	},
	{
		district: 'Dhaka',
		area: 'Matuail',
	},
	{
		district: 'Dhaka',
		area: 'Donia',
	},
	{
		district: 'Dhaka',
		area: 'Postogola',
	},
	{
		district: 'Dhaka',
		area: 'Konapara',
	},
	{
		district: 'Dhaka',
		area: 'Dhaka Uddyan',
	},
	{
		district: 'Dhaka',
		area: 'Shekhertek',
	},
	{
		district: 'Dhaka',
		area: 'Mirpur Taltola',
	},
	{
		district: 'Dhaka',
		area: 'Manda(Dhaka)',
	},
	{
		district: 'Dhaka',
		area: 'Shahjahanpur (Dhaka)',
	},
	{
		district: 'Dhaka',
		area: 'Hatirjheel',
	},
	{
		district: 'Dhaka',
		area: 'Dhaka uddan',
	},
	{
		district: 'Dhaka',
		area: 'Nobodoy',
	},
	{
		district: 'Dhaka',
		area: 'Chad Uddan',
	},
	{
		district: 'Dhaka',
		area: 'Mohammadia Housing',
	},
	{
		district: 'Dhaka',
		area: 'Ring Road',
	},
	{
		district: 'Dhaka',
		area: 'Tajmahal Road',
	},
	{
		district: 'Dhaka',
		area: 'Nurjahan Road',
	},
	{
		district: 'Dhaka',
		area: 'Rajia Sultana Road',
	},
	{
		district: 'Dhaka',
		area: 'Uttara Sector-4',
	},
	{
		district: 'Dhaka',
		area: 'Goaltek',
	},
	{
		district: 'Dhaka',
		area: 'Chalabon',
	},
	{
		district: 'Dhaka',
		area: 'Mollapara',
	},
	{
		district: 'Dhaka',
		area: 'Azampur (East)',
	},
	{
		district: 'Dhaka',
		area: 'Uttara Sector 5',
	},
	{
		district: 'Dhaka',
		area: 'Uttara Sector 14',
	},
	{
		district: 'Dhaka',
		area: 'Uttara Sector 3',
	},
	{
		district: 'Dhaka',
		area: 'Uttara Sector 7',
	},
	{
		district: 'Dhaka',
		area: 'Uttara Sector 9',
	},
	{
		district: 'Dhaka',
		area: 'Uttara Sector 11',
	},
	{
		district: 'Dhaka',
		area: 'Nalbhog',
	},
	{
		district: 'Dhaka',
		area: 'Azampur (West)',
	},
	{
		district: 'Dhaka',
		area: 'Phulbaria',
	},
	{
		district: 'Dhaka',
		area: 'Bhatuliya',
	},
	{
		district: 'Dhaka',
		area: 'Bamnartek',
	},
	{
		district: 'Dhaka',
		area: 'Kallanpur',
	},
	{
		district: 'Dhaka',
		area: 'Lalkuthi',
	},
	{
		district: 'Dhaka',
		area: 'Mirpur 1',
	},
	{
		district: 'Dhaka',
		area: 'Tolarbag',
	},
	{
		district: 'Dhaka',
		area: 'Ahmed Nagar',
	},
	{
		district: 'Dhaka',
		area: 'Paikpara',
	},
	{
		district: 'Dhaka',
		area: 'Pirerbag',
	},
	{
		district: 'Dhaka',
		area: 'Taltola',
	},
	{
		district: 'Dhaka',
		area: 'MES Colony',
	},
	{
		district: 'Dhaka',
		area: 'Zia Colony',
	},
	{
		district: 'Dhaka',
		area: 'Ajiz Market',
	},
	{
		district: 'Dhaka',
		area: 'Aga Nagar',
	},
	{
		district: 'Dhaka',
		area: 'Kunipara',
	},
	{
		district: 'Dhaka',
		area: 'Babli Masjid',
	},
	{
		district: 'Dhaka',
		area: 'Kaderabad Housing',
	},
	{
		district: 'Dhaka',
		area: 'Gacha',
	},
	{
		district: 'Dhaka',
		area: 'Boro Bari',
	},
	{
		district: 'Gazipur',
		area: 'Board Bazar',
	},
	{
		district: 'Dhaka',
		area: 'Kamarjuri',
	},
	{
		district: 'Dhaka',
		area: 'Dattapara',
	},
	{
		district: 'Dhaka',
		area: 'Ershadnagar',
	},
	{
		district: 'Dhaka',
		area: 'Apollo',
	},
	{
		district: 'Dhaka',
		area: 'Nurer Chala',
	},
	{
		district: 'Dhaka',
		area: 'Bawaila Para',
	},
	{
		district: 'Dhaka',
		area: 'Satarkul',
	},
	{
		district: 'Dhaka',
		area: 'Khilbar Tek',
	},
	{
		district: 'Dhaka',
		area: 'Buddho Mondir',
	},
	{
		district: 'Dhaka',
		area: 'Sipahibag',
	},
	{
		district: 'Dhaka',
		area: 'TT Para',
	},
	{
		district: 'Dhaka',
		area: 'Eastern Housing',
	},
	{
		district: 'Dhaka',
		area: 'Teskunipara',
	},
	{
		district: 'Dhaka',
		area: 'DHAKA TENARI MORE',
	},
	{
		district: 'Dhaka',
		area: 'Shahidnagar',
	},
	{
		district: 'Dhaka',
		area: 'Jhigatola',
	},
	{
		district: 'Dhaka',
		area: 'Polashi',
	},
	{
		district: 'Dhaka',
		area: 'Satmoshjid Road',
	},
	{
		district: 'Dhaka',
		area: 'Shukrabad',
	},
	{
		district: 'Dhaka',
		area: 'BNP Bazar',
	},
	{
		district: 'Dhaka',
		area: 'Kalachandpur',
	},
	{
		district: 'Dhaka',
		area: 'Jogonnathpur',
	},
	{
		district: 'Dhaka',
		area: 'Kuratuli',
	},
	{
		district: 'Dhaka',
		area: 'Alatunnessa School Road',
	},
	{
		district: 'Dhaka',
		area: 'Purbo Rampura',
	},
	{
		district: 'Dhaka',
		area: 'Chairman Goli',
	},
	{
		district: 'Dhaka',
		area: 'Confidence Tower',
	},
	{
		district: 'Dhaka',
		area: 'Fuji Trade Center',
	},
	{
		district: 'Dhaka',
		area: 'Khil Barirtek',
	},
	{
		district: 'Dhaka',
		area: 'Korail',
	},
	{
		district: 'Dhaka',
		area: 'Mahanogor',
	},
	{
		district: 'Dhaka',
		area: 'Nimtola',
	},
	{
		district: 'Dhaka',
		area: 'Nurerchala',
	},
	{
		district: 'Dhaka',
		area: 'Pastola Bazar',
	},
	{
		district: 'Dhaka',
		area: 'Poschim Badda',
	},
	{
		district: 'Dhaka',
		area: 'Purbo Badda',
	},
	{
		district: 'Dhaka',
		area: 'Sat-tola Bazar',
	},
	{
		district: 'Dhaka',
		area: 'Shaheenbagh',
	},
	{
		district: 'Dhaka',
		area: 'Subastu',
	},
	{
		district: 'Dhaka',
		area: 'ICDDRB',
	},
	{
		district: 'Dhaka',
		area: 'Satrasta',
	},
	{
		district: 'Dhaka',
		area: 'Tekpara Adorsonagor',
	},
	{
		district: 'Dhaka',
		area: 'Uttar Badda',
	},
	{
		district: 'Dhaka',
		area: 'Wireless',
	},
	{
		district: 'Dhaka',
		area: 'Solmaid',
	},
	{
		district: 'Dhaka',
		area: '300 Feet',
	},
	{
		district: 'Dhaka',
		area: 'Mirhazirbagh',
	},
	{
		district: 'Dhaka',
		area: 'Mahut Tuli',
	},
	{
		district: 'Dhaka',
		area: 'Alubazar',
	},
	{
		district: 'Dhaka',
		area: 'Badam Toli',
	},
	{
		district: 'Dhaka',
		area: 'Chamelibag',
	},
	{
		district: 'Dhaka',
		area: 'Dholaikhal',
	},
	{
		district: 'Dhaka',
		area: 'Doyagonj',
	},
	{
		district: 'Dhaka',
		area: 'Farashgong',
	},
	{
		district: 'Dhaka',
		area: 'Dholpur',
	},
	{
		district: 'Keraniganj',
		area: 'Keraniganj',
	},
	{
		district: 'Keraniganj',
		area: 'Kodomtoli',
	},
	{
		district: 'Dhaka',
		area: 'Kotwali (Puran Dhaka)',
	},
	{
		district: 'Dhaka',
		area: 'Rajar Dewri',
	},
	{
		district: 'Dhaka',
		area: 'Sat rowja',
	},
	{
		district: 'Dhaka',
		area: 'Tantibazar',
	},
	{
		district: 'Dhaka',
		area: 'Rosulbagh',
	},
	{
		district: 'Dhaka',
		area: 'Gopalpur',
	},
	{
		district: 'Dhaka',
		area: 'College Gate',
	},
	{
		district: 'Dhaka',
		area: 'Badekomelosshor',
	},
	{
		district: 'Dhaka',
		area: 'Rail Station',
	},
	{
		district: 'Dhaka',
		area: 'Uttarkhan Mazar',
	},
	{
		district: 'Dhaka',
		area: 'Dakshinkhan Bazar',
	},
	{
		district: 'Dhaka',
		area: 'Hajipara-Dakshinkhan',
	},
	{
		district: 'Dhaka',
		area: 'Ranavola',
	},
	{
		district: 'Dhaka',
		area: 'Joynal Market',
	},
	{
		district: 'Dhaka',
		area: 'Johura Market',
	},
	{
		district: 'Dhaka',
		area: 'Habib Market',
	},
	{
		district: 'Dhaka',
		area: 'BDR Market-House Building',
	},
	{
		district: 'Dhaka',
		area: 'BDR Market-Sector 6',
	},
	{
		district: 'Dhaka',
		area: 'Moinartek',
	},
	{
		district: 'Dhaka',
		area: 'Atipara',
	},
	{
		district: 'Dhaka',
		area: 'Kot Bari',
	},
	{
		district: 'Dhaka',
		area: 'Abdullahpur',
	},
	{
		district: 'Dhaka',
		area: 'Mollartek',
	},
	{
		district: 'Dhaka',
		area: 'Gawair',
	},
	{
		district: 'Dhaka',
		area: 'Kosaibari',
	},
	{
		district: 'Dhaka',
		area: 'Prembagan',
	},
	{
		district: 'Dhaka',
		area: 'Kachkura',
	},
	{
		district: 'Dhaka',
		area: 'Helal Market',
	},
	{
		district: 'Dhaka',
		area: 'Chamur Khan',
	},
	{
		district: 'Dhaka',
		area: 'Ismailkholla',
	},
	{
		district: 'Dhaka',
		area: 'Masterpara',
	},
	{
		district: 'Dhaka',
		area: 'Munda',
	},
	{
		district: 'Dhaka',
		area: 'Barua',
	},
	{
		district: 'Dhaka',
		area: 'Namapara-Khilkhet',
	},
	{
		district: 'Dhaka',
		area: 'Ahalia-Uttara',
	},
	{
		district: 'Dhaka',
		area: 'Ainusbag-Uttara',
	},
	{
		district: 'Dhaka',
		area: 'Diabari',
	},
	{
		district: 'Dhaka',
		area: 'Habib Market-Uttara',
	},
	{
		district: 'Dhaka',
		area: 'Pakuria-Uttara',
	},
	{
		district: 'Dhaka',
		area: 'Aftab Nagar',
	},
	{
		district: 'Dhaka',
		area: 'Gulbagh',
	},
	{
		district: 'Dhaka',
		area: 'Meradiya Bazar',
	},
	{
		district: 'Dhaka',
		area: 'Mirbagh',
	},
	{
		district: 'Dhaka',
		area: 'Modhubagh',
	},
	{
		district: 'Dhaka',
		area: 'Rampura TV center',
	},
	{
		district: 'Dhaka',
		area: 'TV gate',
	},
	{
		district: 'Dhaka',
		area: 'Ulan road',
	},
	{
		district: 'Dhaka',
		area: 'Mirpur 10',
	},
	{
		district: 'Dhaka',
		area: 'Gudaraghat-Mirpur',
	},
	{
		district: 'Dhaka',
		area: 'Namapara-Mirpur',
	},
	{
		district: 'Dhaka',
		area: 'Technical',
	},
	{
		district: 'Dhaka',
		area: 'Mirpur 13 /14 / 15',
	},
	{
		district: 'Dhaka',
		area: 'Benaroshi Polli',
	},
	{
		district: 'Dhaka',
		area: 'Beribadh-Mirpur',
	},
	{
		district: 'Dhaka',
		area: 'Buddhijibi Road',
	},
	{
		district: 'Dhaka',
		area: 'Cantonment',
	},
	{
		district: 'Dhaka',
		area: 'Mondir-Mirpur',
	},
	{
		district: 'Dhaka',
		area: 'Palasnagor',
	},
	{
		district: 'Dhaka',
		area: 'Purobi',
	},
	{
		district: 'Dhaka',
		area: 'Rupnagor',
	},
	{
		district: 'Dhaka',
		area: 'Senpara',
	},
	{
		district: 'Dhaka',
		area: 'Mirpur BRTA',
	},
	{
		district: 'Dhaka',
		area: 'Zoo',
	},
	{
		district: 'Savar',
		area: 'Savar',
	},
	{
		district: 'Savar',
		area: 'Birulia',
	},
	{
		district: 'Savar',
		area: 'Savar Cantonment',
	},
	{
		district: 'Gazipur',
		area: 'Tongi',
	},
	{
		district: 'Dhaka',
		area: 'Demra',
	},
	{
		district: 'Ashulia',
		area: 'Ashulia',
	},
	{
		district: 'Savar',
		area: 'Amin Bazar',
	},
	{
		district: 'Keraniganj',
		area: 'Kamrangirchar',
	},
	{
		district: 'Savar',
		area: 'Dhamrai',
	},
	{
		district: 'Gazipur',
		area: 'Gazipur Sadar',
	},
	{
		district: 'Gazipur',
		area: 'Kaliakoir',
	},
	{
		district: 'Gazipur',
		area: 'Kaliganj',
	},
	{
		district: 'Gazipur',
		area: 'Kapasia',
	},
	{
		district: 'Gazipur',
		area: 'Sreepur(Gazipur)',
	},
	{
		district: 'Narayanganj',
		area: 'Araihazar',
	},
	{
		district: 'Narayanganj',
		area: 'Bhuigrah',
	},
	{
		district: 'Narayanganj',
		area: 'Bandar (Narayanganj)',
	},
	{
		district: 'Narayanganj',
		area: 'Narayanganj Sadar',
	},
	{
		district: 'Narayanganj',
		area: 'Rupganj',
	},
	{
		district: 'Narayanganj',
		area: 'Sonargaon',
	},
	{
		district: 'Narayanganj',
		area: 'Siddhirganj',
	},
	{
		district: 'Narayanganj',
		area: 'Fatullah',
	},
	{
		district: 'Keraniganj',
		area: 'Kalatia',
	},
	{
		district: 'Savar',
		area: 'Baipayl',
	},
	{
		district: 'Narayanganj',
		area: 'Chittagong Road (Narayanganj)',
	},
	{
		district: 'Keraniganj',
		area: 'Kathuria',
	},
	{
		district: 'Keraniganj',
		area: 'Goljarbag',
	},
	{
		district: 'Keraniganj',
		area: 'Nazirabag',
	},
	{
		district: 'Keraniganj',
		area: 'Nazarganj',
	},
	{
		district: 'Keraniganj',
		area: 'Zinzira',
	},
	{
		district: 'Tongi',
		area: 'Auchpara',
	},
	{
		district: 'Gazipur',
		area: 'Cherag Ali',
	},
	{
		district: 'Tongi',
		area: 'Tongi',
	},
	{
		district: 'Tongi',
		area: 'Tongi Bazar (Dhaka)',
	},
	{
		district: 'Narayanganj',
		area: 'Bhuighar',
	},
	{
		district: 'Keraniganj',
		area: 'Hasnabad',
	},
	{
		district: 'Tongi',
		area: 'Bonomala',
	},
	{
		district: 'Tongi',
		area: 'Morkun',
	},
	{
		district: 'Tongi',
		area: 'Boro Dewra Dakkhin Para',
	},
	{
		district: 'Gazipur',
		area: 'Boardbazar',
	},
	{
		district: 'Gazipur',
		area: 'Gazipura',
	},
	{
		district: 'Gazipur',
		area: 'Hossain Market',
	},
	{
		district: 'Gazipur',
		area: 'Signboard',
	},
	{
		district: 'Gazipur',
		area: 'Joydebpur',
	},
	{
		district: 'Gazipur',
		area: 'Choidana',
	},
	{
		district: 'Gazipur',
		area: 'Deger Chala',
	},
	{
		district: 'Gazipur',
		area: 'Majukhan',
	},
	{
		district: 'Tongi',
		area: 'Milgate',
	},
	{
		district: 'Tongi',
		area: 'Surtaranga',
	},
	{
		district: 'Tongi',
		area: 'Targach',
	},
	{
		district: 'Gazipur',
		area: 'Boro Dewra',
	},
	{
		district: 'Dohar',
		area: 'Dohar',
	},
	{
		district: 'Nawabganj',
		area: 'Nawabganj',
	},
	{
		district: 'Faridpur',
		area: 'Alfadanga',
	},
	{
		district: 'Faridpur',
		area: 'Bhanga',
	},
	{
		district: 'Faridpur',
		area: 'Boalmari',
	},
	{
		district: 'Faridpur',
		area: 'Char Bhadrasan',
	},
	{
		district: 'Faridpur',
		area: 'Faridpur Sadar',
	},
	{
		district: 'Faridpur',
		area: 'Madhukhali',
	},
	{
		district: 'Faridpur',
		area: 'Nagarkanda',
	},
	{
		district: 'Faridpur',
		area: 'Sadarpur',
	},
	{
		district: 'Faridpur',
		area: 'Saltha',
	},
	{
		district: 'Gopalganj',
		area: 'Gopalganj Sadar',
	},
	{
		district: 'Gopalganj',
		area: 'Kasiani',
	},
	{
		district: 'Gopalganj',
		area: 'Kotalipara',
	},
	{
		district: 'Gopalganj',
		area: 'Maksudpur',
	},
	{
		district: 'Gopalganj',
		area: 'Tungipara',
	},
	{
		district: 'Jamalpur',
		area: 'Bakshiganj',
	},
	{
		district: 'Jamalpur',
		area: 'Dewanganj',
	},
	{
		district: 'Jamalpur',
		area: 'Islampur(Jamalpur)',
	},
	{
		district: 'Jamalpur',
		area: 'Jamalpur Sadar',
	},
	{
		district: 'Jamalpur',
		area: 'Madarganj',
	},
	{
		district: 'Jamalpur',
		area: 'Melandah',
	},
	{
		district: 'Jamalpur',
		area: 'Sharishabari',
	},
	{
		district: 'Kishoreganj',
		area: 'Austogram',
	},
	{
		district: 'Kishoreganj',
		area: 'Bajitpur',
	},
	{
		district: 'Kishoreganj',
		area: 'Bhairab',
	},
	{
		district: 'Kishoreganj',
		area: 'Hosainpur',
	},
	{
		district: 'Kishoreganj',
		area: 'Itna',
	},
	{
		district: 'Kishoreganj',
		area: 'Karimganj',
	},
	{
		district: 'Kishoreganj',
		area: 'Katiadi',
	},
	{
		district: 'Kishoreganj',
		area: 'Kishoreganj Sadar',
	},
	{
		district: 'Kishoreganj',
		area: 'Kuliarchar',
	},
	{
		district: 'Kishoreganj',
		area: 'Mithamain',
	},
	{
		district: 'Kishoreganj',
		area: 'Nikli',
	},
	{
		district: 'Kishoreganj',
		area: 'Pakundia',
	},
	{
		district: 'Kishoreganj',
		area: 'Tarail',
	},
	{
		district: 'Madaripur',
		area: 'Kalkini',
	},
	{
		district: 'Madaripur',
		area: 'Madaripur Sadar',
	},
	{
		district: 'Madaripur',
		area: 'Rajoir',
	},
	{
		district: 'Madaripur',
		area: 'Shibchar',
	},
	{
		district: 'Manikganj',
		area: 'Daulatpur(Manikganj)',
	},
	{
		district: 'Manikganj',
		area: 'Ghior',
	},
	{
		district: 'Manikganj',
		area: 'Harirampur (Manikganj)',
	},
	{
		district: 'Manikganj',
		area: 'Manikganj Sadar',
	},
	{
		district: 'Manikganj',
		area: 'Saturia',
	},
	{
		district: 'Manikganj',
		area: 'Shibalaya',
	},
	{
		district: 'Manikganj',
		area: 'Singair',
	},
	{
		district: 'Munshiganj',
		area: 'Gazaria',
	},
	{
		district: 'Munshiganj',
		area: 'Lauhajang',
	},
	{
		district: 'Munshiganj',
		area: 'Munshiganj Sadar',
	},
	{
		district: 'Munshiganj',
		area: 'Serajdikhan',
	},
	{
		district: 'Munshiganj',
		area: 'Sreenagar',
	},
	{
		district: 'Munshiganj',
		area: 'Tangibari',
	},
	{
		district: 'Mymensingh',
		area: 'Bhaluka',
	},
	{
		district: 'Mymensingh',
		area: 'Dhubaura',
	},
	{
		district: 'Mymensingh',
		area: 'Fulbaria (Mymensingh',
	},
	{
		district: 'Mymensingh',
		area: 'Fulpur',
	},
	{
		district: 'Mymensingh',
		area: 'Goffargaon',
	},
	{
		district: 'Mymensingh',
		area: 'Gouripur (Mymensingh)',
	},
	{
		district: 'Mymensingh',
		area: 'Haluaghat',
	},
	{
		district: 'Mymensingh',
		area: 'Iswarganj',
	},
	{
		district: 'Mymensingh',
		area: 'Mymensingh Sadar',
	},
	{
		district: 'Mymensingh',
		area: 'Muktagacha',
	},
	{
		district: 'Mymensingh',
		area: 'Nandail',
	},
	{
		district: 'Mymensingh',
		area: 'Trishal',
	},
	{
		district: 'Narsingdi',
		area: 'Belabo',
	},
	{
		district: 'Narsingdi',
		area: 'Monohardi',
	},
	{
		district: 'Narsingdi',
		area: 'Narsingdi Sadar',
	},
	{
		district: 'Narsingdi',
		area: 'Palash',
	},
	{
		district: 'Narsingdi',
		area: 'Raipura',
	},
	{
		district: 'Narsingdi',
		area: 'Shibpur',
	},
	{
		district: 'Netrokona',
		area: 'Atpara',
	},
	{
		district: 'Netrokona',
		area: 'Barhatta',
	},
	{
		district: 'Netrokona',
		area: 'Durgapur(Netrokona)',
	},
	{
		district: 'Netrokona',
		area: 'Kalmakanda',
	},
	{
		district: 'Netrokona',
		area: 'Kendua',
	},
	{
		district: 'Netrokona',
		area: 'Khaliajuri',
	},
	{
		district: 'Netrokona',
		area: 'Madan',
	},
	{
		district: 'Netrokona',
		area: 'Mohanganj',
	},
	{
		district: 'Netrokona',
		area: 'Netrokona Sadar',
	},
	{
		district: 'Netrokona',
		area: 'Purbadhala',
	},
	{
		district: 'Rajbari',
		area: 'Baliakandi',
	},
	{
		district: 'Rajbari',
		area: 'Goalunda',
	},
	{
		district: 'Rajbari',
		area: 'Pangsha',
	},
	{
		district: 'Rajbari',
		area: 'Rajbari Sadar',
	},
	{
		district: 'Rajbari',
		area: 'Kalukhali',
	},
	{
		district: 'Shariatpur',
		area: 'Bhedarganj',
	},
	{
		district: 'Shariatpur',
		area: 'Damudiya',
	},
	{
		district: 'Shariatpur',
		area: 'Gosairhat',
	},
	{
		district: 'Shariatpur',
		area: 'Zajira',
	},
	{
		district: 'Shariatpur',
		area: 'Naria',
	},
	{
		district: 'Shariatpur',
		area: 'Shariatpur Sadar',
	},
	{
		district: 'Sherpur',
		area: 'Jhenaigati',
	},
	{
		district: 'Sherpur',
		area: 'Nakla',
	},
	{
		district: 'Sherpur',
		area: 'Nalitabari',
	},
	{
		district: 'Sherpur',
		area: 'Sherpur Sadar',
	},
	{
		district: 'Sherpur',
		area: 'Sribordi',
	},
	{
		district: 'Tangail',
		area: 'Bashail',
	},
	{
		district: 'Tangail',
		area: 'Bhuapur',
	},
	{
		district: 'Tangail',
		area: 'Delduar',
	},
	{
		district: 'Tangail',
		area: 'Ghatail',
	},
	{
		district: 'Tangail',
		area: 'Gopalpur (Tangail)',
	},
	{
		district: 'Tangail',
		area: 'Kalihati',
	},
	{
		district: 'Tangail',
		area: 'Mirzapur',
	},
	{
		district: 'Tangail',
		area: 'Modhupur',
	},
	{
		district: 'Tangail',
		area: 'Nagarpur',
	},
	{
		district: 'Tangail',
		area: 'Shakhipur',
	},
	{
		district: 'Tangail',
		area: 'Tangail Sadar',
	},
	{
		district: 'Tangail',
		area: 'Dhanbari',
	},
	{
		district: 'Bagerhat',
		area: 'Bagerhat Sadar',
	},
	{
		district: 'Bagerhat',
		area: 'Chitalmari',
	},
	{
		district: 'Bagerhat',
		area: 'Fakirhat (Bagerhat)',
	},
	{
		district: 'Bagerhat',
		area: 'Kachua(Bagerhat)',
	},
	{
		district: 'Bagerhat',
		area: 'Mollarhat',
	},
	{
		district: 'Bagerhat',
		area: 'Mongla',
	},
	{
		district: 'Bagerhat',
		area: 'Morelganj',
	},
	{
		district: 'Bagerhat',
		area: 'Rampal',
	},
	{
		district: 'Bagerhat',
		area: 'Sarankhola',
	},
	{
		district: 'Chuadanga',
		area: 'Alamdanga',
	},
	{
		district: 'Chuadanga',
		area: 'Chuadanga Sadar',
	},
	{
		district: 'Chuadanga',
		area: 'Damurhuda',
	},
	{
		district: 'Chuadanga',
		area: 'Jibannagar',
	},
	{
		district: 'Jessore',
		area: 'Noapara (Jessore)',
	},
	{
		district: 'Jessore',
		area: 'Bagherpara',
	},
	{
		district: 'Jessore',
		area: 'Chowgacha',
	},
	{
		district: 'Jessore',
		area: 'Jessore Sadar',
	},
	{
		district: 'Jessore',
		area: 'Jhikargacha',
	},
	{
		district: 'Jessore',
		area: 'Keshabpur',
	},
	{
		district: 'Jessore',
		area: 'Manirampur',
	},
	{
		district: 'Jessore',
		area: 'Sharsha',
	},
	{
		district: 'Jhenaidah',
		area: 'Kotchandpur',
	},
	{
		district: 'Jhenaidah',
		area: 'Harinakunda',
	},
	{
		district: 'Jhenaidah',
		area: 'Jhenaidah Sadar',
	},
	{
		district: 'Jhenaidah',
		area: 'Kaliganj(Jhenaidah)',
	},
	{
		district: 'Jhenaidah',
		area: 'Moheshpur',
	},
	{
		district: 'Jhenaidah',
		area: 'Shailkupa',
	},
	{
		district: 'Khulna',
		area: 'Batiaghata',
	},
	{
		district: 'Khulna',
		area: 'Dacope',
	},
	{
		district: 'Khulna',
		area: 'Dighalia',
	},
	{
		district: 'Khulna',
		area: 'Dumuria',
	},
	{
		district: 'Khulna',
		area: 'Phultala',
	},
	{
		district: 'Khulna',
		area: 'Koira',
	},
	{
		district: 'Khulna',
		area: 'Paikgacha',
	},
	{
		district: 'Khulna',
		area: 'Rupsa',
	},
	{
		district: 'Khulna',
		area: 'Khalispur',
	},
	{
		district: 'Khulna',
		area: 'Sonadanga',
	},
	{
		district: 'Khulna',
		area: 'Khan jahan ali',
	},
	{
		district: 'Khulna',
		area: 'Doulatpur',
	},
	{
		district: 'Khulna',
		area: 'Terokhada',
	},
	{
		district: 'Kushtia',
		area: 'Bheramara',
	},
	{
		district: 'Kushtia',
		area: 'Daulatpur (Kushtia)',
	},
	{
		district: 'Kushtia',
		area: 'Khoksha',
	},
	{
		district: 'Kushtia',
		area: 'Kumarkhali',
	},
	{
		district: 'Kushtia',
		area: 'Kushtia Sadar',
	},
	{
		district: 'Kushtia',
		area: 'Mirpur (Kushtia)',
	},
	{
		district: 'Magura',
		area: 'Magura Sadar',
	},
	{
		district: 'Magura',
		area: 'Mohammadpur (Magura)',
	},
	{
		district: 'Magura',
		area: 'Shalikha',
	},
	{
		district: 'Magura',
		area: 'Sreepur (Magura)',
	},
	{
		district: 'Meherpur',
		area: 'Gangni',
	},
	{
		district: 'Meherpur',
		area: 'Meherpur Sadar',
	},
	{
		district: 'Meherpur',
		area: 'Mujibnagar',
	},
	{
		district: 'Narail',
		area: 'Kalia',
	},
	{
		district: 'Narail',
		area: 'Lohagara(Narail)',
	},
	{
		district: 'Narail',
		area: 'Narail Sadar',
	},
	{
		district: 'Satkhira',
		area: 'Assasuni',
	},
	{
		district: 'Satkhira',
		area: 'Debhata',
	},
	{
		district: 'Satkhira',
		area: 'Kaliganj(Satkhira)',
	},
	{
		district: 'Satkhira',
		area: 'Kolaroa',
	},
	{
		district: 'Satkhira',
		area: 'Satkhira Sadar',
	},
	{
		district: 'Satkhira',
		area: 'Shyamnagar',
	},
	{
		district: 'Satkhira',
		area: 'Tala',
	},
	{
		district: 'Bogra',
		area: 'Adamdighi (Bogra)',
	},
	{
		district: 'Bogra',
		area: 'Bogra Sadar',
	},
	{
		district: 'Bogra',
		area: 'Dhunot',
	},
	{
		district: 'Bogra',
		area: 'Dhubchanchia',
	},
	{
		district: 'Bogra',
		area: 'Gabtali (Bogra)',
	},
	{
		district: 'Bogra',
		area: 'Kahaloo',
	},
	{
		district: 'Bogra',
		area: 'Nandigram',
	},
	{
		district: 'Bogra',
		area: 'Sariakandi',
	},
	{
		district: 'Bogra',
		area: 'Sherpur (Bogra)',
	},
	{
		district: 'Bogra',
		area: 'Shibganj ( Bogra )',
	},
	{
		district: 'Bogra',
		area: 'Sonatola',
	},
	{
		district: 'Bogra',
		area: 'Shajahanpur (Bogura)',
	},
	{
		district: 'Chapai Nawabganj',
		area: 'Bholahat',
	},
	{
		district: 'Chapai Nawabganj',
		area: 'Gomastapur',
	},
	{
		district: 'Chapai Nawabganj',
		area: 'Nachole',
	},
	{
		district: 'Chapai Nawabganj',
		area: 'Nawabganj Sadar',
	},
	{
		district: 'Chapai Nawabganj',
		area: 'Shipganj ( Chapai )',
	},
	{
		district: 'Joypurhat',
		area: 'Akkelpur',
	},
	{
		district: 'Joypurhat',
		area: 'Joypurhat Sadar',
	},
	{
		district: 'Joypurhat',
		area: 'Kalai',
	},
	{
		district: 'Joypurhat',
		area: 'Khetlal',
	},
	{
		district: 'Joypurhat',
		area: 'Panchbibi',
	},
	{
		district: 'Naogaon',
		area: 'Atrai',
	},
	{
		district: 'Naogaon',
		area: 'Badalgachi',
	},
	{
		district: 'Naogaon',
		area: 'Dhamoirhat',
	},
	{
		district: 'Naogaon',
		area: 'Manda(Naogaon)',
	},
	{
		district: 'Naogaon',
		area: 'Mahadebpur',
	},
	{
		district: 'Naogaon',
		area: 'Naogaon Sadar',
	},
	{
		district: 'Naogaon',
		area: 'Niamatpur',
	},
	{
		district: 'Naogaon',
		area: 'Patnitala',
	},
	{
		district: 'Naogaon',
		area: 'Porsha',
	},
	{
		district: 'Naogaon',
		area: 'Raninagar',
	},
	{
		district: 'Naogaon',
		area: 'Shapahar',
	},
	{
		district: 'Natore',
		area: 'Baghatipara',
	},
	{
		district: 'Natore',
		area: 'Baraigram',
	},
	{
		district: 'Natore',
		area: 'Gurudaspur',
	},
	{
		district: 'Natore',
		area: 'Lalpur',
	},
	{
		district: 'Natore',
		area: 'Natore Sadar',
	},
	{
		district: 'Natore',
		area: 'Singra',
	},
	{
		district: 'Pabna',
		area: 'Atgharia',
	},
	{
		district: 'Pabna',
		area: 'Bera',
	},
	{
		district: 'Pabna',
		area: 'Bhangura',
	},
	{
		district: 'Pabna',
		area: 'Chatmohar',
	},
	{
		district: 'Pabna',
		area: 'Faridpur ( Pabna )',
	},
	{
		district: 'Pabna',
		area: 'Iswardi',
	},
	{
		district: 'Pabna',
		area: 'Pabna Sadar',
	},
	{
		district: 'Pabna',
		area: 'Santhia',
	},
	{
		district: 'Pabna',
		area: 'Sujanagar',
	},
	{
		district: 'Rajshahi',
		area: 'Bagha',
	},
	{
		district: 'Rajshahi',
		area: 'Bagmara (Rajshahi)',
	},
	{
		district: 'Rajshahi',
		area: 'Charghat',
	},
	{
		district: 'Rajshahi',
		area: 'Durgapur(Rajshahi)',
	},
	{
		district: 'Rajshahi',
		area: 'Godagari',
	},
	{
		district: 'Rajshahi',
		area: 'Mohanpur',
	},
	{
		district: 'Rajshahi',
		area: 'Paba',
	},
	{
		district: 'Rajshahi',
		area: 'Putia',
	},
	{
		district: 'Rajshahi',
		area: 'Tanore',
	},
	{
		district: 'Rajshahi',
		area: 'Sadar',
	},
	{
		district: 'Sirajganj',
		area: 'Belkuchi',
	},
	{
		district: 'Sirajganj',
		area: 'Chowhali',
	},
	{
		district: 'Sirajganj',
		area: 'Kamarkhanda',
	},
	{
		district: 'Sirajganj',
		area: 'Kazipur',
	},
	{
		district: 'Sirajganj',
		area: 'Raiganj',
	},
	{
		district: 'Sirajganj',
		area: 'Shahajadpur (Sirajganj)',
	},
	{
		district: 'Sirajganj',
		area: 'Sirajganj Sadar',
	},
	{
		district: 'Sirajganj',
		area: 'Tarash',
	},
	{
		district: 'Sirajganj',
		area: 'Ullapara',
	},
	{
		district: 'Habiganj',
		area: 'Ajmeriganj',
	},
	{
		district: 'Habiganj',
		area: 'Bahubal',
	},
	{
		district: 'Habiganj',
		area: 'Baniachang',
	},
	{
		district: 'Habiganj',
		area: 'Chunarughat',
	},
	{
		district: 'Habiganj',
		area: 'Habiganj Sadar',
	},
	{
		district: 'Habiganj',
		area: 'Lakhai',
	},
	{
		district: 'Habiganj',
		area: 'Madhabpur',
	},
	{
		district: 'Habiganj',
		area: 'Nabiganj',
	},
	{
		district: 'Moulvibazar',
		area: 'Barlekha',
	},
	{
		district: 'Moulvibazar',
		area: 'Kamalganj',
	},
	{
		district: 'Moulvibazar',
		area: 'Kulaura',
	},
	{
		district: 'Moulvibazar',
		area: 'Moulvibazar Sadar',
	},
	{
		district: 'Moulvibazar',
		area: 'Rajnagar',
	},
	{
		district: 'Moulvibazar',
		area: 'Sreemongal',
	},
	{
		district: 'Moulvibazar',
		area: 'Juri',
	},
	{
		district: 'Sunamganj',
		area: 'Biswambharpur',
	},
	{
		district: 'Sunamganj',
		area: 'Chattak',
	},
	{
		district: 'Sunamganj',
		area: 'Derai',
	},
	{
		district: 'Sunamganj',
		area: 'Dharmapasha',
	},
	{
		district: 'Sunamganj',
		area: 'Dowarabazar',
	},
	{
		district: 'Sunamganj',
		area: 'Jagannathpur',
	},
	{
		district: 'Sunamganj',
		area: 'Jamalganj',
	},
	{
		district: 'Sunamganj',
		area: 'Sulla',
	},
	{
		district: 'Sunamganj',
		area: 'Sunamganj Sadar',
	},
	{
		district: 'Sunamganj',
		area: 'Taherpur',
	},
	{
		district: 'Sunamganj',
		area: 'South Sunamganj',
	},
	{
		district: 'Sylhet',
		area: 'Balaganj',
	},
	{
		district: 'Sylhet',
		area: 'Beanibazar',
	},
	{
		district: 'Sylhet',
		area: 'Biswanath',
	},
	{
		district: 'Sylhet',
		area: 'Companyganj (Sylhet)',
	},
	{
		district: 'Sylhet',
		area: 'Fenchuganj',
	},
	{
		district: 'Sylhet',
		area: 'Gowainghat',
	},
	{
		district: 'Sylhet',
		area: 'Golapganj (Sylhet)',
	},
	{
		district: 'Sylhet',
		area: 'Jaintapur',
	},
	{
		district: 'Sylhet',
		area: 'Zakiganj',
	},
	{
		district: 'Sylhet',
		area: 'Kanaighat',
	},
	{
		district: 'Sylhet',
		area: 'Sylhet Sadar',
	},
	{
		district: 'Sylhet',
		area: 'South Surma',
	},
	{
		district: 'Barguna',
		area: 'Amtali',
	},
	{
		district: 'Barguna',
		area: 'Bamna',
	},
	{
		district: 'Barguna',
		area: 'Barguna Sadar',
	},
	{
		district: 'Barguna',
		area: 'Betagi',
	},
	{
		district: 'Barguna',
		area: 'Patharghata (Barguna)',
	},
	{
		district: 'Barisal',
		area: 'Agailjhara',
	},
	{
		district: 'Barisal',
		area: 'Babuganj',
	},
	{
		district: 'Barisal',
		area: 'Bakerganj',
	},
	{
		district: 'Barisal',
		area: 'Banaripara',
	},
	{
		district: 'Barisal',
		area: 'Barisal Sadar',
	},
	{
		district: 'Barisal',
		area: 'Gournadi (Barisal)',
	},
	{
		district: 'Barisal',
		area: 'Hijla',
	},
	{
		district: 'Barisal',
		area: 'Mehendiganj',
	},
	{
		district: 'Barisal',
		area: 'Muladi',
	},
	{
		district: 'Barisal',
		area: 'Wazirpur',
	},
	{
		district: 'Bhola',
		area: 'Bhola Sadar',
	},
	{
		district: 'Bhola',
		area: 'Borhanuddin',
	},
	{
		district: 'Bhola',
		area: 'Charfession',
	},
	{
		district: 'Bhola',
		area: 'Daulatkhan',
	},
	{
		district: 'Bhola',
		area: 'Lalmohan',
	},
	{
		district: 'Bhola',
		area: 'Manpura',
	},
	{
		district: 'Bhola',
		area: 'Tajumuddin',
	},
	{
		district: 'Jhalokathi',
		area: 'Jhalokathi Sadar',
	},
	{
		district: 'Jhalokathi',
		area: 'Kathalia',
	},
	{
		district: 'Jhalokathi',
		area: 'Nalchiti',
	},
	{
		district: 'Jhalokathi',
		area: 'Rajapur (Jhalokathi)',
	},
	{
		district: 'Patuakhali',
		area: 'Bauphal',
	},
	{
		district: 'Patuakhali',
		area: 'Dasmina',
	},
	{
		district: 'Patuakhali',
		area: 'Dumki',
	},
	{
		district: 'Patuakhali',
		area: 'Galachipa',
	},
	{
		district: 'Patuakhali',
		area: 'Kalapara',
	},
	{
		district: 'Patuakhali',
		area: 'Mirjaganj',
	},
	{
		district: 'Patuakhali',
		area: 'Patuakhali Sadar',
	},
	{
		district: 'Perojpur',
		area: 'Bhandaria',
	},
	{
		district: 'Perojpur',
		area: 'Kaukhali (Perojpur)',
	},
	{
		district: 'Perojpur',
		area: 'Mothbaria',
	},
	{
		district: 'Perojpur',
		area: 'Nesarabad (Shawrupkathi)',
	},
	{
		district: 'Perojpur',
		area: 'Nazirpur',
	},
	{
		district: 'Perojpur',
		area: 'Pirojpur Sadar',
	},
	{
		district: 'Perojpur',
		area: 'Zia Nagar (Indurkani)',
	},
	{
		district: 'Bandarban',
		area: 'Ali Kadam',
	},
	{
		district: 'Bandarban',
		area: 'Bandarban Sadar',
	},
	{
		district: 'Bandarban',
		area: 'Lama',
	},
	{
		district: 'Bandarban',
		area: 'Nakhoyngchari',
	},
	{
		district: 'Bandarban',
		area: 'Rawanchari',
	},
	{
		district: 'Bandarban',
		area: 'Ruma',
	},
	{
		district: 'Bandarban',
		area: 'Thanchi',
	},
	{
		district: 'Brahmanbaria',
		area: 'Akhaura',
	},
	{
		district: 'Brahmanbaria',
		area: 'Ashuganj',
	},
	{
		district: 'Brahmanbaria',
		area: 'Bancharampur (Brahmanbaria)',
	},
	{
		district: 'Brahmanbaria',
		area: 'Brahmanbaria Sadar',
	},
	{
		district: 'Brahmanbaria',
		area: 'Kashba',
	},
	{
		district: 'Brahmanbaria',
		area: 'Nabinagar (Brahmanbaria)',
	},
	{
		district: 'Brahmanbaria',
		area: 'Nasirnagar',
	},
	{
		district: 'Brahmanbaria',
		area: 'Sarail',
	},
	{
		district: 'Chandpur',
		area: 'Chandpur Sadar',
	},
	{
		district: 'Chandpur',
		area: 'Faridganj',
	},
	{
		district: 'Chandpur',
		area: 'Haimchar',
	},
	{
		district: 'Chandpur',
		area: 'Hajiganj (Chandpur)',
	},
	{
		district: 'Chandpur',
		area: 'Kachua(Chandpur)',
	},
	{
		district: 'Chandpur',
		area: 'Matlab (South)',
	},
	{
		district: 'Chandpur',
		area: 'Matlab (North)(Chengarchar)',
	},
	{
		district: 'Chandpur',
		area: 'Shahrasti',
	},
	{
		district: 'Chittagong',
		area: 'Anwara',
	},
	{
		district: 'Chittagong',
		area: 'Banshkhali',
	},
	{
		district: 'Chittagong',
		area: 'Boalkhali',
	},
	{
		district: 'Chittagong',
		area: 'Chandanaish',
	},
	{
		district: 'Chittagong',
		area: 'Fatikchari',
	},
	{
		district: 'Chittagong',
		area: 'Hathazari',
	},
	{
		district: 'Chittagong',
		area: 'Lohagara',
	},
	{
		district: 'Chittagong',
		area: 'Mirsarai',
	},
	{
		district: 'Chittagong',
		area: 'Patiya',
	},
	{
		district: 'Chittagong',
		area: 'Rangunia',
	},
	{
		district: 'Chittagong',
		area: 'Raojan',
	},
	{
		district: 'Chittagong',
		area: 'Sandwip',
	},
	{
		district: 'Chittagong',
		area: 'Satkania',
	},
	{
		district: 'Chittagong',
		area: 'Sitakundu',
	},
	{
		district: 'Comilla',
		area: 'Barura',
	},
	{
		district: 'Comilla',
		area: 'Brahmanpara',
	},
	{
		district: 'Comilla',
		area: 'Burichang',
	},
	{
		district: 'Comilla',
		area: 'Chandina',
	},
	{
		district: 'Comilla',
		area: 'Chowddagram',
	},
	{
		district: 'Comilla',
		area: 'Comilla Sadar',
	},
	{
		district: 'Comilla',
		area: 'Daudkandi',
	},
	{
		district: 'Comilla',
		area: 'Debiduar',
	},
	{
		district: 'Comilla',
		area: 'Homna',
	},
	{
		district: 'Comilla',
		area: 'Laksam',
	},
	{
		district: 'Comilla',
		area: 'Meghna',
	},
	{
		district: 'Comilla',
		area: 'Muradnagar',
	},
	{
		district: 'Comilla',
		area: 'Nangolkot',
	},
	{
		district: 'Comilla',
		area: 'Titas',
	},
	{
		district: 'Comilla',
		area: 'Monoharganj',
	},
	{
		district: 'Comilla',
		area: 'Comilla Sadar South',
	},
	{
		district: "Cox's Bazar",
		area: 'Chakoria',
	},
	{
		district: "Cox's Bazar",
		area: "Cox's Bazar Sadar",
	},
	{
		district: "Cox's Bazar",
		area: 'Kutubdia',
	},
	{
		district: "Cox's Bazar",
		area: 'Moheshkhali',
	},
	{
		district: "Cox's Bazar",
		area: 'Pekua',
	},
	{
		district: "Cox's Bazar",
		area: 'Ramu',
	},
	{
		district: "Cox's Bazar",
		area: 'Teknaf',
	},
	{
		district: "Cox's Bazar",
		area: 'Ukhia',
	},
	{
		district: 'Feni',
		area: 'Chagalnayya',
	},
	{
		district: 'Feni',
		area: 'Daganbhuiyan',
	},
	{
		district: 'Feni',
		area: 'Feni Sadar',
	},
	{
		district: 'Feni',
		area: 'Parshuram',
	},
	{
		district: 'Feni',
		area: 'Fulgazi',
	},
	{
		district: 'Feni',
		area: 'Sonagazi',
	},
	{
		district: 'Khagrachari',
		area: 'Dighinala',
	},
	{
		district: 'Khagrachari',
		area: 'Khagrachari Sadar',
	},
	{
		district: 'Khagrachari',
		area: 'Laksmichari',
	},
	{
		district: 'Khagrachari',
		area: 'Mohalchari',
	},
	{
		district: 'Khagrachari',
		area: 'Manikchari',
	},
	{
		district: 'Khagrachari',
		area: 'Matiranga',
	},
	{
		district: 'Khagrachari',
		area: 'Panchari',
	},
	{
		district: 'Khagrachari',
		area: 'Ramgor',
	},
	{
		district: 'Laksmipur',
		area: 'Laksmipur Sadar',
	},
	{
		district: 'Laksmipur',
		area: 'Ramgati',
	},
	{
		district: 'Laksmipur',
		area: 'Ramganj',
	},
	{
		district: 'Laksmipur',
		area: 'Raipur',
	},
	{
		district: 'Laksmipur',
		area: 'Kamalnagar',
	},
	{
		district: 'Noakhali',
		area: 'Begumganj',
	},
	{
		district: 'Noakhali',
		area: 'Chatkhil',
	},
	{
		district: 'Noakhali',
		area: 'Companiganj',
	},
	{
		district: 'Noakhali',
		area: 'Hatiya',
	},
	{
		district: 'Noakhali',
		area: 'Noakhali Sadar(Sudharam)',
	},
	{
		district: 'Noakhali',
		area: 'Senbag',
	},
	{
		district: 'Noakhali',
		area: 'Sonaimuri',
	},
	{
		district: 'Noakhali',
		area: 'Subarnachar',
	},
	{
		district: 'Noakhali',
		area: 'Kabir Hat',
	},
	{
		district: 'Rangamati',
		area: 'Baghaichari',
	},
	{
		district: 'Rangamati',
		area: 'Barkal',
	},
	{
		district: 'Rangamati',
		area: 'Belaichari',
	},
	{
		district: 'Rangamati',
		area: 'Jurachari',
	},
	{
		district: 'Rangamati',
		area: 'Kaptai',
	},
	{
		district: 'Rangamati',
		area: 'Kawkhali (Rangamati)',
	},
	{
		district: 'Rangamati',
		area: 'Langadu',
	},
	{
		district: 'Rangamati',
		area: 'Naniarchar',
	},
	{
		district: 'Rangamati',
		area: 'Rajasthali',
	},
	{
		district: 'Rangamati',
		area: 'Rangamati Sadar',
	},
	{
		district: 'Dinajpur',
		area: 'Birampur',
	},
	{
		district: 'Dinajpur',
		area: 'Birganj',
	},
	{
		district: 'Dinajpur',
		area: 'Birol',
	},
	{
		district: 'Dinajpur',
		area: 'Bochaganj',
	},
	{
		district: 'Dinajpur',
		area: 'Chirirbandar',
	},
	{
		district: 'Dinajpur',
		area: 'Dinajpur Sadar',
	},
	{
		district: 'Dinajpur',
		area: 'Phulbari (Dinajpur)',
	},
	{
		district: 'Dinajpur',
		area: 'Ghoraghat',
	},
	{
		district: 'Dinajpur',
		area: 'Hakimpur',
	},
	{
		district: 'Dinajpur',
		area: 'Kaharole',
	},
	{
		district: 'Dinajpur',
		area: 'Khanshama',
	},
	{
		district: 'Dinajpur',
		area: 'Nawabganj (Dinajpur)',
	},
	{
		district: 'Dinajpur',
		area: 'Parbatipur',
	},
	{
		district: 'Gaibandha',
		area: 'Fulchari',
	},
	{
		district: 'Gaibandha',
		area: 'Gaibandha Sadar',
	},
	{
		district: 'Gaibandha',
		area: 'Gobindaganj ( Gaibandha )',
	},
	{
		district: 'Gaibandha',
		area: 'Palashbari',
	},
	{
		district: 'Gaibandha',
		area: 'Sadullapur',
	},
	{
		district: 'Gaibandha',
		area: 'Shaghatta',
	},
	{
		district: 'Gaibandha',
		area: 'Sundarganj',
	},
	{
		district: 'Kurigram',
		area: 'Bhurungamari',
	},
	{
		district: 'Kurigram',
		area: 'Chilmary',
	},
	{
		district: 'Kurigram',
		area: 'Fulbari (Kurigram)',
	},
	{
		district: 'Kurigram',
		area: 'Kurigram Sadar',
	},
	{
		district: 'Kurigram',
		area: 'Nageswari',
	},
	{
		district: 'Kurigram',
		area: 'Rajarhat',
	},
	{
		district: 'Kurigram',
		area: 'Rajibpur',
	},
	{
		district: 'Kurigram',
		area: 'Rowmari',
	},
	{
		district: 'Kurigram',
		area: 'Ulipur',
	},
	{
		district: 'Lalmonirhat',
		area: 'Aditmari',
	},
	{
		district: 'Lalmonirhat',
		area: 'Hatibandha (Lalmonirhat)',
	},
	{
		district: 'Lalmonirhat',
		area: 'Kaliganj(Lalmonirhat)',
	},
	{
		district: 'Lalmonirhat',
		area: 'Lalmonirhat Sadar',
	},
	{
		district: 'Lalmonirhat',
		area: 'Patgram',
	},
	{
		district: 'Nilphamari',
		area: 'Dimla',
	},
	{
		district: 'Nilphamari',
		area: 'Domar',
	},
	{
		district: 'Nilphamari',
		area: 'Jaldhaka',
	},
	{
		district: 'Nilphamari',
		area: 'Kishoreganj ( Nilphamari)',
	},
	{
		district: 'Nilphamari',
		area: 'Nilphamari Sadar',
	},
	{
		district: 'Nilphamari',
		area: 'Saidpur',
	},
	{
		district: 'Panchagarh',
		area: 'Atwari (Panchagarh)',
	},
	{
		district: 'Panchagarh',
		area: 'Boda',
	},
	{
		district: 'Panchagarh',
		area: 'Debiganj',
	},
	{
		district: 'Panchagarh',
		area: 'Panchagarh Sadar',
	},
	{
		district: 'Panchagarh',
		area: 'Tetulia',
	},
	{
		district: 'Rangpur',
		area: 'Badarganj',
	},
	{
		district: 'Rangpur',
		area: 'Gangachara',
	},
	{
		district: 'Rangpur',
		area: 'Kaunia (Rangpur)',
	},
	{
		district: 'Rangpur',
		area: 'Mithapukur',
	},
	{
		district: 'Rangpur',
		area: 'Pirgacha',
	},
	{
		district: 'Rangpur',
		area: 'Pirganj(Rangpur)',
	},
	{
		district: 'Rangpur',
		area: 'Rangpur Sadar',
	},
	{
		district: 'Rangpur',
		area: 'Taraganj',
	},
	{
		district: 'Thakurgaon',
		area: 'Baliadangi',
	},
	{
		district: 'Thakurgaon',
		area: 'Haripur',
	},
	{
		district: 'Thakurgaon',
		area: 'Pirganj(Thakurgaon)',
	},
	{
		district: 'Thakurgaon',
		area: 'Ranishankail',
	},
	{
		district: 'Thakurgaon',
		area: 'Thakurgaon Sadar',
	},
	{
		district: 'Khulna',
		area: 'Khulna Sadar',
	},
	{
		district: 'Rajshahi',
		area: 'Rajshahi Shadar',
	},
	{
		district: 'Chittagong',
		area: 'Chittagong Sadar',
	},
	{
		district: 'Chapai Nawabganj',
		area: 'Chapai Nawabganj Sadar',
	},
	{
		district: 'Jessore',
		area: 'Benapole',
	},
	{
		district: 'Bandarban',
		area: 'soho area',
	},
	{
		district: 'Thakurgaon',
		area: 'Shibganj (Thakurgaon Sadar)',
	},
	{
		district: 'Chittagong',
		area: 'Bondor (Chittagong)',
	},
	{
		district: 'Chittagong',
		area: 'Barahatia',
	},
	{
		district: 'Chittagong',
		area: 'Karnaphuli',
	},
	{
		district: 'Chittagong',
		area: 'Kotwali Chattogram',
	},
	{
		district: 'Chittagong',
		area: 'Agrabad',
	},
	{
		district: 'Chittagong',
		area: 'AK Khan',
	},
	{
		district: 'Chittagong',
		area: 'Al- Amin Baria Madra',
	},
	{
		district: 'Chittagong',
		area: 'Al- Amin Baria Madrasa',
	},
	{
		district: 'Chittagong',
		area: 'Amin Jute Mills',
	},
	{
		district: 'Chittagong',
		area: 'Anandabazar',
	},
	{
		district: 'Chittagong',
		area: 'Anawara Battali',
	},
	{
		district: 'Chittagong',
		area: 'Anawara Paroikora',
	},
	{
		district: 'Chittagong',
		area: 'Bayezid Bostami',
	},
	{
		district: 'Chittagong',
		area: 'Boalkhali Charandwip',
	},
	{
		district: 'Chittagong',
		area: 'Boalkhali Iqbal Park',
	},
	{
		district: 'Chittagong',
		area: 'Boalkhali Kadurkhal',
	},
	{
		district: 'Chittagong',
		area: 'Boalkhali Kanungopara',
	},
	{
		district: 'Chittagong',
		area: 'Boalkhali Sadar',
	},
	{
		district: 'Chittagong',
		area: 'Boalkhali Sakpura',
	},
	{
		district: 'Chittagong',
		area: 'Boalkhali Saroatoli',
	},
	{
		district: 'Chittagong',
		area: 'Cadet College',
	},
	{
		district: 'Chittagong',
		area: 'Chandgaon',
	},
	{
		district: 'Chittagong',
		area: 'Chattogram Airport',
	},
	{
		district: 'Chittagong',
		area: 'Chattogram Bandar',
	},
	{
		district: 'Chittagong',
		area: 'Chattogram Cantonment',
	},
	{
		district: 'Chittagong',
		area: 'Chattogram Chawkbazar',
	},
	{
		district: 'Chittagong',
		area: 'Chattogram Customs Acca',
	},
	{
		district: 'Chittagong',
		area: 'Chattogram GPO',
	},
	{
		district: 'Chittagong',
		area: 'Chattogram New Market',
	},
	{
		district: 'Chittagong',
		area: 'Chattogram Oxygen',
	},
	{
		district: 'Chittagong',
		area: 'Chattogram Politechnic Institute',
	},
	{
		district: 'Chittagong',
		area: 'Chattogram Sailors Colony',
	},
	{
		district: 'Chittagong',
		area: 'Colonel Hat',
	},
	{
		district: 'Chittagong',
		area: 'Combined Military Hospital (CMH)',
	},
	{
		district: 'Chittagong',
		area: 'Court Buliding',
	},
	{
		district: 'Chittagong',
		area: 'Dakkhin Ghatchak',
	},
	{
		district: 'Chittagong',
		area: 'Dakkshin Pahartoli',
	},
	{
		district: 'Chittagong',
		area: 'Double Mooring',
	},
	{
		district: 'Chittagong',
		area: 'East Joara Barma',
	},
	{
		district: 'Chittagong',
		area: 'East Joara Dohazari',
	},
	{
		district: 'Chittagong',
		area: 'East Joara East Joara',
	},
	{
		district: 'Chittagong',
		area: 'East Joara Gachbaria',
	},
	{
		district: 'Chittagong',
		area: 'Export Processing',
	},
	{
		district: 'Chittagong',
		area: 'Fakirkill',
	},
	{
		district: 'Chittagong',
		area: 'Fatikchhari Bhandar Sharif',
	},
	{
		district: 'Chittagong',
		area: 'Fatikchhari Harualchhari',
	},
	{
		district: 'Chittagong',
		area: 'Fatikchhari Najirhat',
	},
	{
		district: 'Chittagong',
		area: 'Fatikchhari Nanupur',
	},
	{
		district: 'Chittagong',
		area: 'Fatikchhari Narayanhat',
	},
	{
		district: 'Chittagong',
		area: 'Firozshah',
	},
	{
		district: 'Chittagong',
		area: 'GEC',
	},
	{
		district: 'Chittagong',
		area: 'Halishahar',
	},
	{
		district: 'Chittagong',
		area: 'Halishshar',
	},
	{
		district: 'Chittagong',
		area: 'Chattogram University',
	},
	{
		district: 'Chittagong',
		area: 'Hathazari Fatahabad',
	},
	{
		district: 'Chittagong',
		area: 'Hathazari Gorduara',
	},
	{
		district: 'Chittagong',
		area: 'Hathazari Katirhat',
	},
	{
		district: 'Chittagong',
		area: 'Hathazari Madrasa',
	},
	{
		district: 'Chittagong',
		area: 'Hathazari Mirzapur',
	},
	{
		district: 'Chittagong',
		area: 'Hathazari Nuralibari',
	},
	{
		district: 'Chittagong',
		area: 'Hathazari Yunus Nagar',
	},
	{
		district: 'Chittagong',
		area: 'Jalalabad',
	},
	{
		district: 'Chittagong',
		area: 'Jaldi Banigram',
	},
	{
		district: 'Chittagong',
		area: 'Jaldi Gunagari',
	},
	{
		district: 'Chittagong',
		area: 'Jaldi Khan Bahadur',
	},
	{
		district: 'Chittagong',
		area: 'Jaldi Sadar',
	},
	{
		district: 'Chittagong',
		area: 'Jaldia Marine Academy',
	},
	{
		district: 'Chittagong',
		area: 'Jaldia Merine Accade',
	},
	{
		district: 'Chittagong',
		area: 'Kadeer Nagar',
	},
	{
		district: 'Chittagong',
		area: 'Kaptai Chandraghona',
	},
	{
		district: 'Rangamati',
		area: 'Kaptai Kaptai Project',
	},
	{
		district: 'Rangamati',
		area: 'Kaptai Nuton Bazar',
	},
	{
		district: 'Rangamati',
		area: 'Kaptai Sadar',
	},
	{
		district: 'Chittagong',
		area: 'Kattuli',
	},
	{
		district: 'Chittagong',
		area: 'Khulshi',
	},
	{
		district: 'Chittagong',
		area: 'Lohagara Chunti',
	},
	{
		district: 'Chittagong',
		area: 'Lohagara Padua',
	},
	{
		district: 'Chittagong',
		area: 'Middle Patenga',
	},
	{
		district: 'Chittagong',
		area: 'Mirsharai Abutorab',
	},
	{
		district: 'Chittagong',
		area: 'Mirsharai Azampur',
	},
	{
		district: 'Chittagong',
		area: 'Mirsharai Bharawazhat',
	},
	{
		district: 'Chittagong',
		area: 'Mirsharai Darrogahat',
	},
	{
		district: 'Chittagong',
		area: 'Mirsharai Joarganj',
	},
	{
		district: 'Chittagong',
		area: 'Mirsharai Korerhat',
	},
	{
		district: 'Chittagong',
		area: 'Mirsharai Mohazanhat',
	},
	{
		district: 'Chittagong',
		area: 'Mohard',
	},
	{
		district: 'Chittagong',
		area: 'Murad Nagar',
	},
	{
		district: 'Chittagong',
		area: 'Nasirabad',
	},
	{
		district: 'Chittagong',
		area: 'North Halishahar',
	},
	{
		district: 'Chittagong',
		area: 'North Kattali',
	},
	{
		district: 'Chittagong',
		area: 'North Katuli',
	},
	{
		district: 'Chittagong',
		area: 'Noyabazar',
	},
	{
		district: 'Chittagong',
		area: 'Pahartoli',
	},
	{
		district: 'Chittagong',
		area: 'Panchlaish',
	},
	{
		district: 'Chittagong',
		area: 'Patenga',
	},
	{
		district: 'Chittagong',
		area: 'Patiya Budhpara',
	},
	{
		district: 'Chittagong',
		area: 'Patiya Sadar',
	},
	{
		district: 'Chittagong',
		area: 'Rampur',
	},
	{
		district: 'Chittagong',
		area: 'Rampura TSO',
	},
	{
		district: 'Chittagong',
		area: 'Rangunia Dhamair',
	},
	{
		district: 'Chittagong',
		area: 'Rangunia Sadar',
	},
	{
		district: 'Chittagong',
		area: 'Ranir Hat',
	},
	{
		district: 'Chittagong',
		area: 'Raozan B.I.T Post Office',
	},
	{
		district: 'Chittagong',
		area: 'Raozan Beenajuri',
	},
	{
		district: 'Chittagong',
		area: 'Raozan Dewanpur',
	},
	{
		district: 'Chittagong',
		area: 'Raozan Fatepur',
	},
	{
		district: 'Chittagong',
		area: 'Raozan Guzra Noapara',
	},
	{
		district: 'Chittagong',
		area: 'Raozan jagannath Hat',
	},
	{
		district: 'Chittagong',
		area: 'Raozan Kundeshwari',
	},
	{
		district: 'Chittagong',
		area: 'Raozan Mohamuni',
	},
	{
		district: 'Chittagong',
		area: 'Raozan Pouroshobha',
	},
	{
		district: 'Chittagong',
		area: 'Rauzan Gahira',
	},
	{
		district: 'Chittagong',
		area: 'Sadarghat',
	},
	{
		district: 'Chittagong',
		area: 'Sandwip Shiberhat',
	},
	{
		district: 'Chittagong',
		area: 'Sandwip Urirchar',
	},
	{
		district: 'Chittagong',
		area: 'Satkania Baitul Ijjat',
	},
	{
		district: 'Chittagong',
		area: 'Satkania Bazalia',
	},
	{
		district: 'Chittagong',
		area: 'Sayad Bari',
	},
	{
		district: 'Chittagong',
		area: 'Sitakunda Barabkunda',
	},
	{
		district: 'Chittagong',
		area: 'Sitakunda Baroidhala',
	},
	{
		district: 'Chittagong',
		area: 'Sitakunda Bawashbaria',
	},
	{
		district: 'Chittagong',
		area: 'Sitakunda Bhatiari',
	},
	{
		district: 'Chittagong',
		area: 'Sitakunda Fouzdarhat',
	},
	{
		district: 'Chittagong',
		area: 'Sitakunda Jafrabad',
	},
	{
		district: 'Chittagong',
		area: 'Sitakunda Kumira',
	},
	{
		district: 'Chittagong',
		area: 'South Halishahar',
	},
	{
		district: 'Chittagong',
		area: 'Uttar Gatchak',
	},
	{
		district: 'Chittagong',
		area: 'Wazedia',
	},
];
