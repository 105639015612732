import React from 'react';
import { DeliveryReqs } from '../../context/contextTypes';
import { addDays, isBefore, isToday, isYesterday } from 'date-fns';
import { Link } from 'react-router-dom';

import Card from '../Card/Card';
import { colorPalette } from './DeliveryReqRow';
import { DeliveryReqSelect } from '../deliveryCommon/DeliveryCommon';

interface Props {
	reqs: DeliveryReqs;
	index: number;
	handleSelect: ({ type, index }: DeliveryReqSelect) => void;
}

export type Color = 'red' | 'blue' | 'yellow' | 'none';

const DeliveryReqCard: React.FC<Props> = ({
	reqs: { node, selected },
	handleSelect,
	index,
}) => {
	const delivery_type = node.delivery_type;

	const status = node.status;

	const isActiveColor =
		status !== 'waiting'
			? delivery_type === 'dhaka_24hrs' || delivery_type === 'regular'
			: false;

	const _isToday = isToday(new Date(node.pickedupAt));

	const _isYesterday = isYesterday(new Date(node.pickedupAt));

	const _isOld = isBefore(new Date(node.pickedupAt), addDays(new Date(), 2));

	const isTodayWith24 = _isToday && delivery_type === 'dhaka_24hrs';

	const isYesterdayWith24 = _isYesterday && delivery_type === 'dhaka_24hrs';

	const isOldWith24 = _isOld && delivery_type === 'dhaka_24hrs';

	const _color: Color = _isToday
		? 'blue'
		: _isYesterday
		? 'yellow'
		: _isOld
		? 'red'
		: 'none';

	const _variant: 'deep' | 'light' =
		isTodayWith24 || isYesterdayWith24 || isOldWith24 ? 'deep' : 'light';

	return (
		<Card
			className={`card anim-card`}
			style={{
				backgroundColor: isActiveColor
					? colorPalette[_color][_variant]
					: 'inherit',
			}}>
			<div title='checkbox' className='card__title'>
				<input
					type='checkbox'
					name='check-one'
					checked={selected}
					onChange={() => {
						if (selected) {
							handleSelect({ type: 'REMOVE_ONE', index, id: node.objectId });
						} else {
							handleSelect({ type: 'ADD_ONE', index, id: node.objectId });
						}
					}}
				/>
			</div>

			<div title='name' className='deliveryReq__bodyCol'>
				<Link to={`/order/details/${node.objectId}`}>{node.brand.name}</Link>
			</div>
			<div title='address' className='deliveryReq__bodyCol'>
				<p>{node.brand.address}</p>
			</div>

			<div title='customer info' className='deliveryReq__bodyCol'>
				<p>{node.customer_name}</p>
				<p>{node.customer_phone}</p>
				<p>{node.address}</p>
			</div>
			<div title='rider' className='deliveryReq__bodyCol card__footer'>
				{node.deliveryRider && (
					<Link to={`/rider/${node.deliveryRider.objectId}`}>
						{node.deliveryRider.username}
					</Link>
				)}
			</div>
		</Card>
	);
};

export default DeliveryReqCard;
