import { setContext } from '@apollo/client/link/context';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { QueryClient } from 'react-query';

interface User {
	sessionToken: string;
}

export const restClient = new QueryClient();

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists

	const user: User = localStorage.getItem('user')
		? JSON.parse(localStorage.getItem('user') as string)
		: { sessionToken: null };

	const token: string = user.sessionToken;

	if (token) {
		return {
			headers: {
				...headers,
				'X-Parse-Application-Id': process.env.REACT_APP_PARSE_APP_KEY ?? '',
				'X-Parse-Session-Token': token,
			},
		};
	}

	return {
		headers: {
			...headers,
			'X-Parse-Application-Id': process.env.REACT_APP_PARSE_APP_KEY ?? '',
		},
	};
});

const httpLink = createHttpLink({
	uri: process.env.REACT_APP_GRAPHQL_URI,
});

export const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: authLink.concat(httpLink),
});
