import { useMutation } from '@apollo/client';

import { CREATE_ISSUE } from '../graphql/mutations/issue';
import useMessage from './useMessage';

const useActions = () => {
	const { handleNotification } = useMessage();

	const [raiseIssue, { loading }] = useMutation(CREATE_ISSUE);

	const handleRaiseIssue = async (data: any, cb?: () => void) => {
		try {
			await raiseIssue({
				variables: {
					...data,
				},
			});

			cb?.();
		} catch (error: any) {
			handleNotification({
				type: 'error',
				variant: 'standard',
				message: error.message,
			});
		}
	};

	return { handleRaiseIssue, loading };
};

export default useActions;
