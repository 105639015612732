import { gql } from '@apollo/client';

export const GET_DELIVERY_REQS = gql`
	query getDeliveryReqs {
		parcels(
			order: pickedupAt_ASC
			first: 800
			where: {
				status: {
					in: ["picked_up", "rescheduled", "rider_assigned", "picked_up_bag"]
				}
				zone: { equalTo: "dhaka" }
				delivery_type: { notEqualTo: "express" }
			}
		) {
			edges {
				node {
					notes {
						... on Element {
							value
						}
					}
					objectId
					address
					pickedupAt
					createdAt
					brand {
						name

						address
					}
					parcel_type
					delivery_type
					customer_name
					customer_phone
					status
					area
					zone
					expectedDelivery
					deliveryRider {
						objectId
						username
					}
				}
			}
		}
	}
`;

export const GET_DELIVERY_REQS_OUTSIDE = gql`
	query getDeliveryReqsOutside {
		parcels(
			order: pickedupAt_ASC
			first: 800
			where: {
				status: {
					in: ["picked_up", "rescheduled", "rider_assigned", "picked_up_bag"]
				}
				zone: { notEqualTo: "dhaka" }
			}
		) {
			edges {
				node {
					notes {
						... on Element {
							value
						}
					}
					objectId
					address
					pickedupAt
					createdAt
					brand {
						name

						address
					}
					parcel_type
					delivery_type
					customer_name
					customer_phone
					status
					area
					zone

					deliveryRider {
						objectId
						username
					}
				}
			}
		}
	}
`;

export const GET_DELIVERY_REQS_EXPRESS = gql`
	query getDeliveryReqsExpress {
		parcels(
			order: createdAt_DESC
			first: 800
			where: { delivery_type: { equalTo: "express" } }
		) {
			edges {
				node {
					notes {
						... on Element {
							value
						}
					}
					objectId
					address
					pickedupAt
					createdAt
					brand {
						name

						address
					}
					parcel_type
					delivery_type
					customer_name
					customer_phone
					status
					area
					zone

					deliveryRider {
						objectId
						username
					}
				}
			}
		}
	}
`;

export const GET_DELIVERY_REQS_WAITING = gql`
	query getDeliveryReqsExpress {
		parcels(
			order: pickedupAt_ASC
			first: 800
			where: { status: { in: ["waiting"] } }
		) {
			edges {
				node {
					notes {
						... on Element {
							value
						}
					}
					objectId
					address
					pickedupAt
					createdAt
					brand {
						name
						address
					}
					parcel_type
					delivery_type
					customer_name
					customer_phone
					status
					area
					zone

					deliveryRider {
						objectId
						username
					}
				}
			}
		}
	}
`;

export const GET_DELIVERY_REQS_RETURN_CUSTOMER = gql`
	query getDeliveryReqsExpress {
		parcels(
			where: {
				status: { in: ["returned", "returned_paid_by_customer"] }
				return_tracking: { notEqualTo: { key: "status", value: "done" } }
			}
		) {
			edges {
				node {
					notes {
						... on Element {
							value
						}
					}
					objectId
					address
					pickedupAt
					createdAt
					brand {
						name
						address
					}
					parcel_type
					delivery_type
					customer_name
					customer_phone
					status
					area
					zone

					deliveryRider {
						objectId
						username
					}
				}
			}
		}
	}
`;

export const GET_REJECTED_BRANDS = gql`
	query getRejectedBrands {
		parcels(where: { status: { equalTo: "rejected" } }) {
			edges {
				node {
					brand {
						objectId
						name
					}
				}
			}
		}
	}
`;
