/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Select from 'react-select';
import _ from 'lodash';

import './deliveryFeeChart.scss';
import Layout from '../../components/layout/Layout';
import MerchantLayout from '../../components/layout/MerchantLayout';
import CustomInput from '../../components/customInput/CustomInput';
import { Button, Tooltip } from '@material-ui/core';
import PriceArr from '../../components/numberArr/priceArr';
import useMessage from '../../hooks/useMessage';
import useFee, { PriceObj, primaryObj } from '../../hooks/useFee';
import useAdmin from '../../hooks/useAdmin';
import { useStore } from '../../context/Context';
import { handleWheel } from '../../helper/helper';
import { Agreement } from '../../context/contextTypes';

export type Name = 'range1' | 'range2' | 'fee';

export interface WeightArrayProps {
	index: number;
	value: number;
	name: Name;
}

export interface WeightArr {
	fee: number;
	range1: number;
	range2: number;
}

interface UI {
	title: string | null;
	oneKg: string | null;

	rangeKg: string | null;
}

export type DeliveryType =
	| 'regular'
	| 'outside_dhaka'
	| 'dhaka_24hrs'
	| 'express';

const delTypeArr = [
	{ value: 'express', label: 'Express' },
	{ value: 'outside_dhaka', label: 'Outside Dhaka' },
	{ value: 'dhaka_24hrs', label: '24 hr' },
	{ value: 'regular', label: '48 hr' },
];
const initAgg = {
	parcel: 'weight_per_kg',
	fragile: 'weight_per_kg',
	grocery: 'weight_per_kg',
	food: 'distance_range',
};

export type ParcelType = 'parcel' | 'fragile';

const DeliveryFeeChart: React.FC<{ id?: string }> = ({ id }) => {
	const NUM_OF_ROW = 2;

	const MIN_RANGE = 1;

	const {
		store: { planById },
		dispatch,
	} = useStore();

	const [agreement, setAgreement] = useState<Agreement>(initAgg);

	const { loading, updateFee, addFee } = useFee();

	const { getShemeById, isLoading } = useAdmin();

	const [disabled, setDisabled] = useState(true);

	const [numOfRow, setNumOfRow] = useState(NUM_OF_ROW);

	const [stateWeight, setStateWeight] = useState<WeightArr[] | null>(null);

	const [stateObj, setStateObj] = useState<PriceObj | null>(null);

	const [deliveryType, setDeliveryType] = useState<DeliveryType>('express');

	const [note, setNote] = useState('');

	const [oneKg, setOneKg] = useState(0);

	const [oneKgAdd, setOneKgAdd] = useState(0);

	const [_noteDis, setNoteDis] = useState(true);

	const [parcelType, setParcelType] = useState<ParcelType>('parcel');

	const { handleNotification } = useMessage();

	const [cod1, setCod1] = useState(0);

	const [cod2, setCod2] = useState(0);

	const reset = () => {
		setStateObj(null);
		setNote('');
		setCod1(0);
		setOneKg(0);
		setOneKgAdd(0);
		setCod2(0);
		dispatch({ type: 'REMOVE_PLAN_BY_ID', payload: null });
	};

	// if editable

	useEffect(() => {
		reset();
	}, [dispatch]);

	useEffect(() => {
		if (id) {
			getShemeById(id);
		}
	}, [id]);

	useEffect(() => {
		if (!id) return;

		if (planById) {
			setStateObj({
				express: planById.express,
				outside_dhaka: planById.outside_dhaka,
				dhaka_24hrs: planById.dhaka_24hrs,
				regular: planById.regular,
			});
			setAgreement(planById.agreement);
			setNote(planById.name);
			setDeliveryType('outside_dhaka');
		}
	}, [planById, id]);

	const weightRangeArr = useMemo(() => {
		const array = Array(numOfRow)
			.fill({})
			.map(() => {
				return {
					fee: 0,
					range1: 0,
					range2: 0,
				};
			});

		if (!stateWeight) {
			setStateWeight(array);
		} else {
			return stateWeight;
		}

		return array;
	}, [numOfRow, stateWeight, stateObj]);

	const priceObj: PriceObj = useMemo(() => {
		if (!stateObj) {
			setStateObj(primaryObj);
		} else {
			return stateObj;
		}

		return primaryObj;
	}, [stateObj]);

	const keys = (obj: object) => {
		return Object.keys(obj);
	};

	const updateUi = useMemo(() => {
		let ui: UI[] = [];

		keys(priceObj).forEach((delType) => {
			// @ts-ignore
			keys(priceObj[delType]).forEach((parType) => {
				// @ts-ignore
				keys(priceObj[delType][parType]).forEach((weightType) => {
					if (weightType === 'weight_range') {
						if (
							// @ts-ignore
							keys(priceObj[delType][parType][weightType]).length > 0
						) {
							setDisabled(false);
						} else {
							setDisabled(true);
						}
					}

					// @ts-ignore
					keys(priceObj[delType][parType][weightType]).forEach((key, index) => {
						// @ts-ignore
						const value = priceObj[delType][parType][weightType][key];

						if (value !== 0) {
							const isOneCat = weightType === 'weight_per_kg';

							const isRangeCat = weightType === 'weight_range';

							if (isOneCat) {
								if (key === 'extra_per_unit') {
									ui.push({
										title: null,
										oneKg: `Additional charge ${value} TK`,
										rangeKg: null,
									});
								}

								if (key === 'price_unit') {
									ui.push({
										title: `${delType} for ${parType}`,
										oneKg: `Weight for 1 kg: ${value} TK`,
										rangeKg: null,
									});
								}

								if (key === 'cod_charge') {
									ui.push({
										title: null,
										oneKg: `Cod charge ${value}%`,
										rangeKg: null,
									});
								}
							} else if (isRangeCat) {
								if (index === 0) {
									ui.push({
										title: `${delType} for ${parType}`,
										oneKg: null,
										rangeKg: `Weight range upto ${Number(key)}kg: ${value} TK`,
									});
								} else {
									if (key !== 'cod_charge') {
										ui.push({
											title: null,
											oneKg: null,
											rangeKg: `Weight range upto ${Number(
												key
											)}kg: ${value} TK`,
										});
									}

									if (key === 'cod_charge') {
										ui.push({
											title: null,
											oneKg: null,
											rangeKg: `Cod charge ${value}%`,
										});
									}
								}
							}
						}
					});
				});
			});
		});

		return ui.map((elm, i) => (
			<Grid key={i} item container direction='column'>
				<Grid item>
					<h2>{elm.title}</h2>
				</Grid>
				<Grid item container>
					<Grid item>
						<p> {elm.oneKg} </p>
					</Grid>
					<Grid item>
						<p>{elm.rangeKg}</p>
					</Grid>
				</Grid>
			</Grid>
		));
	}, [priceObj]);

	const agreementSelect = useMemo(
		() =>
			_.uniqBy(
				Object.keys(agreement).map((key) => ({
					label: agreement[key as keyof Agreement].split('_').join(' '),
					value: agreement[key as keyof Agreement],
				})),
				'value'
			),

		[agreement]
	);

	// useEffect(() => {
	// 	console.log(stateObj);
	// }, [stateObj]);

	const resetFunc = () => {
		// pre kg

		if (priceObj) {
			const perKgObj = priceObj[deliveryType][parcelType]['weight_per_kg'];

			setCod1(perKgObj.cod_charge);
			setOneKg(perKgObj.price_unit);
			setOneKgAdd(perKgObj.extra_per_unit);
		}

		// range

		if (priceObj) {
			const rangeObj = priceObj[deliveryType][parcelType]['weight_range'];

			const keys = Object.keys(rangeObj);

			setNumOfRow(keys.length);

			if (keys.length === 0) {
				setStateWeight(null);
				setNumOfRow(2);
				setCod2(0);
				return;
			}

			setStateWeight(() => {
				return keys.map((key) => {
					if (key !== 'cod_charge') {
						return {
							fee: rangeObj[key],
							range1: Number(key) - MIN_RANGE,
							range2: Number(key),
						};
					}

					return {
						fee: 0,
						range1: 0,
						range2: 0,
					};
				});
			});

			setCod2(rangeObj.cod_charge);
		}
	};

	useEffect(() => {
		resetFunc();
	}, [deliveryType, parcelType]);

	useEffect(() => {
		if (stateObj) {
			if (stateWeight) {
				let weightObj: { [index: string]: number } = {};

				stateWeight.forEach((weight) => {
					if (weight.range2) {
						weightObj[`${weight.range2}`] = weight.fee;
					}
				});

				setStateObj((old) => {
					if (old) {
						old[deliveryType][parcelType].weight_range = {
							...weightObj,
							cod_charge: cod2,
						};

						old[deliveryType][parcelType].weight_per_kg = {
							price_unit: oneKg,
							extra_per_unit: oneKgAdd,
							cod_charge: cod1,
						};
					} else {
						return null;
					}
					return { ...old };
				});
			}
		}
	}, [cod1, stateWeight, cod2, oneKgAdd, oneKg]);

	const changeWeightRange = ({ index, value, name }: WeightArrayProps) => {
		setStateWeight((old) => {
			if (old) {
				old[index][name] = value;
			} else {
				return null;
			}

			return [...old];
		});
	};

	const handleAdd = (_index: number) => {
		setNumOfRow((old) => old + 1);
		if (stateWeight) {
			stateWeight.push({ fee: 0, range1: 0, range2: 0 });
		}
	};

	const handleDel = (index: number) => {
		if (numOfRow === 1) {
			handleNotification({
				variant: 'standard',
				type: 'warning',
				message: 'Oops! one left.',
			});
		} else {
			if (stateWeight) {
				setStateWeight((old) => {
					if (old) {
						let copy = [...old];

						copy.splice(index, 1);

						return copy;
					} else {
						return null;
					}
				});

				setNumOfRow((old) => old - 1);
			}
		}
	};

	return (
		<Layout
			title='Delivery Fee Chart'
			className='orderDetails'
			loading={isLoading}
			noBack
			noTitle>
			<Grid className='deliveryFeeChart' container spacing={6}>
				<MerchantLayout
					title={`${id ? 'Update' : 'Add'} Delivery Fee Chart`}
					md={12}
					direction='column'>
					<Grid item container spacing={3}>
						<Grid item xs={12} md={2}>
							<Typography variant='subtitle1'>Delivery Type: </Typography>
						</Grid>
						<Grid item xs={12} md style={{ transform: 'translateY(-8px)' }}>
							<FormControl component='fieldset'>
								<RadioGroup
									aria-label='deliveryType'
									name='deliveryType'
									value={deliveryType}
									onChange={(e) => {
										setDeliveryType(e.target.value as 'regular');
									}}>
									<Grid>
										{delTypeArr.map((type, index) => (
											<FormControlLabel
												key={index}
												value={type.value}
												control={<Radio color='primary' />}
												label={type.label}
											/>
										))}
									</Grid>
								</RadioGroup>
							</FormControl>
						</Grid>
					</Grid>
					<Grid item container spacing={3}>
						<Grid item xs={12} md={2}>
							<Typography variant='subtitle1'>Parcel Type: </Typography>
						</Grid>
						<Grid item xs={12} md style={{ transform: 'translateY(-8px)' }}>
							<FormControl component='fieldset'>
								<RadioGroup
									aria-label='fragOrLiq'
									name='fragOrLiq'
									value={parcelType}
									onChange={(e) => {
										setParcelType(e.target.value as 'parcel');
									}}>
									<Grid>
										<FormControlLabel
											value='parcel'
											control={<Radio color='primary' />}
											label='Parcel'
										/>
										<FormControlLabel
											value='fragile'
											control={<Radio color='primary' />}
											label='Fragile'
										/>
										{/* <FormControlLabel
											value='grocery'
											control={<Radio color='primary' />}
											label='Grocery'
										/> */}
									</Grid>
								</RadioGroup>
							</FormControl>
						</Grid>
					</Grid>
				</MerchantLayout>
				<MerchantLayout direction='column' md={12} title='Change Agreement'>
					{Object.keys(agreement).map((key) => (
						<Grid item key={key} spacing={2} container>
							<Grid item md={2} xs={12}>
								<h4 style={{ textTransform: 'capitalize' }}>{key}</h4>
							</Grid>

							<Grid item md={9} xs={12}>
								<Select
									isSearchable
									value={{
										label: agreement[key as keyof Agreement]
											.split('_')
											.join(' '),
										value: agreement[key as keyof Agreement],
									}}
									onChange={(option) => {
										if (!option) return;

										setAgreement((old) => {
											const refOld = { ...old };

											refOld[key as keyof Agreement] = option.value;

											return refOld;
										});
									}}
									options={agreementSelect}
								/>
							</Grid>
						</Grid>
					))}
				</MerchantLayout>
				<MerchantLayout md={9} direction='column'>
					<Grid item xs>
						<CustomInput
							label='Note'
							placeholder='careful.'
							regex={/.*/gi}
							helperTxt={'should be at least 4 charecters long'}
							value={note}
							setValue={setNote}
							setDisabled={setNoteDis}
							type='text'
							fullWidth
							multiline
							rowSpan={5}
						/>
					</Grid>
					<Grid item container spacing={6}>
						<Grid item xs={12} md className='overflow'>
							<table className='rounded'>
								<thead>
									<tr>
										<th></th>
										<th>Delivery Fee</th>
										<th>COD (%)</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Weight For (1 Kg)</td>

										<td>
											<input
												onWheel={handleWheel}
												className={oneKg <= 0 ? 'error' : ''}
												type='number'
												placeholder='60'
												value={oneKg}
												onChange={(e) => setOneKg(Number(e.target.value))}
											/>
										</td>
										<td>
											<input
												onWheel={handleWheel}
												type='number'
												value={cod1 * 100}
												onChange={(e) => setCod1(Number(e.target.value) / 100)}
											/>
										</td>
									</tr>
									<tr>
										<td>Additional Charge For Per Kg</td>
										<td>
											<input
												onWheel={handleWheel}
												type='number'
												placeholder='10'
												value={oneKgAdd}
												onChange={(e) => setOneKgAdd(Number(e.target.value))}
											/>
										</td>
										<td>
											<input
												onWheel={handleWheel}
												type='number'
												value={cod1 * 100}
												onChange={(e) => setCod1(Number(e.target.value) / 100)}
											/>
										</td>
									</tr>
									<tr>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tbody>
							</table>
						</Grid>
						<Grid item xs={12} md>
							<table className='rounded'>
								<thead>
									<tr>
										<th>Weight (Range)</th>
										<th>Delivery Fee</th>
										<th>COD (%)</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{weightRangeArr.map((value, key) => (
										<tr key={key}>
											<td>
												<PriceArr
													name='range1'
													changeWeightRange={changeWeightRange}
													value={value.range1 ? value.range1 : 0}
													index={key}
												/>
												<span>to</span>
												<PriceArr
													name='range2'
													changeWeightRange={changeWeightRange}
													value={value.range2 ? value.range2 : 0}
													index={key}
												/>
											</td>
											<td>
												<PriceArr
													name='fee'
													changeWeightRange={changeWeightRange}
													value={value.fee ? value.fee : 0}
													index={key}
												/>
											</td>
											<td>
												<input
													onWheel={handleWheel}
													type='number'
													value={cod2 * 100}
													onChange={(e) =>
														setCod2(Number(e.target.value) / 100)
													}
												/>
											</td>
											<td>
												<div className='flex'>
													<Tooltip title='add' arrow placement='top'>
														<AddBoxIcon
															color='disabled'
															onClick={() => handleAdd(key)}
														/>
													</Tooltip>
													<Tooltip title='delete' arrow placement='top'>
														<DeleteIcon
															color='disabled'
															titleAccess='delete'
															onClick={() => handleDel(key)}
														/>
													</Tooltip>
												</div>
											</td>
										</tr>
									))}

									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tbody>
							</table>
						</Grid>
					</Grid>
					<Grid item>
						<Button
							color='primary'
							variant='contained'
							onClick={async () => {
								if (id)
									updateFee({ data: priceObj, name: note, id, agreement });

								if (!id) addFee({ data: priceObj, name: note, agreement });

								reset();
							}}
							disabled={disabled || loading || isLoading}>
							{id ? 'Update' : 'Add'} Delivery Fee Chart
						</Button>
					</Grid>
				</MerchantLayout>
				<MerchantLayout md direction='column'>
					<div style={{ maxHeight: '60vh', overflowY: 'auto' }}>{updateUi}</div>
				</MerchantLayout>
			</Grid>
		</Layout>
	);
};

export default DeliveryFeeChart;
