import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ParcelEntity } from '../../context/contextTypes';
import OrderAction from '../actions/OrderAction';
import { PARCEL_STATUS, stringTruncate } from '../../helper/helper';
import { SportsMotorsports } from '@material-ui/icons';

const Card = styled.div`
	margin: 0.5rem 0;
	padding: 1rem;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	border: 1px solid #ddd;
	border-radius: 10px;

	.🚚 {
		color: darkcyan;
		font-size: 0.7rem;
	}

	.🤑 {
		color: darkgreen;
		font-size: inherit;
	}

	p {
		color: #575757;
		font-size: 0.8rem;
	}

	p.tag {
		padding: 0.3rem 0.7rem;
		border-radius: 6px;
		font-size: inherit;
		min-width: 90px;
		text-align: center;
	}

	p.tag.w {
		color: #fff;
	}
`;

const MainContent = styled.main`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	& > * {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.5rem;
	}
`;

const Footer = styled.footer`
	border-top: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 0.5rem;
	padding-top: 0.5rem;
`;

interface Props {
	parcel: ParcelEntity;
	no: number;
	isAdmin?: boolean;
	isRejected?: boolean;
}

const OrderCard: React.FC<Props> = ({ parcel, no, isAdmin, isRejected }) => {
	return (
		<Card className='anim-card'>
			<MainContent>
				<section className='left'>
					<Link to={`/order/details/${parcel.objectId}`}>
						{no}. {parcel.order_id}
					</Link>
					<span className='🚚'>Type: {parcel.parcel_type}</span>
					{isAdmin && (
						<Link to={`/merchant/profile/${parcel.brand.objectId}`}>
							{stringTruncate(parcel.brand.name, 20)}
						</Link>
					)}

					<p>{stringTruncate(parcel.customer_name, 20)}</p>
					<p>{parcel.customer_phone}</p>
					<p>{stringTruncate(parcel.address, 20)}</p>
					<p className='🤑'>Tk. {parcel.amount}</p>
				</section>
				<section className='right'>
					<OrderAction
						isRejected
						status={parcel.status}
						oid={parcel.order_id}
						objectId={parcel.objectId}
						spacing={3}
					/>
				</section>
			</MainContent>
			<Footer>
				<section>
					{parcel.is_express ? (
						<SportsMotorsports style={{ color: 'red' }} fontSize='large' />
					) : (
						'....'
					)}
				</section>
				<section>
					<p className={`${parcel.status} tag w`}>
						{stringTruncate(PARCEL_STATUS[parcel.status], 12)}
					</p>
				</section>
				<section>
					<p
						className={`${
							parcel?.payment_status === 'paid'
								? parcel?.payment_status
								: 'unpaid'
						} tag w`}>
						{parcel.payment_status ? parcel.payment_status : 'Unpaid'}
					</p>
				</section>
			</Footer>
		</Card>
	);
};

export default OrderCard;
