import { gql } from '@apollo/client';

export const GET_BRAND = gql`
	query getBrand($objectId: ID!) {
		brands(where: { ownedBy: { have: { objectId: { equalTo: $objectId } } } }) {
			edges {
				node {
					objectId
					name
					logo
					verified
					whitelisted
					pickup_time
					pickup_locations {
						__typename
						... on Element {
							value
						}
					}
				}
			}
		}
	}
`;

export const GET_BRAND_BY_ID = gql`
	query getBrandById($id: ID!) {
		brand(id: $id) {
			verified
			name
			logo
			address
			whitelisted
			preferred_method
			payment_details
			scheme {
				objectId
				name
			}
			pickup_time
			pickup_locations {
				__typename
				... on Element {
					value
				}
			}
			contact_numbers {
				__typename
				... on Element {
					value
				}
			}
			area
			ownedBy {
				username
				objectId
			}
		}
	}
`;
