 /* eslint-disable */
import { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import { addHours, isBefore, startOfDay } from 'date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';
import { DocumentNode } from 'graphql';

// import BrandCard from '../../components/Card/BrandCard';
import {
	PICKUP_REQUESTS,
	PICKUP_REQUESTS_FAILED_ASSIGNED,
} from '../../graphql/queries/pickup.requests';
import useMessage from '../../hooks/useMessage';
import './pickup.assign.scss';
import PickupReqTable from '../../components/pickupReqTable/pickup.req.table';
import { endOfDay } from 'date-fns/esm';
import { instanceOfAxios } from '../../helper/axios';
import { useStore } from '../../context/Context';
import Layout from '../../components/layout/Layout';
import { PickupReq, PickUpReqs } from '../../context/contextTypes';
import useDelivery from '../../hooks/useDelivery';
import { errorMessage } from '../../helper/helper';
import useParcel from '../../hooks/useParcel';
import { SelectLabel } from '../bulkScreen/BulkScreen';
import useRiders from '../../hooks/useRiders';

type ParcelT = 'regular' | 'failed_or_assigned';

interface Props {
	parcelType: ParcelT;
	title: string;
}

export type SlotType = 'morning' | 'evening';

const parcelTypes: { [index: string]: DocumentNode } = {
	regular: PICKUP_REQUESTS,
	failed_or_assigned: PICKUP_REQUESTS_FAILED_ASSIGNED,
};

const PickupAssign: React.FC<Props> = ({ parcelType = 'regular', title }) => {
	const { timeMakerAdmin, setSlot, setSlotSelect, slotSelect, slot } =
		useParcel();

	const { ridersData, ridersLoading } = useRiders('pickup-rider');

	const zoneRef = useRef() as any;

	const brandRef = useRef() as any;

	const slotRef = useRef() as any;

	const riderRef = useRef() as any;

	const isRegular = parcelType === 'regular';

	const [requestsSelected, setRequestsSelected] = useState<string[]>([]);

	const [slotSort, setSlotSort] = useState<SelectLabel | null>(null);

	const [areas, setAreas] = useState<string[] | null>(null);

	const [brand, setBrand] = useState<string | null>(null);

	const [brands, setBrands] = useState<string[] | null>(null);

	const [area, setArea] = useState<string | null>(null);

	const [rider, setRider] = useState<string | null>(null);

	const [date, setDate] = useState<MaterialUiPickersDate>(new Date());

	const [isSetAll, setIsSetAll] = useState(false);

	const matches = useMediaQuery('(min-width:1024px)');

	const { handleNotification } = useMessage();

	const { isLoading, setIsLoading } = useDelivery();

	// store

	const {
		store: { user, pickUpReqs, pickUpReqsSort },
		dispatch,
	} = useStore();

	const token = user?.sessionToken;

	const POLL_INTERVAL_IN_MIN = 10;

	// @queries

	const { loading, data, error, refetch } = useQuery(parcelTypes[parcelType], {
		variables: isRegular && {
			greater: startOfDay(new Date(date as Date)).toISOString(),
			less: endOfDay(new Date(date as Date)).toISOString(),
		},
		pollInterval: 1000 * 60 * POLL_INTERVAL_IN_MIN,
	});

	// dispatch reqs

	useEffect(() => {
		if (date) {
			refetch();
		}
	}, [date]);

	useEffect(() => {
		if (data) {
			let processedData: PickUpReqs[] = [];
			let unOrderedData: PickUpReqs[] = data['pickup_requests']['edges'];

			setAreas(
				_.uniq(unOrderedData.map(({ node }: PickUpReqs) => node.address?.area))
			);

			setBrands(
				_.uniq(unOrderedData.map(({ node }: PickUpReqs) => node.brand.name))
			);

			// compound

			const isFilterActive = brand || area || slotSort;

			if (isFilterActive) {
				let filtered: PickUpReqs[] = pickUpReqsSort;

				if (area) {
					filtered = filtered.filter(({ node }) => node.address?.area === area);
				}

				if (brand) {
					filtered = filtered.filter(({ node }) => node.brand.name === brand);
				}

				if (slotSort) {
					filtered = filtered.filter(({ node }) => {
						const sevenAm = addHours(
							startOfDay(new Date(node.requestedOn)),
							11
						);

						const isBefore7 = isBefore(new Date(node.requestedOn), sevenAm);

						const slot: SlotType = isBefore7 ? 'morning' : 'evening';

						return slot === slotSort.value;
					});
				}

				dispatch({
					type: 'ADD_PICKUP_REQS',
					payload: filtered,
				});

				return;
			}

			// normal

			unOrderedData.forEach((data: PickUpReqs) => {
				if (!data.node.parent) {
					processedData.push(data);
					const childReqs = unOrderedData.filter(
						(i) => i.node.parent === data.node.objectId
					);
					if (childReqs.length > 0)
						processedData = [...processedData, ...childReqs];
				}
			});

			dispatch({
				type: 'ADD_PICKUP_REQS',
				payload: processedData,
			});

			dispatch({
				type: 'ADD_PICKUP_REQS_SORT',
				payload: processedData,
			});
		}
	}, [data, brand, area, slotSort, dispatch]);

	// check logic

	const handleChange = () => {
		setIsSetAll(!isSetAll);
		if (isSetAll) {
			handleSelectRequests({ pickUpRequestId: [], type: 'REMOVE_ALL' });
		} else {
			handleSelectRequests({
				pickUpRequestId: pickUpReqs.map(({ node: { objectId } }) => objectId),
				type: 'ADD_ALL',
			});
		}
	};

	const handleSelectRequests = ({ pickUpRequestId, type }: PickupReq) => {
		switch (type) {
			case 'ADD_ONE':
				setRequestsSelected((old) => {
					return [...old, pickUpRequestId as string];
				});
				break;
			case 'ADD_ALL':
				setRequestsSelected(pickUpRequestId as string[]);
				break;
			case 'REMOVE_ONE':
				setRequestsSelected((old) => {
					return old.filter((id) => pickUpRequestId !== id);
				});
				break;
			case 'REMOVE_ALL':
				setRequestsSelected([]);
				break;
			default:
				break;
		}
	};

	const reset = () => {
		setRider(null);
		setRequestsSelected([]);
		setIsSetAll(true);
		setIsSetAll(false);

		if (zoneRef.current) zoneRef.current.state.value = null;
		if (brandRef.current) brandRef.current.state.value = null;
		if (riderRef.current) riderRef.current.state.value = null;
		if (riderRef.current) riderRef.current.state.value = null;
	};

	const handleAssign = async () => {
		setIsLoading(true);

		const headers = {
			'X-Parse-Session-Token': token,
		};

		try {
			await instanceOfAxios.post(
				'/functions/assignPickupRider',
				{
					objectIds: requestsSelected,
					rider,
				},
				{
					headers,
				}
			);

			reset();

			await refetch();

			setIsLoading(false);

			handleNotification({
				variant: 'standard',
				message: 'successfully assigned rider',
				type: 'success',
			});
		} catch (error: any) {
			setIsLoading(false);

			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});
		}
	};

	const handleReschedule = async () => {
		setIsLoading(true);

		const headers = {
			'X-Parse-Session-Token': token,
		};

		try {
			await instanceOfAxios.post(
				'/functions/changePickupRequestSlot',
				{
					objectIds: requestsSelected,
					slot,
				},
				{
					headers,
				}
			);

			reset();

			await refetch();

			setIsLoading(false);

			handleNotification({
				variant: 'standard',
				message: 'successfully resheduled parcel',
				type: 'success',
			});
		} catch (error: any) {
			setIsLoading(false);

			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});
		}
	};

	const handleDuplicate = async (id: string) => {
		setIsLoading(true);

		const headers = {
			'X-Parse-Session-Token': token,
		};

		try {
			await instanceOfAxios.post(
				'/functions/clonePickupRequest',
				{
					objectId: id,
				},
				{
					headers,
				}
			);

			await refetch();
			setIsLoading(false);
		} catch (error: any) {
			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});
			setIsLoading(false);
		}
	};

	// errors any

	useEffect(() => {
		if (error) {
			handleNotification({
				variant: 'standard',
				message: error.message,
				type: 'error',
			});
		}
	}, [error, handleNotification]);

	useEffect(() => {
		if (isSetAll) {
			handleSelectRequests({
				pickUpRequestId: pickUpReqs.map(({ node: { objectId } }) => objectId),
				type: 'ADD_ALL',
			});
			return;
		}

		handleSelectRequests({
			pickUpRequestId: [],
			type: 'REMOVE_ALL',
		});
	}, [pickUpReqs, isSetAll]);

	return (
		<Layout className='pickupAssign' loading={loading} title={title}>
			<div className='pickupAssign__container anim-frame rounded shadow-lg'>
				{isRegular ? (
					<div className='pickupAssign__assign'>
						<div className='pickupAssign__assign__each'>
							<div className='pickupAssign__assign__each__title'>
								<h5>Date:</h5>
							</div>
							<div className='pickupAssign__assign__each__select'>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<DatePicker
										fullWidth
										title='date'
										variant={matches ? 'inline' : 'dialog'}
										className='pickupAssign__search date rounded'
										onChange={(value) => setDate(value)}
										value={date}
									/>
								</MuiPickersUtilsProvider>
							</div>
						</div>
						<div className='pickupAssign__assign__each'>
							<div className='pickupAssign__assign__each__title'>
								<h5>Zone:</h5>
							</div>
							<div className='pickupAssign__assign__each__select'>
								<Select
									ref={zoneRef}
									className='pickupAssign__search'
									isClearable
									isSearchable
									isLoading={loading}
									onChange={(value) => {
										setArea(value?.value as string);
									}}
									placeholder='Select Zone....'
									options={areas?.map((area: string) => {
										return {
											label: area,
											value: area,
										};
									})}
								/>
							</div>
						</div>
						<div className='pickupAssign__assign__each'>
							<div className='pickupAssign__assign__each__title'>
								<h5>Brand:</h5>
							</div>
							<div className='pickupAssign__assign__each__select'>
								<Select
									ref={brandRef}
									className='deliveryAssign__search'
									isClearable
									isSearchable
									isLoading={loading}
									onChange={(value) => setBrand(value?.value as string)}
									placeholder='Select Brand....'
									options={brands?.map((name: string) => {
										return {
											label: name,
											value: name,
										};
									})}
								/>
							</div>
						</div>
						<div className='pickupAssign__assign__each'>
							<div className='pickupAssign__assign__each__title'>
								<h5>Slot:</h5>
							</div>
							<div className='pickupAssign__assign__each__select'>
								<Select
									ref={slotRef}
									className='deliveryAssign__search'
									isClearable
									isSearchable
									value={slotSort}
									onChange={(value) => setSlotSort(value)}
									placeholder='Select slot..'
									options={[
										{ label: 'Evening', value: 'evening' },
										{ label: 'Morning', value: 'morning' },
									]}
								/>
							</div>
						</div>
						<div className='pickupAssign__assign__each'>
							<div className='pickupAssign__assign__each__title'>
								<h5>Rider:</h5>
							</div>
							<div className='pickupAssign__assign__each__select'>
								<Select
									ref={riderRef}
									className='pickupAssign__search'
									isClearable
									isSearchable
									isLoading={ridersLoading}
									onChange={(value) => setRider(value?.value as string)}
									placeholder='Select Rider....'
									options={ridersData.map(({ objectId, username }) => {
										return {
											label: username,
											value: objectId,
										};
									})}
								/>
							</div>
						</div>
						<div className='pickupAssign__assign__each btn'>
							<Button
								fullWidth
								variant='contained'
								color='primary'
								onClick={handleAssign}
								disabled={isLoading}>
								Assign <strong> ({requestsSelected.length}) </strong>
							</Button>
						</div>
					</div>
				) : (
					<div className='pickupAssign__assign'>
						<div className='pickupAssign__assign__each'>
							<div className='pickupAssign__assign__each__title'>
								<h5>Zone:</h5>
							</div>
							<div className='pickupAssign__assign__each__select'>
								<Select
									className='pickupAssign__search'
									isClearable
									isSearchable
									isLoading={loading}
									onChange={(value) => {
										setArea(value?.value as string);
									}}
									placeholder='Select Zone....'
									options={areas?.map((area: string) => {
										return {
											label: area,
											value: area,
										};
									})}
								/>
							</div>
						</div>
						<div className='pickupAssign__assign__each'>
							<div className='pickupAssign__assign__each__title'>
								<h5>Brand:</h5>
							</div>
							<div className='pickupAssign__assign__each__select'>
								<Select
									className='deliveryAssign__search'
									isClearable
									isSearchable
									isLoading={loading}
									onChange={(value) => setBrand(value?.value as string)}
									placeholder='Select Brand....'
									options={brands?.map((name: string) => {
										return {
											label: name,
											value: name,
										};
									})}
								/>
							</div>
						</div>
						<div className='pickupAssign__assign__each'>
							<div className='pickupAssign__assign__each__title'>
								<h5>Slot:</h5>
							</div>
							<div className='pickupAssign__assign__each__select'>
								<Select
									className='deliveryAssign__search'
									onChange={(value) => {
										setSlot(value?.value.toISOString());

										setSlotSelect(value);
									}}
									isClearable
									isSearchable
									placeholder='Pickup Slot'
									value={slotSelect}
									options={timeMakerAdmin}
								/>
							</div>
						</div>
						<div className='pickupAssign__assign__each btn'>
							<Button
								fullWidth
								variant='contained'
								color='primary'
								disabled={isLoading}
								onClick={handleReschedule}>
								reshedule <strong> ({requestsSelected.length}) </strong>
							</Button>
						</div>
					</div>
				)}

				<div className='pickupAssign__brandTable'>
					<PickupReqTable
						refetch={refetch}
						handleDuplicate={handleDuplicate}
						setIsSetAll={setIsSetAll}
						isSetAll={isSetAll}
						handleSelectRequests={handleSelectRequests}
						riderType='Pickup'
					/>
				</div>

				<div className='pickupAssign__brandCard'>
					<div className='check'>
						<Checkbox
							checked={isSetAll}
							value={isSetAll}
							onChange={handleChange}
							color='primary'
						/>
						<span>Select All</span>
					</div>
					{/* {pickUpReqs.map((req, i) => (
						<BrandCard
							req={req}
							forceSet={isSetAll}
							handleSelectRequests={handleSelectRequests}
							key={req.node.objectId + i}
						/>
					))} */}
				</div>
			</div>
		</Layout>
	);
};

export default PickupAssign;
