import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import './commonBlock.scss';

export interface Card {
	title?: string;
	subTitle: string;
	svg: string;
	amount: number | undefined;
	color?: string;
}

export interface Sales {
	title?: string;
	subTitle: string;
	svg: string;
	amount: number | undefined;
	color?: string;
	normal: Card;
	online: Card;
}

export interface Footer {
	title: string;
	amount: string;
}

interface Props {
	cards: Card[];
	footers?: Footer[];
	sales?: Sales;
}

const CommonBlock: React.FC<Props> = ({ cards, footers, sales }) => {
	const matches = useMediaQuery('(min-width:960px)');

	return (
		<div className='commonBlock rounded shadow-md'>
			<div
				className='commonBlock__main'
				style={{
					gridTemplateColumns: sales ? (matches ? '1.5fr 1fr' : '1fr') : '',
				}}
			>
				{sales && (
					<div className='sales rounded shadow-md'>
						<div className='main'>
							<h1 className='text-4xl' style={{ color: sales.color }}>
								{sales.title} {sales.amount}
							</h1>
							<p>Total Sales</p>
							<img
								src='/custom/payment/money-green.svg'
								alt='icon'
								height='60'
								width='60'
							/>
						</div>
						<div className='separate'>
							<div className='each separate--1'>
								<h1
									className={matches ? 'text-4xl' : 'text-2xl'}
									style={{ color: sales.normal.color }}
								>
									{sales.normal.title} {sales.normal.amount}
								</h1>
								<p>Cash Payments</p>
							</div>
							<div className='each separate--2'>
								<h1
									className={matches ? 'text-4xl' : 'text-2xl'}
									style={{ color: sales.online.color }}
								>
									{sales.online.title} {sales.online.amount}
								</h1>
								<p>Online Payments</p>
							</div>
						</div>
					</div>
				)}
				{sales ? (
					<div>
						{cards.map((card, i) => (
							<div key={i} className='each rounded shadow-md anim-card'>
								<h1 className='text-4xl' style={{ color: card.color }}>
									{card.title} {card.amount}
								</h1>
								<p>{card.subTitle}</p>
								<img src={card.svg} alt='icon' height='50' width='50' />
							</div>
						))}
					</div>
				) : (
					<>
						{cards.map((card, i) => (
							<div key={i} className='each rounded shadow-md anim-card'>
								<h1 className='text-4xl' style={{ color: card.color }}>
									{card.title} {card.amount}
								</h1>
								<p>{card.subTitle}</p>
								<img src={card.svg} alt='icon' height='50' width='50' />
							</div>
						))}
					</>
				)}
			</div>
			{footers && (
				<div className='commonBlock__footer rounded shadow-sm'>
					{footers.map((footer, i) => (
						<div key={footer.title + i} className='each'>
							<p>
								{footer.title} : {footer.amount}
							</p>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default CommonBlock;
