import React from 'react';
import { Helmet } from 'react-helmet';

import './common.scss';

interface Props {
	className?: string;
	title: string;
}

const Common: React.FC<Props> = ({ children, className, title }) => {
	return (
		<div className={`common`}>
			<Helmet>
				<title>Now | {title}</title>
			</Helmet>
			<div
				className='common__logo '
				style={{ backgroundImage: `url(custom/logo.svg)` }}
			/>
			<div className={`common__child ${className}`}>
				<img
					src='/custom/logoBlack.svg'
					alt='now logo'
					height='220'
					width='220'
					className='common__logo-mobile'
				/>
				{children}
			</div>
		</div>
	);
};

export default Common;
