import React, { useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import { useMutation, useQuery } from "@apollo/client";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import "./orderDetails.scss";
import Layout from "../../components/layout/Layout";
import MerchantLayout from "../../components/layout/MerchantLayout";
import TimelineCom from "../../components/timeline/Timeline";
import { useStore } from "../../context/Context";
import { GET_ORDER_BY_ID } from "../../graphql/queries/parcels";
import { useParams } from "react-router";
import useMessage from "../../hooks/useMessage";
import { OrderDetailsGql } from "../../context/contextTypes";
import {
  getNameOrUsername,
  PARCEL_STATUS,
  relativeNow,
} from "../../helper/helper";
import OrderAction from "../../components/actions/OrderAction";
import RaiseIssueAction from "../../components/actions/RaiseIssueAction";
import useModal from "../../hooks/useModal";
import Modal from "../../components/modal/Modal";
import useActions from "../../hooks/useActions";
import { isEmpty } from "lodash";
import { useCallback } from "react";
import { UPDATE_RETURN_TRACKING } from "../../graphql/mutations/parcel";
import LoadingBtn from "../../components/typography/buttons/LoadingBtn";

const OrderDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { toggle, ...rest } = useModal();

  const { handleRaiseIssue, loading: loadingIssue } = useActions();

  const {
    store: { orderDetails, user },
    dispatch,
  } = useStore();

  const { handleNotification } = useMessage();

  const { data, loading, error, refetch } = useQuery<{
    parcel: OrderDetailsGql;
  }>(GET_ORDER_BY_ID, {
    variables: {
      orderId: id,
    },
  });

  const [updateStatusReturn, { loading: returnLoading }] = useMutation(
    UPDATE_RETURN_TRACKING,
    {
      variables: {
        id: orderDetails?.objectId,
        obj: {
          ...orderDetails?.return_tracking,
          status: "done",
          createdAt: { __type: "Date", iso: new Date().toISOString() },
        },
      },
    }
  );

  useEffect(() => {
    if (error) {
      handleNotification({
        variant: "standard",
        type: "error",
        message: error.message,
      });
    }
  }, [error, handleNotification]);

  useEffect(() => {
    if (data) {
      dispatch({ type: "ADD_ORDER_DETAILS", payload: data.parcel });
    }
  }, [data, dispatch]);

  const makeStatusDone = useCallback(async () => {
    try {
      await updateStatusReturn();

      data?.parcel.return_tracking &&
        dispatch({
          type: "ADD_ORDER_DETAILS",
          payload: {
            ...data.parcel,
            return_tracking: { ...data.parcel.return_tracking, status: "done" },
          },
        });

      refetch();
    } catch (error: any) {
      handleNotification({
        variant: "standard",
        type: "error",
        message: error.message,
      });
    }
  }, [updateStatusReturn, handleNotification, data, dispatch, refetch]);

  return (
    <Layout
      title="Order Details"
      className="orderDetails"
      loading={loading}
      noBack
      noTitle
    >
      <Grid container className="orderDetails__main">
        <Modal {...rest}>
          <RaiseIssueAction
            loading={loadingIssue}
            onSubmit={async (data) => {
              await handleRaiseIssue({
                thread: [
                  {
                    by: getNameOrUsername(user?.user as any),
                    timestamp: new Date().toISOString(),
                    text: data.text,
                  },
                ],
                parcel: id,
                label: data.label,
              });

              toggle();
            }}
          />
        </Modal>

        <MerchantLayout
          md={12}
          direction="column"
          comp={
            <Grid container direction="column" spacing={2}>
              <Grid item container spacing={3}>
                <Grid item xs={12} md={3}>
                  <h2>Delivery #{id}</h2>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        style={{ color: "white" }}
                        disableElevation
                        disableRipple
                        className={`${orderDetails?.status}`}
                        variant="text"
                      >
                        {
                          PARCEL_STATUS[
                            orderDetails ? orderDetails.status : "unknown"
                          ]
                        }
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button disableElevation disableRipple variant="outlined">
                        {orderDetails?.pickup_cash
                          ? "Cash Pickup"
                          : "Online Paid"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md>
                  {orderDetails && (
                    <OrderAction
                      toggleModal={toggle}
                      spacing={1}
                      redirect
                      objectId={orderDetails.objectId}
                      oid={orderDetails.order_id}
                      status={orderDetails.status}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item container direction="column">
                <Typography variant="subtitle2" color="textSecondary">
                  {orderDetails?.brand.name}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  Merchant Order ID - {orderDetails?.moid}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  Order ID - {orderDetails?.order_id}
                </Typography>
              </Grid>
            </Grid>
          }
        >
          <Grid item container spacing={3}>
            <Grid item xs={12} md={3}>
              <p>Requested Slot</p>
              <h4>
                {orderDetails &&
                  // here it will overlap
                  relativeNow(new Date(orderDetails.requestedSlot))}
              </h4>
            </Grid>
            <Grid item xs={12} md={3}>
              <p>Recipient Name </p>
              <h4>{orderDetails?.customer_name}</h4>
            </Grid>
            <Grid item xs={12} md={3}>
              <p>Recipient Phone</p>
              <h4>{orderDetails?.customer_phone}</h4>
            </Grid>
            <Grid item xs={12} md>
              <p>Recipient Address </p>
              <h4>{orderDetails?.address}</h4>
            </Grid>
          </Grid>
          <Grid item container spacing={3}>
            <Grid item xs={12} md={3}>
              <p>Created Date</p>
              <h4>
                {orderDetails &&
                  new Date(orderDetails.createdAt).toDateString()}
              </h4>
            </Grid>
            <Grid item xs={12} md={3}>
              <p>Delivery Rider</p>
              <h4>{orderDetails?.deliveryRider?.name || "Unavailable"}</h4>
            </Grid>
            <Grid item xs={12} md={3}>
              <p>Recipient City </p>
              <h4>{orderDetails?.zone}</h4>
            </Grid>
            <Grid item xs={12} md={3}>
              <p>Recipient Zone</p>
              <h4>{orderDetails?.area}</h4>
            </Grid>
          </Grid>
        </MerchantLayout>

        {orderDetails?.return_tracking && (
          <MerchantLayout title="Return Tracking" md={12}>
            <Grid item container spacing={3}>
              <Grid item xs={12} md={3}>
                <p>Assigned At</p>
                <h4>
                  {new Date(
                    orderDetails.return_tracking.assignedAt.iso
                  ).toDateString()}
                </h4>
              </Grid>
              <Grid item xs={12} md={3}>
                <p>Assigned To</p>
                <h4>{orderDetails.return_tracking.assigned}</h4>
              </Grid>
              <Grid item xs={12} md={3}>
                <p>Status</p>
                <h4 style={{ color: "darkcyan" }}>
                  {orderDetails.return_tracking.status.toUpperCase()}
                </h4>
              </Grid>
              {orderDetails.return_tracking.status !== "done" &&
                user?.user.type === "admin" && (
                  <Grid item xs={12} md={3}>
                    <LoadingBtn
                      disabled={returnLoading}
                      onClick={makeStatusDone}
                    >
                      Done
                    </LoadingBtn>
                  </Grid>
                )}
            </Grid>
          </MerchantLayout>
        )}

        <MerchantLayout title="Notes" md={12}>
          <Grid item xs={12}>
            {isEmpty(orderDetails?.notes) ? (
              <p>No Notes Available</p>
            ) : (
              <ul>
                {orderDetails?.notes.map((note) => {
                  return (
                    <li
                      key={note.value._id}
                      style={{ transform: "translateX(14px)" }}
                    >
                      <h4>
                        {note.value.text} -{" "}
                        <span style={{ color: "#555", fontWeight: "lighter" }}>
                          {" "}
                          {note.value.by}{" "}
                        </span>
                      </h4>
                    </li>
                  );
                })}
              </ul>
            )}
          </Grid>
        </MerchantLayout>
        <MerchantLayout
          direction="column"
          title={`Delivery Charge Details  ${
            orderDetails?.cost_breakdown
              ? ""
              : "(will be available after delivery)"
          }`}
          md={6}
        >
          <Grid item container justify="space-between">
            <Grid item>Delivery Fee</Grid>
            <Grid item>
              Tk.{" "}
              {orderDetails?.cost_breakdown
                ? orderDetails?.cost_breakdown.delivery_charge
                : 0}
            </Grid>
          </Grid>
          <Grid item container justify="space-between">
            <Grid item>COD Fee</Grid>
            <Grid item>
              Tk.{" "}
              {orderDetails?.cost_breakdown
                ? orderDetails?.cost_breakdown.cod
                : 0}
            </Grid>
          </Grid>
          <Divider />
          <Grid item container justify="space-between">
            <Grid item>Total Amount</Grid>
            <Grid item>
              Tk.{" "}
              {orderDetails?.cost_breakdown
                ? orderDetails?.cost_breakdown.total
                : 0}
            </Grid>
          </Grid>
        </MerchantLayout>
        <MerchantLayout
          className=""
          title="Delivery Info "
          direction="column"
          md
        >
          <Grid item container justify="space-between">
            <Grid item>Product Type</Grid>
            <Grid item>{orderDetails?.parcel_type}</Grid>
          </Grid>
          <Grid item container justify="space-between">
            <Grid item>Delivery Type</Grid>
            <Grid item>{orderDetails?.delivery_type}</Grid>
          </Grid>
          <Grid item container justify="space-between">
            <Grid item>Weight</Grid>
            <Grid item>{orderDetails?.weight} KG</Grid>
          </Grid>
          <Grid item container justify="space-between">
            <Grid item>Amount to Collect</Grid>
            <Grid item>Tk. {orderDetails?.amount}</Grid>
          </Grid>
          <Divider />

          <Grid item container direction="column" spacing={1}>
            <Grid item>
              <p>Description</p>
            </Grid>
            <Grid item>
              <h4>{orderDetails?.description}</h4>
            </Grid>
          </Grid>
        </MerchantLayout>
        <MerchantLayout
          className="timeline "
          title="Timeline "
          direction="column"
          md={12}
        >
          <Grid item md={7} xs>
            <TimelineCom timeline={orderDetails?.timelines} />
          </Grid>
        </MerchantLayout>
      </Grid>
    </Layout>
  );
};

export default OrderDetails;
