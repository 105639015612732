import { gql } from '@apollo/client';

export const BECOME_MERCHANT = gql`
	mutation signUp($username: String!, $password: String!, $name: String!) {
		signUp(
			input: {
				fields: {
					username: $username
					password: $password
					name: $name
					type: "merchant"
				}
			}
		) {
			viewer {
				sessionToken
				user {
					username
				}
			}
		}
	}
`;

export const SIGN_UP_MERCHANT = gql`
	mutation createBrand(
		$name: String!
		$address: String!
		$lat: Float!
		$long: Float!
		$number: [Any]!
		$area: String!
		$zone: String!
		$flat: String!
		$pickup_time: String!
	) {
		createBrand(
			input: {
				fields: {
					name: $name
					address: $address
					geolocation: { latitude: $lat, longitude: $long }
					contact_numbers: $number
					zone: $zone
					scheme: { link: "" }
					area: $area
					flat: $flat
					pickup_time: $pickup_time
				}
			}
		) {
			brand {
				name
			}
		}
	}
`;
