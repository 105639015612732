/* eslint-disable */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import {
	makeStyles,
	createStyles,
	Theme,
	withStyles,
} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Select from 'react-select';
import PublishIcon from '@material-ui/icons/Publish';
import { AddLocation } from '@material-ui/icons';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import SaveIcon from '@material-ui/icons/Save';
import { useHistory, useParams } from 'react-router';
import { Form, Formik } from 'formik';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	TextField,
} from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Clipboard from 'react-clipboard.js';

import './merchantProfile.scss';
import Layout from '../../components/layout/Layout';
import StoreIcon from '@material-ui/icons/Store';
import MerchantLayout from '../../components/layout/MerchantLayout';
import { useStore } from '../../context/Context';
import CustomInput from '../../components/customInput/CustomInput';
import useProfile, { Store } from '../../hooks/useProfile';
import { useQuery } from '@apollo/client';
import { GET_BRAND_BY_ID } from '../../graphql/queries/brand';
import NumberArr from '../../components/numberArr/NumberArr';
import { hasChanged, toCap } from '../../helper/helper';
import useAddress from '../../hooks/useAddress';
import { cities } from '../../docs/city';
import { StoreQuery } from '../../context/contextTypes';
import { MOBILE_REGEX_BD } from '../../helper/regex';
import FormDialog from '../../components/formDialog/FormDialog';
import { SelectLabel } from '../bulkScreen/BulkScreen';

export interface BrandInfo {
	brand: Brand;
}
export interface Brand {
	name: string;
	preferred_method?: string;
	address: string;
	payment_details: PaymentDetails | null;
	pickup_time: 'morning' | 'evening';
	pickup_locations: StoreQuery[] | null;
	contact_numbers: {
		__typename: string;
		value: string;
	}[];
	verified: boolean;
	whitelisted?: boolean;
	area: string;
	ownedBy: OwnedBy;
	logo: string;
	scheme:
	| {
		objectId: string;
		name: string;
	}
	| undefined;
}
export interface OwnedBy {
	username: string;
	objectId: string;
}

export interface PaymentDetails {
	bank: Bank;
	bkash?: string;
	rocket?: string;
	nagad?: string;
}
export interface Bank {
	account_name: string;
	account_number: string;
	bank_name: string;
	branch_name: string;
	beneficiary_number: string;
}

const validationScheme = yup.object().shape({
	storeName: yup.string().required('please fill up'),
	address: yup.string().required('please fill up'),
	flat: yup.string().required('please fill up'),
	number: yup
		.string()
		.required('please fill up')
		.matches(
			/^(?:\+88|01)?(?:\d{11}|\d{13})$/,
			'please try (017...) with 11 digit'
		),
	area: yup.object().shape({
		value: yup.string().required('please select an area'),
	}),
});

const useStyles = makeStyles((_theme: Theme) =>
	createStyles({
		input: {
			display: 'none',
		},
		upload: {
			transform: 'translateX(-5px)',
		},
		table: {
			width: '100%',
		},
		appBar: {
			position: 'relative',
		},
	})
);

const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: theme.palette.common.black,
			color: theme.palette.common.white,
		},
		body: {
			fontSize: 14,
		},
	})
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow);

const MerchantProfile = () => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	// const [disabled, _setDisabled] = useState(false);

	const [isEditable, _setIsEditable] = useState(true);

	const [planSelect, setPlanSelect] = useState<SelectLabel | null>(null);
	const [planSelectOld, setPlanSelectOld] = useState({ label: '', value: '' });

	const [methodSelect, setMethodSelect] = useState<SelectLabel | null>(null);
	const [methodSelectOld, setMethodSelectOld] = useState({ label: '', value: '' });

	const [isVerified, setIsVerified] = useState(false);
	const [isVerifiedOld, setIsVerifiedOld] = useState(false);

	const [whitelisted, setWhitelisted] = useState(false);
	const [whitelistedOld, setWhitelistedOld] = useState(false);

	const [bkashOld, setBkashOld] = useState('');
	const [bkash, setBkash] = useState('');
	const [_bkashDis, setBkashDis] = useState(false);

	const [rocketOld, setRocketOld] = useState('');
	const [rocket, setRocket] = useState('');
	const [_rocketDis, setRocketDis] = useState(false);

	const [nagadOld, setNagadOld] = useState('');
	const [nagad, setNagad] = useState('');
	const [_NagadDis, setNagadDis] = useState(false);

	const [nameOld, setNameOld] = useState('');
	const [name, setName] = useState('');
	const [_nameDis, setNameDis] = useState(false);

	const [bankOld, setBankOld] = useState('');
	const [bank, setBank] = useState('');
	const [_bankDis, setBankDis] = useState(true);

	const [addressOld, setAddressOld] = useState('');
	const [address, setAddress] = useState('');
	const [_addressDis, setAddressDis] = useState(true);

	const [AccHolderNameOld, setAccHolderNameOld] = useState('');
	const [AccHolderName, setAccHolderName] = useState('');
	const [_AccHolderNameDis, setAccHolderNameDis] = useState(true);

	const [accNumberOld, setAccNumberOld] = useState('');
	const [accNumber, setAccNumber] = useState('');
	const [_accNumberDis, setAccNumberDis] = useState(true);

	const [branchOld, setBranchOld] = useState('');
	const [branch, setBranch] = useState('');
	const [_branchDis, setBranchDis] = useState(true);

	const [benefNumOld, setBenefNumOld] = useState('');
	const [benefNum, setBenefNum] = useState('');
	const [_benefNumDis, setBenefNumDis] = useState(true);

	const [numbers, setNumbers] = useState<string[] | null>(null);

	const [slot, setSlot] = useState('morning');
	const [slotOld, setSlotOld] = useState('morning');

	const [numbersNext, setNumbersNext] = useState<string[] | null>(null);

	const [secretText, setSecretText] = useState('Copy login secret');

	const handleChangeCheck = (
		_event: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => {
		setSlot(value);
	};

	const {
		store: { brand, user, plan },
		dispatch,
	} = useStore();

	console.log(user)

	const {
		handleUploadChange,
		updateBrand,
		upLoading,
		setAvatar,
		avatar,
		getPlan,
		addStore,
		getToken,
		token,
	} = useProfile();

	const { handleAddress, lat, long } = useAddress();

	const params = useParams<{ id: string }>();

	const { push } = useHistory();

	const areas = useMemo(() => {
		return cities.filter((item) => item.district === 'Dhaka');
	}, []);

	const isAdmin = user?.user.type === 'admin';

	useEffect(() => {
		if (brand) {
			if (brand.node.objectId !== params.id) {
				push(`/${user?.user.type}/${brand.node.objectId}`);
			}
		}
	}, [brand, params, push, user]);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleUpdate = async () => {
		let data = {};

		if (hasChanged({ new: name, old: nameOld })) {
			data = { ...data, name };
		}

		if (
			hasChanged({ new: bank, old: bankOld }) ||
			hasChanged({ new: AccHolderName, old: AccHolderNameOld }) ||
			hasChanged({ new: accNumber, old: accNumberOld }) ||
			hasChanged({ new: branch, old: branchOld }) ||
			hasChanged({ new: benefNum, old: benefNumOld }) ||
			hasChanged({ new: bkash, old: bkashOld }) ||
			hasChanged({ new: nagad, old: nagadOld }) ||
			hasChanged({ new: rocket, old: rocketOld })
		) {
			data = {
				...data,
				payment_details: {
					bank: {
						account_name: AccHolderName,
						account_number: accNumber,
						bank_name: bank,
						branch_name: branch,
						beneficiary_number: benefNum,
					},
					bkash,
					nagad,
					rocket,
				},
			};
		}

		if (
			hasChanged({
				new: JSON.stringify(planSelect),
				old: JSON.stringify(planSelectOld),
			})
		) {
			data = {
				...data,
				scheme: {
					__type: 'Pointer',
					className: 'pricing_scheme',
					objectId: planSelect?.value,
				},
			};
		}

		if (
			hasChanged({
				new: JSON.stringify(methodSelect),
				old: JSON.stringify(methodSelectOld),
			})
		) {
			data = {
				...data,
				preferred_method: methodSelect?.value,
			};
		}

		if (hasChanged({ new: address, old: addressOld })) {
			data = { ...data, address };
		}

		if (
			hasChanged({
				new: JSON.stringify(isVerified),
				old: JSON.stringify(isVerifiedOld),
			})
		) {
			data = { ...data, verified: isVerified };
		}
		if (
			hasChanged({
				new: JSON.stringify(whitelisted),
				old: JSON.stringify(whitelistedOld),
			})
		) {
			data = { ...data, whitelisted };
		}

		if (
			hasChanged({
				new: JSON.stringify(numbersNext),
				old: JSON.stringify(numbers),
			})
		) {
			data = { ...data, contact_numbers: numbersNext };
		}

		if (
			hasChanged({
				new: slot,
				old: slotOld,
			})
		) {
			data = { ...data, pickup_time: slot };
		}

		await updateBrand(params.id, data);

		refetch();
	};

	const { data, loading, refetch } = useQuery<BrandInfo>(GET_BRAND_BY_ID, {
		variables: { id: params.id },
	});

	useEffect(() => {
		if (data?.brand) {
			if (user?.user.type === 'admin') {
				getPlan();
			}

			setName(data.brand.name);
			setNameOld(data.brand.name);

			setAddress(data.brand.address);
			setAddressOld(data.brand.address);

			setNumbersNext(data.brand.contact_numbers?.map((val) => val.value));
			setNumbers(data.brand.contact_numbers?.map((val) => val.value));

			setAvatar(data.brand.logo);

			setSlot(data.brand.pickup_time);
			setSlotOld(data.brand.pickup_time);

			setIsVerified(data.brand?.verified);
			setIsVerifiedOld(data.brand?.verified);

			if (data.brand.whitelisted) {
				setWhitelisted(true);
				setWhitelistedOld(true);
			}

			if (data.brand.scheme) {
				setPlanSelect({
					label: data.brand.scheme.name,
					value: data.brand.scheme.objectId,
				});
				setPlanSelectOld({
					label: data.brand.scheme.name,
					value: data.brand.scheme.objectId,
				});
			}

			if (data.brand.preferred_method) {
				setMethodSelect({
					label: toCap(data.brand.preferred_method),
					value: data.brand.preferred_method,
				});

				setMethodSelectOld({
					label: toCap(data.brand.preferred_method),
					value: data.brand.preferred_method,
				});
			}

			brand &&
				dispatch({
					type: 'ADD_BRAND',
					payload: {
						node: {
							objectId: params.id,
							logo: data.brand.logo,
							name: data.brand.name,
							pickup_locations: data.brand.pickup_locations,
							pickup_time: data.brand.pickup_time,
							verified: data.brand.verified,
						},
					},
				});

			// bank

			if (data.brand.payment_details?.bank) {
				const {
					bank: {
						account_name,
						bank_name,
						beneficiary_number,
						branch_name,
						account_number,
					},
					bkash,
					nagad,
					rocket,
				} = data.brand.payment_details;

				if (bkash) {
					setBkash(bkash);
					setBkashOld(bkash);
				}

				if (nagad) {
					setNagad(nagad);
					setNagadOld(nagad);
				}

				if (rocket) {
					setRocket(rocket);
					setRocketOld(rocket);
				}

				setAccHolderName(account_name);
				setAccHolderNameOld(account_name);
				setAccNumber(accNumber);
				setAccNumberOld(accNumber);
				setBank(bank_name);
				setBankOld(bank_name);
				setBenefNum(beneficiary_number);
				setBenefNumOld(beneficiary_number);
				setBranch(branch_name);
				setBranchOld(branch_name);
				setAccNumber(account_number);
				setAccNumberOld(account_number);
			}
		}
	}, [data, user]);

	useEffect(() => {
		if (isAdmin && data) getToken({ userId: data.brand.ownedBy.objectId });
	}, [isAdmin, data]);

	console.log({data, brand, user, numbers});

	return (
		<Layout
			className='merchantProfile '
			title={isEditable ? ' Edit Profile' : 'Profile'}
			loading={loading}
			noBack
			noTitle>
			<Grid container className='merchantProfile__main '>
				{/* store info */}
				<MerchantLayout
					md={12}
					comp={
						<Grid container alignItems='center' justify='space-between'>
							<Grid item>
								<h3>Stores</h3>
							</Grid>
							<Grid item>
								<Button
									startIcon={<AddLocation />}
									onClick={handleClickOpen}
									className='anim-frame'
									variant='outlined'
									color='primary'
									fullWidth
									disabled={false}>
									Create New Pickup Location
								</Button>
							</Grid>
						</Grid>
					}
					title='Stores'>
					<Grid item md={12}>
						<Table className={classes.table} aria-label='customized table'>
							<TableHead>
								<TableRow>
									<StyledTableCell>ID</StyledTableCell>
									<StyledTableCell align='left'>Name</StyledTableCell>
									<StyledTableCell align='left'>Address</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data?.brand.pickup_locations?.map(({ value }) => (
									<StyledTableRow className='anim-card' key={value._id}>
										<StyledTableCell component='th' scope='row'>
											{value._id}
										</StyledTableCell>
										<StyledTableCell align='left'>{value.name}</StyledTableCell>
										<StyledTableCell align='left'>
											{value.address.full_address}
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
						<FormDialog open={open} setOpen={setOpen}>
							<Formik
								validateOnChange
								validationSchema={validationScheme}
								onSubmit={(values) => {
									let data: Store = {
										name: values.storeName,
										address: {
											flat: values.flat,
											full_address: values.address,
										},
										zone: values.zone,
										area: values.area.value,
										geolocation: [20.890519117189676, 90.88852070921179],
										phone: values.number,
									};

									if (lat && long) {
										data = { ...data, geolocation: [lat, long] };
									}

									addStore(data)
										.then(() => {
											return refetch();
										})
										.then(() => {
											setOpen(false);
										});
								}}
								initialValues={{
									storeName: '',
									address: '',
									flat: '',
									number: '',
									zone: 'dhaka',
									area: {
										label: 'please select an area',
										value: '',
									},
								}}>
								{({
									handleChange,
									handleSubmit,
									handleBlur,
									setFieldValue,
									isValid,
									errors,
									values,
									touched,
								}) => (
									<Form onSubmit={handleSubmit}>
										<TextField
											required
											margin='normal'
											fullWidth
											error={
												errors.storeName && touched.storeName ? true : false
											}
											variant='outlined'
											name='storeName'
											label='Pickup location name'
											placeholder='Pickup Point 2'
											type='text'
											onBlur={handleBlur}
											onChange={handleChange}
											helperText={
												touched.storeName &&
												errors.storeName &&
												errors.storeName
											}
											value={values.storeName}
										/>
										<TextField
											required
											multiline
											rows={4}
											margin='normal'
											fullWidth
											error={errors.address && touched.address ? true : false}
											variant='outlined'
											name='address'
											label='Address'
											placeholder='Apartment name, Road, Block, Area'
											type='text'
											onBlur={(e) => {
												handleAddress(e);
												handleBlur(e);
											}}
											onChange={handleChange}
											helperText={
												touched.address && errors.address && errors.address
											}
											value={values.address}
										/>
										<TextField
											required
											margin='normal'
											fullWidth
											error={errors.flat && touched.flat ? true : false}
											variant='outlined'
											name='flat'
											label='Flat no.'
											placeholder='32 D/C'
											type='text'
											onBlur={handleBlur}
											onChange={handleChange}
											helperText={touched.flat && errors.flat && errors.flat}
											value={values.flat}
										/>
										<TextField
											required
											margin='normal'
											fullWidth
											error={errors.number && touched.number ? true : false}
											variant='outlined'
											name='number'
											label='Pickup Point Phone Number'
											placeholder='017...'
											type='text'
											onBlur={handleBlur}
											onChange={handleChange}
											helperText={
												touched.number && errors.number && errors.number
											}
											value={values.number}
										/>
										<div className='select'>
											<Select
												isSearchable
												placeholder='Zone'
												value={{ label: 'Dhaka', name: 'dhaka' }}
												options={[{ label: 'Dhaka', name: 'dhaka' }]}
											/>
										</div>
										<div className='select'>
											<Select
												onChange={(value) => {
													setFieldValue('area', value);
												}}
												value={values.area}
												isClearable
												isSearchable
												placeholder='Area'
												options={areas.map((value) => {
													return {
														label: value.area,
														value: value.area,
													};
												})}
											/>
										</div>
										<Button
											startIcon={<StoreIcon />}
											fullWidth
											disabled={!isValid || upLoading}
											type='submit'
											size='large'
											variant='contained'
											color='primary'>
											Add Store
										</Button>
									</Form>
								)}
							</Formik>
						</FormDialog>
					</Grid>
				</MerchantLayout>

				{/* profile */}

				<MerchantLayout
					md={12}
					comp={
						<Grid container alignItems='center' justify='space-between'>
							<Grid item>
								<h3>{isEditable ? 'Edit Profile' : 'Profile'}</h3>
							</Grid>
							<Grid item>
								{isAdmin && (
									<>
										<FormControlLabel
											control={
												<Checkbox
													checked={isVerified}
													name='checkedC'
													onChange={(_e, checked) => setIsVerified(checked)}
												/>
											}
											label='Verify'
										/>
										<FormControlLabel
											control={
												<Checkbox
													color='primary'
													checked={whitelisted}
													name='checkedW'
													onChange={(_e, checked) => setWhitelisted(checked)}
												/>
											}
											label='whitelisted'
										/>
									</>
								)}
							</Grid>
						</Grid>
					}>
					<Grid item container justify='space-between' spacing={2}>
						<Grid item>
							<Grid
								container
								direction='column'
								alignItems='flex-start'
								spacing={3}>
								<Grid item container alignItems='center' spacing={2}>
									<Grid item>
										<Avatar
											src={avatar ? avatar : undefined}
											variant='circular'
											style={{ backgroundColor: '#222', cursor: 'pointer' }}>
											{data?.brand.name.split('')[0]}
										</Avatar>
									</Grid>
									<Grid item>
										<h3>
											{data?.brand.name}{' '}
											<VerifiedUserIcon
												style={{ transform: 'translateY(6px)' }}
												color={isVerifiedOld ? 'secondary' : 'disabled'}
											/>
										</h3>
									</Grid>
								</Grid>

								<Grid item>
									<input
										onChange={handleUploadChange}
										disabled={!isEditable}
										accept='image/*'
										className={classes.input}
										id='icon-button-file'
										type='file'
									/>
									<label htmlFor='icon-button-file'>
										<Tooltip title='Upload image'>
											<Button
												aria-label='upload picture'
												component='span'
												disabled={!isEditable}
												size='small'
												variant='outlined'
												endIcon={<PublishIcon />}>
												upload
											</Button>
										</Tooltip>
									</label>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{isAdmin && token && (
						<Grid item>
							<Clipboard
								onClick={() => {
									setSecretText('copied!!');

									setTimeout(() => {
										setSecretText('copy login secret');
									}, 2000);
								}}
								data-clipboard-text={token}
								component='a'
								button-href='#'
								className=' btn btn-primary'>
								{secretText}
							</Clipboard>
						</Grid>
					)}
				</MerchantLayout>
				<MerchantLayout title='Information' md={6}>
					<Grid item xs={12} md={9}>
						<CustomInput
							label=' name'
							placeholder='Tom Cruise'
							regex={/^.{2,}/gi}
							helperTxt={'should be at least 2 charecters long'}
							value={name}
							setValue={setName}
							setDisabled={setNameDis}
							type='text'
							fullWidth
							disabled={!isEditable}
						/>
						<CustomInput
							label='Recipient address'
							placeholder='Your address...'
							regex={/^.{2,}/gi}
							helperTxt={'should be at least 2 charecters long'}
							value={address}
							setValue={setAddress}
							setDisabled={setAddressDis}
							type='text'
							fullWidth
							multiline
							rowSpan={5}
							disabled
						/>

						{numbers?.map((num, i) => {

							if (i === 0) {
								return (
									<NumberArr
										key={num}
										value={num}
										setValue={setNumbersNext}
										index={i}
										isEditable={true}
									/>
								);
							}

							return (
								<NumberArr
									key={num}
									value={num}
									setValue={setNumbersNext}
									index={i}
									isEditable={isEditable}
								/>
							);
						})}

						<div className='select'>
							<Select
								onChange={(value) => {
									value &&
										setMethodSelect({
											label: value.label,
											value: value.value,
										});
								}}
								isSearchable
								placeholder='Select payment method'
								value={methodSelect}
								options={[
									{ label: 'Bank', value: 'bank' },
									{ label: 'Bkash', value: 'bkash' },
									{ label: 'Nagad', value: 'nagad' },
									{ label: 'Rocket', value: 'rocket' },
								]}
							/>
						</div>

						<CustomInput
							disabled={!isEditable}
							label='Bkash Number'
							placeholder='017...'
							regex={MOBILE_REGEX_BD}
							helperTxt={'please select a valid number (eg. 017)'}
							value={bkash}
							setValue={setBkash}
							setDisabled={setBkashDis}
							type='number'
							fullWidth
						/>
						{/* rocket */}
						<CustomInput
							disabled={!isEditable}
							label='Rocket Number'
							placeholder='017...'
							regex={MOBILE_REGEX_BD}
							helperTxt={'please select a valid number (eg. 017)'}
							value={rocket}
							setValue={setRocket}
							setDisabled={setRocketDis}
							type='number'
							fullWidth
						/>
						{/* nagad */}
						<CustomInput
							disabled={!isEditable}
							label='Nagad Number'
							placeholder='017...'
							regex={MOBILE_REGEX_BD}
							helperTxt={'please select a valid number (eg. 017)'}
							value={nagad}
							setValue={setNagad}
							setDisabled={setNagadDis}
							type='number'
							fullWidth
						/>
					</Grid>
				</MerchantLayout>
				<MerchantLayout title='Bank Details' md>
					<Grid item xs={12} md={9}>
						<CustomInput
							disabled={!isEditable}
							label='Bank Name'
							placeholder='EBL'
							regex={/^.{2,}/gi}
							helperTxt={'should be at least 2 charecters long'}
							value={bank}
							setValue={setBank}
							setDisabled={setBankDis}
							type='text'
							fullWidth
						/>
						<CustomInput
							disabled={!isEditable}
							label='Account Holder Name'
							placeholder='James Bond'
							regex={/^.{2,}/gi}
							helperTxt={'should be at least 2 charecters long'}
							value={AccHolderName}
							setValue={setAccHolderName}
							setDisabled={setAccHolderNameDis}
							type='text'
							fullWidth
						/>

						<CustomInput
							disabled={!isEditable}
							label='Account Number'
							placeholder='23124...'
							regex={/^.{8,}/gi}
							helperTxt={'should be at least 8 charecters long'}
							value={accNumber}
							setValue={setAccNumber}
							setDisabled={setAccNumberDis}
							type='number'
							fullWidth
						/>
						<CustomInput
							disabled={!isEditable}
							label='Account Beneficiary Number'
							placeholder='3454524...'
							regex={/^.{8,}/gi}
							helperTxt={'should be at least 8 charecters long'}
							value={benefNum}
							setValue={setBenefNum}
							setDisabled={setBenefNumDis}
							type='number'
							fullWidth
						/>
						<CustomInput
							disabled={!isEditable}
							label='Branch Name'
							placeholder='Gulshan'
							regex={/^.{2,}/gi}
							helperTxt={'should be at least 2 charecters long'}
							value={branch}
							setValue={setBranch}
							setDisabled={setBranchDis}
							type='text'
							fullWidth
						/>
					</Grid>
					<Grid item>
						<Button
							size='large'
							startIcon={<SaveIcon />}
							onClick={handleUpdate}
							className='anim-frame'
							variant='contained'
							color='primary'
							fullWidth
							disabled={upLoading || !isEditable}>
							Save Changes
						</Button>
					</Grid>
				</MerchantLayout>

				{isAdmin && (
					<>
						<MerchantLayout md={6} title='Assign Plan'>
							<div className='select'>
								<Select
									onChange={(value) => {
										setPlanSelect(value);
									}}
									isSearchable
									placeholder='Select pricing'
									value={planSelect}
									options={plan?.map((p) => {
										return {
											label: p.name,
											value: p.objectId,
										};
									})}
								/>
							</div>
						</MerchantLayout>
						<MerchantLayout md title='Change Slot'>
							<FormControl component='fieldset' className='time'>
								<RadioGroup
									aria-label='slot'
									name='slot'
									value={slot}
									onChange={handleChangeCheck}>
									<Grid>
										<FormControlLabel
											value='morning'
											control={<Radio color='primary' />}
											label='7:00-9:00 AM'
										/>
										<FormControlLabel
											value='evening'
											control={<Radio color='primary' />}
											label='4:00-7:00 PM'
										/>
									</Grid>
								</RadioGroup>
							</FormControl>
						</MerchantLayout>
					</>
				)}
			</Grid>
		</Layout>
	);
};

export default MerchantProfile;
