import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ApolloProvider } from '@apollo/client';
import ErrorBoundary from './components/Error/ErrorBoundary';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import CtxProvider from './context/Context';
import { client } from './config/client';

Sentry.init({
	dsn:
		'https://42ea7de4c44c4548aeda2bffe0203980@o575176.ingest.sentry.io/5727065',
	integrations: [new Integrations.BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
			<ApolloProvider client={client}>
				<CtxProvider>
					<App />
				</CtxProvider>
			</ApolloProvider>
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById('root') as HTMLElement
);
