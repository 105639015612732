import React, { useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { DateRangePicker } from 'react-date-range';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import './breakDown.scss';
import Layout from '../layout/Layout';
import LoadingBtn from '../typography/buttons/LoadingBtn';
import useBreakDown from '../../hooks/useBreakDown';
import { Header } from '../pickupReqTable/pickup.req.table';
import TableHeader from '../TableCommon/TableHeader';
import { toCap } from '../../helper/helper';

type Breakdown = 'cash_breakdown' | 'brand_breakdown';

export interface Props {
	breakdown: Breakdown;
	title: string;
	name: 'rider' | 'brand';
}

const key: { [index: string]: string } = {
	cash_breakdown: 'cashCollectionBreakdown',
	brand_breakdown: 'cashCollectionByBrand',
};

const BreakDown: React.FC<Props> = ({ breakdown: index, title, name }) => {
	const {
		handleSelect,
		setIsDateOpen,
		getPickUpCash,
		isLoading,
		selectionRange,
		isDateOpen,
		breakDown,
	} = useBreakDown({ funcName: key[index] });

	const handleSearch = () => {
		setIsDateOpen(false);
		getPickUpCash();
	};

	const headers: Header[] = [
		{ field: `${toCap(name)} name` },
		{ field: `${toCap(name)} Id` },
		{ field: 'Parcels' },
		{ field: 'Delivery Charge' },
		{ field: 'Total collection (TK)' },
	];

	useEffect(() => {
		getPickUpCash();
	}, []);

	return (
		<Layout
			className='breakdown'
			title={title}
			loading={isLoading}
			noBack
			noTitle
		>
			<div className='breakdown__container shadow-lg anim-frame rounded'>
				<div className='breakdown__search'>
					<div>
						<span className='text-xl'>
							( {format(selectionRange.startDate, 'do LLLL')} -{' '}
							{format(selectionRange.endDate, 'do LLLL')} )
						</span>
						<IconButton
							color='primary'
							onClick={() => setIsDateOpen(!isDateOpen)}
						>
							<DateRangeIcon />
						</IconButton>
						{isDateOpen && (
							<div className='breakdown__datePicker shadow-lg'>
								<DateRangePicker
									ranges={[selectionRange]}
									onChange={handleSelect}
								/>
							</div>
						)}
					</div>
					<LoadingBtn disabled={isLoading} onClick={handleSearch}>
						Search
					</LoadingBtn>
				</div>
				<main className='breakdown__main'>
					<section>
						<div className='breakdown__statsItem anim-card rounded shadow-md '>
							<h1>Total Cash </h1>
							<h1 className='text-5xl'>
								TK. {breakDown ? breakDown.cash_overall.collection : 0}
							</h1>
						</div>
						<div className='breakdown__statsItem anim-card rounded shadow-md '>
							<h1>Total Orders(delivered + returned) </h1>
							<h1 className='text-5xl'>
								{breakDown ? breakDown.cash_overall.no_orders : 0}
							</h1>
						</div>
						<div className='breakdown__statsItem anim-card rounded shadow-md '>
							<h1>Total Revenue </h1>
							<h1 className='text-5xl'>
								{breakDown?.cash_overall.delivery_charge
									? breakDown.cash_overall.delivery_charge
									: 0}
							</h1>
						</div>
					</section>
          {
            breakDown?.riders_total_collection &&
              <section>
              <div className='breakdown__statsItem anim-card rounded shadow-md '>
                <h1>Riders collection total</h1>
                <h1 className='text-5xl'>
                  TK. {breakDown && breakDown.riders_total_collection ? breakDown.riders_total_collection : 0}
                </h1>
              </div>
              <div className='breakdown__statsItem anim-card rounded shadow-md '>
                <h1>Taka difference</h1>
                <h1 className='text-5xl' style={{color: 'red'}}>
                  {breakDown && breakDown.riders_total_collection ? breakDown.cash_overall.collection - breakDown.riders_total_collection : 0}
                </h1>
              </div>
              <div className='breakdown__statsItem anim-card rounded shadow-md '>
                <ol>
                  {
                    breakDown?.jobs_mismatch?.map(jb => {
                      return <li><Link to={`/order/details/${jb}`} target='_blank'>{jb}</Link></li>
                    })
                  }

                </ol>
              </div>
            </section>
          }
					<section>
						{breakDown?.parcel_stats && (
							<>
								<div className='breakdown__statsItem anim-card rounded shadow-md '>
									<h1>Total Parcel In Office </h1>
									<h1 className='text-5xl'>
										{breakDown.parcel_stats.in_office}
									</h1>
								</div>
								<div className='breakdown__statsItem anim-card rounded shadow-md '>
									<h1>Total Pickup </h1>
									<h1 className='text-5xl'>
										{breakDown.parcel_stats.pickedup}
									</h1>
								</div>
								<div className='breakdown__statsItem anim-card rounded shadow-md '>
									<h1>Total Dispatched For Delivery </h1>
									<h1 className='text-5xl'>
										{breakDown.parcel_stats.dispatched_for_delivery}
									</h1>
								</div>
							</>
						)}
					</section>
					<section className='overflow'>
						<div className='breakdown__table'>
							<div
								className='breakdown__tableHeader'
								style={{
									gridTemplateColumns: `repeat(${headers.length}, 1fr)`,
								}}
							>
								{headers.map(({ field }, i) => (
									<TableHeader key={i} field={field} />
								))}
							</div>
							{breakDown &&
								breakDown[index]?.map((detail, i) => (
									<div
										key={detail.o_id + i}
										className='breakdown__body anim-card'
										style={{
											gridTemplateColumns: `repeat(${headers.length}, 1fr)`,
										}}
									>
										<div className='breakdown__bodyCol'>
											{index === 'cash_breakdown' ? (
												<Link
													target='_blank'
													to={`/rider/${detail[name]?.objectId}?name=${detail[name]?.name}`}
												>
													<p>
														{i + 1}
														{'. '}
														{`${detail[name]?.name} (${detail.assigned_orders})`}
													</p>
												</Link>
											) : (
												<Link
													target='_blank'
													to={`/merchant/profile/${detail[name]?.objectId}`}
												>
													<p>
														{i + 1}
														{'. '}
														{detail[name]?.name}
													</p>
												</Link>
											)}
										</div>
										<div className='breakdown__bodyCol'>
											<p>{detail[name]?.objectId}</p>
										</div>
										{name === 'brand' ? (
											<div className='breakdown__bodyCol'>
												<Link
													// target='_blank'
													to={`/admin/all-orders?process-start=${selectionRange.startDate.toISOString()}&process-end=${selectionRange.endDate.toISOString()}&brand=${
														detail.brand?.objectId
													}`}
												>
													{detail.no_orders}
												</Link>
											</div>
										) : (
											<div className='breakdown__bodyCol'>
												<p>{detail.no_orders}</p>
											</div>
										)}
										<div className='breakdown__bodyCol'>
											<p>{detail.delivery_charge}</p>
										</div>
										<div className='breakdown__bodyCol'>
											<p>{detail.collection}</p>
										</div>
									</div>
								))}
						</div>
					</section>
				</main>
			</div>
		</Layout>
	);
};

export default BreakDown;
