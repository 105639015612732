import { useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BaseAlert from '../components/typography/alerts/BaseAlert';

interface Notification {
	type: 'success' | 'info' | 'warning' | 'error' | undefined;
	variant: 'outlined' | 'standard' | 'filled' | undefined;
	message: string;
}

toast.configure({
	position: 'top-right',
	draggable: true,
	autoClose: 3000,
	hideProgressBar: true,
});

const useMessage = () => {
	const handleNotification = useCallback(
		({ variant, message, type }: Notification) => {
			toast(
				<BaseAlert variant={variant} type={type}>
					{message}
				</BaseAlert>
			);
		},
		[]
	);

	return { handleNotification };
};

export default useMessage;
