import React from 'react';
import { CSSTransition } from 'react-transition-group';

const TransitionApp: React.FC<{ className: string; show: boolean }> = ({
	children,
	className,
	show,
}) => {
	return (
		<CSSTransition
			classNames={className}
			mountOnEnter
			unmountOnExit
			timeout={300}
			in={show}>
			{children}
		</CSSTransition>
	);
};

export default TransitionApp;
