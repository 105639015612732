import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

import './pickup.req.table.scss';
import PickupTableRow from './pickup.table.row';
import TableHeader from '../TableCommon/TableHeader';
import { useStore } from '../../context/Context';
import { PickupReq } from '../../context/contextTypes';

interface Props {
	handleSelectRequests: ({ pickUpRequestId, type }: PickupReq) => void;
	isSetAll: boolean;
	setIsSetAll: React.Dispatch<React.SetStateAction<boolean>>;
	riderType: string;
	handleDuplicate: (id: string) => void;
	refetch: () => void;
}

export interface Header {
	field: any;
}

const PickupReqTable: React.FC<Props> = ({
	handleSelectRequests,
	setIsSetAll,
	isSetAll,
	riderType,
	handleDuplicate,
	refetch,
}) => {
	const {
		store: { pickUpReqs },
	} = useStore();

	const handleChange = (
		_event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		setIsSetAll(checked);

		if (checked) {
			return;
		}

		handleSelectRequests({ pickUpRequestId: [], type: 'REMOVE_ALL' });
	};

	const headers: Header[] = [
		{
			field: (
				<Checkbox
					checked={isSetAll}
					value={isSetAll}
					onChange={handleChange}
					color='primary'
				/>
			),
		},
		{ field: 'logo' },
		{ field: 'Brand' },
		{ field: 'Address' },
		{ field: 'Created at' },
		{ field: 'Pickup Slots' },
		{ field: `${riderType} Rider` },
		{ field: 'Orders' },
		{ field: 'Received' },
		{ field: 'Phone' },
		{ field: 'Note' },
		{ field: 'Status' },
		{ field: '' },
		{ field: '' },
	];

	return (
		<div className='pickTable '>
			<div
				className='pickTable__header'
				style={{ gridTemplateColumns: `repeat(${headers.length}, 1fr)` }}
			>
				{headers.map(({ field }, i) => (
					<TableHeader key={i} field={field} />
				))}
			</div>
			<div className='pickTable__body '>
				{pickUpReqs.map((pickUpReq, i) => (
					<PickupTableRow
						refetch={refetch}
						key={i + pickUpReq.node.objectId}
						pickUpReq={pickUpReq}
						handleDuplicate={handleDuplicate}
						length={headers.length}
						setIsSetAll={setIsSetAll}
						forceSet={isSetAll}
						handleSelectRequests={handleSelectRequests}
					/>
				))}
			</div>
		</div>
	);
};

export default PickupReqTable;
