import React, { useEffect, useState } from 'react';

import { MOBILE_REGEX_BD } from '../../helper/regex';
import CustomInput from '../customInput/CustomInput';

interface Props {
	value: string;
	setValue: any;
	isEditable: boolean;
	index: number;
}

const NumberArr: React.FC<Props> = ({ value, setValue, isEditable, index }) => {
	const [number, setNumber] = useState<string>(value);
	const [numberDis, setNumberDis] = useState(true);

	useEffect(() => {
		setValue((old: string[] | null) => {
			return old?.map((str, i) => {
				if (i === index) {
					return number;
				} else {
					return str;
				}
			});
		});
	}, [number]);

	return (
		<CustomInput
			label='Recipient phone'
			placeholder='+008'
			regex={MOBILE_REGEX_BD}
			helperTxt={'please select a valid number (eg. 017)'}
			value={number}
			setValue={setNumber}
			setDisabled={setNumberDis}
			type='number'
			fullWidth
			disabled={!isEditable}
		/>
	);
};

export default NumberArr;
