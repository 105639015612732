import React, { createContext, useContext, useReducer } from 'react';

import { ContextType, Actions, State } from './contextTypes';

export const BRAND = 'brand';

export const DEFAULT_STORE = 'default_store';

export const USER = 'user';

const locStorUser = localStorage.getItem(USER);

const locStorDefStore = localStorage.getItem(DEFAULT_STORE);

const locStorBrand = localStorage.getItem(BRAND);

const parse = (data: string) => JSON.parse(data);

const init: State = {
	user: locStorUser ? parse(locStorUser) : null,
	brand: locStorBrand ? parse(locStorBrand) : null,
	store: locStorDefStore ? parse(locStorDefStore) : null,
	pickUpReqs: [],
	pickUpReqsSort: [],
	deliveryReqs: [],
	deliveryReqsSort: [],
	brandParcel: null,
	orderDetails: null,
	payment: null,
	allBrands: null,
	editOrderDetails: null,
	plan: null,
	statsOfBrand: null,
	planById: null,
	settings: null,
};

const defaultValue = {
	dispatch: () => {},
	store: init,
};

export const Context = createContext<ContextType>(defaultValue);

const reducer = (state: State, action: Actions) => {
	switch (action.type) {
		case 'ADD_USER':
			localStorage.setItem(USER, JSON.stringify(action.payload));
			return { ...state, user: action.payload };

		case 'REMOVE_USER':
			return { ...state, user: action.payload, brand: null };

		case 'ADD_BRAND':
			localStorage.setItem(BRAND, JSON.stringify(action.payload));
			return { ...state, brand: action.payload };

		case 'REMOVE_BRAND':
			return { ...state, brand: action.payload };

		case 'ADD_PICKUP_REQS':
			return { ...state, pickUpReqs: action.payload };

		case 'ADD_PICKUP_REQS_SORT':
			return { ...state, pickUpReqsSort: action.payload };

		case 'ADD_DELIVERY_REQS':
			return { ...state, deliveryReqs: action.payload };

		case 'ADD_DELIVERY_REQS_SORT':
			return { ...state, deliveryReqsSort: action.payload };

		case 'ADD_PARCELS_BY_BRAND':
			return { ...state, brandParcel: action.payload };

		case 'ADD_ORDER_DETAILS':
			return { ...state, orderDetails: action.payload };

		case 'ADD_PAYMENT_DETAILS':
			return { ...state, payment: action.payload };

		case 'ADD_ALL_BRAND':
			return { ...state, allBrands: action.payload };

		case 'ADD_EDIT_ORDER':
			return { ...state, editOrderDetails: action.payload };

		case 'REMOVE_EDIT_ORDER':
			return { ...state, editOrderDetails: action.payload };

		case 'ADD_PLAN':
			return { ...state, plan: action.payload };

		case 'ADD_PLAN_BY_ID':
			return { ...state, planById: action.payload };

		case 'REMOVE_PLAN_BY_ID':
			return { ...state, planById: action.payload };

		case 'ADD_BRAND_STATS':
			return { ...state, statsOfBrand: action.payload };

		case 'ADD_STORE':
			localStorage.setItem(DEFAULT_STORE, JSON.stringify(action.payload));

			return { ...state, store: action.payload };
		case 'ADD_SETTINGS':
			return { ...state, settings: action.payload };

		default:
			return state;
	}
};

const CtxProvider: React.FC = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, init);

	const value = {
		dispatch,
		store: state,
	};
	// console.log(state)

	return <Context.Provider value={value}>{children}</Context.Provider>;
};


export const useStore = () => useContext(Context);

export default CtxProvider;
