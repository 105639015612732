import { Avatar, Button, Card, Grid } from "@material-ui/core";
import Layout, { KeyValue } from "../../components/layout/Layout";
import { useStore } from "../../context/Context";
import styles from "./profile.module.css";

export default function Profile() {
  const {
    store: { user },
  } = useStore();

  console.log(user);

  return (
    <Layout title="Profile" className="Bangladesh" loading={false}>
      <div className={styles.container}>
        <Grid container>
          <Grid container item md={6} component={Card} className={styles.card}>
            <Grid container justify="space-evenly">
              <Avatar
                className={styles.avatar}
                alt={user?.user.name}
                variant="circular"
                sizes="large"
              >
                {user?.user.name[0]}
              </Avatar>
            </Grid>
            <br />
            <br />
            <Grid container item md={6}>
              <KeyValue keey="Name" value={user?.user.name} />
              <KeyValue keey="User Name" value={user?.user.username} />
              <KeyValue keey="Role" value={user?.user.type} />
              <br />
              <br />
              <Button variant="outlined" size="small">
                Sign out
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
}
