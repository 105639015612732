import React, { useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { DateRangePicker } from 'react-date-range';
import DateRangeIcon from '@material-ui/icons/DateRange';

import './paymentDetails.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Layout from '../../components/layout/Layout';
import CommonBlock, { Card } from '../../components/commonBlock/CommonBlock';
import { IconButton } from '@material-ui/core';

import { useStore } from '../../context/Context';
import { endOfDay, startOfDay } from 'date-fns';
import LoadingBtn from '../../components/typography/buttons/LoadingBtn';
import useMerchant from '../../hooks/useMerchant';

export interface Slug {
	brandId: string;
}

const ButtonWhite = styled(Button)`
	background-color: white;
	border: 1px solid black;
	color: black;
	text-transform: none;
`;

const ButtonDark = styled(Button)`
	border: 1px solid black;
	text-transform: capitalize;
`;

const PaymentDetails: React.FC = () => {
	const [isDateOpen, setIsDateOpen] = useState(false);

	const { brandId } = useParams<Slug>();

	const {
		setStartDate,
		setEndDate,
		selectionRange,
		isFetching,
		isLoading,
		refetch,
	} = useMerchant(brandId);

	const {
		store: { statsOfBrand },
	} = useStore();

	const { goBack, location } = useHistory<{ brandName: string | undefined }>();

	const handleSearch = () => {
		refetch();
		setIsDateOpen(false);
	};

	const cardsOne: Card[] = useMemo(() => {
		return [
			{
				subTitle: 'Order Placed',
				amount: statsOfBrand?.order_placed,
				svg: '/custom/merchant/shop.svg',
			},
			{
				subTitle: 'Order Delivered',
				amount: statsOfBrand?.order_delivered,
				svg: '/custom/payment/box.svg',
			},
			{
				subTitle: 'Order In Transit',
				amount: statsOfBrand?.order_transit,
				svg: '/custom/payment/shop.svg',
			},
			{
				subTitle: 'Order Rescheduled',
				amount: statsOfBrand?.order_rescheduled,
				svg: '/custom/payment/calender.svg',
			},
		];
	}, [statsOfBrand]);

	const cardsTwo: Card[] = useMemo(() => {
		return [
			{
				title: 'Tk.',
				amount: statsOfBrand?.money?.cash_collected,
				subTitle: 'Total Sales (cash)',
				svg: '/custom/payment/money-green.svg',
			},
			{
				title: 'Tk.',
				amount: statsOfBrand?.money?.delivery_fee,
				subTitle: 'Total Delivery Fee',
				svg: '/custom/payment/money.svg',
			},
			{
				title: 'Tk.',
				amount: statsOfBrand?.money?.paid,
				subTitle: 'Total Paid',
				svg: '/custom/payment/money.svg',
			},
			{
				title: 'Tk.',
				amount: statsOfBrand?.money?.unpaid,
				subTitle: 'Total Unpaid',
				svg: '/custom/payment/money.svg',
			},
		];
	}, [statsOfBrand]);

	const handleSelect = (ranges: any) => {
		setStartDate(startOfDay(ranges.selection.startDate));
		setEndDate(endOfDay(ranges.selection.endDate));
	};

	return (
		<Layout
			className='paymentDetails'
			title={`Payment Details`}
			subTitle={location.state ? location.state.brandName : brandId}
			loading={isLoading}
		>
			<div className='paymentDetails__body '>
				<div className='paymentDetails__header'>
					<div className='date-today'>
						<p className='text-lg'>Date: {new Date().toLocaleDateString()}</p>
					</div>
					<div className='search'>
						<p className='text-lg'>Date: </p>

						<IconButton
							color='primary'
							onClick={() => setIsDateOpen(!isDateOpen)}
						>
							<DateRangeIcon />
						</IconButton>

						{isDateOpen && (
							<div className='search__datePicker shadow-lg'>
								<DateRangePicker
									ranges={[selectionRange]}
									onChange={handleSelect}
								/>
							</div>
						)}
						<LoadingBtn onClick={handleSearch} disabled={isFetching}>
							search
						</LoadingBtn>
					</div>
				</div>
				<div className='paymentDetails__block '>
					<CommonBlock cards={cardsOne} />
				</div>
				<div className='paymentDetails__block '>
					<CommonBlock cards={cardsTwo} />
				</div>
				<div className='paymentDetails__footer anim-fade'>
					<div className='left'>
						<Link to={`/accounts/payment-details/${brandId}/history`}>
							<ButtonWhite size='large'>Full Payment History</ButtonWhite>
						</Link>
						<Link to={`/accounts/payment-details/${brandId}/new-payment`}>
							<ButtonDark color='primary' variant='contained' size='large'>
								New Payment
							</ButtonDark>
						</Link>
					</div>
					<div className='right'>
						<ButtonWhite onClick={goBack} size='large'>
							All Brands
						</ButtonWhite>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default PaymentDetails;
