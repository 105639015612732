import Layout from '../../components/layout/Layout';
import { Container } from '../../components/styles/Container';

const AdminScreen = () => {
	return (
		<Layout
			title='Admin Dashboard'
			className='admin'
			loading={false}
			noBack
			noTitle>
			<Container></Container>
		</Layout>
	);
};

export default AdminScreen;
