import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, TextField } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import Add from '@material-ui/icons/Add';
import { useState } from 'react';
import Layout from '../../components/layout/Layout'
import { Container } from '../../components/styles/Container'
import { DELETE_NOTIFICATION, SENT_NOTIFICATION, UPDATE_NOTIFICATION } from '../../graphql/mutations/noticeBoard';
import { GET_NOTICE } from '../../graphql/queries/noticeBoard';
import styles from './noticeBoard.module.css'
import NoticeTable from './NoticeTable/NoticeTable';

export default function NoticeBoard() {
    const [open, setOpen] = useState(false);
    const [createNotification, setCreateNotification]: any = useState('')
    const [createANotice] = useMutation(SENT_NOTIFICATION)
    const [updateANotice] = useMutation(UPDATE_NOTIFICATION)
    const [deleteANotice] = useMutation(DELETE_NOTIFICATION)
    // "createANotice" this is a variable i can use anything instead of it.

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSwitch = useCallback(async (event: any) => {
        try {
            if (event?.visible === true) {
                await updateANotice({
                    variables: {
                        id: event?.objectId,
                        visible: false
                    },
                    refetchQueries: [{ query: GET_NOTICE }]
                })
            }
            else {
                await updateANotice({
                    variables: {
                        id: event?.objectId,
                        visible: true
                    },
                    refetchQueries: [{ query: GET_NOTICE }]
                })
            }
        } catch (error) {
            console.log(error)
        }
    }, [updateANotice])

    const handleSentNotification = async () => {
        try {
            await createANotice({
                variables: createNotification,
                refetchQueries: [{ query: GET_NOTICE }]
            })
        } catch (error) {
            console.log(error)
        } finally {
            handleClose()
            setCreateNotification('')
        }
    }

    const deleteNotice = async (event: any) => {
        try {
            await deleteANotice({
                variables: {
                    id: event?.objectId
                },
                refetchQueries: [{ query: GET_NOTICE }]
            })
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div>
            <Layout
                title='Notice board'
                className='className-noticeboard'
                loading={false}
            // noBack
            // noTitle
            >
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent style={{ width: '400px' }}>
                        <DialogContentText id="alert-dialog-description">
                            <TextField
                                id="outlined-multiline-static"
                                label="Notification text"
                                multiline
                                rows={4}
                                defaultValue={createNotification}
                                onChange={
                                    useCallback(
                                        (e) => setCreateNotification({ text: e.target.value }),
                                        [],
                                    )
                                }
                                variant="outlined"
                                fullWidth
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" variant="outlined">
                            cancle
                        </Button>
                        <Button color="primary" variant="contained" startIcon={<Save />} onClick={handleSentNotification}>
                            save
                        </Button>
                    </DialogActions>
                </Dialog>

                <Container>
                    <Grid container justify="space-between" alignItems="center">
                        <h1>Notice Board</h1>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                            startIcon={<Add />}
                        >Add notice</Button>
                    </Grid>
                    <br />
                    <div className={styles.root}>
                        <NoticeTable
                            handleSwitch={handleSwitch}
                            deleteNotice={deleteNotice}
                        />
                    </div>
                </Container>
            </Layout>
        </div>
    )
}
