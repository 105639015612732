import React from "react";
import FreeBreakfastIcon from "@material-ui/icons/FreeBreakfast";
import {
  addDays,
  formatRelative,
  isBefore,
  isToday,
  isYesterday,
} from "date-fns";
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";

import { DeliveryReqs } from "../../context/contextTypes";
import { PARCEL_STATUS } from "../../helper/helper";
import { DeliveryReqSelect, DelType } from "../deliveryCommon/DeliveryCommon";
// import { formatRelative } from 'date-fns';
// <p>{formatRelative(new Date(node.requestedOn), new Date())}</p>

interface Props {
  reqs: DeliveryReqs;
  index: number;
  length: number;

  deliveryType: DelType;
  handleSelect: ({ type, index }: DeliveryReqSelect) => void;
}

export const colorPalette = {
  red: {
    deep: "#ff575785",
    light: "#ff858575",
  },
  yellow: {
    deep: "#ffc70e",
    light: "#f4ea8e",
  },
  blue: {
    deep: "#0062a88f",
    light: "#b9dfffa6",
  },
  none: {
    deep: "inherit",
    light: "inherit",
  },
};

const DeliveryReqRow: React.FC<Props> = ({
  length,
  reqs: { node, selected },
  deliveryType,
  handleSelect,
  index,
}) => {
  // console.log(node)

  const delivery_type = node.delivery_type;

  const status = node.status;

  const isFragile = node.parcel_type === "fragile";

  const isActiveColor =
    status !== "waiting"
      ? delivery_type === "dhaka_24hrs" || delivery_type === "regular"
      : false;

  const _isToday = isToday(new Date(node.pickedupAt));

  const _isYesterday = isYesterday(new Date(node.pickedupAt));

  const _isOld = isBefore(new Date(node.pickedupAt), addDays(new Date(), 2));

  const isTodayWith24 = _isToday && delivery_type === "dhaka_24hrs";

  const isYesterdayWith24 = _isYesterday && delivery_type === "dhaka_24hrs";

  const isOldWith24 = _isOld && delivery_type === "dhaka_24hrs";

  const _color: "red" | "blue" | "yellow" | "none" = _isToday
    ? "blue"
    : _isYesterday
    ? "yellow"
    : _isOld
    ? "red"
    : "none";

  const _variant: "deep" | "light" =
    isTodayWith24 || isYesterdayWith24 || isOldWith24 ? "deep" : "light";

  return (
    <div
      className={`deliveryReqTable__bodyMain anim-card `}
      style={{
        gridTemplateColumns: `repeat(${length}, 1fr)`,
        backgroundColor: isActiveColor
          ? colorPalette[_color][_variant]
          : "inherit",
      }}
    >
      <div title="checkbox" className="deliveryReqTable__bodyCol">
        <input
          type="checkbox"
          name="check-one"
          checked={selected}
          onChange={() => {
            if (selected) {
              handleSelect({ type: "REMOVE_ONE", index, id: node.objectId });
            } else {
              handleSelect({ type: "ADD_ONE", index, id: node.objectId });
            }
          }}
        />
      </div>

      <div title="name" className="deliveryReqTable__bodyCol ">
        <p>{formatRelative(new Date(node.createdAt), new Date())}</p>
      </div>
      <div title="name" className="deliveryReqTable__bodyCol ">
        {/* <p>{new Date(node.pickedupAt).toLocaleString()}</p> */}
        <p>
          {node.pickedupAt
            ? formatRelative(new Date(node.pickedupAt), new Date())
            : "No Date"}
        </p>
      </div>
      <div title="name" className="deliveryReqTable__bodyCol ">
        <Link target="_blank" to={`/order/details/${node.objectId}`}>
          {node.brand.name}
        </Link>
      </div>
      <div title="name" className="deliveryReqTable__bodyCol ">
        <p>{node.area} </p>
      </div>
      <div title="address of customer" className="deliveryReqTable__bodyCol">
        <p>{node.customer_name}</p>
        <p>{node.customer_phone}</p>
        <p>{node.address}</p>
      </div>
      <div className="deliveryReqTable__bodyCol">
        {isFragile && (
          <Tooltip arrow title="Fragile item">
            <FreeBreakfastIcon color="error" />
          </Tooltip>
        )}
      </div>

      {deliveryType !== "waiting" && (
        <>
          {" "}
          <div title="rider" className="deliveryReqTable__bodyCol ">
            {node.deliveryRider ? (
              <Link
                target="_blank"
                to={`/rider/${node.deliveryRider.objectId}`}
              >
                <h3>{node.deliveryRider.username}</h3>
              </Link>
            ) : (
              ""
            )}
          </div>
          <div title="notes" className="deliveryReqTable__bodyCol ">
            <ul>
              {node.notes?.map((note, i) => (
                <li key={note.value.text + i}>{note.value.text}</li>
              ))}
            </ul>
          </div>
          <div title="status" className="deliveryReqTable__bodyCol">
            <p className={`${node.status} tag`}>{PARCEL_STATUS[node.status]}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default DeliveryReqRow;
