import { gql } from '@apollo/client';

export const GET_NOTICE = gql`
    query getNotices {
        notice_boards(where: {
            # visible: { equalTo: true }
        }
        order: createdAt_DESC
        ) {
        edges {
            node {
                objectId
                text
                visible
                createdAt
            }
        }
        }
    }
`;
