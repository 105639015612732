import React, { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
/* eslint-disable*/
import Tooltip from '@material-ui/core/Tooltip';
import CallMergeIcon from '@material-ui/icons/CallMerge';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Button, Checkbox, Grid } from '@material-ui/core';

import './retarget.scss';
import Layout from '../../components/layout/Layout';
import TableHeader from '../../components/TableCommon/TableHeader';
import useRetarget, { OptVars } from '../../hooks/useRetarget';
import FormDialog from '../../components/formDialog/FormDialog';
import { useStore } from '../../context/Context';
import { errorMessage, getNameOrUsername } from '../../helper/helper';

const Retarget = () => {
	const {
		store: { user },
	} = useStore();

	const {
		getRetargetMerchants,
		loading,
		retargetedData,
		postLoading,
		retarget,
		handleNotification,
	} = useRetarget();

	const textRef = useRef() as React.MutableRefObject<HTMLInputElement>;

	const [date, setDate] = useState<MaterialUiPickersDate | null>(null);

	const [open, setOpen] = useState(false);

	const [brandId, setBrandId] = useState<string | null>(null);

	const [willReturn, setWillReturn] = useState(false);

	const headers = [
		{ field: 'Brand' },
		{ field: 'Contacts' },
		{ field: 'Registered' },
		{ field: 'Last Ordered Date' },
		{ field: 'Total Orders' },
		{ field: '' },
	];

	useEffect(() => {
		getRetargetMerchants();
	}, []);

	useEffect(() => {
		if (!open) reset();
	}, [open]);

	const reset = () => {
		if (open) setOpen(false);

		if (textRef.current) {
			textRef.current.value = '';
		}
		setWillReturn(false);
		setBrandId(null);
		setDate(null);
	};

	const handleSubmit = async () => {
		if (!user || !brandId) return;

		try {
			let data: OptVars = {
				brand: brandId,
				handler: getNameOrUsername(user.user),
				notes: [textRef.current.value],
				will_return: willReturn,
			};

			if (willReturn && date) data.date = date;

			await retarget({ variables: data });

			handleNotification({
				variant: 'standard',
				message: 'Brand retargated successfully.',
				type: 'success',
			});

			reset();

			await getRetargetMerchants();
		} catch (error: any) {
			reset();
			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});
		}
	};

	const handleOpen = (bId: string) => {
		setOpen(true);
		setBrandId(bId);
	};

	return (
		<Layout title={`Retarget`} loading={loading} className='retarget'>
			<div className='retarget__container'>
				<div className='overflow'>
					<div className='retarget__table'>
						<div
							className='retarget__header'
							style={{
								gridTemplateColumns: `repeat(${headers.length}, 1fr)`,
							}}
						>
							{headers.map(({ field }, i) => (
								<TableHeader key={i} field={field} />
							))}
						</div>
						{retargetedData?.map((data) => (
							<div
								key={data._id}
								className='retarget__body anim-card'
								style={{
									gridTemplateColumns: `repeat(${headers.length}, 1fr)`,
								}}
							>
								<div className='retarget__bodyCol'>
									<p>{data.brand.name}</p>
								</div>

								<div className='retarget__bodyCol'>
									{data.brand.contact_numbers?.map((number) => (
										<p>{number}</p>
									))}
								</div>

								<div className='retarget__bodyCol'>
									<p>{format(new Date(data.brand.createdAt.iso), 'PPpp')} </p>
								</div>
								<div className='retarget__bodyCol'>
									<p>{format(new Date(data.lastOrderPlaced.iso), 'PPpp')}</p>
								</div>
								<div className='retarget__bodyCol'>
									<p>{data.total_order}</p>
								</div>
								<div className='retarget__bodyCol'>
									<Tooltip title='mitigate!' arrow placement='top'>
										<IconButton
											onClick={() => handleOpen(data.o_id)}
											style={{ transform: 'translateY(-11px)' }}
										>
											<CallMergeIcon color='primary' />
										</IconButton>
									</Tooltip>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<FormDialog open={open} setOpen={setOpen}>
				<Grid direction='column' container spacing={2}>
					<Grid
						item
						container
						alignItems='center'
						style={{ transform: 'translateX(-8px)' }}
					>
						<Checkbox
							color='primary'
							checked={willReturn}
							onChange={(_e, checked) => setWillReturn(checked)}
						/>
						<span>Will Return</span>
					</Grid>
					{willReturn && (
						<Grid item container alignItems='center'>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<DatePicker
									fullWidth
									title='date'
									className='pickupAssign__search date rounded'
									onChange={(value) => setDate(value)}
									value={date}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
					)}
					<Grid item>
						<TextField
							inputRef={textRef}
							placeholder='messages....'
							rows={4}
							variant='outlined'
							multiline
							fullWidth
						/>
					</Grid>
					<Grid item>
						<Button
							disabled={postLoading}
							onClick={handleSubmit}
							variant='contained'
							color='primary'
							fullWidth
						>
							{postLoading ? 'Loading...' : 'Submit'}
						</Button>
					</Grid>
				</Grid>
			</FormDialog>
		</Layout>
	);
};

export default Retarget;
