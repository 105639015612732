import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useStore } from '../../context/Context';

const PickupRoute: React.FC<RouteProps> = ({
	component: Component,
	...rest
}) => {
	const {
		store: { user },
	} = useStore();

	if (!Component) {
		return null;
	}

	const isAuthenticated: boolean =
		user?.user.type === 'pickup-manager' || user?.user.type === 'admin'
			? true
			: false;

	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{ pathname: '/login', state: { from: props.location } }}
					/>
				)
			}
		/>
	);
};

export default PickupRoute;
