import { useState } from 'react';
import { useStore } from '../context/Context';
import { Agreement } from '../context/contextTypes';
import { instanceOfAxios } from '../helper/axios';
import { errorMessage } from '../helper/helper';
import useMessage from './useMessage';

export interface Module {
	parcel: ParcelOrFragileOrGrocery;
	fragile: ParcelOrFragileOrGrocery;
	// grocery: ParcelOrFragileOrGrocery;
}

export interface ParcelOrFragileOrGrocery {
	weight_per_kg: WeightPerKg;
	weight_range: { [index: string]: number };
}

export interface WeightPerKg {
	price_unit: number;
	extra_per_unit: number;
	cod_charge: number;
}

export interface PriceObj {
	express: Module;
	outside_dhaka: Module;
	dhaka_24hrs: Module;
	regular: Module;
}

export const primaryObj: PriceObj = {
	express: {
		parcel: {
			weight_per_kg: {
				price_unit: 0,
				extra_per_unit: 0,
				cod_charge: 0,
			},
			weight_range: {},
		},
		fragile: {
			weight_per_kg: {
				price_unit: 0,
				extra_per_unit: 0,
				cod_charge: 0,
			},
			weight_range: {},
		},
	},
	regular: {
		parcel: {
			weight_per_kg: {
				price_unit: 0,
				extra_per_unit: 0,
				cod_charge: 0,
			},
			weight_range: {},
		},
		fragile: {
			weight_per_kg: {
				price_unit: 0,
				extra_per_unit: 0,
				cod_charge: 0,
			},
			weight_range: {},
		},
	},
	dhaka_24hrs: {
		parcel: {
			weight_per_kg: {
				price_unit: 0,
				extra_per_unit: 0,
				cod_charge: 0,
			},
			weight_range: {},
		},
		fragile: {
			weight_per_kg: {
				price_unit: 0,
				extra_per_unit: 0,
				cod_charge: 0,
			},
			weight_range: {},
		},
	},
	outside_dhaka: {
		parcel: {
			weight_per_kg: {
				price_unit: 0,
				extra_per_unit: 0,
				cod_charge: 0,
			},
			weight_range: {},
		},
		fragile: {
			weight_per_kg: {
				price_unit: 0,
				extra_per_unit: 0,
				cod_charge: 0,
			},
			weight_range: {},
		},
	},
};

const useFee = () => {
	const { handleNotification } = useMessage();
	const {
		store: { user },
	} = useStore();

	const [loading, setLoading] = useState(false);

	const addFee = async ({
		data,
		name,
		agreement,
	}: {
		data: PriceObj;
		name: string;
		agreement: Agreement;
	}) => {
		setLoading(true);
		const headers = {
			'X-Parse-Session-Token': user?.sessionToken,
		};

		try {
			await instanceOfAxios.post(
				'/classes/pricing_scheme',
				{ ...data, agreement, name },
				{
					headers,
				}
			);

			handleNotification({
				variant: 'standard',
				message: 'successfully updated chart',
				type: 'success',
			});
			setLoading(false);
		} catch (error) {
			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});

			setLoading(false);
		}
	};

	const updateFee = async ({
		data,
		name,
		id,
		agreement,
	}: {
		data: PriceObj;
		name: string;
		id: string;
		agreement: Agreement;
	}) => {
		setLoading(true);
		const headers = {
			'X-Parse-Session-Token': user?.sessionToken,
		};

		try {
			await instanceOfAxios.put(
				`/classes/pricing_scheme/${id}`,
				{ ...data, agreement, name },
				{
					headers,
				}
			);

			handleNotification({
				variant: 'standard',
				message: 'successfully updated chart',
				type: 'success',
			});
			setLoading(false);
		} catch (error) {
			console.log(error.response);

			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});

			setLoading(false);
		}
	};

	return { updateFee, loading, addFee };
};

export default useFee;
