import gql from 'graphql-tag';

export const SET_NEW_RETAGRET = gql`
	mutation setRetarget(
		$date: Date
		$handler: String!
		$will_return: Boolean!
		$notes: [Any]!
		$brand: ID!
	) {
		createRetarget(
			input: {
				fields: {
					approx_return: $date
					brand: { link: $brand }
					will_return: $will_return
					handler: $handler
					notes: $notes
					status: "open"
				}
			}
		) {
			retarget {
				objectId
			}
		}
	}
`;
