import { useParams } from 'react-router-dom';
import DeliveryFeeChart from './DeliveryFeeChart';

const UpdateFee = () => {
	const { id } = useParams<{ id: string }>();

	return <DeliveryFeeChart id={id} />;
};

export default UpdateFee;
