import { TextField, Button, Dialog, DialogContent } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import Select from "react-select";

export default function FormDialgo({
  open,
  setOpen,
  submit,
  formData,
  setFormData,
  handleSubmit,
}) {
  const profileTypes = [
    { label: "Rider", value: "rider" },
    { label: "Pickup-rider", value: "pickup-rider" },
    { label: "Pickup-manager", value: "pickup-manager" },
    { label: "Admin", value: "admin" },
    { label: "Accounts", value: "accounts" },
  ];

  const handleChange = (e) => {
    setFormData(
      e.target.type === "number"
        ? { ...formData, [e.target.name]: Number(e.target.value) }
        : { ...formData, [e.target.name]: e.target.value }
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <form onSubmit={handleSubmit} style={{ width: "350px" }}>
            <TextField
              required
              fullWidth
              size="small"
              variant="outlined"
              name="username"
              label="Username"
              placeholder="Faisal"
              onChange={handleChange}
              value={formData?.username}
            />
            <br />
            <br />
            <TextField
              // required
              fullWidth
              size="small"
              variant="outlined"
              name="name"
              label="Name"
              placeholder="Anit fat"
              onChange={handleChange}
              value={formData?.name}
            />
            <br />
            <br />
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              name="phone"
              label="Phone"
              placeholder="017********"
              onChange={handleChange}
              value={formData?.phone}
            />
            <br />
            <br />
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              name="password"
              label="Password"
              placeholder="*****"
              type="password"
              onChange={handleChange}
            />
            <br />
            <br />
            {submit === "create" && (
              <div className="select">
                <Select
                  placeholder="Type"
                  options={profileTypes}
                  onChange={(e) => setFormData({ ...formData, type: e.value })}
                  inputProps={{}}
                />
              </div>
            )}
            <Button
              startIcon={<AddCircle />}
              fullWidth
              type="submit"
              size="large"
              variant="contained"
              color="primary"
            >
              {submit === "create" ? "Add user" : "edit user"}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
