import { useState } from 'react';
import { useMutation } from '@apollo/client';

import { instanceOfAxios } from '../helper/axios';
import { errorMessage } from '../helper/helper';
import useMessage from './useMessage';
import { SET_NEW_RETAGRET } from '../graphql/mutations/retarget';

export interface Retarget {
	result: ResultEntity[];
}
export interface ResultEntity {
	_id: string;
	lastOrderPlaced: CreatedAtOrLastOrderPlacedOrApproxReturnOrUpdatedAt;
	o_id: string;
	total_order: number;
	brand: Brand;
	retarget_again?: RetargetAgainEntity[];
	retarget_count: number;
	retarget_history_count: number;
}
export interface CreatedAtOrLastOrderPlacedOrApproxReturnOrUpdatedAt {
	__type: string;
	iso: string;
}
export interface Brand {
	name: string;
	createdAt: CreatedAtOrLastOrderPlacedOrApproxReturnOrUpdatedAt;
	contact_numbers?: string[] | null;
}
export interface RetargetAgainEntity {
	_id: string;
	approx_return: CreatedAtOrLastOrderPlacedOrApproxReturnOrUpdatedAt;
	_p_brand: string;
	will_return: boolean;
	handler: string;
	_created_at: CreatedAtOrLastOrderPlacedOrApproxReturnOrUpdatedAt;
	_updated_at: CreatedAtOrLastOrderPlacedOrApproxReturnOrUpdatedAt;
}

export interface OptVars {
	date?: Date;
	handler: string;
	will_return: boolean;
	notes: string[];
	brand: string;
}

const useRetarget = () => {
	const { handleNotification } = useMessage();

	const [loading, setLoading] = useState(false);

	const [retargetedData, setRetargetedData] =
		useState<ResultEntity[] | null>(null);

	const [retarget, { loading: postLoading }] =
		useMutation<any, OptVars>(SET_NEW_RETAGRET);

	const getRetargetMerchants = async () => {
		setLoading(true);
		try {
			const { data } = await instanceOfAxios.post<Retarget>(
				'/functions/getInactiveBrands'
			);

			setRetargetedData(data.result);

			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});
		}
	};

	return {
		loading,
		getRetargetMerchants,
		retargetedData,
		retarget,
		postLoading,
		handleNotification,
	};
};

export default useRetarget;
