import React, { useEffect } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import localforage from 'localforage';
import { useFormikContext } from 'formik';

import useParcel from '../../hooks/useParcel';
import { useStore } from '../../context/Context';
import { makeNameStr } from '../../helper/helper';

interface Props {
	price: number;
	index: number;
}

export interface DataBulk {
	data: DataEntity[];
}
export interface DataEntity {
	no: number;
	merchantOrderId: string;
	recipentName: string;
	recipentPhone: string;
	recipentPhoneName: string;
	recipientAddress: string;
	recipientAddressName: string;
	productDesc: string;
	productDescName: string;
	weightOption: number;
	delType: string;
	parcelType: string;
	paymentOption: string;
	totalPrice: number;
	amountCollect: number;
	note: string;
	areaSelect: string;
	outside: boolean;
	slot?: null;
	zone: string;
}

const AsyncDeliveryCharge: React.FC<Props> = ({ price, index }) => {
	const { values, setFieldValue } = useFormikContext<DataBulk>();

	const { updateEstimation, loading } = useParcel();

	const {
		store: { brand },
	} = useStore();

	const ref = values.data[index];

	useEffect(() => {
		const isPriceAvailable =
			ref.amountCollect && ref.weightOption && ref.amountCollect;

		if (isPriceAvailable) {
			const zone = ref.outside ? 'outside_dhaka' : 'dhaka';
			const delivery_type = ref.delType;
			const parcel_type = ref.parcelType;
			const pickup_cash =
				ref.paymentOption === 'cash_on_delivery' ? true : false;

			const weight = ref.weightOption;

			updateEstimation({
				zone,
				delivery_type,
				parcel_type,
				pickup_cash,
				weight,
				brandId: brand ? brand.node.objectId : null,
				amount: ref.amountCollect,
			}).then((data) => {
				data
					? setFieldValue(
							makeNameStr({
								i: index,
								name: 'totalPrice',
							}),
							data.result.cost.total
					  )
					: setFieldValue(
							makeNameStr({
								i: index,
								name: 'totalPrice',
							}),
							60
					  );
			});
		}
	}, [
		ref.zone,
		ref.outside,
		ref.delType,
		ref.parcelType,
		ref.amountCollect,
		ref.weightOption,
		ref.paymentOption,
		brand,
		index,
	]);

	useEffect(() => {
		if (ref.merchantOrderId) {
			localforage.setItem('bulk', values).then(() => {
				console.log('used localForage');
				return;
			});
		}
	}, [values]);

	return <>{loading ? <Skeleton animation='wave' /> : <h2>{price}</h2>}</>;
};

export default AsyncDeliveryCharge;
