import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Pagination from '@material-ui/lab/Pagination';
// import _ from 'lodash';

import './riderProfile.scss';
import Layout from '../../components/layout/Layout';
import { Header } from '../../components/pickupReqTable/pickup.req.table';
import TableHeader from '../../components/TableCommon/TableHeader';
import { GET_RIDER_STAT } from '../../graphql/queries/riders';
import useMessage from '../../hooks/useMessage';
import {
	NotesEntity,
	RiderStat,
} from '../../context/contextTypes';
import { PARCEL_STATUS, searchIsoDate, toCap } from '../../helper/helper';
import { Link, useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { DateRangePicker } from 'react-date-range';
import LoadingBtn from '../../components/typography/buttons/LoadingBtn';
import useRiderProfile from '../../hooks/useRiderProfile';

const STAT_PER_PAGE = 100;

const RiderProfile = () => {
	const { location } = useHistory();

	const query = new URLSearchParams(location.search);

	const name = query.get('name');

	const [isDateOpen, setIsDateOpen] = useState(false);

	const [toSkip, setToSkip] = useState(false);

	const [skip, setSkip] = useState(0);

	const [startDate, setStartDate] = useState<Date>(new Date());

	const [endDate, setEndDate] = useState<Date>(new Date());

	const [count, setCount] = useState<number | null>(null);

	const [currentPage, setCurrentPage] = useState(1);

	const [totalStat, setTotalStat] = useState<RiderStat[] | null>(null);

	const { id } = useParams<{ id: string }>();

	const { handleNotification } = useMessage();

	const { getRidersStat, stat, statLoading } = useRiderProfile();

	const selectionRange = {
		startDate,
		endDate,
		key: 'selection',
		color: 'black',
	};

	const { data, loading, error } = useQuery<{
		parcels: { edges: RiderStat[] };
	}>(GET_RIDER_STAT, {
		variables: {
			gt: searchIsoDate(startDate, 'start'),
			lt: searchIsoDate(endDate, 'end'),
			id,
			skip,
			first: STAT_PER_PAGE,
		},
		skip: toSkip,
	});

	const riderStat = useCallback(
		() =>
			getRidersStat({
				startDate: searchIsoDate(startDate, 'start'),
				endDate: searchIsoDate(endDate, 'end'),
				riderId: id,
			}),
		[startDate, endDate, id, getRidersStat]
	);

	const stats: { title: string; amount: number }[] = useMemo(() => {
		if (stat) {
			const cardStat = stat.breakdown.reduce(
				(ac: any, curr) => ({
					...ac,
					[curr._id]: curr.order_count,
				}),
				{}
			);

			if (stat.total_count) {
				const total = Math.ceil(stat.total_count / STAT_PER_PAGE);

				setCount(total);
			}

			return [
				{
					title: 'Total Orders',
					amount: stat.total_count ? stat.total_count : 0,
				},
				{
					title: 'Orders Delivered',
					amount: cardStat.delivered ? cardStat.delivered : 0,
				},
				{
					title: 'Orders in Transit',
					amount: cardStat.picked_up_bag ? cardStat.picked_up_bag : 0,
				},
				{
					title: 'Orders Cancelled',
					amount: cardStat.cancelled ? cardStat.cancelled : 0,
				},
				{
					title: 'Total Resheduled',
					amount: cardStat.rescheduled ? cardStat.rescheduled : 0,
				},
				{
					title: 'Total Returned',
					amount: cardStat.returned ? cardStat.returned : 0,
				},
				{
					title: 'Returned paid By Customer',
					amount: cardStat.returned_paid_by_customer
						? cardStat.returned_paid_by_customer
						: 0,
				},
				{
					title: 'Total Collected (cash) TK.',
					amount: stat.collection ? stat.collection : 0,
				},
			];
		}

		return [
			{ title: 'Total Orders', amount: 0 },
			{ title: 'Orders Delivered', amount: 0 },
			{ title: 'Orders in Transit', amount: 0 },
			{ title: 'Orders Cancelled', amount: 0 },
			{ title: 'Total Resheduled', amount: 0 },
			{ title: 'Total Collected (cash)', amount: 0 },
		];
	}, [stat]);

	const headers: Header[] = [
		{ field: 'Order Id' },
		{ field: 'Recipient Info' },
		{ field: 'Amount' },
		{ field: 'Collected Cash' },
		{ field: 'Delivery Status' },
		{ field: 'Brand' },
		{ field: 'Area' },
		{ field: 'Note' },
	];

	useEffect(() => {
		if (!loading && !!data) {
			setToSkip(true);
		}
	}, [data, loading]);

	useEffect(() => {
		riderStat();
	}, [riderStat]);

	useEffect(() => {
		if (data) {
			console.log(data);

			setTotalStat(
				data['parcels']['edges'].map(({ node }) => {
					const status = node.status;

					const nonZeroStatus = ['returned_paid_by_customer', 'delivered'];

					const hasColledted: boolean = node.pickup_cash
						? nonZeroStatus.includes(status)
						: false;

					let cash_collected = 0;

					if (hasColledted && node.status === 'delivered')
						cash_collected = node.amount;

					if (hasColledted && node.status === 'returned_paid_by_customer')
						cash_collected = node.delivery_charge ? node.delivery_charge : 0;

					return { node, cash_collected };
				})
			);
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			handleNotification({
				variant: 'standard',
				message: error.message,
				type: 'error',
			});
		}
	}, [error, handleNotification]);

	const handleSelect = (ranges: any) => {
		setStartDate(ranges.selection.startDate);
		setEndDate(ranges.selection.endDate);
	};

	const handleSearch = async () => {
		setToSkip(false);
		setIsDateOpen(false);
		riderStat();
	};

	const handleChange = (_x: unknown, page: number) => {
		setCurrentPage(page);

		const presentCount = (page - 1) * STAT_PER_PAGE;

		setToSkip(false);

		setSkip(presentCount);
	};

	return (
		<Layout
			className='riderProfile'
			title={`${name ? toCap(name) : 'Rider'}'s Profile`}
			loading={loading || statLoading}>
			<div className='riderProfile__container rounded shadow-lg anim-frame'>
				<section className='riderProfile__header'>
					<div className='riderProfile__headerLeft'>
						<h1 className='text-xl'>
							Data: ( {format(new Date(selectionRange.startDate), 'do LLLL')} -{' '}
							{format(new Date(selectionRange.endDate), 'do LLLL')} )
						</h1>
					</div>
					<div className='riderProfile__headerRight'>
						<div className='riderProfile__date'>
							<IconButton
								color='primary'
								onClick={() => setIsDateOpen(!isDateOpen)}>
								<DateRangeIcon />
							</IconButton>
							{isDateOpen && (
								<div className='riderProfile__datePicker shadow-lg'>
									<DateRangePicker
										ranges={[selectionRange]}
										onChange={handleSelect}
									/>
								</div>
							)}
							<LoadingBtn disabled={loading} onClick={handleSearch}>
								Search
							</LoadingBtn>
						</div>
					</div>
				</section>
				<section className='riderProfile__stats'>
					{stats.map((stat, i) => (
						<div
							key={i + stat.title}
							className='riderProfile__statsItem anim-card rounded shadow-md '>
							<p className='text-xl'>{stat.title}</p>
							<h1 className='text-5xl'>{stat.amount}</h1>
						</div>
					))}
				</section>
				<section className='overflow'>
					<div className='riderProfile__table'>
						<div
							className='riderProfile__tableHeader'
							style={{ gridTemplateColumns: `repeat(${headers.length}, 1fr)` }}>
							{headers.map(({ field }, i) => (
								<TableHeader key={i + field} field={field} />
							))}
						</div>
						{totalStat?.map(({ node, cash_collected }, i) => (
							<Link
								to={`/order/details/${node.objectId}`}
								key={node.objectId + i}
								className='riderProfile__body anim-card'
								style={{
									gridTemplateColumns: `repeat(${headers.length}, 1fr)`,
								}}>
								<div className='riderProfile__bodyCol'>
									<p>
										{i + 1}
										{'.  '} {node.order_id}
									</p>
								</div>
								<div className='riderProfile__bodyCol'>
									<p>{node.customer_name}</p>
									<p>{node.customer_phone}</p>
									<p>{node.address}</p>
								</div>
								<div className='riderProfile__bodyCol'>
									<p>{node.amount}</p>
								</div>
								<div className='riderProfile__bodyCol'>
									<p>{cash_collected}</p>
								</div>
								<div className='riderProfile__bodyCol'>
									<p className={`${node.status} tag`}>
										{PARCEL_STATUS[node.status]}
									</p>
								</div>

								<div className='riderProfile__bodyCol'>
									<p>{node.weight} kg</p>
								</div>
								<div className='riderProfile__bodyCol'>
									<p>{node.area}</p>
								</div>

								<div className='riderProfile__bodyCol'>
									{node?.notes?.map((note: NotesEntity) => {
										return (
											<li
												style={{ transform: 'translateX(14px)' }}
												key={note.value.text}>
												<h4>{note.value.text}</h4>
											</li>
										);
									})}
								</div>
							</Link>
						))}

						{count && (
							<div className='allOrders__pagination anim-frame'>
								<Pagination
									onChange={handleChange}
									count={count}
									color='primary'
									page={currentPage}
									shape='rounded'
								/>
							</div>
						)}
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default RiderProfile;
