import { useHistory } from 'react-router-dom';

import { instanceOfAxios } from '../helper/axios';
import { errorMessage } from '../helper/helper';
import useMessage from './useMessage';

interface Payload {
	number: string;
	isForgot: boolean;
}

const useOtp = () => {
	const { replace } = useHistory();

	const { handleNotification } = useMessage();

	const handleOtp = async ({ number, isForgot }: Payload) => {
		try {
			const { data } = await instanceOfAxios.post('/functions/generateOtp', {
				phone: number,
			});

			replace('/merchant-otp', { number, code: data.result, isForgot });
		} catch (error) {
			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});
		}
	};
	return { handleOtp };
};

export default useOtp;
