import './notification.scss';
import Skeleton from '@material-ui/lab/Skeleton';

const NotifSkeleton = () => {
	return (
		<div className='notification__each'>
			<div className='notification__icon'>
				<Skeleton height={20} width={20} animation='wave' />
			</div>
			<div className='notification__container'>
				<div className='notification__title'>
					<div className='notification__titleLeft'>
						<p>
							<Skeleton height={20} width={150} animation='wave' />
						</p>
					</div>
					<div className='notification__titleRight'>
						<Skeleton height={20} width={20} animation='wave' />
					</div>
				</div>
				<div className='notification__body'>
					<Skeleton height={20} width={200} animation='wave' />
					<Skeleton height={20} width={200} animation='wave' />
				</div>
			</div>
		</div>
	);
};

export default NotifSkeleton;
