/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState, MutableRefObject, useMemo } from 'react';
import { useStore } from '../context/Context';
import { useHistory } from 'react-router-dom';
import { endOfDay, startOfDay } from 'date-fns';

import useMessage from './useMessage';
import { instanceOfAxios } from '../helper/axios';
import { errorMessage, PARCEL_STATUS, toParseDate } from '../helper/helper';
import { STATUS_REJECTED } from '../AppRoutes';
import { SelectLabel } from '../screens/bulkScreen/BulkScreen';
import { cities } from '../docs/city';
import { useQuery } from 'react-query';
import { BrandParcel } from '../context/contextTypes';

interface OrderProps {
	objectId?: string;
	callApi?: boolean;
}

const useOrders = ({
	admin,
	isRejected = false,
	callApi = true,
	objectId,
}: {
	admin: boolean;
	isRejected?: boolean;
} & OrderProps) => {
	const PARCELS_PER_PAGE = 100;

	const { location, push } = useHistory();
	const query = new URLSearchParams(location.search);
	const payment_transaction = query.get('payment_transaction');
	const page = query.get('page');
	const _skip = query.get('skip');
	const _phone = query.get('phone');
	const _brandNameId = query.get('brand');
	const _orderId = query.get('orderId');
	const moid = query.get('moid');
	const _name = query.get('name');
	const _status = query.get('status');
	const _startDate = query.get('startDate');
	const _endDate = query.get('endDate');
	const _area = query.get('area');
	const _pickup_location = query.get('pickup_location');
	const _requestedSlot = query.get('requestedSlot');
	const _processStart = query.get('process-start');
	const _processEnd = query.get('process-end');

	const phoneRef = useRef() as MutableRefObject<HTMLInputElement>;
	const nameRef = useRef() as MutableRefObject<HTMLInputElement>;
	const orderIdRef = useRef() as MutableRefObject<HTMLInputElement>;
	const merchantOrderIdRef = useRef() as MutableRefObject<HTMLInputElement>;

	const [brandNameId, setBrandNameId] = useState('');
	const [brandName, setBrandName] = useState('');
	const [isDateOpen, setIsDateOpen] = useState(false);
	const [startDate, setStartDate] = useState<Date | undefined>(undefined);
	const [endDate, setEndDate] = useState<Date | undefined>(undefined);
	const [areaSelect, setAreaSelect] = useState<SelectLabel | null>(null);
	const [zoneSelect, setZoneSelect] = useState<SelectLabel | null>(null);
	const [status, setStatus] = useState<SelectLabel | null>(null);
	const [count, setCount] = useState<undefined | number>(undefined);
	const [parcelCount, setParcelCount] = useState(0);
	const [perPage, _setPerPage] = useState(PARCELS_PER_PAGE);


	const areas = useMemo(() => {
		if (zoneSelect) {
			return cities.filter((item) => item.district === zoneSelect.value);
		}
		return cities.filter((item) => item.district !== 'Dhaka');
	}, [zoneSelect]);


	const parcelStatus = useMemo(() => {
		return Object.keys(PARCEL_STATUS).map((key) => {
			return {
				label: PARCEL_STATUS[key],
				value: key,
			};
		});
	}, [PARCEL_STATUS]);


	const { handleNotification } = useMessage();

	const [currentPage, setCurrentPage] = useState(() => {
		if (page) {
			return Number(page);
		}
		return 1;
	});

	const {
		store: { brandParcel },
		dispatch,
	} = useStore();

	const reset = () => {
		setIsDateOpen(false);
		setBrandNameId('');
		setBrandName('');
		setStartDate(undefined);
		setEndDate(undefined);

		if (phoneRef.current) phoneRef.current.value = '';
		if (orderIdRef.current) orderIdRef.current.value = '';
		if (merchantOrderIdRef.current) nameRef.current.value = '';
		if (nameRef.current) nameRef.current.value = '';
	};

	useEffect(() => {
		if (brandParcel) {
			const total = Math.ceil((brandParcel.count as number) / PARCELS_PER_PAGE);

			setCount(total);
			setParcelCount(brandParcel.count as number);
		}
	}, [brandParcel]);

	const pathConstructor = (paths: string[], join: string) => {
		const main = `/${admin ? 'admin' : 'merchant'}/all-orders${isRejected ? '/rejected' : ''
			}?`;

		return main + paths.join(join);
	};

	const handleChange = (_x: unknown, page: number) => {
		setCurrentPage(page);

		const presentCount = (page - 1) * PARCELS_PER_PAGE;

		let sub = [`skip=${presentCount}`];

		if (_brandNameId) {
			sub.push(`brand=${_brandNameId}`);
		}

		if (_orderId) {
			sub.push(`orderId=${_orderId}`);
		}

		if (_phone) {
			sub.push(`phone=${_phone}`);
		}

		if (_name) {
			sub.push(`name=${_name}`);
		}

		if (_status) {
			sub.push(`status=${_status}`);
		}

		if (payment_transaction) {
			sub.push(`payment_transaction=${payment_transaction}`);
		}

		if (_startDate && _endDate) {
			sub.push(`startDate=${_startDate}&endDate=${_endDate}`);
		}

		if (_processStart && _processEnd) {
			sub.push(`process-start=${_processStart}&process-end=${_processEnd}`);
		}

		if (_area) {
			sub.push(`area=${_area}`);
		}

		if (_pickup_location) {
			sub.push(`pickup_location=${_pickup_location}`);
		}

		if (_requestedSlot) {
			sub.push(`requestedSlot=${_requestedSlot}`);
		}

		if (moid) {
			sub.push(`moid=${moid}`);
		}

		push(pathConstructor(sub, '&'));
	};

	const { data, error, isLoading, refetch, isFetching } = useQuery<BrandParcel>(
		[
			'orders',
			objectId,
			_brandNameId,
			payment_transaction,
			_phone,
			_orderId,
			_name,
			_status,
			isRejected,
			_area,
			_pickup_location,
			_startDate,
			_endDate,
			_processStart,
			_processEnd,
			_requestedSlot,
			moid,
		],
		async () => {
			let mandatory: any = {};

			const hasBrand = objectId || _brandNameId;

			if (hasBrand) {
				mandatory = {
					brand: { __type: 'Pointer', className: 'brand', objectId: hasBrand },
				};
			}

			let actualQuery: any = {
				...mandatory,
			};

			if (payment_transaction) {
				actualQuery = {
					...actualQuery,
					payment_transaction,
				};
			}

			if (_phone) {
				const customer_phone = {
					$regex: `${_phone}`,
					$options: 'i',
				};

				actualQuery = {
					...actualQuery,
					customer_phone,
				};
			}

			if (_orderId) {
				const order_id = {
					$regex: `${_orderId}`,
					$options: 'i',
				};

				actualQuery = {
					...actualQuery,
					order_id,
				};
			}

			if (moid) {
				actualQuery = {
					...actualQuery,
					moid: {
						$regex: `${moid}`,
						$options: 'i',
					},
				};
			}

			if (_name) {
				const customer_name = {
					$regex: `${_name}`,
					$options: 'i',
				};

				actualQuery = {
					...actualQuery,
					customer_name,
				};
			}

			if (_status) {
				actualQuery = {
					...actualQuery,
					status: _status,
				};
			}

			console.log({ isRejected });

			if (isRejected) {
				actualQuery = {
					...actualQuery,
					status: STATUS_REJECTED,
				};
			}

			if (_area) {
				actualQuery = {
					...actualQuery,
					area: _area,
				};
			}

			if (_pickup_location) {
				actualQuery = {
					...actualQuery,
					pickup_location: _pickup_location,
				};
			}

			if (_startDate && _endDate) {
				const createdAt = {
					$gt: toParseDate(startOfDay(new Date(_startDate)).toISOString()),
					$lt: toParseDate(endOfDay(new Date(_endDate)).toISOString()),
				};

				actualQuery = {
					...actualQuery,
					createdAt,
				};
			}

			if (_processStart && _processEnd) {
				const processingFinishedAt = {
					$gt: toParseDate(startOfDay(new Date(_processStart)).toISOString()),
					$lt: toParseDate(endOfDay(new Date(_processEnd)).toISOString()),
				};

				actualQuery = {
					...actualQuery,
					processingFinishedAt,
				};
			}

			if (_requestedSlot) {
				const requestedSlot = {
					$eq: toParseDate(_requestedSlot),
				};

				actualQuery = {
					...actualQuery,
					requestedSlot,
				};
			}

			const res = await instanceOfAxios.get(
				`/classes/parcel?order=-createdAt&where=${JSON.stringify(
					actualQuery
				)}&count=${'1'}&skip=${_skip ? _skip : 0
				}&limit=${perPage}&include=brand`
			);
			return res.data;
		},

		{ refetchOnWindowFocus: false, enabled: callApi }
	);

	useEffect(() => {
		if (data) {
			dispatch({ type: 'ADD_PARCELS_BY_BRAND', payload: data });
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			handleNotification({
				variant: 'standard',
				type: 'error',
				message: errorMessage({ error: error as any }),
			});
		}
	}, [error]);

	return {
		isLoading,
		handleChange,
		currentPage,
		count,
		reset,
		startDate,
		endDate,
		setStartDate,
		setEndDate,
		isDateOpen,
		setIsDateOpen,
		brandNameId,
		setBrandNameId,
		brandName,
		setBrandName,
		phoneRef,
		nameRef,
		orderIdRef,
		pathConstructor,
		refetch,
		payment_transaction,
		_status,
		parcelStatus,
		status,
		setStatus,
		parcelCount,
		areaSelect,
		setAreaSelect,
		zoneSelect,
		setZoneSelect,
		areas,
		merchantOrderIdRef,
		isFetching,
	};
};

export default useOrders;
