import React from 'react'
import { useQuery } from '@apollo/client';
import { IconButton, LinearProgress, Paper, Switch, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogContent, Button, Grid, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useState } from 'react';
import { GET_NOTICE } from '../../../graphql/queries/noticeBoard';
import styles from '../noticeBoard.module.css'
import { Delete } from '@material-ui/icons';

function NoticeTable(props: any) {
    const { data, loading } = useQuery(GET_NOTICE);
    const { handleSwitch, createdNotice, deleteNotice } = props
    const [deleteDialog, setDeleteDialog] = useState({ open: false, deleteItem: {} })
    const [notice, setNotice] = useState([])

    useEffect(() => {
        setNotice(data?.notice_boards?.edges)
    }, [data, createdNotice])

    if (loading) return <LinearProgress />

    return (
        <>
            <Dialog open={deleteDialog.open} onClose={() => setDeleteDialog({ open: false, deleteItem: {} })}>
                <DialogContent>
                    <Typography variant="h6">
                        Are you sure, you want to delete this notice?
                    </Typography>
                    <hr />
                    <br />
                    <Grid container justify="space-evenly">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setDeleteDialog({ open: false, deleteItem: {} })}
                        >cancel</Button>
                        <Button
                            startIcon={<Delete />}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setDeleteDialog({ open: false, deleteItem: {} })
                                deleteNotice(deleteDialog.deleteItem)
                            }}
                        >Delete</Button>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Paper className={styles.paper}>
                <Table
                    className={styles.table}
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                >
                    <TableHead className={styles.tableHead}>
                        <TableRow className={styles.tableHeadRow}>
                            <TableCell align="left"><h4>Text</h4></TableCell>
                            <TableCell />
                            <TableCell align="left"><h4>Publish / Un Published</h4></TableCell>
                            <TableCell align="left"><h4>Delete</h4></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {notice?.map((row: any, index: number) => {
                            return (
                                <TableRow hover key={index}>
                                    <TableCell align="left">{row.node.text}</TableCell>
                                    <TableCell />
                                    <TableCell>
                                        <Switch
                                            checked={row.node.visible}
                                            onChange={() => handleSwitch(row.node)}
                                            name="checkedA"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            color="primary"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton aria-label="delete" color="primary" onClick={() => {
                                            setDeleteDialog({ open: true, deleteItem: row.node })
                                        }}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Paper>
        </>
    )
}

export default React.memo(NoticeTable)