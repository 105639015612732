import React, { useCallback, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { formatRelative } from 'date-fns';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router';
import styled from 'styled-components';

import Layout from '../../components/layout/Layout';
import Modal from '../../components/modal/Modal';
import { Container } from '../../components/styles/Container';
import useIssues, { IssueEntity, ThreadEntity } from '../../hooks/useIssues';
import useModal from '../../hooks/useModal';
import LoadingBtn from '../../components/typography/buttons/LoadingBtn';
import {
	getNameOrUsername,
	getSearchParam,
	ISSUE_LABEL,
} from '../../helper/helper';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

function ThreadBy({ thread }: { thread: ThreadEntity }) {
	return (
		<>
			<ThreadByStyle>
				<div>
					<code>👨 {thread.by}</code>
					<span> {formatRelative(new Date(thread.timestamp), new Date())}</span>
				</div>
			</ThreadByStyle>
			<section>{thread.text}</section>
		</>
	);
}

const Issues: React.FC = () => {
	const { parcelId } = useParams<{ parcelId: string }>();
	console.log(parcelId)

	const { data, isLoading, mutateThread, user } = useIssues({ id: parcelId });

	const [text, setText] = useState('');

	const { toggle, ...rest } = useModal();

	const activeRef = useRef() as React.MutableRefObject<HTMLDivElement>;

	const [issue, setIssue] = useState<IssueEntity>();

	const [solve, setSolve] = useState(false);

	const issueActive = getSearchParam().get('issue');

	const reset = () => {
		setSolve(false);
		setText('');
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!!text.trim() && !!issue) {
			const data = {
				...issue,
				thread: [
					...issue.thread,
					{
						by: getNameOrUsername(user?.user as any),
						timestamp: new Date().toISOString(),
						text,
					},
				],
				resolved: solve,
			};

			setIssue(data);

			mutateThread.mutate(data);

			reset();
		}
	};

	const isActiveOne = useCallback(
		(id: string) => issueActive === id,
		[issueActive]
	);


	return (
		<Layout className='issues' title={`Issues`} loading={isLoading}>
			<Modal {...rest}>
				<IssueModal>
					{issue && (
						<>
							<Thread>
								<ThreadHead>
									<ThreadBy thread={issue.thread[0]} />
								</ThreadHead>
								{[...issue.thread].splice(1).map((thread) => (
									<ThreadEach key={thread.timestamp + thread.text}>
										<ThreadBy thread={thread} />
									</ThreadEach>
								))}
							</Thread>
							{issue.resolved ? (
								<>
									<hr />
									<h2 className='text-center error '>THIS ISSUE IS RESOLVED</h2>
									<hr />
								</>
							) : (
								<ThreadAction>
									<form onSubmit={handleSubmit}>
										<Check>
											<input
												type='checkbox'
												checked={solve}
												onChange={() => setSolve((old) => !old)}
											/>

											<p>resolve on submit</p>
										</Check>
										<TextField
											fullWidth
											margin='normal'
											required
											variant='outlined'
											name='description'
											label={`Your ${solve ? 'resolved' : ''} issue`}
											placeholder='any message...'
											type='text'
											multiline
											rows={2}
											onChange={(e) => setText(e.target.value)}
											value={text}
										/>

										<LoadingBtn fullWidth disabled={false} type='submit'>
											{solve ? 'Resolve issue' : 'Submit'}
										</LoadingBtn>
									</form>
								</ThreadAction>
							)}
						</>
					)}
				</IssueModal>
			</Modal>
			<Container>
				{data && isEmpty(data.results) && (
					<>
						<h1 className='text-center error '>THIS PARCEL HAS NO ISSUE</h1>
						<hr />
					</>
				)}
				<Section className='rounded '>
					<br/>
					<Link to={`/order/details/${parcelId}`}>
						<Button
							variant="contained"
							color="primary"
							fullWidth
							size="large"
						>parcel</Button>
					</Link>
					{data?.results.map((result) => (
						<Issue
							ref={isActiveOne(result.objectId) ? activeRef : null}
							active={isActiveOne(result.objectId)}
							key={result.objectId}>
							<H2
								onClick={() => {
									if (solve) setSolve(false);
									setIssue(result);
									toggle();
								}}
							>{result.thread[0]?.text}</H2>
							<Tag>{ISSUE_LABEL[result.label]}</Tag>
							{result.resolved && <Tag solved>solved</Tag>}
						</Issue>
					))}
				</Section>
			</Container>
		</Layout>
	);
};

export default Issues;

const Issue = styled.div<{ active: boolean }>`
	transition: 0.2s ease all;
	padding: 1rem;
	border-radius: 6px;
	margin: 0.7rem 0;
	background: white;
	border: ${({ active }) => (active ? '2px solid red' : 'none')};
	&:hover {
		transform: scale(1.03);
	}
`;

const Section = styled.section`
	background: linear-gradient(to bottom, hsl(0, 80%, 97%), #fff);
	width: 600px;
	max-width: 100%;
	margin: 2rem auto;
	padding: 0 1rem;
	border: 1px solid hsla(0, 0%, 70%, 0.5);
	min-height: 600px;
	box-shadow: 0 6px 8px 1px hsla(0, 0%, 70%, 0.2);
	transition: 0.2s ease all;
`;

const Tag = styled.span<{ solved?: boolean }>`
	margin-right: 0.2rem;
	padding: 0.4rem;
	background-color: ${({ solved }) =>
		`hsla(${solved ? 119 : 200}, 100%, 60%, 0.225)`};
	color: ${({ solved }) => (solved ? 'darkgreen' : 'darkblue')};
	border-radius: 10px;
`;

const H2 = styled.h1`
	color: hsl(0, 0%, 40%);
	line-height: 1.6rem;
	letter-spacing: 0.08rem;
	margin-bottom: 0.5rem;
	text-transform: lowercase;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #006eff;
	text-decoration: underline;
	cursor: pointer;
`;

const Thread = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-start;
	position: relative;
	max-height: 70vh;
	overflow-y: auto;
`;

const IssueModal = styled.div`
	width: 500px;
	max-width: 100%;
`;

const ThreadEach = styled.div`
	margin-bottom: 1rem;
	border-radius: 10px;
	padding: 0.4rem;
	background: hsl(0, 0%, 94%);
	color: #222;
	text-transform: lowercase;
	border: 1px solid hsla(0, 0%, 80%, 0.8);
	max-width: 80%;
`;

const ThreadHead = styled(ThreadEach)`
	align-self: flex-start;
`;

const ThreadByStyle = styled.section`
	margin-bottom: 0.5rem;
	font-size: smaller;
	color: #444;

	span {
		color: orangered;
	}
`;

const ThreadAction = styled.div`
	padding: 0.8rem;
`;

const Check = styled.div`
	display: grid;
	grid-template-columns: 1fr 12fr;
	font-size: medium;
	align-items: center;
`;
