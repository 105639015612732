import React from 'react';
import BreakDown from '../../components/breakDown/BreakDown';

const PickupManager: React.FC = () => {
	return (
		<>
			<BreakDown
				title='Manager (Rider)'
				breakdown='cash_breakdown'
				name='rider'
			/>
		</>
	);
};

export default PickupManager;
