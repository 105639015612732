import React from 'react';
import styled from 'styled-components';

interface Props {
	color?: string;
	className?: string;
	style?: React.CSSProperties;
}

const StyledCard = styled.div`
	padding: 1rem;
	background: white;
`;

const Card: React.FC<Props> = ({ children, color, className, style }) => {
	return (
		<StyledCard
			style={style}
			className={`rounded shadow-md anim-card ${className}`}
			color={color}
		>
			{children}
		</StyledCard>
	);
};

export default Card;
