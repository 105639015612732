import { useCallback, useState } from 'react';
import { useStore } from '../context/Context';

import { instanceOfAxios } from '../helper/axios';
import { errorMessage } from '../helper/helper';
import useMessage from './useMessage';

interface Stat {
	breakdown: { _id: string; order_count: number }[];
	collection?: number;
	total_count?: number;
}

const useRiderProfile = () => {
	const [stat, setStat] = useState<Stat | null>(null);

	const [statLoading, setStatLoading] = useState(false);

	const {
		store: { user },
	} = useStore();

	const { handleNotification } = useMessage();

	const getRidersStat = useCallback(
		async (riderData: any) => {
			// setStatLoading(true);
			try {
				const { data } = await instanceOfAxios.post(
					`/functions/riderStats`,
					riderData,
					{
						headers: {
							'x-parse-session-token': user?.sessionToken,
						},
					}
				);

				setStat(data.result);

				setStatLoading(false);
			} catch (error) {
				// setStatLoading(false);

				handleNotification({
					variant: 'standard',
					message: errorMessage({ error }),
					type: 'error',
				});
			}
		},
		[handleNotification, user]
	);

	return { statLoading, getRidersStat, stat };
};

export default useRiderProfile;
