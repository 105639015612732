import React from 'react'
import ReactPlayer from 'react-player'
import { Grid, Typography } from '@material-ui/core'
import Layout from '../../components/layout/Layout'

// "https://www.youtube.com/watch?v=rAAhu9_HpYs&ab_channel=NowDelivery",
// "https://www.youtube.com/watch?v=RwuDo5yc9f4&ab_channel=NowDelivery",
const videos = [
    { title: "How to create a percel (কিভাবে একটি পার্সেল অ্যাড করবেন)", url: "https://www.youtube.com/watch?v=zlf82lCr7X0&ab_channel=NowDelivery" },
    { title: "Payment details (পেমেন্ট তথ্য)", url: "https://www.youtube.com/watch?v=dx5-uiyWUjg&ab_channel=NowDelivery" },
    { title: "How to deliver fragile product (কিভাবে একটি ভঙ্গুর প্রোডাক্ট ডেলিভারি করবেন)", url: "https://www.youtube.com/watch?v=5jwQXSbv7AU&ab_channel=NowDelivery" },
    { title: "How to update payment method (কিভাবে পেমেন্ট মেথড পরিবর্তন করবেন)", url: "https://www.youtube.com/watch?v=KXLpzTym0mk&ab_channel=NowDelivery" },
    { title: "How to update your pickup location and number (কিভাবে পিকআপ লোকেশন/নম্বর পরিবর্তন করবেন)", url: "https://www.youtube.com/watch?v=Wneahg99IFY&ab_channel=NowDelivery" },
    { title: "Raise issue (কিভাবে আপনি আপনার সমস্যা তুলে ধরবেন)", url: "https://www.youtube.com/watch?v=znLqrp4-mLU&ab_channel=NowDelivery" }
]

export default function Tutorial() {
    const sm = window.innerWidth < 600


    return (
        <Layout
            className='merchant '
            title="Tutorials"
            noBack
            // loading={isLoading}
            // noTitle
        >
            {/* <Container maxWidth="lg"> */}
            <Grid container spacing={2} >
                {videos?.map((item, i) => {
                    return <Grid key={i} item xs={12} sm={6} md={4}>
                        <Typography style={{ marginBottom: '16px', height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '15px' }} variant="h6">{item.title}</Typography>
                        <ReactPlayer controls url={item.url} width="100%" height={sm ? '200px' : '300px'} />
                    </Grid>
                })}
            </Grid>
            {/* </Container> */}
        </Layout>
    )
}
