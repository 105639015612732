import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Switch } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function UserTable({
  users,
  handleEditUser,
  updateRiderStatus,
}) {
  const classes = useStyles();
  const [admin, setAdmin] = useState();
  const [deliveryRider, setDelivery] = useState();

  useEffect(() => {
    users?.map((user) => {
      setDelivery(user.type === "rider");
      return setAdmin(user.type === "admin");
    });
  }, [users]);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow style={{ backgroundColor: "#999", color: "#fff" }}>
            <TableCell>No.</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>User name</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Status</TableCell>
            {!admin && <TableCell>Edit</TableCell>}
            {!admin && <TableCell>Delete</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((user, i) => {
            return (
              <TableRow key={user.objectId}>
                <TableCell>{i}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>
                  {deliveryRider ? (
                    <Link to={`/rider/${user.objectId}?name=${user.username}`}>
                      {user.username}
                    </Link>
                  ) : (
                    user.username
                  )}
                </TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell>
                  <Switch
                    checked={user.active}
                    color="primary"
                    onChange={(_e, checked) =>
                      updateRiderStatus({
                        active: checked,
                        userId: user.objectId,
                      })
                    }
                  />
                </TableCell>
                {!admin && (
                  <>
                    <TableCell>
                      <Button
                        color="primary"
                        variant="contained"
                        startIcon={<Edit />}
                        onClick={() => handleEditUser(user)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        variant="contained"
                        startIcon={<Delete />}
                        disableElevation
                        disabled={true}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
