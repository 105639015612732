import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useStore } from '../context/Context';
import { instanceOfAxios } from '../helper/axios';
import { errorMessage, ISSUE_LABEL, makeRestHeader } from '../helper/helper';
import useMessage from './useMessage';

interface IssueParam {
	id?: string;
	resolved?: boolean;
	merchantId?: string;
}

const useIssues = (issue?: IssueParam) => {
	const queryClient = useQueryClient();

	const {
		store: { user },
	} = useStore();

	const queryParamStr = `issue-${issue?.id}`;

	const { handleNotification } = useMessage();

	const { data, isLoading, error, isError } = useQuery<Issue>(
		queryParamStr,
		async () => {
			let obj = {};

			if (issue?.id) {
				obj = {
					parcel: {
						__type: 'Pointer',
						className: 'parcel',
						objectId: issue?.id,
					},
				};
			}

			if (typeof issue?.resolved !== 'undefined') {
				obj = {
					resolved: issue.resolved,
				};
			}

			if (issue?.merchantId) {
				obj = {
					resolved: false,
					parcel: {
						$inQuery: {
							where: {
								brand: {
									__type: "Pointer",
									className: "brand",
									objectId: issue?.merchantId
								}
							},
							className: "parcel"
						}
					}
				}
			}

			const keys = [
				'parcel.order_id',
				'parcel.brand',
				'parcel.brand.name',
				'objectId',
				'parcel',
				'label',
				'thread',
				'resolved',
			];

			const res = await instanceOfAxios.get(`/classes/question?order=-createdAt&where=${JSON.stringify(obj)}&include=parcel&keys=${keys}`,
				makeRestHeader(user)
			);
			return res.data;
		}
	);

	const mutateThread = useMutation<any, any, IssueEntity, any>(
		({ thread, resolved, objectId }) => {
			let data = {};

			if (resolved) data = { ...data, resolved };

			data = { ...data, thread };

			return instanceOfAxios.put(
				`/classes/question/${objectId}`,
				data,
				makeRestHeader(user)
			);
		},
		{
			onMutate: async (issue) => {
				await queryClient.cancelQueries(queryParamStr);

				const previousValue = queryClient.getQueryData(queryParamStr);

				queryClient.setQueryData(queryParamStr, (old: any) => {
					return {
						results: old.results.map((result: IssueEntity) => {
							if (issue.objectId === result.objectId) {
								return issue;
							}

							return result;
						}),
					};
				});

				return previousValue;
			},
			onError: (err, variables, previousValue) => {
				handleNotification({
					type: 'error',
					variant: 'standard',
					message: errorMessage({ error: err }),
				});
				return queryClient.setQueryData(queryParamStr, previousValue);
			},
			onSettled: () => {
				queryClient.invalidateQueries(queryParamStr);
			},
		}
	);

	useEffect(() => {
		if (isError && error) {
			handleNotification({
				type: 'error',
				variant: 'standard',
				message: errorMessage({ error: error as any }),
			});
		}
	}, [error, isError, handleNotification]);

	return { isLoading, data, mutateThread, user };
};

export default useIssues;

export interface Issue {
	results: IssueEntity[];
}
export interface IssueEntity {
	objectId: string;
	parcel?: Parcel;
	label: keyof typeof ISSUE_LABEL;
	thread: ThreadEntity[];
	resolved: boolean;
	createdAt: string;
	updatedAt: string;
}
export interface Parcel {
	__type: string;
	className: string;
	objectId: string;
	order_id: string;
	brand: Brand;
}
export interface Brand {
	__type: string;
	className: string;
	objectId: string;
	name: string;
}
export interface ThreadEntity {
	by: string;
	timestamp: string;
	text: string;
}
