// interface Props {
// 	field: any;
// 	setOrderBy: any
// }
import './tableHeader.scss'

const TableHeader = (props: any) => {
	const { field, handleOrderText } = props

	return (
		<div className='commonTable__headerCol' onClick={handleOrderText} >
			{typeof field === 'string' ? <h4>{field}</h4> : field}
		</div>
	);
};

export default TableHeader;
