import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { makeNameStr } from '../../helper/helper';
import useAddress from '../../hooks/useAddress';

type Type = 'input' | 'text' | 'number';

interface Props {
	index?: number;
	error: string | undefined;
	value: Value;
	name: string;
	type?: Type;
	placeholder?: string;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
}

type Value = string | number;

const errorMessage = ({ error, name }: { error: string; name: string }) => {
	const firstChar = error?.split(' ')[0];

	if (firstChar === name) {
		return error?.split(' ').splice(1).join(' ');
	}

	return error;
};

const CellInput: React.FC<Props> = ({
	error,
	value,
	name,
	index,
	placeholder,
	type = 'input',
}) => {
	const { handleAddress, lat, long, meta } = useAddress();

	const [stateValue, setStateValue] = useState(value);

	const { setFieldValue } = useFormikContext();

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const val = e.target.value;
		setStateValue(val);

		const validate = !value && placeholder;

		if (validate) {
			setFieldValue(name, val);
		}
	};

	const handleBlur = (
		e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const val = e.target.value;

		if (type === 'number') {
			setFieldValue(name, Number(val));
			return;
		}

		if (type === 'text') {
			handleAddress(e);
		}

		setFieldValue(name, val);
	};

	useEffect(() => {
		if (lat && long) {
			setFieldValue(makeNameStr({ i: index as number, name: 'lat' }), lat);
			setFieldValue(makeNameStr({ i: index as number, name: 'long' }), long);
		}
	}, [lat, long, index]);

	useEffect(() => {
		if (meta) {
			setFieldValue(makeNameStr({ i: index as number, name: 'meta' }), meta);
		}
	}, [meta]);

	useEffect(() => {
		setStateValue(value);
	}, [value]);

	switch (type) {
		case 'input':
			return (
				<div className='custom'>
					{error && <span>{errorMessage({ name, error })}</span>}
					<input
						placeholder={placeholder}
						onBlur={handleBlur}
						name={name}
						type='text'
						value={stateValue}
						onChange={handleChange}
					/>
				</div>
			);

		case 'number':
			return (
				<div className='custom'>
					{error && <span>{errorMessage({ name, error })}</span>}
					<input
						placeholder={placeholder}
						min={0}
						onBlur={handleBlur}
						name={name}
						type='number'
						value={stateValue}
						onChange={handleChange}
					/>
				</div>
			);

		case 'text':
			return (
				<div className='custom'>
					<textarea
						placeholder={placeholder}
						rows={6}
						onBlur={handleBlur}
						name={name}
						onChange={handleChange}
						value={stateValue}
					/>
					{error && (
						<span className='text'>{errorMessage({ name, error })}</span>
					)}
				</div>
			);
	}
};

export default CellInput;
