import React, { useEffect, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useAuth } from './hooks/useAuth';
import { useStore } from './context/Context';
import Login from './screens/login/Login';
import AccountScreen from './screens/accounts/AccountScreen';
import NewPayment from './screens/newPayment/NewPayment';
import PickupManager from './screens/pickupManagerScreen/PickupManager';
import PaymentDetails from './screens/paymentDetails/PaymentDetails';
import BecomeMerchant from './screens/merchantSignup/BecomeMerchant';
import MerchantOtp from './screens/merchantSignup/MerchantOtp';
import ResetPassword from './screens/resetPassword/ResetPassword';
import CreateParcel from './screens/createParcel/CreateParcel';
import MerchantProfile from './screens/profile/MerchantProfile';
import OrderDetails from './screens/orderDetails/OrderDetails';
import AuthRoute from './components/auth/AuthRoute';
import MerchantRoute from './components/auth/MerchantRoute';
import AccountsRoute from './components/auth/AccountsRoute';
import SignupRoute from './components/auth/SignupRoute';
import PickupRoute from './components/auth/PickupRoute';
import DeliveryFeeChart from './screens/deliveryFeeChart/DeliveryFeeChart';
import AdminRoute from './components/auth/AdminRoute';
import AdminScreen from './screens/admin/AdminScreen';
import AllBrands from './screens/allBrands/AllBrands';
import AllParcels from './screens/allParcels/AllParcels';
import AllSchemas from './screens/allSchemas/AllSchemas';
import UpdateFee from './screens/deliveryFeeChart/UpdateFee';
import BulkScreen from './screens/bulkScreen/BulkScreen';
import ErrorScreen from './screens/errorScreen/ErrorScreen';
import DeliveryRegular from './screens/pickupManager/DeliveryRegular';
import OutsideDhaka from './screens/OutsideDhaka/OutsideDhaka';
import Express from './screens/express/Express';
import Riders from './screens/rider/Riders';
import RiderProfile from './screens/rider/RiderProfile';
import Profile from './screens/profile/Profile';
import Summary from './screens/accounts/Summary';
import WaitingScreen from './screens/waitingScreen/WaitingScreen';
import MerchantAllParcels from './screens/merchantOrders/MerchantAllParcels';
import MerchantRejectedParcels from './screens/merchantOrders/MerchantRejectedParcels';
import AdminRejectedParcels from './screens/admin/AdminRejectedParcels';
import PickupFailOrAssigned from './screens/pickupManager/PickupFailOrAssigned';
import PickupAssignScreen from './screens/pickupManager/PickupAssignScreen';
import Retarget from './screens/retarget/Retarget';
import MerchantPickup from './screens/merchantPickup/MerchantPickup';
import Issues from './screens/issues/Issues';
import AllIssues from './screens/issues/AllIssues';
import MerAllIssue from './screens/issues/MerAllIssue';
import { LinearProgress } from '@material-ui/core';
import ReturnParcelAssign from './screens/returnParcelAssign/ReturnParcelAssign';
import NoticeBoard from './screens/noticeBoard/NoticeBoard';
import HandleUser from './screens/manageUser/HandleUser'
import Tutorial from './screens/Turorial/Tutorial';
import Settings from './screens/Settings';

export const STATUS_REJECTED = 'rejected';

const LazyMerchantDashboard = React.lazy(
	() => import('./screens/merchant/Merchant')
);

const LazyMerchantPaymentDetails = React.lazy(
	() => import('./screens/paymentDetails/merchant/MerchantPaymentDetails')
);

const LazyPaymentHistory = React.lazy(
	() => import('./screens/paymentHistory/PaymentHistory')
);

const AppRoutes = () => {

	const {store: { user }} = useStore();
	const { refetchBrand, setId } = useAuth();

	useEffect(() => {
		const isMerchant = user?.user.type === 'merchant';

		if (user && isMerchant) {
			setId(user.user.objectId);
			refetchBrand({ objectId: user.user.objectId });
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, refetchBrand]);

	return (
		<Suspense fallback={<div>Loading...</div>}>
			<Switch>
				{/* common */}
				<AuthRoute exact path='/order/details/:id' component={OrderDetails} />

				{/* admin */}
				<AdminRoute path='/admin' exact component={AdminScreen} />
				<AdminRoute path='/delivery-fee-chart/update/:id' exact component={UpdateFee} />
				<AdminRoute path='/delivery-fee-chart' exact component={DeliveryFeeChart} />
				<AdminRoute path='/admin/schemas' exact component={AllSchemas} />
				<AdminRoute path='/all/issues' exact component={AllIssues} />
				<AdminRoute path='/admin/retarget' exact component={Retarget} />
				<AdminRoute path='/admin/brands' exact component={AllBrands} />
				<AdminRoute path='/admin/all-orders' exact component={AllParcels} />
				<AdminRoute path={`/admin/all-orders/rejected`} exact component={AdminRejectedParcels} />
				<AdminRoute path='/admin/notice-board' exact component={NoticeBoard} />
				<AdminRoute path='/profile' exact component={Profile} />

				{/* merchant AuthRoutes */}
				<MerchantRoute exact path='/issues/:parcelId' component={Issues} />
				<MerchantRoute exact path='/merchant/tutorial' component={Tutorial} />
				<MerchantRoute exact path='/merchant/all-issues' component={MerAllIssue} />{/* ALL ISSUES */}
				<MerchantRoute exact path='/merchant/update-parcel' component={CreateParcel} />
				<MerchantRoute exact path='/merchant/pickups' component={MerchantPickup} />
				<MerchantRoute exact path='/merchant/create-parcel/bulk' component={BulkScreen} />
				<MerchantRoute exact path='/merchant/payment-details' component={LazyMerchantPaymentDetails} />
				<MerchantRoute exact path='/merchant/profile/:id' component={MerchantProfile} />
				<MerchantRoute exact path='/merchant/create-parcel' component={CreateParcel} />
				<MerchantRoute exact path='/merchant/all-orders' component={MerchantAllParcels} />
				<MerchantRoute exact path={`/merchant/all-orders/rejected`} component={MerchantRejectedParcels} />

				{/* pickup-manager AuthRoutes */}
				<PickupRoute path='/riders' component={Riders} exact />
				<PickupRoute path='/rider/:id' component={RiderProfile} exact />
				<PickupRoute exact path='/pickup-manager/assign/pickup-riders' component={PickupAssignScreen} />
				<PickupRoute exact path='/pickup-manager/assign/return-parcels' component={ReturnParcelAssign} />
				<PickupRoute exact path='/pickup-manager/assign/pickup-riders/fail-or-assigned' component={PickupFailOrAssigned} />
				<PickupRoute exact path='/pickup-manager/assign/delivery-riders' component={DeliveryRegular} />
				<PickupRoute exact path='/pickup-manager/outside-dhaka' component={OutsideDhaka} />
				<PickupRoute exact path='/pickup-manager/express' component={Express} />
				<PickupRoute exact path='/pickup-manager/waiting' component={WaitingScreen} />
				<PickupRoute path='/pickup-manager' component={PickupManager} exact />
				<PickupRoute path='/manage-user' exact component={HandleUser} />
				<PickupRoute path='/settings' exact component={Settings} />

				{/* accounts AuthRoutes */}
				<AccountsRoute exact path='/accounts/summary' component={Summary} />
				<AccountsRoute exact path='/accounts/payment-details/:brandId/new-payment' component={NewPayment} />
				<AccountsRoute exact path='/accounts/payment-details/:brandId' component={PaymentDetails} />
				<AccountsRoute path='/accounts' component={AccountScreen} exact />

				{/* login */}
				<Route path='/login' component={Login} exact />

				{/* merchant-login-signup */}
				<Route path='/merchant-signup' component={BecomeMerchant} exact />
				<SignupRoute path='/reset-password' component={ResetPassword} exact />
				<SignupRoute path='/merchant-otp' component={MerchantOtp} exact />

				{/* suspense */}

				{/* redirects */}
				<Redirect path='/' to='/login' exact />

				<React.Suspense fallback={<LinearProgress className='progress' />}>
					<MerchantRoute path='/merchant/:id' component={LazyMerchantDashboard} exact />
					<AccountsRoute exact path='/accounts/payment-details/:brandId/history' component={LazyPaymentHistory} />
					<AccountsRoute exact path='/accounts/payment-details' component={LazyMerchantPaymentDetails} />
				</React.Suspense>

				{/* error page */}
				<Route component={ErrorScreen} />
			</Switch>
		</Suspense>
	);
};

export default AppRoutes;
