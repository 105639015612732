import React, { useMemo } from "react";

import "./delivery.req.table.scss";
import DeliveryReqRow from "./DeliveryReqRow";
import TableHeader from "../TableCommon/TableHeader";
import { Header } from "../pickupReqTable/pickup.req.table";
import { DeliveryReqs } from "../../context/contextTypes";
import { DeliveryReqSelect, DelType } from "../deliveryCommon/DeliveryCommon";

interface Props {
  isSetAll: boolean;

  deliveryType: DelType;
  handleSelect: ({ type, index }: DeliveryReqSelect) => void;
  handleCheck: () => void;
  deliveryReqs: DeliveryReqs[] | null;
}

const DeliveryReqTable: React.FC<Props> = ({
  isSetAll,
  deliveryType,
  handleCheck,
  deliveryReqs,
  handleSelect,
}) => {
  const headers: Header[] = useMemo(() => {
    switch (deliveryType) {
      case "waiting":
        return [
          {
            field: (
              <input
                type="checkbox"
                name="check-all"
                checked={isSetAll}
                onChange={handleCheck}
              />
            ),
          },
          { field: "Date" },
          { field: "Brand" },
          { field: "Area" },
          { field: "Customer Info" },
        ];
    }

    return [
      {
        field: (
          <input
            type="checkbox"
            name="check-all"
            checked={isSetAll}
            onChange={handleCheck}
          />
        ),
      },
      { field: "Created" },
      { field: "pickedup" },
      { field: "Brand" },
      { field: "Area" },
      { field: "Customer Info" },
      { field: "Parcel Info" },
      { field: "Delivery Rider" },
      { field: "Notes" },
      { field: "Status" },
    ];
  }, [isSetAll, deliveryType, handleCheck]);

  return (
    <div className="deliveryReqTable ">
      <div
        className="deliveryReqTable__header"
        style={{ gridTemplateColumns: `repeat(${headers.length}, 1fr)` }}
      >
        {headers.map(({ field }, i) => (
          <TableHeader field={field} key={i} />
        ))}
      </div>
      <div className="deliveryReqTable__body ">
        {deliveryReqs?.map((reqs, i) => (
          <DeliveryReqRow
            index={i}
            handleSelect={handleSelect}
            deliveryType={deliveryType}
            key={reqs.node.objectId}
            reqs={reqs}
            length={headers.length}
          />
        ))}
      </div>
    </div>
  );
};

export default DeliveryReqTable;
