import React from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Fade } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import Close from '@material-ui/icons/Close';

import MerchantLayout from '../layout/MerchantLayout';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Fade ref={ref} {...props} />;
});

interface Props {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setFormData: any
}

const FormDalog = (props:any) => {
	const { open, setOpen, setFormData, children } = props
	const handleClose = () => {
		setOpen(false);
		setFormData(null)
	};

	return (
		<Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
			<AppBar style={{ position: 'relative' }}>
				<Toolbar>
					<IconButton
						edge='start'
						color='inherit'
						onClick={handleClose}
						aria-label='close'
					>
						<Close />
					</IconButton>
				</Toolbar>
			</AppBar>

			<MerchantLayout className='container store'>{children}</MerchantLayout>
		</Dialog>
	);
};

export default FormDalog;
