import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { GET_ALL_RIDERS, GET_RIDERS } from '../graphql/queries/riders';
import useMessage from './useMessage';
import { Riders } from '../context/contextTypes';

export type RiderType = 'pickup-rider' | 'rider';

interface Options {
	all?: boolean;
}

const useRiders = (type: RiderType, options?: Options) => {
	const [active, setActive] = useState(true);

	const [ridersData, setRidersData] = useState<Riders[]>([]);

	const { handleNotification } = useMessage();

	const toGetAll = !!options?.all;

	const {
		loading: ridersLoading,
		data,
		error: ridersError,
		refetch,
	} = useQuery(toGetAll ? GET_ALL_RIDERS : GET_RIDERS, {
		variables: {
			type,
			active,
		},
	});

	console.log({data})

	useEffect(() => {
		if (data)
			setRidersData(
				data['users']['edges'].map((rider: { node: Riders }) => rider.node)
			);
	}, [data]);

	useEffect(() => {
		refetch();
	}, [active, refetch]);

	useEffect(() => {
		if (ridersError) {
			handleNotification({
				variant: 'standard',
				message: ridersError.message,
				type: 'error',
			});
		}
	}, [ridersError, handleNotification]);

	return { ridersLoading, ridersData, setActive, active, refetch };
};

export default useRiders;
