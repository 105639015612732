import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Timeline as TimeProps } from '../../context/contextTypes';
import { formatRelative } from 'date-fns/esm';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: '16px',
		boxShadow: 'none',
		backgroundColor: '#e6f7f0',
	},
	secondaryTail: {
		backgroundColor: theme.palette.secondary.main,
	},
}));

interface Props {
	timeline: TimeProps[] | null | undefined;
}

const TimelineCom: React.FC<Props> = ({ timeline }) => {
	const classes = useStyles();
	return (
		<Timeline align='left'>
			{/*  */}
			{timeline
				?.slice()
				.reverse()
				.map((time, i) => (
					<TimelineItem key={i} style={{ justifyContent: 'flex-start' }}>
						<TimelineSeparator>
							<TimelineDot
								style={{ backgroundColor: i === 0 ? '#22c46b' : 'black' }}
							>
								<FiberManualRecordOutlinedIcon />
							</TimelineDot>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent>
							<Paper elevation={3} className={classes.paper}>
								<Typography variant='subtitle1'>{time.value.text}</Typography>
								<Typography
									variant='subtitle2'
									style={{ color: 'darkcyan', fontWeight: 800 }}
								>
									{formatRelative(new Date(time.value.date.iso), new Date())}
								</Typography>
							</Paper>
						</TimelineContent>
					</TimelineItem>
				))}
			{/*  */}
		</Timeline>
	);
};

export default TimelineCom;
