import React from 'react';
import Avatar from '@material-ui/core/Avatar';

interface Props {
	logo: string | null;
	name: string;
	variant?: 'circle' | 'circular' | 'rounded' | 'square';
	style?: React.CSSProperties;
}

const CustomAvatar: React.FC<Props> = ({ logo, name, variant, style }) => {
	return (
		<Avatar src={logo ? logo : undefined} variant={variant} style={style}>
			{name.split('')[0]}
		</Avatar>
	);
};

export default CustomAvatar;
