import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from "@apollo/client";
import { useState } from "react";
import { useStore } from "../context/Context";
import { SET_LOCATION } from "../graphql/mutations/delivery";
import { instanceOfAxios } from "../helper/axios";
import { errorMessage } from "../helper/helper";

import useMessage from "./useMessage";

interface Location {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  location: string;
  id: string;
  refetchPage: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
}

interface Assign {
  objectIds: string[];
  rider: string | null;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
  reset: () => void;
  funcName: string;
}

interface StatusChange {
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
  objectIds: string[];
  status: "picked_up" | "rejected";
  reset: () => void;
}

interface Commnet {
  parceId: string;
  text: string;
}

const useDelivery = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [setLoc] = useMutation(SET_LOCATION);

  const { handleNotification } = useMessage();

  const {
    store: { user },
  } = useStore();

  // assign rider

  const handleAssign = async ({
    rider,
    objectIds,
    refetch,
    reset,
    funcName,
  }: Assign) => {
    setIsLoading(true);

    const headers = {
      "X-Parse-Session-Token": user?.sessionToken,
    };

    // assignReturnRider

    try {
      await instanceOfAxios.post(
        `/functions/${funcName}`,
        {
          objectIds,
          rider,
        },
        {
          headers,
        }
      );

      reset();

      await refetch();

      handleNotification({
        variant: "standard",
        message: "successfully assigned rider",
        type: "success",
      });

      setIsLoading(false);
    } catch (error: any) {
      handleNotification({
        variant: "standard",
        message: errorMessage({ error }),
        type: "error",
      });
    }

    setIsLoading(false);
  };

  // set location

  const handleLoaction = async ({
    setOpen,
    location,
    refetchPage,
    id,
  }: Location) => {
    if (!location.match(/,/gi)) {
      handleNotification({
        variant: "standard",
        type: "warning",
        message: "Please give a valid location",
      });
      return;
    }

    setIsLoading(true);

    const [lat, long] = location.split(",");

    try {
      await setLoc({
        variables: {
          id,
          lat: Number(lat),
          long: Number(long),
        },
      });

      setOpen(false);

      await refetchPage();

      handleNotification({
        variant: "standard",
        type: "success",
        message: "location set successfully.",
      });

      setIsLoading(false);
    } catch ({ graphQLErrors, networkError, message }) {
      handleNotification({
        variant: "standard",
        type: "error",
        message: `${message}`,
      });
      console.log(graphQLErrors);
      console.log(networkError);

      setIsLoading(false);
    }
  };

  // update commnet

  const handleComment = async ({ parceId, text }: Commnet) => {
    setIsLoading(true);
    const headers = {
      "X-Parse-Session-Token": user?.sessionToken,
    };

    const data = {
      notes: {
        __op: "Add",
        objects: [{ by: `${user?.user.username}|${user?.user.type}`, text }],
      },
    };

    try {
      await instanceOfAxios.put(`/classes/parcel/${parceId}`, data, {
        headers,
      });

      handleNotification({
        variant: "standard",
        message: "successfully Added note",
        type: "success",
      });
      setIsLoading(false);
    } catch (error: any) {
      handleNotification({
        variant: "standard",
        message: errorMessage({ error }),
        type: "error",
      });
      setIsLoading(false);
    }
  };

  const handleStatusChange = async ({
    objectIds,
    refetch,
    status,
    reset,
  }: StatusChange) => {
    setIsLoading(true);

    const headers = {
      "X-Parse-Session-Token": user?.sessionToken,
    };

    const data = {
      objectIds,
      status,
    };

    try {
      await instanceOfAxios.post(`/functions/bulkStatusChange`, data, {
        headers,
      });

      reset();

      await refetch();

      handleNotification({
        variant: "standard",
        message: `successfully chaged status to ${status}`,
        type: "success",
      });

      setIsLoading(false);
    } catch (error: any) {
      handleNotification({
        variant: "standard",
        message: errorMessage({ error }),
        type: "error",
      });
      setIsLoading(false);
    }
  };

  return {
    handleLoaction,
    handleComment,
    handleAssign,
    isLoading,
    setIsLoading,
    handleStatusChange,
  };
};

export default useDelivery;
