import { useEffect, useRef, useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import localforage from "localforage";
import * as yup from "yup";

import "./bulkScreen.scss";
import CellInput from "../../components/cell/CellInput";
import { getStringErr, makeNameStr } from "../../helper/helper";
import Checkbox from "@material-ui/core/Checkbox";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import Select from "react-select";
import { cities } from "../../docs/city";
import _ from "lodash";
import { Helmet } from "react-helmet";
import Header from "../../components/header/Header";
import useParcel, { Parcel } from "../../hooks/useParcel";
import { useStore } from "../../context/Context";
import useMessage from "../../hooks/useMessage";
import AsyncDeliveryCharge from "../../components/cell/AsyncDeliveryCharge";
import { instanceOfAxios } from "../../helper/axios";

export const COLUMNS = [
  { accessor: "no", Header: "Serial" },
  { accessor: "merchantOrderId", Header: " Order Id" },
  { accessor: "recipentName", Header: " Name" },
  { accessor: "recipentPhone", Header: "Phone" },
  { accessor: "recipientAddress", Header: "Address" },
  { accessor: "outside", Header: "Outside Dhaka" },
  { accessor: "areaSelect", Header: "Area" },
  { accessor: "weightOption", Header: "Weight (kg)" },
  { accessor: "amount", Header: "Amount To Collect" },
  { accessor: "delType", Header: "Delivery Type" },
  { accessor: "parcel", Header: "Parcel Type" },
  { accessor: "paymentType", Header: "Payment Type" },
  { accessor: "estimated", Header: "Estimated Cost" },
  { accessor: "note", Header: "Note" },
  { accessor: "delete", Header: "Delete" },
];

export interface BulkData {
  no: number;
  merchantOrderId: string;
  recipentName: string;
  recipentPhone: string;
  recipentPhoneName: string;
  recipientAddress: string;
  recipientAddressName: string;
  productDesc: string;
  productDescName: string;
  weightOption: number;
  delType: string;
  parcelType: string;
  paymentOption: string;
  totalPrice: number;
  amountCollect: string;
  note: string;
  areaSelect: string;
  outside: boolean;
  zone: string;
  lat: null | number;
  long: null | number;
  meta: any | null;
}

const validationScheme = yup.object().shape({
  data: yup.array().of(
    yup.object().shape({
      merchantOrderId: yup.string().required("please fill up"),
      recipentName: yup.string().required("please fill up"),
      recipentPhone: yup
        .string()
        .matches(/^(?:\+88|01)?(?:\d{11}|\d{13})$/, "please try (017...)")
        .required("please fill up"),
      recipientAddress: yup.string().required("please fill up"),
      productDesc: yup.string(),
      amountCollect: yup.string().required("please fill up"),
      parcelType: yup.string().required("please fill up"),
      delType: yup.string().required("please fill up"),
      zone: yup.string().required("please fill up"),
      weightOption: yup.number().min(1).required("please fill up"),
      areaSelect: yup.string().required("please fill up"),
      note: yup.string(),
    })
  ),
});

let data: BulkData = {
  no: 1,
  merchantOrderId: "",
  recipentName: "",
  recipentPhone: "",
  recipentPhoneName: "",
  recipientAddress: "",
  recipientAddressName: "",
  productDesc: "",
  productDescName: "",
  weightOption: 0,
  delType: "",
  parcelType: "parcel",
  paymentOption: "cash_on_delivery",
  totalPrice: 0,
  amountCollect: "",
  note: "",
  areaSelect: "",
  outside: false,
  zone: "",
  lat: null,
  long: null,
  meta: null,
};

export interface SelectLabel {
  label: string;
  value: string;
}

const BulkScreen = () => {
  const [initValues, setInitValues] = useState<{ data: BulkData[] }>({
    data: [data],
  });

  const {
    store: { user, store, brand },
    dispatch,
  } = useStore();

  const {
    createBulk,
    loading,
    slotSelect,
    setSlotSelect,
    timeMaker,
    checkTypeDisabled,
  } = useParcel();

  useEffect(() => {
    localforage.getItem("bulk").then((bulkData: any) => {
      if (bulkData) {
        setInitValues(bulkData);
      }
    });
    /* eslint-disable */
  }, [localforage]);

  // const [_areaSelect, setAreaSelect] = useState<SelectLabel | null>(null);
  // const [_zoneSelect, setZoneSelect] = useState<SelectLabel | null>(null);
  const { handleNotification } = useMessage();
  const addRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const removeRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const exRef = useRef() as React.MutableRefObject<any>;

  const [deliverySettings, setDeliverySettings] = useState<any>({});
  useEffect(() => {
    (async () => {
      try {
        const { data } = await instanceOfAxios.get(
          `/classes/config?where={"key":"deliverySettings"}`
        );
        console.log({ data });
        setDeliverySettings(data.results[0].value);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const isWhitelisted = localStorage.getItem("brand")
    ? JSON.parse(localStorage.getItem("brand") as any).node.whitelisted
    : false;

  return (
    <div title="Bulk Parcel" className="bulk">
      <Header />
      <Helmet>
        <title>Now | Bulk Parcel</title>
      </Helmet>
      <header>
        <Grid container alignItems="center" spacing={4}>
          <Grid item xs md={4}>
            <h2>Bulk Parcel</h2>
          </Grid>
          <Grid item xs md={4}>
            <div className="select">
              <Select
                ref={exRef}
                onChange={(value) => {
                  setSlotSelect(value);
                }}
                isClearable
                isSearchable
                placeholder="Pickup Slot"
                value={slotSelect}
                options={timeMaker}
              />
            </div>
          </Grid>
          <Grid item xs md={4}>
            <Select
              onChange={(value) => {
                dispatch({
                  type: "ADD_STORE",
                  payload: value ? value.value : null,
                });
              }}
              isSearchable
              placeholder="Pickup point"
              value={
                store
                  ? {
                      label: `${store.value.name} (${store.value.address.full_address})`,
                      value: store,
                    }
                  : null
              }
              options={brand?.node.pickup_locations?.map(
                ({ value, __typename }) => {
                  return {
                    value: {
                      value,
                      __typename,
                    },
                    label: `${value.name} (${value.address.full_address})`,
                  };
                }
              )}
            />
          </Grid>
        </Grid>
      </header>
      <div className="bulk__container ">
        <div className="bulk__table">
          <Formik
            enableReinitialize
            initialValues={initValues}
            onSubmit={(values) => {
              if (slotSelect && store) {
                createBulk(
                  values.data.map((field) => {
                    const zone = field.outside ? field.zone : "dhaka";

                    const pickup_cash =
                      field.paymentOption === "cash_on_delivery" ? true : false;

                    const is_express = field.outside
                      ? false
                      : field.delType === "express"
                      ? true
                      : false;

                    const delivery_type = field.outside
                      ? "outside_dhaka"
                      : field.delType;

                    let data: Parcel = {
                      address: field.recipientAddress,
                      amount: Number(field.amountCollect),
                      area: field.areaSelect,
                      zone,
                      customer_name: field.recipentName,
                      customer_phone: field.recipentPhone,
                      delivery_type,
                      description: field.productDesc,
                      is_express,
                      moid: field.merchantOrderId,
                      parcel_type: field.parcelType,
                      pickup_cash,
                      requestedSlot: slotSelect.value.toISOString(),
                      weight: field.weightOption,
                      pickup_location: store.value._id,
                    };

                    if (field.note) {
                      data = {
                        ...data,
                        notes: [
                          {
                            by: `${user?.user.username} (${user?.user.type})`,
                            text: field.note,
                          },
                        ],
                      };
                    }

                    if (field.lat && field.long) {
                      data = {
                        ...data,
                        geolocation: {
                          __type: "GeoPoint",
                          latitude: field.lat,
                          longitude: field.long,
                        },
                      };
                    }

                    if (field.meta) {
                      data = {
                        ...data,
                        meta: field.meta,
                      };
                    }

                    return data;
                  })
                ).then(() => {
                  localforage.removeItem("bulk");
                });

                return;
              }

              exRef.current.focus();

              handleNotification({
                variant: "standard",
                message: "please select pickup slot",
                type: "warning",
              });
            }}
            validationSchema={validationScheme}
          >
            {({ handleSubmit, values, errors, setFieldValue, isValid }) => (
              <Form onSubmit={handleSubmit}>
                <div className="main">
                  <table className="rounded">
                    <thead>
                      <tr>
                        {COLUMNS.map((col, i) => (
                          <th
                            className={
                              col.accessor +
                              ` ${(i + 1) % 2 === 0 && "alternate"}`
                            }
                            key={col.accessor + col.Header}
                          >
                            {col.Header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray
                        name="data"
                        render={({ insert: _i, remove, push }) => (
                          <>
                            {values.data.map((d, i) => {
                              const isEven = (i + 1) % 2 === 0;

                              return (
                                <tr
                                  className={`row  field ${
                                    isEven && "alternate"
                                  }`}
                                  key={i / 2}
                                >
                                  <td className="no">
                                    <p>{i + 1}</p>
                                  </td>
                                  <td className="moid">
                                    <CellInput
                                      value={d.merchantOrderId}
                                      name={makeNameStr({
                                        i,
                                        name: "merchantOrderId",
                                      })}
                                      setFieldValue={setFieldValue}
                                      error={getStringErr({
                                        errors,
                                        i,
                                        name: "merchantOrderId",
                                      })}
                                    />
                                  </td>
                                  <td className="name">
                                    <CellInput
                                      value={d.recipentName}
                                      name={makeNameStr({
                                        i,
                                        name: "recipentName",
                                      })}
                                      setFieldValue={setFieldValue}
                                      error={getStringErr({
                                        errors,
                                        i,
                                        name: "recipentName",
                                      })}
                                    />
                                  </td>
                                  <td className="phone">
                                    <CellInput
                                      value={d.recipentPhone}
                                      name={makeNameStr({
                                        i,
                                        name: "recipentPhone",
                                      })}
                                      setFieldValue={setFieldValue}
                                      error={getStringErr({
                                        i,
                                        errors,
                                        name: "recipentPhone",
                                      })}
                                    />
                                  </td>
                                  <td className="address">
                                    <CellInput
                                      placeholder={
                                        "Flat, Apartment name, Road, Block, Area"
                                      }
                                      index={i}
                                      type="text"
                                      value={d.recipientAddress}
                                      name={makeNameStr({
                                        i,
                                        name: "recipientAddress",
                                      })}
                                      setFieldValue={setFieldValue}
                                      error={getStringErr({
                                        i,
                                        errors,
                                        name: "recipientAddress",
                                      })}
                                    />
                                  </td>
                                  <td className="outside">
                                    <Checkbox
                                      disabled={checkTypeDisabled.outside_dhaka}
                                      name={makeNameStr({
                                        i,
                                        name: "outside",
                                      })}
                                      value={d.outside}
                                      onChange={(_e, cheked) => {
                                        setFieldValue(
                                          makeNameStr({
                                            i,
                                            name: "zone",
                                          }),
                                          ""
                                        );
                                        setFieldValue(
                                          makeNameStr({
                                            i,
                                            name: "areaSelect",
                                          }),
                                          ""
                                        );

                                        setFieldValue(
                                          makeNameStr({
                                            i,
                                            name: "outside",
                                          }),
                                          cheked
                                        );
                                      }}
                                      checked={d.outside}
                                      color="primary"
                                    />
                                  </td>
                                  <td className="area">
                                    {d.outside ? (
                                      <>
                                        <div className="select">
                                          <Select
                                            onChange={(val) => {
                                              // setZoneSelect(val);

                                              setFieldValue(
                                                makeNameStr({
                                                  i,
                                                  name: "zone",
                                                }),
                                                val?.value
                                              );
                                            }}
                                            value={{
                                              label: d.zone,
                                              value: d.zone,
                                            }}
                                            isClearable
                                            isSearchable
                                            placeholder="City"
                                            options={_.uniqBy(
                                              cities
                                                .filter(
                                                  (city) =>
                                                    city.district !== "Dhaka"
                                                )
                                                .map((value) => {
                                                  return {
                                                    label: value.district,
                                                    value: value.district,
                                                  };
                                                }),
                                              "label"
                                            )}
                                          />
                                        </div>
                                        <div
                                          className="select"
                                          style={{ marginTop: ".5rem" }}
                                        >
                                          <Select
                                            onChange={(val) => {
                                              setFieldValue(
                                                makeNameStr({
                                                  i,
                                                  name: "areaSelect",
                                                }),
                                                val?.value
                                              );

                                              // setAreaSelect(val);
                                            }}
                                            isClearable
                                            isSearchable
                                            placeholder="Zone"
                                            options={cities
                                              .filter((item) => {
                                                if (d.zone) {
                                                  return (
                                                    d.zone === item.district
                                                  );
                                                }

                                                return d.zone !== "Dhaka";
                                              })
                                              .map((value) => {
                                                return {
                                                  label: value.area,
                                                  value: value.area,
                                                };
                                              })}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <div className="select">
                                        <Select
                                          onChange={(val) => {
                                            // setZoneSelect(val);

                                            setFieldValue(
                                              makeNameStr({
                                                i,
                                                name: "areaSelect",
                                              }),
                                              val?.value
                                            );

                                            setFieldValue(
                                              makeNameStr({
                                                i,
                                                name: "zone",
                                              }),
                                              "Dhaka"
                                            );
                                          }}
                                          value={{
                                            label: d.areaSelect,
                                            value: d.areaSelect,
                                          }}
                                          isClearable
                                          isSearchable
                                          placeholder="Area"
                                          options={cities
                                            .filter(
                                              (city) =>
                                                city.district === "Dhaka"
                                            )
                                            .map((value) => {
                                              return {
                                                label: value.area,
                                                value: value.area,
                                              };
                                            })}
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td className="weight">
                                    <div className="select">
                                      <Select
                                        onChange={(val) => {
                                          setFieldValue(
                                            makeNameStr({
                                              i,
                                              name: "weightOption",
                                            }),
                                            val?.value
                                          );
                                        }}
                                        value={
                                          d.weightOption
                                            ? {
                                                label: `${d.weightOption - 1}-${
                                                  d.weightOption
                                                }`,
                                                value: d.weightOption,
                                              }
                                            : null
                                        }
                                        isSearchable
                                        placeholder="weight"
                                        options={[
                                          { label: "0-1", value: 1 },
                                          { label: "1-2", value: 2 },
                                          { label: "2-3", value: 3 },
                                          { label: "3-4", value: 4 },
                                          { label: "4-5", value: 5 },
                                          { label: "5-6", value: 6 },
                                          { label: "6-7", value: 7 },
                                          { label: "7-8", value: 8 },
                                          { label: "8-9", value: 9 },
                                          { label: "9-10", value: 10 },
                                        ]}
                                      />
                                    </div>
                                  </td>
                                  <td className="amount">
                                    <CellInput
                                      type="number"
                                      value={d.amountCollect}
                                      name={makeNameStr({
                                        i,
                                        name: "amountCollect",
                                      })}
                                      setFieldValue={setFieldValue}
                                      error={getStringErr({
                                        i,
                                        errors,
                                        name: "amountCollect",
                                      })}
                                    />
                                  </td>
                                  <td className="delivery">
                                    <FormControl
                                      disabled={d.outside}
                                      component="fieldset"
                                    >
                                      <RadioGroup
                                        aria-label="delType"
                                        name="delType"
                                        value={d.delType}
                                        onChange={(_e, value) => {
                                          setFieldValue(
                                            makeNameStr({
                                              i,
                                              name: "delType",
                                            }),
                                            value
                                          );
                                        }}
                                      >
                                        <Grid
                                          container
                                          direction="column"
                                          justify="flex-start"
                                        >
                                          {/* <FormControlLabel
																						style={{
																							display: checkTypeDisabled.express
																								? 'none'
																								: undefined,
																						}}
																						value='express'
																						control={<Radio color='primary' />}
																						label='Express'
																					/> */}
                                          <FormControlLabel
                                            style={{
                                              display:
                                                checkTypeDisabled.dhaka_24hrs
                                                  ? "none"
                                                  : undefined,
                                            }}
                                            value="dhaka_24hrs"
                                            control={<Radio color="primary" />}
                                            label="24 hr"
                                            disabled={
                                              !isWhitelisted &&
                                              !deliverySettings?.["24h"]
                                            }
                                          />
                                          <FormControlLabel
                                            style={{
                                              display: checkTypeDisabled.regular
                                                ? "none"
                                                : undefined,
                                            }}
                                            value="regular"
                                            control={<Radio color="primary" />}
                                            label="48 hr"
                                            disabled={
                                              !isWhitelisted &&
                                              !deliverySettings?.["48h"]
                                            }
                                          />
                                        </Grid>
                                      </RadioGroup>
                                    </FormControl>
                                  </td>
                                  <td className="parcel">
                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        aria-label="parcelType"
                                        name="parcelType"
                                        value={d.parcelType}
                                        onChange={(_e, value) => {
                                          setFieldValue(
                                            makeNameStr({
                                              i,
                                              name: "parcelType",
                                            }),
                                            value
                                          );
                                        }}
                                      >
                                        <Grid
                                          container
                                          direction="column"
                                          justify="flex-start"
                                        >
                                          <FormControlLabel
                                            value="parcel"
                                            control={<Radio color="primary" />}
                                            label="Regular"
                                          />
                                          <FormControlLabel
                                            value="fragile"
                                            control={<Radio color="primary" />}
                                            label="Fragile"
                                            disabled={d.outside}
                                          />
                                        </Grid>
                                      </RadioGroup>
                                    </FormControl>
                                  </td>

                                  <td className="payment">
                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        aria-label="paymentOpt"
                                        name="paymentOpt"
                                        value={d.paymentOption}
                                        onChange={(_e, value) => {
                                          setFieldValue(
                                            makeNameStr({
                                              i,
                                              name: "paymentOption",
                                            }),
                                            value
                                          );
                                        }}
                                      >
                                        <Grid
                                          container
                                          direction="column"
                                          justify="flex-start"
                                        >
                                          <FormControlLabel
                                            value="cash_on_delivery"
                                            control={<Radio color="primary" />}
                                            label="Cash on delivery"
                                          />
                                          <FormControlLabel
                                            value="paid_via_online"
                                            control={<Radio color="primary" />}
                                            label="Already Paid"
                                          />
                                        </Grid>
                                      </RadioGroup>
                                    </FormControl>
                                  </td>

                                  <td className="estimated">
                                    <AsyncDeliveryCharge
                                      price={d.totalPrice}
                                      index={i}
                                    />
                                  </td>
                                  <td className="note">
                                    <CellInput
                                      type="text"
                                      value={d.note}
                                      name={makeNameStr({
                                        i,
                                        name: "note",
                                      })}
                                      setFieldValue={setFieldValue}
                                      error={getStringErr({
                                        i,
                                        errors,
                                        name: "note",
                                      })}
                                    />
                                  </td>
                                  {i !== 0 ? (
                                    <td className="delete">
                                      <Button
                                        ref={removeRef}
                                        fullWidth
                                        color="primary"
                                        type="button"
                                        variant="contained"
                                        onClick={() => remove(i)}
                                      >
                                        Remove Parcel
                                      </Button>
                                    </td>
                                  ) : (
                                    <td
                                      style={{
                                        backgroundColor: "#e0dddd",
                                      }}
                                    ></td>
                                  )}
                                </tr>
                              );
                            })}
                            <tr>
                              <td>
                                <Button
                                  ref={addRef}
                                  fullWidth
                                  type="button"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => push(data)}
                                >
                                  Add Parcel
                                </Button>
                              </td>
                              {Array(COLUMNS.length - 1)
                                .fill(0)
                                .map((_x, i) => (
                                  <td key={i} className="cross">
                                    x
                                  </td>
                                ))}
                            </tr>
                          </>
                        )}
                      />
                    </tbody>
                  </table>
                </div>
                <Grid
                  container
                  alignItems="center"
                  style={{ marginTop: "1rem" }}
                  spacing={4}
                >
                  <Grid item xs>
                    <Typography
                      style={{ lineHeight: "3rem" }}
                      variant="h6"
                      color="textPrimary"
                      align="center"
                    >
                      Note: ** To add more orders click on "{" "}
                      <Button
                        onClick={() => {
                          addRef.current.focus();
                        }}
                        variant="text"
                        style={{ backgroundColor: "black", color: "#fff" }}
                      >
                        add parcel
                      </Button>{" "}
                      " and to cancel it click on "{" "}
                      <Button
                        onClick={() => {
                          removeRef.current?.focus();
                        }}
                        variant="text"
                        style={{ backgroundColor: "black", color: "#fff" }}
                      >
                        remove parcel
                      </Button>{" "}
                      " **
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Button
                      fullWidth
                      disabled={!isValid || loading}
                      size="large"
                      style={{ marginTop: 10 }}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Confirm Parcels
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default BulkScreen;
