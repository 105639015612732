import DeliveryAssign from '../../components/deliveryCommon/DeliveryCommon';

const OutsideDhaka = () => {
	return (
		<DeliveryAssign
			deliveryType='outside_dhaka'
			title='Outside Delivery Assign'
		/>
	);
};

export default OutsideDhaka;
