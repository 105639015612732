import { useState } from 'react';

import { getLocation } from '../helper/helper';

const useAddress = () => {
	const [lat, setLat] = useState<number | null>(null);
	const [long, setLong] = useState<number | null>(null);
	const [meta, setMeta] = useState<any | null>(null);

	const handleAddress = async (
		e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const value = e.target.value;

		let dataAddress;

		try {
			dataAddress = await getLocation(value);
			setMeta({ barikoi: dataAddress });

			if (dataAddress.confidence_score_percentage < 75) {
				return;
			}

			setLat(Number(dataAddress.geocoded_address.latitude));
			setLong(Number(dataAddress.geocoded_address.longitude));
		} catch (error) {
			console.log(error);
		}
	};
	return { lat, long, handleAddress, meta };
};

export default useAddress;
