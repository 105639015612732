import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import './newPayment.scss';
import PaymentSelect from '../../components/paymentSelect/PaymentSelect';
import Layout from '../../components/layout/Layout';
import { Card } from '../../components/commonBlock/CommonBlock';
import { Slug } from '../paymentDetails/PaymentDetails';
import { useQuery } from '@apollo/client';
import { GET_UNPAID_PARCELS_BY_BRAND } from '../../graphql/queries/accounts';
import { isBefore, subDays } from 'date-fns';
import { instanceOfAxios } from '../../helper/axios';
import { useStore } from '../../context/Context';
import { startOfDay } from 'date-fns/esm';
import useMessage from '../../hooks/useMessage';
import LoadingBtn from '../../components/typography/buttons/LoadingBtn';
import Checkbox from '@material-ui/core/Checkbox';
import { StatusStream } from '../../context/contextTypes';
import { errorMessage } from '../../helper/helper';

export interface Parcel {
	node: Node;
}

export interface Brand {
	name: string;
}

export interface Node {
	objectId: string;
	deliveredAt: string;
	processingFinishedAt: string;
	order_id: string;
	brand: Brand;
	amount: number;
	delivery_charge: number;
	pickup_cash: boolean;
	status: StatusStream;
	customer_name: string;
	address: string;
}

export interface Calculation {
	id: string;
	checked: boolean;
	parcel: Parcel;
}

const NewPayment: React.FC = () => {
	const { brandId } = useParams<Slug>();

	const { push, location } = useHistory();

	const {
		store: { user },
	} = useStore();

	const query = new URLSearchParams(location.search);

	const _brandName = query.get('name');

	const { handleNotification } = useMessage();

	const [note, setNote] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	const [payments, setPayments] = useState<Parcel[]>([]);

	const [selectAllNew, setSelectAllNew] = useState(false);

	const [selectAllOld, setSelectAllOld] = useState(false);

	const paymentSet = new Set<Parcel>();

	// calc logic

	const card: { cash: Card; fee: Card; worth: Card } = useMemo(() => {
		const cash = payments.reduce((previous, current) => {
			const status = current.node.status;

			const pickup_cash = current.node.pickup_cash;

			let canAddAmmount: boolean = status === 'delivered' && pickup_cash;

			let canAddDeliveryCharge: boolean =
				status === 'returned_paid_by_customer';

			if (canAddAmmount) {
				return previous + current.node.amount;
			}

			if (canAddDeliveryCharge) {
				return previous + current.node.delivery_charge;
			}

			return previous;
		}, 0);

		const fee = payments.reduce(
			(previous, current) => previous + current.node.delivery_charge,
			0
		);

		const worth = cash - fee;

		return {
			cash: {
				title: 'TK.',
				subTitle: 'Cash',
				amount: cash,
				svg: '/custom/payment/money-green.svg',
			},
			fee: {
				title: 'TK.',
				subTitle: 'Delivery Fee',
				amount: fee,
				svg: '/custom/payment/money.svg',
			},
			worth: {
				title: 'TK.',
				subTitle: 'Net Worth',
				amount: worth,
				svg: '/custom/payment/money.svg',
			},
		};
	}, [payments]);

	const handleCalc = ({ checked, parcel }: Calculation) => {
		if (checked) {
			setPayments((old) => {
				old.forEach((f) => paymentSet.add(f));
				paymentSet.add(parcel);
				return [...paymentSet];
			});
			return;
		}

		setPayments((old) =>
			old.filter((item) => item.node.objectId !== parcel.node.objectId)
		);
	};

	// handlePay func

	const handlePay = async () => {
		setIsLoading(true);

		const paymentData = {
			objectIds: payments.map((i) => i.node.objectId),
			brandId,
			note,
			cash_collected: card.cash.amount,
			delivery_charge: card.fee.amount,
			net_amount: card.worth.amount,
		};

		const headers = {
			'X-Parse-Session-Token': user?.sessionToken,
		};

		try {
			await instanceOfAxios.post('/functions/payMerchant', paymentData, {
				headers,
			});

			handleNotification({
				message: 'Success',
				type: 'success',
				variant: 'standard',
			});

			setPayments([]);
			setIsLoading(false);
			push('/accounts');
		} catch (error) {
			setIsLoading(false);
			handleNotification({
				message: errorMessage({ error }),
				type: 'error',
				variant: 'standard',
			});
		}
	};

	const POLL_INTERVAL_IN_MIN = 10;

	// queries

	const { data, loading, error } = useQuery(GET_UNPAID_PARCELS_BY_BRAND, {
		variables: {
			objectId: brandId,
			lt: startOfDay(new Date()),
		},
		pollInterval: 1000 * 60 * POLL_INTERVAL_IN_MIN,
	});

	const parcelsNew: Parcel[] = useMemo(() => {
		if (data) {
			const unSortedData = data['parcels']['edges'];
			return unSortedData.filter(
				({ node }: Parcel) =>
					!isBefore(
						new Date(node.processingFinishedAt),
						subDays(startOfDay(new Date()), 1)
					)
			);
		}

		return [];
	}, [data]);

	const parcelsOld: Parcel[] = useMemo(() => {
		if (data) {
			const unSortedData = data['parcels']['edges'];
			return unSortedData.filter(({ node }: Parcel) =>
				isBefore(
					new Date(node.processingFinishedAt),
					subDays(startOfDay(new Date()), 1)
				)
			);
		}

		return [];
	}, [data]);

	// effects

	useEffect(() => {
		if (error) {
			handleNotification({
				message: error.message,
				type: 'error',
				variant: 'standard',
			});
		}
	}, [error, handleNotification]);

	return (
		<Layout
			title='New Payment'
			subTitle={_brandName ? _brandName : '___OOPS___'}
			className='newPayment'
			loading={loading}
		>
			<div className='newPayment__container rounded shadow-lg anim-frame'>
				<div className='newPayment__cards'>
					{/* cash */}
					<div className='each  rounded shadow-md'>
						<img src={card.cash.svg} alt='icon' height='50' width='50' />
						<h3 className='text-3xl'>
							{card.cash.title} {card.cash.amount}
						</h3>
						<p>{card.cash.subTitle}</p>
					</div>
					{/* fee */}
					<div className='each  rounded shadow-md'>
						<img src={card.fee.svg} alt='icon' height='50' width='50' />
						<h3 className='text-3xl'>
							{card.fee.title} {card.fee.amount}
						</h3>
						<p>{card.fee.subTitle}</p>
					</div>
					{/* net worth */}
					<div className='each  rounded shadow-md'>
						<img src={card.worth.svg} alt='icon' height='50' width='50' />
						<h3 className='text-3xl'>
							{card.worth.title} {card.worth.amount}
						</h3>
						<p>{card.worth.subTitle}</p>
					</div>
				</div>
				<div className='newPayment__select rounded shadow-md'>
					<h3>Yesterday's payments</h3>
					{parcelsNew.length === 0 ? (
						<p>No Payments Yesterday</p>
					) : (
						<>
							<Checkbox
								checked={selectAllNew}
								value={selectAllNew}
								onChange={(_, checked: boolean) => setSelectAllNew(checked)}
								color='primary'
							/>
							{parcelsNew.map((parcel) => (
								<PaymentSelect
									parcel={parcel}
									selectAll={selectAllNew}
									handleCalc={handleCalc}
									moid={parcel.node.order_id}
									key={JSON.stringify(parcel.node)}
									orderId={parcel.node.objectId}
									date={new Date(parcel.node.processingFinishedAt)}
								/>
							))}
						</>
					)}
					<h3>Other / Payments</h3>
					{parcelsOld.length === 0 ? (
						<p>No Payments Available</p>
					) : (
						<>
							<Checkbox
								checked={selectAllOld}
								value={selectAllOld}
								onChange={(_, checked: boolean) => setSelectAllOld(checked)}
								color='primary'
							/>
							{parcelsOld.map((parcel) => (
								<PaymentSelect
									parcel={parcel}
									selectAll={selectAllOld}
									handleCalc={handleCalc}
									moid={parcel.node.order_id}
									key={JSON.stringify(parcel.node)}
									orderId={parcel.node.objectId}
									date={new Date(parcel.node.processingFinishedAt)}
								/>
							))}
						</>
					)}
				</div>
				<h3 className='text-sm'>Remarks</h3>
				<div className='newPayment__remarks rounded shadow-md'>
					<textarea
						onChange={(e) => setNote(e.target.value)}
						value={note}
						placeholder='Payment method, transaction number, etc...'
						name='remarks'
						id='remarks'
					></textarea>
				</div>
				<LoadingBtn disabled={isLoading} onClick={handlePay}>
					Fullfill Payment
				</LoadingBtn>
			</div>
		</Layout>
	);
};

export default NewPayment;
