import React, { useEffect, useMemo, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Calculation, Parcel } from '../../screens/newPayment/NewPayment';

interface Props {
	orderId: string;
	moid: string;
	date: Date;
	handleCalc: ({ id }: Calculation) => void;
	selectAll: boolean;
	parcel: Parcel;
}

const PaymentSelect: React.FC<Props> = ({
	orderId,
	moid,
	date,
	handleCalc,
	selectAll,
	parcel,
}) => {
	const [isSelected, setIsSelected] = useState(false);

	const [loaded, setLoaded] = useState(false);

	const handleChange = (
		_: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		setIsSelected(!isSelected);

		handleCalc({ id: orderId, checked, parcel });
	};

	useEffect(() => {
		setIsSelected(selectAll);
	}, [selectAll]);

	useEffect(() => {
		if (loaded) {
			handleCalc({ id: orderId, checked: selectAll, parcel });
		}

		return () => {
			if (!loaded) {
				setLoaded(true);
			}
		};
	}, [selectAll, loaded]);

	const subDescription = useMemo(() => {
		const customerInfo = `( ${parcel.node.customer_name} ), ${parcel.node.address}`;

		if (parcel.node.status === 'delivered')
			return `charge: ${parcel.node.delivery_charge}, amount: ${
				parcel.node.amount
			}, ${
				parcel.node.pickup_cash ? 'Cash pickup' : 'Online Paid'
			}, ${customerInfo}`;

		return `${parcel.node.status.toUpperCase()} charge: ${
			parcel.node.delivery_charge
		}, ${customerInfo}`;
	}, [parcel]);

	return (
		<div className='newPayment__select--each'>
			<Checkbox
				checked={isSelected}
				value={orderId}
				onChange={handleChange}
				color='primary'
			/>
			<p>
				{' '}
				<Link target='_blank' to={`/order/details/${orderId}`}>
					{moid?.toUpperCase()}
				</Link>
			</p>
			<p> ({format(date, 'PPpp')}) </p>
			<p>{subDescription}</p>
		</div>
	);
};

export default PaymentSelect;
