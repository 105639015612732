import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './login.scss';
import { useStore } from '../../context/Context';
import { useAuth } from '../../hooks/useAuth';
import Common from '../merchantSignup/Common';
import CustomInput from '../../components/customInput/CustomInput';
import { MOBILE_REGEX_BD } from '../../helper/regex';
import { isDev } from '../../helper/helper';
import useOtp from '../../hooks/useOtp';

const LoginButton = styled(Button)`
	margin-top: 10px;
	text-transform: inherit;
`;

const Login: React.FC = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState(isDev() ? 'qweqwe' : '');
	const [number, setNumber] = useState(isDev() ? '01713007689' : '');
	const [passwordDis, setPasswordDis] = useState(true);
	const [numberDis, setNumberDis] = useState(true);
	const [usernameDis, setUsernameDis] = useState(true);
	const [disbaled, setDisbaled] = useState(true);
	const [isForgotActive, setIsForgotActive] = useState(false);

	const {
		store: { user, brand },
	} = useStore();

	const { loading, login } = useAuth();
	const { handleOtp } = useOtp();
	const { replace } = useHistory();

	useEffect(() => {
		const isValid = !usernameDis && !passwordDis;

		if (isValid) {
			setDisbaled(false);
		}
	}, [usernameDis, passwordDis]);

	useEffect(() => {
		const userType = user?.user.type;

		if (user && userType !== 'merchant') {
			replace(`/${userType}`);
			return;
		}

		if (userType === 'merchant' && brand) {
			replace(`/${userType}/${brand.node.objectId}`);
		}
	}, [user, brand, replace]);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (isForgotActive) {
			handleOtp({ number, isForgot: true });
			return;
		}

		login({ username, password });
	};

	return (
		<Common title='Login' className='login '>
			<Helmet>
				<title>Now | Login</title>
			</Helmet>
			<form className='text-lg' onSubmit={handleSubmit}>
				{isForgotActive ? (
					<>
						<h2>Enter Phone Number</h2>
						<CustomInput
							label='Phone Number'
							placeholder='01....'
							value={number}
							regex={MOBILE_REGEX_BD}
							helperTxt={'please select a valid number (eg. 01...)'}
							setDisabled={setNumberDis}
							setValue={setNumber}
							type='number'
						/>
						<LoginButton
							disabled={numberDis}
							className='text-lg'
							size='large'
							variant='contained'
							color='primary'
							type='submit'>
							Submit
						</LoginButton>
						<Link
							to='#'
							onClick={(e) => {
								e.preventDefault();
								setIsForgotActive(false);
							}}>
							Go To Login
						</Link>
					</>
				) : (
					<>
						<h2>Login</h2>
						<CustomInput
							label='Phone Number'
							placeholder='01...'
							value={username}
							regex={/.*/gi}
							helperTxt={'please select a valid number (eg. 01...)'}
							setValue={setUsername}
							setDisabled={setUsernameDis}
							type='text'
						/>
						<CustomInput
							label='Password'
							placeholder='****'
							regex={/^.{6,}/gi}
							helperTxt={'should be at least 6 charecters long'}
							value={password}
							setValue={setPassword}
							setDisabled={setPasswordDis}
							type='password'
						/>
						<LoginButton
							disabled={disbaled || loading}
							className='text-lg'
							size='large'
							variant='contained'
							color='primary'
							type='submit'>
							{loading ? 'Loading...' : 'Login'}
						</LoginButton>
						<Link
							to='#'
							onClick={(e) => {
								e.preventDefault();
								setIsForgotActive(true);
							}}>
							Forgot Password
						</Link>
						<p>
							Not a merchant yet ! <Link to='/merchant-signup'>Register</Link>
						</p>
					</>
				)}
			</form>
		</Common>
	);
};

export default Login;
