import { FormikErrors, getIn } from 'formik';
import { endOfDay, formatRelative, startOfDay } from 'date-fns';

import { instanceOfBarikoi } from './axios';
import { DeliveryReqs, User, UserInfo } from '../context/contextTypes';

export interface BariKoi {
	given_address: string;
	fixed_address: string;
	address_status: string;
	geocoded_address: GeocodedAddress;
	confidence_score_percentage: number;
	status: number;
}
export interface GeocodedAddress {
	Address: string;
	address_bn: string;
	alternate_address: string;
	area: string;
	city: string;
	created_at: string;
	district: string;
	flag: number;
	id?: null;
	latitude: string;
	location: string;
	longitude: string;
	new_address: string;
	pType: string;
	place_name: string;
	postCode: number;
	subType: string;
	tag?: null;
	thana: string;
	uCode: string;
	unions?: null;
	user_id?: null;
}

interface Change {
	new: string | number;
	old: string | number;
}

export const hasChanged = (obj: { new: any; old: any }): boolean => {
	if (JSON.stringify(obj.new) !== JSON.stringify(obj.old)) return true;

	return false;
};

export const getLocation = async (address: string): Promise<BariKoi> => {
	let formData = new FormData();

	formData.append('q', address);

	const { data } = await instanceOfBarikoi.post('', formData);

	return data;
};

export const isDev = () => {
	return process.env.REACT_APP_TYPE === 'DEV';
};

export const toParseDate = (date: string): { __type: 'Date'; iso: string } => {
	return {
		__type: 'Date',
		iso: date,
	};
};

export const searchIsoDate = (date: Date, type: 'start' | 'end') =>
	type === 'start'
		? startOfDay(date).toISOString()
		: endOfDay(date).toISOString();

interface CuttOfData extends Change {
	key: string;
}

export interface Error {
	error: {
		response?: {
			data: {
				error: string;
			};
		};
		message: string;
	};
}

export const errorMessage = ({ error }: Error): string => {
	if (error.response) {
		return error.response.data.error;
	}

	if (error.message) {
		return error.message;
	}

	return 'Oops an unknown error occured';
};

export const cutOfData = (objArr: CuttOfData[]) => {
	let data = {};

	objArr.forEach((obj) => {
		if (hasChanged({ old: obj.old, new: obj.new })) {
			let key: { [index: string]: string | number } = {};

			key[`${obj.key}`] = obj.new;

			data = { ...data, ...key };
		}
	});

	return data;
};

interface StrProps {
	i: number;
	name: string;
}

export const makeNameStr = ({ i, name }: StrProps) => `data[${i}].${name}`;

export function isEmpty<T>(data: T[]) {
	return data.length === 0;
}

interface ErrProps extends StrProps {
	errors: FormikErrors<any>;
}

export const getStringErr = ({ errors, i, name }: ErrProps) =>
	getIn(errors, makeNameStr({ i, name }));

export const handleWheel = (e: React.WheelEvent<HTMLInputElement>) => {
	e.currentTarget.blur();
};

export const relativeNow = (date: Date) => formatRelative(date, new Date());

export const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

export const toCap = (str: string) =>
	str.charAt(0).toUpperCase() + str.substring(1);

export const stringTruncate = (str: string, length: number) => {
	const dots = str.length > length ? '...' : '';
	return str.substring(0, length) + dots;
};

export const PARCEL_STATUS: { [index: string]: string } = {
	created: 'created',
	picked_up: 'picked up',
	waiting: 'waiting',
	rejected: 'rejected',
	rider_assigned: 'rider assigned', //
	picked_up_bag: 'picked up in bag', //
	delivered: 'delivered', //
	pending: 'pending',
	returned: 'returned DC merchant', //
	rescheduled: 're scheduled', //
	cancelled: 'cancelled', //
	returned_paid_by_customer: 'returned DC customer', //
};

export const PARCEL_STATUS_LEGEND: { [index: string]: string } = {
	rejected: 'RE',
	delivered: 'D', //
	returned: 'RM', //
	returned_paid_by_customer: 'RC', //
};

export const PRIORITY_STATUS: { [index: string]: any } = {
	created: 0,
	waiting: 1,
	rescheduled: 2,
	picked_up: 3,
	picked_up_bag: 4,
	rider_assigned: 5,
	cancelled: 6,
	returned: 7,
	returned_paid_by_customer: 8,
	rejected: 9,
	nearest_hub: 10,
	pending: 11,
	delivered: 12,
};

export const PICKUP_STATUS: { [index: string]: string } = {
	requested: 'Requested',
	assigned: 'Assigned',
	fulfilled: 'Fulfilled',
	rejected: 'Rejected',
	failed: 'Failed',
};

export const makeRestHeader = (user: User | null) => ({
	headers: {
		'x-parse-session-token': user?.sessionToken,
	},
});

export const dateMake = (date?: string) => (date ? new Date(date) : new Date());

export const getSearchParam = () => new URLSearchParams(window.location.search);

export const getNameOrUsername = (user: UserInfo) =>
	user.name ? user.name : user.username;

export const countSelected = (data: DeliveryReqs[]) =>
	data.reduce((old, iterator) => (iterator.selected ? old + 1 : old), 0);

export const getSelected = (data: DeliveryReqs[]) =>
	data.filter((f) => f.selected).map((d) => d.node.objectId);

// TAKA = '&#2547;';

// enum raise issue

export interface SelectLabelGen<T> {
	label: string;
	value: T;
}

export enum ISSUE_LABEL {
	change_information = 'Change Information',
	delivery = 'Delivery',
	pickup = 'Pick up',
	accounts = 'Accounts',
}

export const issuesInfo = [
	{
		label: 'change_information',
		options: [
			'change address',
			'change the amount',
			'change phone number',
			'The parcel is already paid',
		],
	},
	{
		label: 'delivery',
		options: [
			'Delay in delivery',
			'Cancel the delivery',
			'Need tracking id',
			'Hold the delivery',
			'Issue with delivery man',
			'Product was damaged',
			"Customer didn't receive any call",
			'Deliveryman took extra money from customer',
		],
	},
	{
		label: 'pickup',
		options: [
			'Cancel my pick up',
			'Need a pick up van',
			'Status not changed',
			'Issue with pick up man',
			'Some products are yet to be picked up',
			'Products are not picked up yet',
		],
	},
	{
		label: 'accounts',
		options: [
			'Overcharged',
			"I haven't received the amount",
			'The payment is mismatched',
		],
	},
];
