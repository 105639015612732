import { Button } from '@material-ui/core';
import styled from 'styled-components';

const Error = styled.div`
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const ErrorScreen = () => {
	return (
		<Error className='error'>
			<img
				src='/custom/error.svg'
				alt='error vector'
				height={360}
				width={360}
			/>
			<Button
				variant='outlined'
				color='primary'
				onClick={() => {
					localStorage.clear();
					window.location.href = 'https://merchant.now.com.bd/login';
				}}>
				Go Back
			</Button>
		</Error>
	);
};

export default ErrorScreen;
