import gql from 'graphql-tag';

export const CREATE_ISSUE = gql`
	mutation raiseIssue($parcel: ID!, $label: String!, $thread: [Any]!) {
		createQuestion(
			input: {
				fields: { parcel: { link: $parcel }, label: $label, thread: $thread }
			}
		) {
			question {
				objectId
			}
		}
	}
`;
