/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@material-ui/core/TextField';
import DateRangeIcon from '@material-ui/icons/DateRange';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from 'react-select';
import { DateRangePicker } from 'react-date-range';
import { endOfDay, format, formatRelative, startOfDay } from 'date-fns';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import './allOrders.scss';
import Layout from '../../components/layout/Layout';
import TableHeader from '../../components/TableCommon/TableHeader';
import { Header } from '../../components/pickupReqTable/pickup.req.table';
import { useStore } from '../../context/Context';
import useOrders from '../../hooks/useOrders';
import LoadingBtn from '../../components/typography/buttons/LoadingBtn';
import { Link, useHistory } from 'react-router-dom';
import { PARCEL_STATUS, relativeNow } from '../../helper/helper';
import { instanceOfAxios } from '../../helper/axios';
import OrderAction from '../../components/actions/OrderAction';
import { GET_REJECTED_BRANDS } from '../../graphql/queries/delivery.requests';
import { FormControlLabel, Checkbox, Hidden } from '@material-ui/core';
import { cities } from '../../docs/city';
import OrderCard from '../../components/orderCard/OrderCard';

interface Props {
	admin: boolean;
	title: string;
	isRejected?: boolean;
}

export interface BrandType {
	objectId: string;
	name: string;
}

const AllOrders: React.FC<Props> = ({ admin, title, isRejected }) => {
	const {
		store: { brand, brandParcel },
	} = useStore();

	const { push, location } = useHistory();

	const {
		pathConstructor,
		refetch,
		isFetching,
		handleChange,
		isLoading,
		currentPage,
		count,
		reset,
		setEndDate,
		endDate,
		startDate,
		setStartDate,
		isDateOpen,
		setIsDateOpen,
		brandNameId,
		setBrandNameId,
		merchantOrderIdRef,
		brandName,
		setBrandName,
		phoneRef,
		nameRef,
		orderIdRef,
		status,
		setStatus,
		parcelStatus,
		parcelCount,
		payment_transaction,
		areaSelect,
		setAreaSelect,
		zoneSelect,
		setZoneSelect,
		areas,
	} = useOrders({ admin, isRejected, objectId: brand?.node.objectId });

	const [open, setOpen] = useState(false);

	const [isOutside, setIsOutside] = useState(false);

	const [loading, setLoading] = useState(false);

	const [options, setOptions] = useState<BrandType[]>([]);

	const selectionRange = {
		startDate,
		endDate,
		key: 'selection',
		color: 'black',
	};

	const { data } = useQuery(GET_REJECTED_BRANDS, { skip: !isRejected });

	useEffect(() => {
		if (data) {
			setOptions(
				_.uniqBy(
					data['parcels']['edges'].map(
						({ node }: { node: { brand: BrandType } }) => node.brand
					),
					'name'
				) as any
			);
		}
	}, [data]);

	useEffect(() => {
		refetch();
	}, [location]);

	const headers: Header[] = useMemo(() => {
		let titles = [
			{ field: 'Recipient Name' },
			{ field: 'Order ID (Merchant)' },
			{ field: 'Recipient Info' },
			{ field: 'Amount' },
			{ field: 'Delivery status' },
			{ field: 'Payment Status' },
			{ field: 'Actions' },
		];

		if (isRejected) {
			titles = [{ field: 'Pickup slot' }, ...titles];
		}

		if (admin) {
			titles = [{ field: 'Brand' }, { field: 'Brand Info' }, ...titles];
		}

		return [{ field: 'ID' }, ...titles];
	}, [admin, isRejected]);

	const handleSelect = (ranges: any) => {
		setStartDate(startOfDay(ranges.selection.startDate));
		setEndDate(endOfDay(ranges.selection.endDate));
	};

	const handleSearch = () => {
		let sub = [];

		if (brandNameId) {
			sub.push(`brand=${brandNameId}`);
		}

		const phone = phoneRef.current?.value;

		if (phone) {
			sub.push(`phone=${phone}`);
		}

		const orderId = orderIdRef.current?.value;

		if (orderId) {
			sub.push(`orderId=${orderId}`);
		}

		const name = nameRef.current?.value;

		if (name) {
			sub.push(`name=${name}`);
		}

		const moid = merchantOrderIdRef.current?.value;

		if (moid) {
			sub.push(`moid=${moid}`);
		}

		if (payment_transaction) {
			sub.push(`payment_transaction=${payment_transaction}`);
		}

		if (startDate && endDate) {
			sub.push(
				`startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
			);
		}

		if (status) {
			sub.push(`status=${status.value}`);
		}

		if (areaSelect) {
			sub.push(`area=${areaSelect.value}`);
		}

		push(pathConstructor(sub, '&'));

		reset();
	};

	// auto suggestions

	useEffect(() => {
		const LEAST_NO_OF_WORDS_TO_CALL_API = 3;

		const canNotFetch =
			brandName.length < LEAST_NO_OF_WORDS_TO_CALL_API || isRejected;

		if (canNotFetch) return;

		let active = true;

		(async () => {
			setLoading(true);
			let query = {
				name: {
					$regex: `${brandName}`,
					$options: 'i',
				},
			};

			const keys = ['objectId', 'name'];

			try {
				const { data } = await instanceOfAxios.get(
					`/classes/brand?where=${JSON.stringify(query)}&keys=${keys.join(',')}`
				);

				if (active) {
					setLoading(false);
					setOptions(data.results);
				}
			} catch (error) {
				setLoading(false);
				setOptions([]);
				console.log(error);
			}
		})();

		return () => {
			active = false;
		};
	}, [brandName]);

	return (
		<Layout
			title={`${title} | ${
				isLoading || isFetching ? 'Loading...' : parcelCount
			}`}
			loading={isLoading}
			className='allOrders'>
			<div className='allOrders__container rounded shadow-lg anim-frame'>
				<form
					className='allOrders__search'
					onSubmit={(e) => {
						e.preventDefault();

						handleSearch();
					}}>
					<div className='allOrders__date checkbox'>
						<IconButton
							color='primary'
							onClick={() => setIsDateOpen(!isDateOpen)}>
							<DateRangeIcon />
						</IconButton>
						{isDateOpen && (
							<div className='allOrders__datePicker shadow-lg'>
								<DateRangePicker
									ranges={[selectionRange]}
									onChange={handleSelect}
								/>
							</div>
						)}
					</div>
					<TextField
						inputRef={phoneRef}
						variant='outlined'
						size='small'
						placeholder='Phone number'
						type='number'
					/>
					<TextField
						inputRef={nameRef}
						variant='outlined'
						size='small'
						placeholder='Recipient Name'
						type='text'
					/>
					<TextField
						inputRef={merchantOrderIdRef}
						variant='outlined'
						size='small'
						placeholder='Merchant Order Id'
						type='text'
					/>
					{!isRejected && (
						<div className='select'>
							<Select
								menuPortalTarget={document.body}
								menuPosition={'absolute'}
								onChange={(value) => {
									setStatus(value);
								}}
								isClearable
								isSearchable
								placeholder='Parcel status'
								value={status}
								options={parcelStatus}
							/>
						</div>
					)}
					{admin && (
						<>
							<TextField
								inputRef={orderIdRef}
								variant='outlined'
								size='small'
								placeholder='Order Id'
								type='text'
							/>

							{/* <TextField
								inputRef={merchantOrderIdRef}
								variant='outlined'
								size='small'
								placeholder='Merchant Order Id'
								type='text'
							/> */}

							<Autocomplete
								id='asynchronous'
								style={{ width: '260px', transform: 'scaleY(.75)' }}
								open={open}
								onOpen={() => {
									setOpen(true);
								}}
								onClose={() => {
									setOpen(false);
								}}
								getOptionSelected={(option, value) => {
									return option.name === value.name;
								}}
								onChange={(_e, value) => {
									if (value) setBrandNameId(value.objectId);
								}}
								getOptionLabel={(option) => option.name}
								options={options}
								loading={loading}
								renderInput={(params) => (
									<TextField
										variant='outlined'
										onChange={(e) => {
											setBrandName(e.target.value);
										}}
										{...params}
										label='Brand'
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{loading ? (
														<CircularProgress color='inherit' size={20} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>

							<div className='checkbox'>
								<FormControlLabel
									onChange={(_event, checked) => {
										setIsOutside(checked);
									}}
									name='outside'
									checked={isOutside}
									value={isOutside}
									control={<Checkbox color='primary' />}
									label='Outside Dhaka'
									labelPlacement='end'
								/>
							</div>

							{isOutside ? (
								<>
									<div className='select'>
										<Select
											onChange={(value) => {
												setZoneSelect(value);
											}}
											isClearable
											isSearchable
											placeholder='City'
											value={zoneSelect}
											options={_.uniqBy(
												cities
													.filter((city) => city.district !== 'Dhaka')
													.map((value) => {
														return {
															label: value.district,
															value: value.district,
														};
													}),
												'label'
											)}
										/>
									</div>
									<div className='select'>
										<Select
											onChange={(value) => {
												setAreaSelect(value);
											}}
											value={areaSelect}
											isClearable
											isSearchable
											placeholder='Zone'
											options={areas.map((value) => {
												return {
													label: value.area,
													value: value.area,
												};
											})}
										/>
									</div>
								</>
							) : (
								<div className='select'>
									<Select
										onChange={(value) => {
											setAreaSelect(value);
										}}
										value={areaSelect}
										isClearable
										isSearchable
										placeholder='Area'
										options={cities
											.filter((city) => city.district === 'Dhaka')
											.map((value) => {
												return {
													label: value.area,
													value: value.area,
												};
											})}
									/>
								</div>
							)}
						</>
					)}

					<LoadingBtn
						type='submit'
						className='🌏'
						startIcon={<SearchIcon />}
						disabled={isLoading}
						onClick={handleSearch}>
						Search
					</LoadingBtn>
				</form>
				<Hidden smDown>
					<div className='overflow'>
						<div className='allOrders__table'>
							<div
								className='allOrders__header'
								style={{
									gridTemplateColumns: `repeat(${headers.length}, 1fr)`,
								}}>
								{headers.map(({ field }, i) => (
									<TableHeader key={i} field={field} />
								))}
							</div>
							{brandParcel?.results?.map((parcel, i) => (
								<div
									key={parcel.objectId}
									className='allOrders__body anim-card'
									style={{
										gridTemplateColumns: `repeat(${headers.length}, 1fr)`,
									}}>
									<div className='allOrders__bodyCol'>
										<Link to={`/order/details/${parcel.objectId}`}>
											{i + 1}
											{'. '} {parcel.order_id}{' '}
										</Link>
										<p>Type: {parcel.parcel_type}</p>
									</div>

									{admin && (
										<>
											<div className='allOrders__bodyCol'>
												<Link to={`/merchant/profile/${parcel.brand.objectId}`}>
													{parcel.brand.name}
												</Link>
											</div>

											<div className='allOrders__bodyCol'>
												{parcel.brand.contact_numbers?.map((number) => {
													return <p key={number}>{number}</p>;
												})}

												<p>{parcel.brand.address}</p>
											</div>
										</>
									)}

									{isRejected && (
										<div className='allOrders__bodyCol '>
											<p>{relativeNow(new Date(parcel.requestedSlot.iso))}</p>
										</div>
									)}

									<div className='allOrders__bodyCol'>
										<p>{parcel.customer_name} </p>
									</div>
									<div className='allOrders__bodyCol'>
										<p>{parcel.moid} </p>
										<p>
											{parcel.is_express ? (
												<SportsMotorsportsIcon
													titleAccess='express'
													style={{
														color: 'red',
														transform: 'translateX(-10px)',
													}}
													fontSize='large'
												/>
											) : (
												'....'
											)}
										</p>
									</div>
									<div className='allOrders__bodyCol'>
										<p>{parcel.customer_phone}</p>
										<p>{parcel.address}</p>
									</div>
									<div className='allOrders__bodyCol'>
										<p>Tk. {parcel.amount}</p>
										{!parcel.pickup_cash && (
											<p style={{ color: 'blue', fontWeight: 600 }}>
												Paid Online
											</p>
										)}
									</div>
									<div className='allOrders__bodyCol '>
										<p className={`${parcel.status} tag`}>
											{PARCEL_STATUS[parcel.status]}
										</p>
										<p>
											Updated{' '}
											{formatRelative(new Date(parcel.updatedAt), new Date())}
										</p>
									</div>

									<div className='allOrders__bodyCol '>
										<p
											className={`${
												parcel.payment_status === 'paid'
													? parcel.payment_status
													: 'unpaid'
											} tag`}>
											{parcel.payment_status ? parcel.payment_status : 'Unpaid'}
										</p>
										{parcel.payment_status === 'paid' ? (
											<p>
												Paid at {format(new Date(parcel.paidAt.iso), 'do LLLL')}
											</p>
										) : (
											''
										)}
									</div>
									<div className='allOrders__bodyCol	'>
										<OrderAction
											isRejected={isRejected}
											spacing={3}
											oid={parcel.order_id}
											objectId={parcel.objectId}
											status={parcel.status}
										/>
									</div>
								</div>
							))}

							<div className='allOrders__pagination anim-frame'>
								{isFetching && <span className='text-lg'>Loading...</span>}
								<Pagination
									onChange={handleChange}
									count={count as number}
									color='primary'
									page={currentPage}
									shape='rounded'
								/>
							</div>
						</div>
					</div>
				</Hidden>
				<Hidden mdUp>
					<div className='allOrders__cards'>
						{brandParcel?.results?.map((parcel, i) => (
							<OrderCard
								isRejected={isRejected}
								no={i + 1}
								parcel={parcel}
								key={parcel.objectId}
								isAdmin={admin}
							/>
						))}
					</div>
					<div className='allOrders__pagination anim-frame'>
						<Pagination
							onChange={handleChange}
							count={count as number}
							color='primary'
							page={currentPage}
							shape='rounded'
						/>
					</div>
				</Hidden>
			</div>
		</Layout>
	);
};

export default AllOrders;
