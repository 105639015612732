import React, { ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
	disabled: boolean;
	className?: string;
	type?: any;
	fullWidth?: boolean;
	startIcon?: ReactNode;
	endIcon?: ReactNode;
	size?: 'small' | 'medium' | 'large';
}

const LoadingBtn: React.FC<Props> = ({
	onClick,
	disabled,
	className,
	children,
	fullWidth,
	startIcon,
	endIcon,
	type,
	size,
}) => {
	return (
		<Button
			type={type}
			disableElevation
			size={size}
			endIcon={endIcon}
			startIcon={startIcon}
			fullWidth={fullWidth}
			className={className}
			onClick={onClick}
			color='primary'
			variant='contained'
			disabled={disabled}>
			{disabled && (
				<CircularProgress size={30} style={{ position: 'absolute' }} />
			)}
			{children}
		</Button>
	);
};

export default LoadingBtn;
