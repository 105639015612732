import styled from 'styled-components';

export const HeaderTable = styled.div<{ length: number }>`
	background-color: hsl(0, 0%, 96%);
	padding: 1.5rem 1rem;
	display: grid;
	grid-template-columns: repeat(${({ length }) => length}, 1fr);
	align-items: center;
	gap: 1rem;
`;

export const TableBodyRow = styled(HeaderTable)`
	background: white;

	align-items: flex-start;
	border-top: 1px solid #ddd;
`;

export const TableCell = styled.div`
	p {
		font-size: 0.9rem;
		line-height: 1.2rem;
		text-align: start;
		text-transform: capitalize;
	}

	a {
		font-size: 1.2rem;
		color: $link-color;
	}

	span {
		width: 20px;
		height: 20px;
		padding: 0.4rem;
		border-radius: 50%;
		background-color: $tag-color;

		@include respond-to(lg) {
			background-color: transparent;
		}
	}

	& > * {
		max-width: 200px;
	}
`;
