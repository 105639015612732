import axios from 'axios';
import { useState } from 'react';
import { useStore } from '../context/Context';
import { instanceOfAxios } from '../helper/axios';
import { errorMessage } from '../helper/helper';
import useMessage from './useMessage';

export interface Token {
	result: Result;
}
export interface Result {
	policy: string;
	signature: string;
	key: string;
	'x-amz-credential': string;
}

export interface Store {
	zone: string;
	area: string;
	geolocation?: number[] | null;
	name: string;
	address: Address;
	phone: string;
}
export interface Address {
	flat: string;
	full_address: string;
}

const useProfile = () => {
	const [avatar, setAvatar] = useState<string | null>(null);

	const [token, setToken] = useState<string | null>(null);

	const [upLoading, setUploding] = useState(false);

	const {
		store: { user, brand },
		dispatch,
	} = useStore();

	const { handleNotification } = useMessage();

	const [directory, setDirectory] = useState<string | null | undefined>(
		brand?.node.logo
	);

	const IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

	const updateBrand = async (brandId: string, data: object) => {
		setUploding(true);

		const headers = {
			'X-Parse-Session-Token': user?.sessionToken,
		};

		try {
			await instanceOfAxios.put(
				`/classes/brand/${brandId}`,
				{ ...data, logo: directory },
				{
					headers,
				}
			);
			setUploding(false);

			handleNotification({
				variant: 'standard',
				message: 'Successfully updated profile',
				type: 'success',
			});
		} catch (error:any) {
			setUploding(false);

			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});
		}
	};

	const upload = async (file: File, blob: Blob) => {
		const ext = file.type.split('/')[1];

		const cred = {
			ext,
			destination: 'brand',
			type: file.type,
		};

		try {
			const { data: token } = await instanceOfAxios.post<Token>(
				'/functions/getUploadToken',
				cred
			);
			{
				const data = new FormData();

				data.append('key', token.result.key);
				data.append('acl', 'public-read');
				data.append('Content-Type', blob.type);
				data.append(
					'x-amz-server-side-encryption',
					process.env.REACT_APP_AMZ_SSE as string
				);
				data.append('x-amz-credential', token.result['x-amz-credential']);
				data.append(
					'x-amz-algorithm',
					process.env.REACT_APP_AMZ_ALGO as string
				);
				data.append(
					'AWSAccessKeyId',
					process.env.REACT_APP_AMZ_ACCESS_KEY as string
				);
				data.append('Policy', token.result.policy);
				data.append('signature', token.result.signature);
				data.append('file', blob);
				const { data: _s3Data } = await axios.post(
					'https://now-delivery-dev.s3.amazonaws.com/',
					data
				);

				setAvatar(
					`https://now-delivery-dev.s3-ap-southeast-1.amazonaws.com/${token.result.key}`
				);

				setDirectory(
					`https://now-delivery-dev.s3-ap-southeast-1.amazonaws.com/${token.result.key}`
				);

				setUploding(false);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const resizeImage = (file: File) => {
		const reader = new FileReader();

		reader.onload = (e) => {
			const img = document.createElement('img');
			img.src = e.target?.result as string;

			const i = new Image();
			let width: number = 0;
			let height: number = 0;

			i.onload = () => {
				width = i.width;
				height = i.height;

				const canvas = document.createElement('canvas');

				let ctx = canvas.getContext('2d');

				ctx?.drawImage(img, 0, 0);

				const MAX_WIDTH = 400;
				const MAX_HEIGHT = 400;

				if (width > height) {
					if (width > MAX_WIDTH) {
						height *= MAX_WIDTH / width;
						width = MAX_WIDTH;
					}
				} else {
					if (height > MAX_HEIGHT) {
						width *= MAX_HEIGHT / height;
						height = MAX_HEIGHT;
					}
				}

				canvas.width = width;
				canvas.height = height;

				ctx?.drawImage(img, 0, 0, width, height);

				const url = canvas.toDataURL(file.type);

				fetch(url)
					.then((res) => res.blob())
					.then((blob) => {
						var fd = new FormData();
						fd.append('image', blob, 'filename');

						upload(file, blob);
					});
			};

			i.src = img.src;
		};
		reader.readAsDataURL(file);
	};

	const handleUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) return;

		setUploding(true);

		const file = e.target.files[0];

		if (IMAGE_TYPES.includes(file?.type)) {
			resizeImage(file);
		} else {
			handleNotification({
				variant: 'standard',
				message: 'Must be jpg/jpeg or png formatted',
				type: 'warning',
			});
			setUploding(false);
		}
	};

	const getPlan = async () => {
		setUploding(true);

		const headers = {
			'X-Parse-Session-Token': user?.sessionToken,
		};

		try {
			const keys = ['objectId', 'name'];

			const { data } = await instanceOfAxios.get(
				`/classes/pricing_scheme?keys=${keys.join(',')}`,
				{
					headers,
				}
			);
			setUploding(false);

			dispatch({ type: 'ADD_PLAN', payload: data.results });
		} catch (error:any) {
			setUploding(false);

			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});
		}
	};

	const addStore = async (data: Store) => {
		setUploding(true);
		const headers = {
			'X-Parse-Session-Token': user?.sessionToken,
		};

		try {
			await instanceOfAxios.post(`/functions/addPickupLocation`, data, {
				headers,
			});

			handleNotification({
				variant: 'standard',
				message: 'Successfully created store',
				type: 'success',
			});
			setUploding(false);
		} catch (error:any) {
			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});
			setUploding(false);
		}
	};

	const getToken = async (data: { userId: string }) => {
		setUploding(true);

		const headers = {
			'X-Parse-Session-Token': user?.sessionToken,
		};

		try {
			const { data: tokenData } = await instanceOfAxios.post(
				`/functions/finder`,
				data,
				{
					headers,
				}
			);

			setToken(tokenData.result._session_token);

			setUploding(false);
		} catch (error:any) {
			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});
			setUploding(false);
		}
	};

	return {
		handleUploadChange,
		upLoading,
		updateBrand,
		avatar,
		setAvatar,
		getPlan,
		addStore,
		getToken,
		token,
	};
};

export default useProfile;
