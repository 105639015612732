import React, { useEffect, useRef, useState } from 'react';
import MessageIcon from '@material-ui/icons/Message';
import ClearIcon from '@material-ui/icons/Clear';

import './notification.scss';
import NotifSkeleton from './NotifSkeleton';

interface Props {
	isOpen: boolean;
}

const Notification: React.FC<Props> = ({ isOpen }) => {
	const ref = useRef() as React.MutableRefObject<HTMLDivElement>;

	const [openMain, setOpenMain] = useState(false);

	useEffect(() => {
		if (isOpen) {
			setOpenMain(true);
			return;
		}

		if (ref.current) {
			ref.current.classList.add('close');

			const timeout = setTimeout(() => {
				ref.current.classList.remove('close');
				setOpenMain(false);
			}, 200);

			return () => clearTimeout(timeout);
		}
	}, [isOpen]);

	if (openMain) {
		return (
			<div className='notification shadow-lg' ref={ref}>
				<div className='notification__main'>
					<div className='notification__each'>
						<div className='notification__icon'>
							<MessageIcon color='secondary' />
						</div>
						<div className='notification__container'>
							<div className='notification__title'>
								<div className='notification__titleLeft'>
									<p>APP NAME . NOW</p>
								</div>
								<div className='notification__titleRight'>
									<ClearIcon color='primary' />
								</div>
							</div>
							<div className='notification__body'>
								<p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
							</div>
						</div>
					</div>
					<div className='notification__each'>
						<div className='notification__icon'>
							<MessageIcon color='primary' />
						</div>
						<div className='notification__container'>
							<div className='notification__title'>
								<div className='notification__titleLeft'>
									<p>APP NAME . NOW</p>
								</div>
								<div className='notification__titleRight'>
									<ClearIcon color='primary' />
								</div>
							</div>
							<div className='notification__body'>
								<p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
							</div>
						</div>
					</div>
					<NotifSkeleton />
					<NotifSkeleton />
					<NotifSkeleton />
				</div>
			</div>
		);
	}

	return <></>;
};

export default Notification;
