import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

const SignupRoute: React.FC<RouteProps> = ({
	component: Component,
	...rest
}) => {
	if (!Component) {
		return null;
	}

	const isAuthenticated: boolean = rest.location?.state ? true : false;

	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{ pathname: '/login', state: { from: props.location } }}
					/>
				)
			}
		/>
	);
};

export default SignupRoute;
