import { Link } from 'react-router-dom';
import Layout from '../../components/layout/Layout';
import { Container } from '../../components/styles/Container';
import {
	HeaderTable,
	TableBodyRow,
	TableCell,
} from '../../components/styles/Table';
import TableHeader from '../../components/TableCommon/TableHeader';
import useIssues from '../../hooks/useIssues';

const headers = [
	{ field: 'NO' },
	{ field: 'ORDER ID' },
	{ field: 'BRAND' },
	{ field: 'DATE' },
	{ field: 'LABEL' },
	{ field: 'STAUTS' },
];

const AllIssues = () => {
	const { data, isLoading } = useIssues({ resolved: false });

	return (
		<Layout title='All Issues' loading={isLoading} className='allIssues'>
			<Container>
				<HeaderTable length={headers.length}>
					{headers.map(({ field }, i) => (
						<TableHeader key={i + field} field={field} />
					))}
				</HeaderTable>

				{data?.results.map((issue, i) =>
					!!issue.parcel ? (
						<TableBodyRow key={issue.objectId} length={headers.length}>
							<TableCell>
								<p>{i + 1}</p>
							</TableCell>
							<TableCell>
								<Link
									to={`/issues/${issue.parcel.objectId}?issue=${issue.objectId}`}>
									<p>{issue.parcel.order_id}</p>
								</Link>
							</TableCell>
							<TableCell>
								<p>{issue.parcel.brand.name}</p>
							</TableCell>
							<TableCell>
								<p>{new Date(issue.createdAt).toDateString()}</p>
							</TableCell>
							<TableCell>
								<p>{issue.label}</p>
							</TableCell>
							<TableCell>
								<p>{issue.resolved ? 'resolved' : 'active'}</p>
							</TableCell>
						</TableBodyRow>
					) : undefined
				)}
			</Container>
		</Layout>
	);
};

export default AllIssues;
