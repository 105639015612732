import React from 'react';
import PickupAssign from './PickupAssign';

const PickupFailOrAssigned = () => {
	return (
		<PickupAssign
			parcelType='failed_or_assigned'
			title='Reschedule Failed or Assigned Pickups'
		/>
	);
};

export default PickupFailOrAssigned;
