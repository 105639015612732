/* eslint-disable */
import React, { useEffect } from 'react';
import { formatRelative } from 'date-fns';

import './allSchemas.scss';
import Layout from '../../components/layout/Layout';
import TableHeader from '../../components/TableCommon/TableHeader';
import { Header } from '../../components/pickupReqTable/pickup.req.table';
import { useStore } from '../../context/Context';
import { Link } from 'react-router-dom';
import useAdmin from '../../hooks/useAdmin';
import { Button } from '@material-ui/core';

const AllSchemas: React.FC = () => {
	const {
		store: { plan },
	} = useStore();

	const { getShemes, isLoading } = useAdmin();

	useEffect(() => {
		getShemes();
	}, []);

	const headers: Header[] = [
		{ field: 'Id' },
		{ field: 'Pricing Model Name' },
		{ field: 'Updated At' },
		{ field: 'Created At' },
	];

	return (
		<Layout
			title='All Pricing Lists'
			loading={isLoading}
			className='allSchemas'
		>
			<div className='allSchemas__container rounded shadow-lg '>
				<Link to='/delivery-fee-chart'>
					<Button color='primary' variant='contained'>
						Add Pricing Chart
					</Button>
				</Link>
				<div className='overflow'>
					<div className='allSchemas__table'>
						<div
							className='allSchemas__header'
							style={{ gridTemplateColumns: `repeat(${headers.length}, 1fr)` }}
						>
							{headers.map(({ field }, i) => (
								<TableHeader key={i} field={field} />
							))}
						</div>
						{plan?.map((p) => (
							<div
								key={p.objectId}
								className='allSchemas__body '
								style={{
									gridTemplateColumns: `repeat(${headers.length}, 1fr)`,
								}}
							>
								<div className='allSchemas__bodyCol'>
									<Link to={`/delivery-fee-chart/update/${p.objectId}`}>
										{p.objectId}{' '}
									</Link>
								</div>
								<div className='allSchemas__bodyCol'>
									<p>{p.name}</p>
								</div>
								<div className='allSchemas__bodyCol '>
									<p>
										Updated {formatRelative(new Date(p.updatedAt), new Date())}
									</p>
								</div>
								<div className='allSchemas__bodyCol'>
									<p>
										Created {formatRelative(new Date(p.createdAt), new Date())}
									</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default AllSchemas;
