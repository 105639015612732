import { gql } from '@apollo/client';

export const GET_RIDERS = gql`
	query getRiders($type: String!, $active: Boolean!) {
		users(where: { type: { equalTo: $type }, active: { equalTo: $active } }) {
			edges {
				node {
					objectId
					username
					active
					name
					phone
				}
			}
		}
	}
`;

export const GET_ALL_RIDERS = gql`
	query getRiders($active: Boolean!) {
		users(where: { active: { equalTo: $active } }) {
			edges {
				node {
					objectId
					username
					active
					phone
				}
			}
		}
	}
`;

export const GET_RIDER_STAT = gql`
	query getRiderStat(
		$gt: Date!
		$lt: Date!
		$id: ID!
		$skip: Int!
		$first: Int!
	) {
		parcels(
			where: {
				assignedAt: { greaterThan: $gt, lessThan: $lt }
				status: {
					in: [
						"delivered"
						"rider_assigned"
						"returned"
						"returned_paid_by_customer"
						"cancelled"
						"rescheduled"
						"picked_up_bag"
						"delivered"
					]
				}
				deliveryRider: { have: { objectId: { equalTo: $id } } }
			}
			skip: $skip
			first: $first
			order: createdAt_DESC
		) {
			edges {
				node {
					objectId
					createdAt
					updatedAt
					amount
					address
					area
					active
					status
					pickup_cash
					customer_name
					customer_phone
					order_id
					brand {
						name
					}
					paidAt
					payment_transaction
					cost_breakdown
					is_express
					description
					notes {
						__typename
						... on Element {
							value
						}
					}
					weight
					delivery_charge
					delivery_type
					parcel_type
					payment_status
				}
			}
		}
	}
`;
