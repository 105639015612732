import { gql } from '@apollo/client';

export const GET_UNPAID_PARCELS_BY_BRAND = gql`
	query getUnpaidParcelsByBrand($objectId: ID!, $lt: Date!) {
		parcels(
			where: {
				brand: { have: { objectId: { equalTo: $objectId } } }
				payment_status: { equalTo: "unpaid" }
				status: { in: ["delivered", "returned", "returned_paid_by_customer"] }
				processingFinishedAt: { lessThan: $lt }
			}
			first: 500
		) {
			edges {
				node {
					brand {
						name
					}
					customer_name
					address
					status
					objectId
					deliveredAt
					delivery_charge
					amount
					pickup_cash
					order_id
					processingFinishedAt
				}
			}
		}
	}
`;

export const PAYMENT_HISTORYS_BY_BRAND = gql`
	query merchantPayments($brandId: ID!) {
		merchant_payments(
			where: { brand: { have: { objectId: { equalTo: $brandId } } } }
		) {
			edges {
				node {
					cash_collected
					createdAt
					note

					brand {
						name
						area
					}
				}
			}
		}
	}
`;
