import React, { useEffect, useState } from 'react';
import { handleWheel } from '../../helper/helper';
import {
	Name,
	WeightArrayProps,
} from '../../screens/deliveryFeeChart/DeliveryFeeChart';

interface Props {
	value: number;
	index: number;
	changeWeightRange?: ({ index, value }: WeightArrayProps) => void;
	name: Name;
}

const PriceArr: React.FC<Props> = ({
	value,
	changeWeightRange,
	index,
	name,
}) => {
	const [number, setNumber] = useState<number>(value);

	useEffect(() => {
		setNumber(value);
	}, [value]);

	return (
		<input
			className={value <= 0 ? `error` : ''}
			onWheel={handleWheel}
			type='number'
			placeholder=''
			onChange={(e) => {
				setNumber(Number(e.target.value));
				changeWeightRange &&
					changeWeightRange({ index, value: Number(e.target.value), name });
			}}
			value={number}
		/>
	);
};

export default PriceArr;
