import React from 'react';
import Grid from '@material-ui/core/Grid';

import './merchantLayout.scss';

type Md =
	| boolean
	| 2
	| 1
	| 'auto'
	| 3
	| 4
	| 5
	| 6
	| 7
	| 8
	| 9
	| 10
	| 11
	| 12
	| undefined;

type Direction =
	| 'row'
	| 'row-reverse'
	| 'column'
	| 'column-reverse'
	| undefined;

interface Props {
	title?: string;
	direction?: Direction;
	md?: Md;
	className?: string;
	comp?: JSX.Element;
}

const MerchantLayout: React.FC<Props> = ({
	title,
	children,
	md,
	direction,
	className,
	comp,
}) => {
	return (
		<Grid
			item
			xs={12}
			md={md}
			className={`merchantLayout rounded ${className}`}
		>
			<header>{comp ? comp : <h3>{title}</h3>}</header>
			<Grid className='main' direction={direction} container spacing={6}>
				{children}
			</Grid>
		</Grid>
	);
};

export default MerchantLayout;
