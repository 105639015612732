import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import './layout.scss';
import Header from '../header/Header';
import LinearProgress from '@material-ui/core/LinearProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Grid, IconButton } from '@material-ui/core';
import { scrollTop } from '../../helper/helper';

interface Props {
	className: string;
	title: string;
	subTitle?: string;
	loading: boolean;
	noBack?: boolean;
	noTitle?: boolean;
}

const Layout: React.FC<Props> = ({
	children,
	className,
	title,
	subTitle,
	loading,
	noBack,
	noTitle,
}) => {
	const { goBack } = useHistory();

	useEffect(() => {
		scrollTop();
	}, []);

	return (
		<div className={`layout ${className}`}>
			<Helmet>
				<title>Now | {title}</title>
			</Helmet>
			<Header />
			<div className='container'>
				<div className='layout__header'>
					{!noTitle && (
						<h2 className='layout__title'>
							{title} {subTitle && <span>{` - ${subTitle}`}</span>}
						</h2>
					)}
					{!noBack && (
						<IconButton onClick={goBack}>
							<ArrowBackIcon color='primary' />
						</IconButton>
					)}
				</div>
				{loading ? (
					<LinearProgress className='progress' />
				) : (
					<div>{children}</div>
				)}
			</div>
		</div>
	);
};

export default Layout;


export const KeyValue = (props: any) => {
	const { keey, value } = props
	return <>
		<Grid item container justify='space-between' className="keyValue" >
			<div>
				<h5>{keey} -</h5>
			</div>
			<div>
				<p>{value}</p>
			</div>
		</Grid>
	</>
}