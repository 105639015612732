import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useStore } from '../../context/Context';

const AuthRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
	const {
		store: { user },
	} = useStore();

	if (!Component) {
		return null;
	}

	return (
		<Route
			{...rest}
			render={(props) =>
				user ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{ pathname: '/login', state: { from: props.location } }}
					/>
				)
			}
		/>
	);
};

export default AuthRoute;
