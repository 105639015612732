import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import { formatRelative } from 'date-fns';

import { PickupReq, PickUpReqs } from '../../context/contextTypes';
import { Link } from 'react-router-dom';
import { UPDATE_PICKUP_STATUS_BY_ID } from '../../graphql/mutations/parcel';
import useMessage from '../../hooks/useMessage';
import LoadingBtn from '../typography/buttons/LoadingBtn';
import { errorMessage, PICKUP_STATUS } from '../../helper/helper';
import { SelectLabel } from '../../screens/bulkScreen/BulkScreen';
import Select from 'react-select';
import { useStore } from '../../context/Context';
import { instanceOfAxios } from '../../helper/axios';

interface Props {
	forceSet: boolean;
	length: number;
	handleSelectRequests: ({ pickUpRequestId, type }: PickupReq) => void;
	setIsSetAll: React.Dispatch<React.SetStateAction<boolean>>;
	handleDuplicate: (id: string) => void;
	pickUpReq: PickUpReqs;
	refetch: () => void;
}

const PickupTableRow: React.FC<Props> = ({
	pickUpReq: { node },
	forceSet,
	length,
	refetch,
	handleSelectRequests,
}) => {
	const [isSelected, setIsSelected] = useState(false);
	const [status, setStatus] = useState<SelectLabel | null>(null);
	const [fLoading, setFLoading] = useState<boolean>(false);
	const { handleNotification } = useMessage();

	const {
		store: { user },
	} = useStore();

	const handleChange = (
		_event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		setIsSelected(checked);

		if (checked) {
			handleSelectRequests({ pickUpRequestId: node.objectId, type: 'ADD_ONE' });
			return;
		}

		handleSelectRequests({
			pickUpRequestId: node.objectId,
			type: 'REMOVE_ONE',
		});
	};



	const fulfilPickupRequestHandler = () => {
		const headers = {
			'X-Parse-Session-Token': user?.sessionToken,
		};

		return instanceOfAxios.post(
			'/functions/fulfilPickupRequest',
			{
				pickupRequestId: node.objectId,
				received: 0,
				note: 'status updated from dashboard',
			},
			{
				headers,
			}
		);
	};

	const [updateParcelStatus, { loading, error }] = useMutation(
		UPDATE_PICKUP_STATUS_BY_ID,
		{
			variables: {
				id: node.objectId,
				status: status?.value,
			},
		}
	);

	const handleUpdateStatus = async () => {
		try {
			if (status?.value === 'fulfilled') {
				console.log('fulfil pickup req. calling cloud function.');
				setFLoading(true);
				await fulfilPickupRequestHandler();
				setFLoading(false);
			} else {
				console.log('changing pickup req status != fulfilled . calling gql.');
				await updateParcelStatus();
			}

			await refetch();

			setStatus(null);

			handleNotification({
				message: 'Pickup status updated Successfully',
				type: 'success',
				variant: 'standard',
			});
		} catch (error: any) {
			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});
		}
	};

	const pickupStatus = useMemo(
		() =>
			Object.keys(PICKUP_STATUS).map((key) => {
				return {
					label: PICKUP_STATUS[key],
					value: key,
				};
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[PICKUP_STATUS]
	);

	useEffect(() => {
		if (forceSet) {
			setIsSelected(true);
			return;
		}

		setIsSelected(false);
	}, [forceSet]);

	useEffect(() => {
		if (error) {
			handleNotification({
				message: error.message,
				type: 'error',
				variant: 'standard',
			});
		}
	}, [error]);

	const isFailed = node.status === 'failed';

	return (
		<div
			className='pickTable__bodyMain anim-card'
			style={{
				gridTemplateColumns: `repeat(${length}, 1fr)`,
				borderTop: node.parent ? 'none' : '1px solid #ddd',
				background: isFailed ? '#ffecec' : node.pickupBy ? '#DEEFFE' : '',
			}}>
			<div title='checkbox' className='pickTable__bodyCol'>
				<Checkbox
					style={{ transform: 'translateX(10px)' }}
					checked={isSelected}
					value={isSelected}
					onChange={handleChange}
					color='primary'
				/>
			</div>
			<div title='logo' className='pickTable__bodyCol'>
				<Link to={`/merchant/profile/${node.brand.objectId}`}>
					<Avatar
						src={node.brand.logo ? node.brand.logo : undefined}
						variant='circular'
						style={{ backgroundColor: '#222', cursor: 'pointer' }}>
						{node.brand.name.split('')[0]}
					</Avatar>
				</Link>
			</div>

			<Link
				target='_blank'
				to={`/admin/all-orders?brand=${node.brand.objectId}&pickup_location=${node.pickup_location}&requestedSlot=${node.requestedOn}`}
				title='name'
				className='pickTable__bodyCol'>
				<p>{node.brand.name} </p>
			</Link>
			<div title='address' className='pickTable__bodyCol'>
				<p>{node.address?.address.full_address}</p>
				<p>flat no: {node.address?.address.flat}</p>
			</div>
			<div title='address' className='pickTable__bodyCol'>
				<p>{`${new Date(node.createdAt).toLocaleDateString()} ${new Date(node.createdAt).toLocaleTimeString()}`}</p>
			</div>
			<div title='slot' className='pickTable__bodyCol'>
				<p>{formatRelative(new Date(node.requestedOn), new Date())}</p>
			</div>
			<div title='rider' className='pickTable__bodyCol '>
				<h2>{node.pickupBy ? node.pickupBy.username : '....'}</h2>
			</div>
			<div title='picked up' className='pickTable__bodyCol tag'>
				<span>{node.orders}</span>
			</div>
			<div title='picked up' className='pickTable__bodyCol tag'>
				<span>{node.received ? node.received : 'N/A'}</span>
			</div>
			<div title='phone' className='pickTable__bodyCol'>
				{node.address?.phone
					? node.address.phone
					: node.brand.contact_numbers?.map((num) => <p>{num.value}</p>)}
			</div>
			<div title='phone' className='pickTable__bodyCol'>
				<p>{node.note ? node.note : '...'}</p>
			</div>
			<div title='phone' className='pickTable__bodyCol'>
				<p>{PICKUP_STATUS[node.status]}</p>
			</div>
			<div title='phone' className='pickTable__bodyCol'>
				<Select
					menuPortalTarget={document.body}
					menuPosition={'absolute'}
					id={node.objectId}
					className='select'
					isClearable
					isSearchable
					value={status}
					onChange={(value) => setStatus(value)}
					placeholder='status'
					options={pickupStatus}
				/>
			</div>
			<div title='phone' className='pickTable__bodyCol'>
				<LoadingBtn onClick={handleUpdateStatus} disabled={loading || fLoading}>
					{loading || fLoading ? '...' : 'Update'}
				</LoadingBtn>
			</div>

			{/* <div title='add more' className='pickTable__bodyCol'>
				<IconButton
					color='primary'
					className='icon'
					onClick={() => handleDuplicate(node.objectId)}
				>
					<AddBox />
				</IconButton>
			</div> */}
		</div>
	);
};

export default PickupTableRow;
