import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'inline-block',
			alignItems: 'center',
		},
		wrapper: {
			margin: theme.spacing(1),
			position: 'relative',
		},

		buttonProgress: {
			color: green[300],
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
	})
);

const MIN = 1;

interface Props {
	click: () => void;
}

const TimerBtn: React.FC<Props> = ({ children, click }) => {
	const classes = useStyles();
	const [loading, setLoading] = React.useState(false);
	const [success, setSuccess] = React.useState(false);
	const timer = React.useRef<number>();

	React.useEffect(() => {
		startTimer();

		return () => {
			clearTimeout(timer.current);
		};
	}, []);

	const startTimer = () => {
		if (!loading) {
			setSuccess(false);
			setLoading(true);
			timer.current = window.setTimeout(() => {
				setSuccess(true);
				setLoading(false);
			}, 1000 * 60 * MIN);
		}
	};

	const handleButtonClick = () => {
		click();
		startTimer();
	};

	return (
		<div className={classes.root}>
			<div className={classes.wrapper}>
				<Button
					variant='outlined'
					color='primary'
					disabled={loading}
					onClick={handleButtonClick}
				>
					{children}
				</Button>
				{loading && (
					<CircularProgress size={24} className={classes.buttonProgress} />
				)}
			</div>
		</div>
	);
};

export default TimerBtn;
