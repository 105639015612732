import { ThemeProvider } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../theme/theme';

interface Props {
	type: 'success' | 'info' | 'warning' | 'error' | undefined;
	variant: 'outlined' | 'standard' | 'filled' | undefined;
}

const StyledAlert = styled(Alert)`
	min-width: 100%;
	border-top: 3px solid
		${({ severity }) =>
			severity === 'error'
				? 'red'
				: severity === 'warning'
				? 'orange'
				: severity === 'success'
				? 'green'
				: 'blue'};
	box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.2);
	z-index: 100;
	margin: 0 auto;
	margin-bottom: 10px;
`;

const StyledAlertTitle = styled(AlertTitle)`
	text-transform: capitalize;
`;

const BaseAlert: React.FC<Props> = ({ type, variant, children }) => {
	return (
		<ThemeProvider theme={theme}>
			<StyledAlert variant={variant} severity={type}>
				<StyledAlertTitle>{type}</StyledAlertTitle>
				<strong>{children}</strong>
			</StyledAlert>
		</ThemeProvider>
	);
};

export default BaseAlert;
