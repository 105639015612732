import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
	mutation login($username: String!, $password: String!) {
		logIn(input: { username: $username, password: $password }) {
			viewer {
				sessionToken
				user {
					type
					username
					objectId
					name
				}
			}
		}
	}
`;
