import React, { useMemo, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { AddCircle, Edit } from '@material-ui/icons';
import Select from 'react-select';

import './riders.scss';
import Layout from '../../components/layout/Layout';
import useRiders from '../../hooks/useRiders';
import TableHeader from '../../components/TableCommon/TableHeader';
import FormDalog from '../../components/formDialog/FormDialog';
import useMessage from '../../hooks/useMessage';
import { Header } from '../../components/pickupReqTable/pickup.req.table';
import { instanceOfAxios } from '../../helper/axios';
import { useStore } from '../../context/Context';
import { errorMessage } from '../../helper/helper';
import { Checkbox } from '@material-ui/core';



const Riders: React.FC = () => {
	const [open, setOpen] = useState(false);
	// const [addLoading, setAddLoading] = useState(false);
	const initialData = { username: "", name: "", password: "", type: "" }
	const [formData, setFormData]: any = useState(initialData)
	const { store: { user } } = useStore();
	const { handleNotification } = useMessage();
	const headers: Header[] = [{ field: 'Name' }, { field: 'Take Action' }, { field: 'Edit' }];
	const { ridersLoading, ridersData, refetch, active, setActive } = useRiders('rider');
	const [submit, setSubmit] = useState('create')

	// console.log(ridersData)


	const handleChange = (e: any) => {
		setFormData(e.target.type === 'number' ? { ...formData, [e.target.name]: Number(e.target.value) } : { ...formData, [e.target.name]: e.target.value })
	}


	const updateRiderStatus = async (data: {
		userId: string;
		active: boolean;
	}) => {
		try {
			// console.log(data)
			await instanceOfAxios.post(`/functions/changeUserStatus`, data, {
				headers: {
					'x-parse-session-token': user?.sessionToken,
				},
			});

			await refetch();

			handleNotification({
				variant: 'standard',
				type: 'success',
				message: 'Success',
			});
		} catch (error: any) {
			handleNotification({
				variant: 'standard',
				message: errorMessage({ error }),
				type: 'error',
			});
		}
	};

	const handleEditRider = (e: any) => {

		// console.log(e)
		setOpen(true)
		setFormData({ username: e.username, name: e.name, userId: e.objectId, phone: e.phone })
	}


	const profileTypes = useMemo(() => {
		return [
			{ label: 'Rider', value: 'rider' },
			{ label: 'Pickup-rider', value: 'pickup-rider' },
			{ label: 'Pickup-manager', value: 'pickup-manager' },
			{ label: 'Admin', value: 'admin' },
			{ label: 'Accounts', value: 'accounts' },
		];
	}, []);


	const handleSubmit = async (e: any) => {
		e.preventDefault()
		// console.log(formData)
		if (submit === 'edit') {
			// {...}
			try {
				await instanceOfAxios.post(`/functions/updateUser`, formData, {
					headers: {
						'x-parse-session-token': user?.sessionToken,
						// 'X-Parse-MASTER-KEY': 'master'
					},
				});

				await refetch();

				handleNotification({
					variant: 'standard',
					type: 'success',
					message: 'Success',
				});
			} catch (error) {
				console.log(error)
			}
		}

		if (submit === 'create') {

			try {
				await instanceOfAxios.post(`/functions/createNewUser`, formData, {
					headers: {
						'x-parse-session-token': user?.sessionToken,
					},
				});

				handleNotification({
					variant: 'standard',
					type: 'success',
					message: 'Success',
				});

				// setAddLoading(false);
			} catch (error: any) {
				// setAddLoading(false);

				console.log(error)

				handleNotification({
					variant: 'standard',
					message: errorMessage({ error }),
					type: 'error',
				});
			}
		}

		await refetch();
		setOpen(false);
	}




	return (
		<Layout loading={ridersLoading} title='All Riders' className='riders'>
			<div className='allOrders__container rounded shadow-lg anim-frame'>
				<FormDalog open={open} setOpen={setOpen} setFormData={setFormData} >
					<form onSubmit={handleSubmit} style={{ width: '350px' }} >
						<TextField
							required
							fullWidth
							variant='outlined'
							name='username'
							label='Username'
							placeholder='Faisal'
							onChange={handleChange}
							value={formData?.username}
						/>
						<br />
						<br />
						<TextField
							// required
							fullWidth
							variant='outlined'
							name='name'
							label='Name'
							placeholder='Anit fat'
							onChange={handleChange}
							value={formData?.name}
						/>
						<br />
						<br />
						<TextField
							fullWidth
							variant='outlined'
							name='phone'
							label='Phone'
							placeholder='017********'
							onChange={handleChange}
							value={formData?.phone}
						/>
						<br />
						<br />
						<TextField
							fullWidth
							variant='outlined'
							name='password'
							label='Password'
							placeholder='*****'
							type="password"
							onChange={handleChange}
						/>
						<br />
						<br />
						{submit === 'create' && (
							<div className='select'>
								<Select
									placeholder='Type'
									options={profileTypes}
									onChange={(e: any) => setFormData({ ...formData, type: e.value })}
									inputProps={{}}
								/>
							</div>
						)}
						<Button
							startIcon={<AddCircle />}
							fullWidth
							type='submit'
							size='large'
							variant='contained'
							color='primary'>
							{submit === 'create' ? 'Add user' : 'edit user'}
						</Button>
					</form>

				</FormDalog>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginBottom: 10,
					}}>
					<Button
						startIcon={<AddCircle />}
						onClick={() => {
							setOpen(true)
							setSubmit('create')
						}}
						variant='outlined'>
						Add Rider
					</Button>
					<div>
						<Checkbox
							color='primary'
							checked={active}
							onChange={(_e, checked) => setActive(checked)}
						/>
						<span>Active Riders</span>
					</div>
				</div>
				<div className='overflow'>
					<div className='allOrders__table'>
						<div
							className='allOrders__header'
							style={{ gridTemplateColumns: `repeat(${headers.length}, 1fr)` }}>
							{headers.map(({ field }, i) => (
								<TableHeader key={i} field={field} />
							))}
						</div>
						{ridersData.map((rider, i) => (
							<div
								key={rider.objectId}
								className='allOrders__body'
								style={{
									gridTemplateColumns: `repeat(${headers.length}, 1fr)`,
								}}>
								<Link
									to={`/rider/${rider.objectId}?name=${rider.username}`}
									className='allOrders__bodyCol'>
									<h4 className='text-md'>
										{i + 1}
										{'. '}
										{rider.username.toUpperCase()}
									</h4>
								</Link>

								<div className="className='allOrders__bodyCol'">
									<Checkbox
										color='primary'
										checked={rider.active}
										onChange={(_e, checked) =>
											updateRiderStatus({
												active: checked,
												userId: rider.objectId,
											})
										}
									/>
									<span>Active</span>
								</div>
								<div>
									<div>
										<Button
											className="editBtn"
											onClick={() => {
												handleEditRider(rider)
												setSubmit('edit')
											}}
											startIcon={<Edit />}
											variant="contained"
											color="primary"
										>Edit</Button>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Riders;
