/* eslint-disable */
import React, { useEffect, useMemo, useState, useRef } from "react";
import Select from "react-select";
import {
  Grid,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  FormControl,
  RadioGroup,
  Radio,
  Button,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import _ from "lodash";

import "./createParcel.scss";
import Layout from "../../components/layout/Layout";
import CustomInput from "../../components/customInput/CustomInput";
import useParcel, { Data, Parcel } from "../../hooks/useParcel";
import { MOBILE_REGEX_BD } from "../../helper/regex";
import MerchantLayout from "../../components/layout/MerchantLayout";
import { format } from "date-fns";
import {
  hasChanged,
  toParseDate,
  cutOfData,
  PARCEL_STATUS,
  getNameOrUsername,
} from "../../helper/helper";
import { cities } from "../../docs/city";
import { useStore } from "../../context/Context";
import { useHistory } from "react-router";
import { SelectLabel } from "../bulkScreen/BulkScreen";
import useAddress from "../../hooks/useAddress";
import { TextField } from "@material-ui/core";
import useMessage from "../../hooks/useMessage";
import { instanceOfAxios } from "../../helper/axios";

const CreateParcel: React.FC = () => {
  const matches = useMediaQuery("(min-width:960px)");
  const lolRef = useRef<HTMLDivElement>(null);
  const { meta, lat, long, handleAddress } = useAddress();
  const { location } = useHistory();
  const query = new URLSearchParams(location.search);
  const userType = query.get("edit");
  const id = query.get("id");

  const {
    store: { user, editOrderDetails: details, brand, store },
    dispatch,
  } = useStore();

  const { handleNotification } = useMessage();

  const {
    loading,
    eiditLoading,
    slotSelect,
    slot,
    delLoading,
    timeMaker,
    timeMakerAdmin,
    checkTypeDisabled,
    updateEstimation,
    createOrUpdateParcel,
    editFetch,
    setSlotSelect,
    setSlot,
    handleSlot,
    updateDeliveryCharge,
  } = useParcel();

  const canEditAdmin = user?.user.type !== "merchant";

  const [disabled, setDisabled] = useState(true);

  const [toggle, setToggle] = useState(false);

  const [isEditable, setIsEditable] = useState(false);
  const [editableID, setEditableID] = useState("");

  const [orderId, setOrderId] = useState("");
  const [orderIdOld, setOrderIdOld] = useState("");
  const [orderIdDis, setOrderIdDis] = useState(true);

  const [deliveryCharge, setDeliveryCharge] = useState("");
  const [cod, setCod] = useState("");

  const [respName, setRespName] = useState("");
  const [respNameOld, setRespNameOld] = useState("");
  const [respNameDis, setRespNameDis] = useState(true);

  const [respPhone, setRespPhone] = useState("");
  const [respPhoneOld, setRespPhoneOld] = useState("");
  const [respPhoneDis, setRespPhoneDis] = useState(true);

  const [respAddress, setRespAddress] = useState("");
  const [respAddressOld, setRespAddressOld] = useState("");
  const [respAddressDis, setRespAddressDis] = useState(true);

  const [isOutside, setIsOutside] = useState(false);

  const [productDesc, setProductDesc] = useState("");
  const [productDescOld, setProductDescOld] = useState("");
  const [productDescDis, setProductDescDis] = useState(true);

  const [amountToCol, setAmountToCol] = useState("");
  const [amountToColOld, setAmountToColOld] = useState("");
  const [amountToColDis, setAmountToColDis] = useState(true);

  const [note, setNote] = useState("");
  const [noteDis, setNoteDis] = useState(true);

  const [delType, setDelType] = useState("");
  const [delTypeOld, setDelTypeOld] = useState("regular");

  const [estm, setEstm] = useState<Data | null>(null);

  const [areaSelect, setAreaSelect] = useState<SelectLabel | null>(null);
  const [areaSelectOld, setAreaSelectOld] = useState<SelectLabel | null>(null);

  const [zoneSelect, setZoneSelect] = useState<SelectLabel | null>(null);
  const [zoneSelectOld, setZoneSelectOld] = useState<SelectLabel | null>(null);

  const [slotOld, setSlotOld] = useState<string | null | undefined>(null);

  const [status, setStatus] = useState<SelectLabel | null>(null);
  const [statusOld, setStatusOld] = useState<SelectLabel | null>(null);

  const [weight, setWeight] = useState<string | null | undefined>(null);
  const [weightOld, setWeightOld] = useState<string | null | undefined>(null);
  const [weightSelect, setWeightSelect] = useState<SelectLabel | null>(null);

  const [fragOrLiq, setFragOrLiq] = useState<null | string>("parcel");
  const [fragOrLiqOld, setFragOrLiqOld] = useState<null | string>("parcel");

  const [paymentOpt, setPaymentOpt] = useState<string>("cash_on_delivery");
  const [paymentOptOld, setPaymentOptOld] =
    useState<string>("cash_on_delivery");

  const [deliverySettings, setDeliverySettings] = useState<any>({});

  const parcelStatus = useMemo(() => {
    return Object.keys(PARCEL_STATUS).map((key) => {
      return {
        label: PARCEL_STATUS[key],
        value: key,
      };
    });
  }, []);

  const areas = useMemo(() => {
    if (zoneSelect) {
      return cities.filter((item) => item.district === zoneSelect.value);
    }

    return cities.filter((item) => item.district !== "Dhaka");
  }, [zoneSelect]);

  // no scroll

  useEffect(() => {
    const onScroll = (e: Event) => {
      if (!lolRef.current) return;

      const pageOffset = window.pageYOffset;

      const divHeight = Math.ceil(lolRef.current.offsetHeight / 1.5);

      const OFF_SET_Y = divHeight;

      const cantScroll = pageOffset >= OFF_SET_Y && matches;

      if (cantScroll) {
        window.scrollTo({
          top: OFF_SET_Y + 1,
        });
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [matches]);

  // if search

  useEffect(() => {
    const common = userType === "admin" || userType === "pickup-manager";

    const canMerchantEdit = userType === "merchant";

    const editable = common || canMerchantEdit;

    if (editable && id) {
      setIsEditable(true);
      setEditableID(id);
      editFetch({ id });
    }
  }, [id, userType]);

  useEffect(() => {
    const type = Object.keys(checkTypeDisabled).find(
      (d) => checkTypeDisabled[d]
    );

    if (type) {
      setDelType("");
    }
  }, [checkTypeDisabled]);

  // edit logic

  useEffect(() => {
    if (!id) {
      handleSlot();
      setIsEditable(false);
      setRespName("");
      setRespAddress("");
      setRespPhone("");
      setAreaSelectOld(null);
      setAreaSelect(null);
      setZoneSelect(null);
      setZoneSelectOld(null);
      setIsOutside(false);
      setOrderId("");
      // setDelType("express");
      setFragOrLiq("parcel");

      setAmountToCol("");
      setPaymentOpt("cash_on_delivery");

      setWeightSelect(null);

      setWeight(null);

      setProductDesc("");

      setStatus(null);

      dispatch({ type: "REMOVE_EDIT_ORDER", payload: null });

      return;
    }

    if (details) {
      const isOutsideDhaka = details.zone !== "dhaka";

      // will update

      setRespName(details.customer_name);
      setRespAddress(details.address);
      setRespPhone(details.customer_phone);
      setAreaSelectOld({
        value: details.area,
        label: details.area,
      });
      setAreaSelect({ label: details.area, value: details.area });
      setZoneSelect({ label: details.zone, value: details.zone });
      setZoneSelectOld({ label: details.zone, value: details.zone });
      setIsOutside(isOutsideDhaka);
      setOrderId(details.moid);
      setDelType(details.delivery_type);
      setFragOrLiq(details.parcel_type);

      setSlot(details.requestedSlot.iso);

      setSlotSelect({
        value: new Date(details.requestedSlot.iso),
        label: `${format(new Date(details.requestedSlot.iso), "do LLLL p")}`,
      });

      setAmountToCol(`${details.amount}`);
      setPaymentOpt(
        details.pickup_cash ? "cash_on_delivery" : "paid_via_online"
      );

      setWeightSelect({
        label: `${details.weight - 1}-${details.weight}`,
        value: `${details.weight}`,
      });

      setWeight(`${details.weight}`);

      setProductDesc(details.description);

      setStatus({
        label: PARCEL_STATUS[details.status],
        value: details.status,
      });

      if (details.delivery_charge && details.cost_breakdown) {
        setDeliveryCharge(details.delivery_charge.toString());
        setCod(details.cost_breakdown.cod.toString());
      }

      // old

      setRespNameOld(details.customer_name);
      setRespAddressOld(details.address);
      setRespPhoneOld(details.customer_phone);
      setAreaSelectOld({ label: details.area, value: details.area });
      setOrderIdOld(details.moid);
      setDelTypeOld(details.delivery_type);
      setFragOrLiqOld(details.parcel_type);

      setSlotOld(details.requestedSlot.iso);
      setAmountToColOld(`${details.amount}`);
      setPaymentOptOld(
        details.pickup_cash ? "cash_on_delivery" : "paid_via_online"
      );

      setWeightOld(`${details.weight}`);

      setProductDescOld(details.description);
      setStatusOld({
        label: PARCEL_STATUS[details.status],
        value: details.status,
      });
    }
  }, [details, id]);

  console.log(details);

  useEffect(() => {
    const isEstimateAvailable =
      paymentOpt && weight && delType && fragOrLiq && amountToCol;

    // if (details) {
    //   paymentOpt === "paid_via_online"
    //     ? setAmountToCol("0")
    //     : setAmountToCol(`${details?.amount}`);
    // }

    if (isEstimateAvailable) {
      (async () => {
        const data = await updateEstimation({
          weight: Number(weight),
          delivery_type: delType,
          zone: isOutside ? "outside_dhaka" : "dhaka",
          pickup_cash: paymentOpt === "cash_on_delivery" ? true : false,
          amount: Number(amountToCol),
          parcel_type: fragOrLiq as string,
          brandId: details
            ? details.brand.objectId
            : brand
            ? brand.node.objectId
            : null,
        });

        setEstm(data);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isOutside,
    delType,
    fragOrLiq,
    amountToCol,
    paymentOpt,
    weight,
    brand,
    details,
  ]);

  const hadleDeliveryChargeUpdate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const net = +deliveryCharge + +cod;

    updateDeliveryCharge({
      variables: {
        id,
        delivery_charge: net,
        cost_breakdown: {
          total: net,
          cod: +cod,
          delivery_charge: +deliveryCharge,
        },
      },
    })
      .then(() => {
        handleNotification({
          variant: "standard",
          message: "Delivery charge Updated Successfully",
          type: "success",
        });
      })
      .catch((e) => {
        handleNotification({
          variant: "standard",
          message: e.message,
          type: "error",
        });
      });
  };

  const handleCreateOrUpdate = () => {
    let data = {};

    const is_express = isOutside ? false : delType === "express" ? true : false;

    const pickup_cash = paymentOpt === "cash_on_delivery" ? true : false;

    data = {
      ...data,
      ...cutOfData([
        { new: respName, old: respNameOld, key: "customer_name" },
        { new: respPhone, old: respPhoneOld, key: "customer_phone" },
        { new: respAddress, old: respAddressOld, key: "address" },
        {
          new: Number(amountToCol),
          old: Number(amountToColOld),
          key: "amount",
        },
        { new: Number(weight), old: Number(weightOld), key: "weight" },
        { new: orderId as string, old: orderIdOld as string, key: "moid" },
        {
          new: fragOrLiq as string,
          old: fragOrLiqOld as string,
          key: "parcel_type",
        },
        {
          new: productDesc,
          old: productDescOld,
          key: "description",
        },
        {
          new: areaSelect?.value as string,
          old: areaSelectOld?.value as string,
          key: "area",
        },
        {
          new: status?.value as string,
          old: statusOld?.value as string,
          key: "status",
        },
      ]),
    };

    if (hasChanged({ new: delType, old: delTypeOld })) {
      data = { ...data, is_express };
    }

    if (hasChanged({ new: paymentOpt, old: paymentOptOld })) {
      data = { ...data, pickup_cash };
    }

    if (
      hasChanged({
        new: JSON.stringify(slot),
        old: JSON.stringify(slotOld),
      })
    ) {
      data = {
        ...data,
        pickup_cash,
        requestedSlot: toParseDate(slot as string),
      };
    }

    if (lat && long) {
      data = {
        ...data,
        geolocation: { latitude: lat, longitude: long, __type: "GeoPoint" },
      };
    }

    if (meta) {
      data = {
        ...data,
        meta,
      };
    }

    if (store && user?.user.type === "merchant") {
      data = {
        ...data,
        pickup_location: store.value._id,
      };
    }

    if (note && details?.notes) {
      data = {
        ...data,
        notes: [
          ...details.notes,
          {
            by: `${user?.user && getNameOrUsername(user.user)} (${
              user?.user.type
            })`,
            text: note,
          },
        ],
      };
    }

    if (
      hasChanged({
        new: delType,
        old: delTypeOld,
      })
    ) {
      data = {
        ...data,
        delivery_type: isOutside ? "outside_dhaka" : delType,
      };
    }

    const zone = isOutside ? (zoneSelect?.value as string) : "dhaka"; // to fix

    if (
      hasChanged({
        new: JSON.stringify(zoneSelect),
        old: JSON.stringify(zoneSelectOld),
      })
    ) {
      data = {
        ...data,
        zone,
      };
    }

    // ALWYOS GET UPDATED CHARGE
    data = { ...data, delivery_type: isOutside ? "outside_dhaka" : delType };

    // update or create

    if (isEditable) {
      createOrUpdateParcel({ ...data } as Parcel, isEditable, editableID);
      return;
    }

    {
      const delivery_type = isOutside ? "outside_dhaka" : delType;

      let data: Parcel = {
        amount: Number(amountToCol),
        address: respAddress,
        customer_name: respName,
        is_express,
        weight: Number(weight),
        customer_phone: respPhone,
        pickup_cash,
        zone,
        parcel_type: fragOrLiq as string,
        requestedSlot: toParseDate(slot as string),
        moid: orderId,
        description: productDesc,
        area: areaSelect?.value as string,
        delivery_type,
      };

      if (note) {
        data = {
          ...data,
          notes: [
            { by: `${user?.user.username} (${user?.user.type})`, text: note },
          ],
        };
      }

      if (lat && long) {
        data = {
          ...data,
          geolocation: { latitude: lat, longitude: long, __type: "GeoPoint" },
        };
      }

      if (meta) {
        data = {
          ...data,
          meta,
        };
      }

      if (store) {
        data = {
          ...data,
          pickup_location: store.value._id,
        };
      }

      createOrUpdateParcel(data, isEditable, editableID);
    }
  };

  // validation effect
  useEffect(() => {
    const isValid =
      !orderIdDis &&
      !respNameDis &&
      !respPhoneDis &&
      !respAddressDis &&
      !productDescDis &&
      !amountToColDis &&
      !noteDis &&
      areaSelect &&
      slot &&
      weight &&
      fragOrLiq &&
      delType;

    if (isValid) {
      setDisabled(false);
    }
    if (!areaSelect) {
      setDisabled(true);
    }
  }, [
    orderIdDis,
    respNameDis,
    respPhoneDis,
    respAddressDis,
    productDescDis,
    amountToColDis,
    noteDis,
    areaSelect,
    slot,
    weight,
    fragOrLiq,
    delType,
  ]);

  // useEffect(() => {
  //   if (!isOutside) {
  //     setDelType("express");
  //   }
  // }, [isOutside]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await instanceOfAxios.get(
          `/classes/config?where={"key":"deliverySettings"}`
        );
        console.log({ data });
        setDeliverySettings(data.results[0].value);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const isWhitelisted = localStorage.getItem("brand")
    ? JSON.parse(localStorage.getItem("brand") as any).node.whitelisted
    : false;
  // console.log({ isWhitelisted });
  // console.log({ deliverySettings });

  return (
    <Layout
      className="createParcel"
      title={isEditable ? "Update Parcel" : `New Parcel`}
      noTitle
      noBack
      loading={eiditLoading}
    >
      <Grid
        justify="center"
        alignItems="flex-start"
        container
        className="createParcel__main"
      >
        <MerchantLayout
          className="fields"
          md={8}
          title={isEditable ? "Update Parcel" : `New Parcel`}
        >
          <Grid innerRef={lolRef} item xs={12} md={6} className="section">
            {canEditAdmin ? (
              <>
                <div className="group">
                  <h2>Change Delivery charge:</h2>
                  <form className="grid" onSubmit={hadleDeliveryChargeUpdate}>
                    <TextField
                      label="Delivery Charge"
                      variant="outlined"
                      value={deliveryCharge}
                      onChange={(e) => setDeliveryCharge(e.target.value)}
                    />
                    <TextField
                      label="COD"
                      placeholder="COD"
                      variant="outlined"
                      value={cod}
                      onChange={(e) => setCod(e.target.value)}
                    />
                    <Button
                      disabled={delLoading}
                      type="submit"
                      variant="outlined"
                      color="primary"
                    >
                      Update
                    </Button>
                  </form>
                </div>
                <h2>Change Status</h2>
                <div className="select">
                  <Select
                    onChange={(value) => {
                      setStatus(value);
                    }}
                    isSearchable
                    placeholder="Parcel status"
                    value={status}
                    options={parcelStatus}
                  />
                </div>
              </>
            ) : (
              <>
                <h2>Select Pickup Point</h2>
                <div className="select">
                  <Select
                    isDisabled={user?.user.type !== "merchant"}
                    onChange={(value) => {
                      dispatch({
                        type: "ADD_STORE",
                        payload: value ? value.value : null,
                      });
                    }}
                    isSearchable
                    placeholder="Pickup point"
                    value={
                      store
                        ? {
                            label: `${store.value.name} (${store.value.address.full_address})`,
                            value: store,
                          }
                        : null
                    }
                    options={brand?.node.pickup_locations?.map(
                      ({ value, __typename }) => {
                        return {
                          value: {
                            value,
                            __typename,
                          },
                          label: `${value.name} (${value.address.full_address})`,
                        };
                      }
                    )}
                  />
                </div>
              </>
            )}
            <h2>Customer's Details</h2>
            <CustomInput
              focused
              label="Customer's name"
              placeholder="....."
              regex={/^.{2,}/gi}
              helperTxt={"should be at least 2 charecters long"}
              value={respName}
              setValue={setRespName}
              setDisabled={setRespNameDis}
              type="text"
              fullWidth
            />
            <CustomInput
              focused
              label="Customer's address"
              placeholder="Flat, Apartment name, Road, Block, Area"
              regex={/^.{2,}/gi}
              helperTxt={"should be at least 2 charecters long"}
              value={respAddress}
              setValue={setRespAddress}
              setDisabled={setRespAddressDis}
              onBlur={handleAddress}
              type="text"
              fullWidth
              multiline
              rowSpan={5}
            />
            <CustomInput
              focused
              label="Customer's phone"
              placeholder="017.."
              regex={MOBILE_REGEX_BD}
              helperTxt={"please select a valid number (eg. 017..)"}
              value={respPhone}
              setValue={setRespPhone}
              setDisabled={setRespPhoneDis}
              type="text"
              fullWidth
            />{" "}
            <div className="checkbox">
              <FormControlLabel
                // disabled={checkTypeDisabled.outside_dhaka}
                onChange={(_event, checked) => {
                  setZoneSelect(null);
                  setAreaSelect(null);
                  setIsOutside(checked);
                }}
                name="outside"
                checked={isOutside}
                value={isOutside}
                control={<Checkbox color="primary" />}
                label="Outside Dhaka"
                labelPlacement="end"
                disabled={!isWhitelisted && !deliverySettings?.outsideDhaka}
              />
            </div>
            {isOutside ? (
              <>
                <div className="select">
                  <Select
                    onChange={(value) => setZoneSelect(value)}
                    isClearable
                    isSearchable
                    placeholder="City"
                    value={zoneSelect}
                    options={_.uniqBy(
                      cities
                        .filter((city) => city.district !== "Dhaka")
                        .map((value) => {
                          return {
                            label: value.district,
                            value: value.district,
                          };
                        }),
                      "label"
                    )}
                  />
                </div>
                <div className="select">
                  <Select
                    onChange={(value) => setAreaSelect(value)}
                    value={areaSelect}
                    isClearable
                    isSearchable
                    placeholder="Zone"
                    options={areas.map((value) => {
                      return {
                        label: value.area,
                        value: value.area,
                      };
                    })}
                  />
                </div>
              </>
            ) : (
              <div className="select">
                <Select
                  onChange={(value) => setAreaSelect(value)}
                  value={areaSelect}
                  isClearable
                  isSearchable
                  placeholder="Area"
                  options={cities
                    .filter((city) => city.district === "Dhaka")
                    .map((value) => {
                      return {
                        label: value.area,
                        value: value.area,
                      };
                    })}
                />
              </div>
            )}
            <h2>Merchant: </h2>
            <div className="section__main">
              <CustomInput
                label="Merchant order id"
                placeholder="order no 1"
                regex={/.*/gi}
                helperTxt={"should be at least 4 charecters long"}
                value={orderId}
                setValue={setOrderId}
                setDisabled={setOrderIdDis}
                type="text"
                fullWidth
              />

              <div />
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="section">
            <div className="group">
              <h2>Delivery Information:</h2>
              <div className="checkbox">
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="fragOrLiq"
                    name="fragOrLiq"
                    value={fragOrLiq}
                    onChange={(e) => setFragOrLiq(e.target.value)}
                  >
                    <Grid>
                      <FormControlLabel
                        value="parcel"
                        control={<Radio color="primary" />}
                        label="Parcel"
                      />
                      {!isOutside && (
                        <FormControlLabel
                          value="fragile"
                          control={<Radio color="primary" />}
                          label="Fragile"
                        />
                      )}
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="group">
              <h4>Pickup Slot:</h4>
              <div className="select">
                <Select
                  onChange={(value) => {
                    setSlot(value?.value.toISOString());
                    setSlotSelect(value);
                  }}
                  isClearable
                  isSearchable
                  placeholder="Pickup Slot"
                  value={slotSelect}
                  options={canEditAdmin ? timeMakerAdmin : timeMaker}
                />
              </div>
            </div>

            <div className="group ">
              <h4>Delivery Type</h4>
              <div className=" rounded checkbox">
                <FormControl disabled={isOutside} component="fieldset">
                  <RadioGroup
                    aria-label="fragOrLiq"
                    name="fragOrLiq"
                    value={delType}
                    onChange={(e) => setDelType(e.target.value)}
                  >
                    <Grid>
                      <FormControlLabel
                        value="express"
                        control={<Radio color="primary" />}
                        label="Express"
                        disabled={!isWhitelisted && !deliverySettings?.express}
                      />
                      <FormControlLabel
                        value="dhaka_24hrs"
                        control={<Radio color="primary" />}
                        label="24 hr"
                        disabled={!isWhitelisted && !deliverySettings?.["24h"]}
                      />
                      <FormControlLabel
                        value="regular"
                        control={<Radio color="primary" />}
                        label="48 hr"
                        disabled={!isWhitelisted && !deliverySettings?.["48h"]}
                      />
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <CustomInput
              focused
              label="Amount (delivery charge included)"
              placeholder="৳ ...."
              regex={/^[0-9]\d*$/}
              helperTxt={"amount is required"}
              value={paymentOpt === "paid_via_online" ? "0" : amountToCol}
              setValue={(value: any) => {
                value.match(/^[0-9]\d*$/)
                  ? setAmountToCol(value)
                  : setAmountToCol(amountToCol);
              }}
              setDisabled={setAmountToColDis}
              type="text"
              fullWidth
            />

            <div className="checkbox">
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="paymentOpt"
                  name="paymentOpt"
                  value={paymentOpt}
                  onChange={(e) => {
                    setPaymentOpt(e.target.value);
                  }}
                >
                  <Grid>
                    <FormControlLabel
                      value="cash_on_delivery"
                      control={<Radio color="primary" />}
                      label="Cash on delivery"
                    />
                    <FormControlLabel
                      value="paid_via_online"
                      control={<Radio color="primary" />}
                      label="Already Paid"
                    />
                  </Grid>
                </RadioGroup>
              </FormControl>
            </div>

            <div className="select">
              <Select
                onChange={(value) => {
                  setWeightSelect(value);
                  setWeight(value?.value);
                }}
                isClearable
                isSearchable
                value={weightSelect}
                placeholder="Package weight (KG)"
                options={[
                  { label: "0-1", value: "1" },
                  { label: "1-2", value: "2" },
                  { label: "2-3", value: "3" },
                  { label: "3-4", value: "4" },
                  { label: "4-5", value: "5" },
                  { label: "5-6", value: "6" },
                  { label: "6-7", value: "7" },
                  { label: "7-8", value: "8" },
                  { label: "8-9", value: "9" },
                  { label: "9-10", value: "10" },
                  { label: "10-11", value: "11" },
                  { label: "11-12", value: "12" },
                  { label: "12-13", value: "13" },
                  { label: "13-14", value: "14" },
                  { label: "14-15", value: "15" },
                  { label: "15-16", value: "16" },
                  { label: "16-17", value: "17" },
                  { label: "17-18", value: "18" },
                  { label: "18-19", value: "19" },
                  { label: "19-20", value: "20" },
                ]}
              />
            </div>

            <CustomInput
              label="Product Description"
              placeholder="black mirror, smooth and silcky."
              regex={/.*/gi}
              helperTxt={"should be at least 4 charecters long"}
              value={productDesc}
              setValue={setProductDesc}
              setDisabled={setProductDescDis}
              type="text"
              fullWidth
              multiline
              rowSpan={5}
            />
            <CustomInput
              label="Special Note"
              placeholder="careful."
              regex={/.*/gi}
              helperTxt={"should be at least 4 charecters long"}
              value={note}
              setValue={setNote}
              setDisabled={setNoteDis}
              type="text"
              fullWidth
              multiline
              rowSpan={5}
            />
            <Button
              fullWidth
              onClick={handleCreateOrUpdate}
              disabled={loading || disabled}
              size="large"
              variant="contained"
              color="primary"
            >
              {loading
                ? "Loading..."
                : isEditable
                ? "Update Parcel"
                : "Confirm Parcel"}
            </Button>
          </Grid>
        </MerchantLayout>
        <MerchantLayout
          md
          title="Estimated Delivery Charge"
          className={`stick ${toggle ? "open-calc" : ""} ${
            estm ? "active" : ""
          }`}
        >
          <button className="toggle" onClick={() => setToggle((old) => !old)}>
            ৳
          </button>

          <Grid item xs={12} className="calculator ">
            <div className="estimation">
              <h4>Delivery Fee</h4>
              <h4>
                TK.{" "}
                {loading ? (
                  <Skeleton
                    width={20}
                    variant="text"
                    animation="wave"
                    style={{
                      display: "inline-block",
                      transform: "translateY(5px)",
                    }}
                  />
                ) : estm ? (
                  estm.result.cost.delivery_charge
                ) : (
                  0
                )}
              </h4>
            </div>
            <div className="estimation">
              <h4>COD</h4>
              <h4>
                TK.{" "}
                {loading ? (
                  <Skeleton
                    width={20}
                    variant="text"
                    animation="wave"
                    style={{
                      display: "inline-block",
                      transform: "translateY(5px)",
                    }}
                  />
                ) : estm ? (
                  estm.result.cost.cod
                ) : (
                  0
                )}
              </h4>
            </div>
            <div className="estimation total">
              <h4>Total Amount</h4>
              <h4>
                TK.{" "}
                {loading ? (
                  <Skeleton
                    width={20}
                    variant="text"
                    animation="wave"
                    style={{
                      display: "inline-block",
                      transform: "translateY(5px)",
                    }}
                  />
                ) : estm ? (
                  estm.result.cost.total
                ) : (
                  0
                )}
              </h4>
            </div>
          </Grid>

          {matches && (
            <Grid item xs={12}>
              <ul>
                <li>The last time to request a Pick up for is 3 pm</li>
                <li>For Inside Dhaka parcel cancel charge is free.</li>
                <li>
                  For the Outside Dhaka return parcel, an additional 65 tk will
                  be charged with the delivery charge.
                </li>
              </ul>
            </Grid>
          )}
        </MerchantLayout>
      </Grid>
    </Layout>
  );
};

export default CreateParcel;
