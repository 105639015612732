import React from 'react';

const useModal = () => {
	const [open, setOpen] = React.useState(false);

	const toggle = () => setOpen((old) => !old);

	const closeModal = (e: React.MouseEvent<HTMLDivElement>) => {
		const isClosable = e.currentTarget === e.target;

		if (isClosable) toggle();
	};

	return { toggle, open, closeModal };
};

export default useModal;
