import { gql } from '@apollo/client';

export const DELETE_PARCEL_BY_ID = gql`
	mutation deleteParcel($id: ID!) {
		deleteParcel(input: { id: $id }) {
			parcel {
				objectId
			}
		}
	}
`;

export const UPDATE_PARCEL_DELIVERY_CHARGE_BY_ID = gql`
	mutation updateParcelDeliveryCharge(
		$id: ID!
		$delivery_charge: Float!
		$cost_breakdown: Object!
	) {
		updateParcel(
			input: {
				id: $id
				fields: {
					delivery_charge: $delivery_charge
					cost_breakdown: $cost_breakdown
				}
			}
		) {
			parcel {
				objectId
			}
		}
	}
`;

export const UPDATE_RETURN_TRACKING = gql`
	mutation updateParcelDeliveryCharge($id: ID!, $obj: Object!) {
		updateParcel(input: { id: $id, fields: { return_tracking: $obj } }) {
			parcel {
				objectId
			}
		}
	}
`;

export const UPDATE_PICKUP_STATUS_BY_ID = gql`
	mutation changePickupStatus($id: ID!, $status: String!) {
		updatePickup_request(input: { id: $id, fields: { status: $status } }) {
			pickup_request {
				id
			}
		}
	}
`;
